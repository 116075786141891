export function StarIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Star 1"
        d="M6.28671 1.19529C6.51122 0.504303 7.48878 0.504305 7.71329 1.19529L8.62773 4.00963C8.72813 4.31864 9.0161 4.52786 9.34102 4.52786H12.3002C13.0267 4.52786 13.3288 5.45758 12.741 5.88463L10.347 7.62398C10.0841 7.81497 9.97415 8.15349 10.0746 8.46251L10.989 11.2768C11.2135 11.9678 10.4226 12.5424 9.83486 12.1154L7.44084 10.376C7.17797 10.185 6.82203 10.185 6.55916 10.376L4.16514 12.1154C3.57735 12.5424 2.7865 11.9678 3.01101 11.2768L3.92544 8.46251C4.02585 8.15349 3.91586 7.81497 3.65299 7.62398L1.25897 5.88463C0.671185 5.45757 0.973268 4.52786 1.69981 4.52786H4.65898C4.9839 4.52786 5.27187 4.31864 5.37227 4.00963L6.28671 1.19529Z"
        fill="#FFCF53"
      />
    </svg>
  );
}
