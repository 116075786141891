import { useState, useRef } from 'react';
import {
  TickIcon,
  EditIcon,
  ListIcon,
  FilledDownChevron,
} from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import { INPUT_TYPE_OPTIONS, REGULAR_INPUT } from 'ui/constants/rules';

interface Props {
  selectedValue: string;
  selectValue: (value: string) => void;
}

export default function ChooseInputDropdown({
  selectedValue,
  selectValue,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setIsOpen, ref, dropdownRef);

  function select(value: string) {
    selectValue(value);
    setIsOpen(false);
  }

  function renderOptions() {
    return INPUT_TYPE_OPTIONS.map((option) => {
      const { label, value } = option;
      const selected = value === selectedValue;

      return (
        <div
          key={value}
          className={`bg-white 
          ${selected && 'font-bold !leading-5'} 
          flex justify-between rounded p-3 text-sm hover:cursor-pointer`}
          onClick={() => select(value)}
        >
          {label}
          {selected && <TickIcon />}
        </div>
      );
    });
  }

  return (
    <>
      <div
        ref={dropdownRef}
        className={`absolute bottom-3 left-[0.531rem] flex hover:cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Select condition value type"
      >
        <div className={`w-[1.125rem]`}>
          {selectedValue === REGULAR_INPUT ? (
            <EditIcon stroke="stroke-neutral-100" />
          ) : (
            <ListIcon />
          )}
        </div>
        <div className="ml-0.5 flex flex-col self-center">
          <FilledDownChevron />
        </div>
      </div>
      {isOpen && (
        <div className={`absolute !z-50 mt-1 w-80 rounded-xl bg-white  `}>
          <div
            ref={ref}
            className="border-neutral-20 scrollbar-hide max-h-[13.75rem] overflow-scroll rounded-xl border px-4 py-6 text-left text-sm"
            style={{
              boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)',
            }}
          >
            {renderOptions()}
          </div>
        </div>
      )}
    </>
  );
}
