import { Profile, SetupError } from 'shared/legacy-types';
import BrandCard from 'components/Cards/BrandCard';
import { Brand } from 'shared/legacy-types';

interface Props {
  brandProfiles: Profile[] | undefined;
  profiles: Profile[] | undefined;
  selectBrand: (id: number, name: string) => void;
  selectedBrands: Brand[];
  error: SetupError | undefined;
  atLimit: boolean;
  selectMarketplace: (
    event: React.ChangeEvent<HTMLInputElement>,
    brandName: string,
  ) => void;
}

export default function BrandComponent({
  brandProfiles,
  selectedBrands,
  profiles,
  atLimit,
  selectMarketplace,
  selectBrand,
}: Props) {
  const renderBrands = () => {
    return brandProfiles?.map((brand, index) => {
      const { id, accountName, type } = brand;
      return (
        <div key={index}>
          <BrandCard
            brandId={id}
            accountName={accountName}
            selectedBrands={selectedBrands}
            brandProfiles={profiles}
            selectMarketplace={selectMarketplace}
            selectBrand={selectBrand}
            brandType={type}
            atLimit={atLimit}
          />
        </div>
      );
    });
  };

  return (
    <div
      className={`max-h-[78vh] overflow-y-auto scrollbar-hide overscroll-none pt-0.5 pb-[7.5rem]`}
    >
      {renderBrands()}
    </div>
  );
}
