import { useState } from 'react';
import { DataType, RunStep } from 'shared/legacy-types';

import ConditionGroups from './ConditionGroups';
import SummaryActions from './SummaryActions';
import { DATA_TYPE_LABELS, DATE_OPTIONS } from '../../constants/rules';

interface Props {
  step: RunStep;
  dataType: DataType;
  showAll?: boolean;
  userEmail: string;
}

export default function StepSummary({
  step,
  dataType,
  showAll,
  userEmail,
}: Props) {
  const { actions, period } = step || {};
  const [open, setOpen] = useState<boolean>(false);
  const dataTypeLabel = DATA_TYPE_LABELS.find(
    (label) => label.value === dataType,
  )?.label;
  const dateOption = DATE_OPTIONS.find(
    (option) => option.value === period,
  )?.label;

  return (
    <div className="flex flex-col">
      <div className="border-primary-100 mt-2 border-l-2">
        <div className="mb-2 flex pl-6 text-sm">
          <div className="text-neutral-80 mr-2 ">Data type</div>
          <div>{dataTypeLabel}</div>
        </div>
        <div className="flex pl-6 text-sm">
          <div className="text-neutral-80 mr-2 ">Look back</div>
          <div>{dateOption}</div>
        </div>
      </div>
      <div className="mt-6">
        <ConditionGroups step={step} />
      </div>
      {(open || showAll) && (
        <>
          <div className="text-neutral-80 mb-2 mt-4 font-bold">Then</div>
          {actions.map((action, index) => {
            const isLast = index === actions.length - 1;
            return (
              <>
                <SummaryActions
                  dataType={dataType}
                  action={action}
                  isLast={isLast}
                  userEmail={userEmail}
                />
              </>
            );
          })}
        </>
      )}
      {!showAll && (
        <div
          className="border-primary-100 mb-6 mt-4 w-fit border-b-2 text-sm font-bold text-neutral-100 hover:cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          {open ? <>Show less</> : <>Show more</>}
        </div>
      )}
    </div>
  );
}
