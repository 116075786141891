import './Spinner.css';

interface Props {
  colour?: string;
}

export default function Spinner({ colour = 'stroke-neutral-80' }: Props) {
  return (
    <svg className={`spinner ${colour}`} viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
}
