import * as Sentry from '@sentry/react';
import BrandStatus from './BrandStatus';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import useBrands from './useBrands';
import Header from '../Header';
import { TickCircle } from 'components/Icons/';
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Card from 'design-system/components/Card/Card';
import { getBrandCardSubtitle } from '../utils';

const NEW_ACCOUNT_CONNECTION_STATUS = {
  PARAMETER_NAME: 'new_account_connection_status',
  SUCCESS_STATUS: 'success',
};

type Props = {
  setOpenModal: (open: boolean) => void;
};

export default function Brands({ setOpenModal }: Props): JSX.Element {
  const { brands, loading, error } = useBrands();
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayAccountSuccessMessage, setDisplayAccountSuccessMessage] =
    useState(false);
  const navigate = useNavigate();

  const isNewAccountConnectionSuccess =
    searchParams.get(NEW_ACCOUNT_CONNECTION_STATUS.PARAMETER_NAME) ===
    NEW_ACCOUNT_CONNECTION_STATUS.SUCCESS_STATUS;

  useEffect(() => {
    if (isNewAccountConnectionSuccess) {
      setDisplayAccountSuccessMessage(true);
    }
    searchParams.delete(NEW_ACCOUNT_CONNECTION_STATUS.PARAMETER_NAME);
    setSearchParams(searchParams);

    // eslint-disable-next-line
  }, []);

  if (error) {
    Sentry.captureException(error);
  }

  function renderBrands(): JSX.Element[] {
    return brands.map((brand) => {
      const subtitle = getBrandCardSubtitle(brand);

      function navigateToBrand() {
        navigate(brand.id);
      }

      return (
        <Card
          title={brand.name}
          subtitle={subtitle}
          onClick={navigateToBrand}
          child={<BrandStatus brand={brand} />}
        />
      );
    });
  }

  return (
    <>
      <Header setOpenModal={setOpenModal} />
      {!loading && brands.length === 0 && (
        <div className="mt-6 text-sm text-neutral-80">
          Click "Add marketplaces" to start connecting your accounts
        </div>
      )}
      {displayAccountSuccessMessage ? (
        <div className="bg-primary-100/20 border-primary-100/30 mb-3 flex rounded border px-4 py-4 mt-8">
          <div>
            <div className="flex space-between">
              <div>
                <TickCircle
                  background="fill-primary-100"
                  iconColour="stroke-white"
                />
              </div>
              <div className="text-sm font-bold pl-2">
                Amazon account connected successfully
              </div>
            </div>
            <div className="text-sm mt-3">
              <div className="mt-2">
                - Importing brands can take a few minutes
              </div>
              <div className="mt-2">
                - Refresh this page a couple of times to confirm all your brands
                have been imported
              </div>
              <div className="mt-2">
                - Reach out to support if something is not working as expected
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-sm mt-8">
          <span className="font-bold">Don't see all your brands?</span>{' '}
          Authorise more Amazon accounts or regions by clicking on "Add
          marketplaces"
        </div>
      )}
      <div className="mt-10">
        {loading && (
          <div className="flex justify-center mt-20">
            <LargeSpinner />
          </div>
        )}
        {!loading && brands.length > 0 && renderBrands()}
      </div>
    </>
  );
}
