interface Props {
  colour?: string;
}

export function ChevronDownIcon({ colour = 'stroke-black' }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33594 6.5L8.33594 10.5L12.3359 6.5"
        strokeWidth="1.33333"
        strokeLinejoin="round"
        className={`${colour}`}
      />
    </svg>
  );
}
