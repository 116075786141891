import { useParams } from 'react-router-dom';

import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { Radio } from 'components/Radio/Radio';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import useFoldersSelector from 'state/folders/useFoldersSelector';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  moveRules: () => Promise<void>;
  movingRules: boolean;
  setMoveFolder: (folder: string) => void;
  moveFolder: string;
  rulesCount: number;
};

const SINGLE_RULE = 1;

export default function MoveRulesModal({
  open,
  setOpen,
  moveRules,
  movingRules,
  setMoveFolder,
  moveFolder,
  rulesCount,
}: Props): JSX.Element {
  const { folders } = useFoldersSelector();
  const { folderId } = useParams();

  // add home folder to the list of folders if not in home folder
  const addHomeFolder = folderId != null ? [{ id: 'home', name: 'Home' }] : [];

  const folderList = [...addHomeFolder, ...folders];
  // remove the folder their currently in from the list of folders
  const filteredFolders = folderList.filter((folder) => folder.id !== folderId);

  const plural = rulesCount === SINGLE_RULE ? '' : 's';

  function renderFolders(): JSX.Element[] {
    const sortedFolders = filteredFolders
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));

    return sortedFolders.map((folder) => {
      const selected = moveFolder === folder.id;
      return (
        <div
          className="mb-4 flex h-[1.625rem] hover:cursor-pointer"
          onClick={() => {
            setMoveFolder(folder.id);
          }}
          aria-label={`Select ${folder.name}`}
        >
          <div className="mr-3 pt-0.5">
            <Radio
              id={folder.id.toString()}
              checked={selected}
              select={() => {
                setMoveFolder(folder.id);
              }}
            />
          </div>
          <div
            className={`text-sm !leading-[1.625rem] ${selected && 'font-bold'}`}
          >
            {' '}
            {folder.name}
          </div>
        </div>
      );
    });
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="relative w-[28rem] bg-white p-6">
        <div className="flex justify-between">
          <h3 className="truncate font-bold">
            Move {rulesCount} rule{plural}
          </h3>
          <div
            className="mt-[0.1875rem] hover:cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="text-neutral-80 mr-4 mt-2 truncate pb-3">
          Choose a folder to move your rule{plural} to
        </div>
        <div
          className="scrollbar-hide z-10 max-h-[11.5rem] overflow-auto pt-3"
          aria-label="Folders"
        >
          {renderFolders()}
        </div>
        <div className="z-50">
          <div className="border-neutral-40 mx-[-24px] border-t" />
          <div className="flex  justify-end pt-4">
            <div className="ml-4">
              <PrimaryButton
                onClick={() => {
                  void moveRules();
                }}
                disabled={moveFolder.length === 0}
              >
                {movingRules ? <Spinner colour="stroke-white" /> : <>Confirm</>}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
