import PortalLink from './PortalLink';
import { capitalizeFirstLetter } from 'helpers/textFormat';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import useUserSelector from 'state/user/useUserSelector';
import { useContext } from 'react';
import { AppContext } from 'App';

export default function PlanInfo() {
  const { openChangePlanModal } = useContext(AppContext);

  const { user, access, isInternalUser } = useUserSelector();
  const plan = access?.plan;
  const providePortalAccess = user?.isStripeCustomer;

  return (
    <div
      className="bg-white rounded-md min-w-[12.5rem] w-fit p-5 ml-6 h-fit"
      style={{ boxShadow: '0rem 0.25rem 1rem -0.25rem rgba(5, 26, 22, 0.08)' }}
    >
      <div
        className={`${providePortalAccess && 'border-b pb-6 mb-5 border-neutral-40'} `}
      >
        <div className="text-xs text-neutral-80">Your plan</div>
        <div className="flex justify-between">
          <div>
            <div className="font-bold text-xl mt-2">
              {capitalizeFirstLetter(plan)}
            </div>
          </div>
          {isInternalUser && (
            <div className="flex flex-col justify-end">
              <PrimaryButton onClick={() => openChangePlanModal(true)}>
                <>Change plan</>
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
      {providePortalAccess && <PortalLink />}
    </div>
  );
}
