import Checkbox from 'ui/components/Checkbox/Checkbox';
import { MoveFolderIcon } from 'components/Icons';

interface Props {
  allSelected: boolean;
  hasSelections: boolean;
  selectAllRules: () => void;
  setMoveRulesModalOpen: (open: boolean) => void;
}

export default function RuleToolbar({
  allSelected,
  hasSelections,
  selectAllRules,
  setMoveRulesModalOpen,
}: Props) {
  return (
    <div
      className="w-full h-[2.75rem] bg-white rounded px-4 py-3"
      style={{ boxShadow: '0rem 0.125rem 0.25rem rgba(0, 18, 51, 0.05)' }}
    >
      <div className="flex">
        <div className="mr-2" aria-label="Select all rules">
          <Checkbox
            checked={allSelected}
            select={() => selectAllRules()}
            size="5"
          />
        </div>
        <div
          className={`text-neutral-80 text-sm !leading-5 ${hasSelections && 'border-r border-neutral-40'} pr-4 `}
        >
          Select all
        </div>
        {hasSelections && (
          <div className="relative group">
            <span
              className={`absolute h-[1.875rem] w-24 p-2 rounded bg-neutral-100 text-white text-xs group-hover/item:translate-y-[-2.415rem] bottom-8 -right-[2.25rem] group-hover:visible invisible`}
            >
              Move to folder
            </span>
            <div
              className="ml-4 hover:cursor-pointer"
              onClick={() => setMoveRulesModalOpen(true)}
              aria-label="Move folder"
            >
              <MoveFolderIcon
                stroke={'stroke-neutral-80 group-hover:stroke-primary-100'}
                fill={'fill-neutral-80 group-hover:fill-primary-100'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
