import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IsValid, Optional, Rule, RuleStatus } from 'shared/legacy-types';
import { sendEvent } from 'helpers/analytics';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import { ENABLED, DISABLED } from 'ui/constants/rules';
import useRulesSelector from 'state/rules/useRulesSelector';
import { scopeIsValid, ruleStepsValid } from 'ui/helpers/rules/isValid';
import useUser from 'hooks/useUser';

type UseRuleHook = {
  toggleRuleStatus: (
    enabled: boolean,
    setRule: (rule: Rule) => void,
  ) => Promise<void>;
  isSavingEnabled: boolean;
  isToggleEnabled: boolean;
  isValid: IsValid;
  isRuleValid: boolean;
  checkInvalidFields: () => void;
  highlightInvalidFields: boolean;
  isEditing: boolean;
};

export default function useRule(rule: Optional<Rule, 'id'>): UseRuleHook {
  const [isValid, setIsValid] = useState<IsValid>({ scope: false, steps: [] });
  const [highlightInvalidFields, setHighlightInvalidFields] =
    useState<boolean>(false);
  const { editingRules } = useRulesSelector();
  const { ruleEnabled, ruleDisabled } = useRuleRequests();
  const { ruleId } = useParams();
  const { allProfilesHaveSpendTargets } = useUser();

  const RULE_VALIDATION_TIMEOUT = 30000;
  const isEditing = editingRules.includes(rule.id ?? ruleId ?? '');

  useEffect(
    function updateIsValid() {
      setIsValid({
        scope: scopeIsValid(rule),
        steps: ruleStepsValid(
          rule,
          allProfilesHaveSpendTargets(rule.profileIds),
        ),
      });
    },
    // eslint-disable-next-line
    [rule, setIsValid],
  );

  const validScope = isValid.scope ?? false;

  const isRuleValid =
    validScope && isValid.steps.every((isStepValid) => isStepValid);

  function checkInvalidFields(): void {
    if (!isRuleValid) {
      setHighlightInvalidFields(true);
      setTimeout(() => {
        setHighlightInvalidFields(false);
      }, RULE_VALIDATION_TIMEOUT);
    }
  }

  const isRuleOff = rule.status !== ENABLED;
  const isRuleOn = rule.status === ENABLED;
  const isSavedToDb = rule.id != null;

  const isSavingEnabled = isRuleOff || isRuleValid; // WHY: User can save an INCOMPLETE rule as a DRAFT, but can't enable it
  const canBeDisabled = isSavedToDb && isRuleOn;
  const canBeEnabled = isSavedToDb && isRuleOff && !isEditing && isRuleValid; // WHY: User can enable saved and valid rules
  const isToggleEnabled = canBeEnabled || canBeDisabled;

  async function enableRule(setRule: (rule: Rule) => void): Promise<void> {
    if (rule.id == null) return;
    await ruleEnabled(rule.id);
    const updatedRule = { ...rule, status: ENABLED as RuleStatus };
    setRule(updatedRule as Rule);
  }

  async function disableRule(setRule: (rule: Rule) => void): Promise<void> {
    if (rule.id == null) return;
    await ruleDisabled(rule.id);
    const updatedRule = { ...rule, status: DISABLED as RuleStatus };
    setRule(updatedRule as Rule);
  }

  async function toggleRuleStatus(
    enabled: boolean,
    setRule: (rule: Rule) => void,
  ): Promise<void> {
    if (enabled) {
      sendEvent('turn_rule_on');
      await enableRule(setRule);
    }
    if (!enabled) {
      await disableRule(setRule);
    }
  }

  return {
    toggleRuleStatus,
    isSavingEnabled,
    isToggleEnabled,
    isValid,
    isRuleValid,
    checkInvalidFields,
    highlightInvalidFields,
    isEditing,
  };
}
