import { useState, useRef, ReactElement } from 'react';
import { TickIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import Tooltip from 'ui/Tooltips/Tooltip';

interface Option {
  label: string;
  value: string;
  tooltip?: { content: ReactElement; position: string };
}

interface Props {
  label?: string;
  options: Option[];
  selectedValue: string | undefined;
  selectValue: (value: string) => void;
  ariaLabel?: string;
  disabled?: boolean;
  position?: string;
  styles?: string;
  shortOptionsList?: boolean;
  disableOptions?: boolean;
  allowScroll?: boolean;
  error?: boolean;
}

export default function Dropdown({
  label,
  options,
  selectedValue,
  selectValue,
  ariaLabel,
  disabled,
  position,
  styles,
  shortOptionsList,
  disableOptions,
  allowScroll = true,
  error,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  function getErrorStyles(): string {
    if (error && !open) {
      return 'border-l-0 !border-error !border !border-opacity-[.4] !bg-error !bg-opacity-[.2] p-[0.5rem] !pl-[3.063rem] !pt-[0.688rem]';
    }
    if (error && open) {
      return 'p-[0.625rem] pt-[0.688rem] bg-white !border-none ';
    }

    return '';
  }

  useClickOutside(setOpen, ref, dropdownRef);

  function select(value: string) {
    selectValue(value);
    setOpen(false);
  }

  function getLabel() {
    const selectedLabel = options.find(
      (option) => option.value === selectedValue,
    )?.label;

    if (selectedLabel) {
      return selectedLabel;
    }
    return (
      <div
        className={`${disableOptions ? 'text-neutral-60' : 'text-neutral-80'} font-normal`}
      >
        {label}
      </div>
    );
  }

  function renderDropdownMenu() {
    function renderOptions() {
      return options.map((option) => {
        const { label, value } = option;
        const selected = value === selectedValue;
        const tooltip = option.tooltip;

        return (
          <div
            key={value}
            className={`bg-white ${
              selected && 'font-bold !leading-5'
            } flex justify-between rounded p-3 text-sm hover:cursor-pointer ${
              disableOptions && 'text-neutral-60 hover:cursor-not-allowed'
            }`}
            onClick={() => !disableOptions && select(value)}
          >
            <div className="flex">
              {label}
              {tooltip && !disableOptions && (
                <span className="ml-1 mt-0.5">
                  <Tooltip
                    content={tooltip.content}
                    useHover
                    width="w-fit"
                    position={tooltip.position}
                  />
                </span>
              )}
            </div>
            {selected && <TickIcon />}
          </div>
        );
      });
    }
    return (
      <div className={`fixed !z-50 mt-1 w-80 rounded-xl bg-white ${position} `}>
        <div
          ref={ref}
          aria-label="Dropdown list"
          className={`rounded-xl border px-4 py-6 text-sm ${
            shortOptionsList && 'max-h-[11.7rem]'
          } border-neutral-20 scrollbar-hide max-h-[11.75rem] ${allowScroll && 'overflow-scroll'} text-left`}
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          {renderOptions()}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`text-left text-sm text-neutral-100 ${
          disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
        }`}
        onClick={() => !disabled && setOpen(!open)}
        aria-label={ariaLabel}
      >
        <div
          tabIndex={0}
          className={`relative h-[2.563rem] w-full truncate rounded
          border border-neutral-40 bg-white p-[0.688rem] pr-[1.75rem] font-bold focus:pl-[0.625rem] focus:pr-[1.688rem] focus:pt-[0.625rem]
          ${styles} 
          ${getErrorStyles()}
          ${
            !error && (disabled || disableOptions)
              ? 'text-neutral-60 focus:border-neutral-40 focus:pl-[0.688rem] focus:pt-[0.688rem] placeholder:text-neutral-60'
              : 'focus:border-primary-100 focus:border-2'
          }`}
        >
          {getLabel()}
        </div>
        <div
          className={`pointer-events-none absolute ${
            open ? 'top-[1.07rem]' : 'top-[1.18rem]'
          }  right-4 flex items-center`}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {!open && (
              <path
                d="M1 0.5L5 4.5L9 0.5"
                className="stroke-neutral-80"
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            )}
            {open && (
              <path
                d="M1 5.5L5 1.5L9 5.5"
                className={`${open ? 'stroke-black' : 'stroke-neutral-80'}`}
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            )}
          </svg>
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
