import { useState, useRef } from 'react';
import { SharedWorkspaceIcon, LockIconCircle } from 'components/Icons';
import ShareWorkspaceModal from 'pages/Dashboard/ShareWorkspace/ShareWorkspaceModal';
import { useContext } from 'react';
import { AppContext } from 'App';
import useClickOutside from 'ui/hooks/useClickOutside';
import usePermissions from 'components/Permissions/usePermissions';

export default function SharedWorkspaceTab() {
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const { openChangePlanModal } = useContext(AppContext);
  const { hasPermission } = usePermissions();

  const ref = useRef(null);
  const tooltipRef = useRef(null);

  useClickOutside(setShareModalOpen, ref, tooltipRef);
  const permissionGranted = hasPermission(['shareWorkspace'], 'features');

  return (
    <>
      <div>
        <div
          ref={ref}
          className="flex pl-6 cursor-pointer"
          onClick={() => setShareModalOpen(!shareModalOpen)}
        >
          {permissionGranted ? <SharedWorkspaceIcon /> : <LockIconCircle />}
          <div className="text-white text-sm pl-2 tracking-tighter my-auto ">
            Share workspace
          </div>
        </div>
      </div>
      {shareModalOpen && permissionGranted && (
        <ShareWorkspaceModal
          open={shareModalOpen}
          setOpen={setShareModalOpen}
        />
      )}
      {!permissionGranted && shareModalOpen && (
        <span
          ref={tooltipRef}
          className="absolute h-fit w-[14.5rem] flex-wrap z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-[8.25rem] left-[12rem]"
        >
          <span
            className="text-primary-100 font-bold underline hover:cursor-pointer"
            onClick={() => openChangePlanModal(true)}
          >
            Upgrade your plan
          </span>{' '}
          to start sharing your workspace with your colleagues
        </span>
      )}
    </>
  );
}
