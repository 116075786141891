import { InfoIcon } from 'components/Icons';
import Checkbox from 'ui/components/Checkbox/Checkbox';
import { Action, HarvestKeywordsAction } from 'shared/legacy-types';
import { HARVEST_KEYWORDS } from 'ui/constants/actions';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import TextLink, {
  TextLinkFontSize,
  TextLinkStyle,
} from 'design-system/components/TextLink';

type Props = {
  setSelection: (selection: Action) => void;
  selection: Action;
  hasCampaignLink: boolean;
  campaignLinksLoading: boolean;
};

export default function HarvestKeywords({
  setSelection,
  selection,
  hasCampaignLink,
  campaignLinksLoading,
}: Props): JSX.Element {
  const keywordsSelection = selection as HarvestKeywordsAction;

  function selectSendEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    setSelection({
      type: HARVEST_KEYWORDS,
      properties: {
        sendEmail: event.target.checked,
      },
    });
  }

  function campaignLinkContent(): JSX.Element {
    if (campaignLinksLoading) {
      return (
        <div className="mt-32 flex justify-center">
          <LargeSpinner />
        </div>
      );
    }

    return (
      <>
        {hasCampaignLink ? (
          <div className="bg-primary-40 border-primary-80 flex h-fit w-fit mt-6 rounded border px-[0.688rem] py-[0.4rem]">
            <span className="mr-1">
              <InfoIcon />
            </span>
            <span className="mt-px text-sm !leading-5">
              You must approve the results of this action before any keywords
              get added to your campaigns
            </span>
          </div>
        ) : (
          <div className="bg-error-bg border-error flex h-fit w-fit mt-6 rounded border px-3 py-2">
            <span className="mr-1 my-auto">
              <InfoIcon fill={'fill-error'} />
            </span>
            <span>
              <div className="font-bold">Action required</div>
              <div className="mt-px pr-2 text-sm !leading-5 ">
                No campaign mapping has been found for one or more of your
                chosen marketplaces.{' '}
                <TextLink
                  link="/campaign-mapping"
                  label="Add now"
                  style={TextLinkStyle.INTERNAL}
                  size={TextLinkFontSize.SMALL}
                />
              </div>
            </span>
          </div>
        )}
        {hasCampaignLink && (
          <div className="flex absolute bottom-[6.563rem] w-[32.938rem]">
            <Checkbox
              checked={keywordsSelection.properties.sendEmail}
              select={(event) => {
                selectSendEmail(event);
              }}
              label="Add an email alert action to this workflow to get notified when new search terms need approval"
              labelStyles="font-normal !leading-[1.05rem] mt-[-0.188rem] ml-3"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="mt-2 text-sm">
        <div className="border-b border-neutral-40">
          <div className="text-neutral-80 pb-6 leading-[16.8px]  max-w-[30.188rem]">
            <span>
              Create new <span className="font-medium">Keywords</span> and{' '}
              <span className="font-medium">Product Targets</span> from{' '}
              <span className="font-medium">Search Terms</span> in your
              destination ad groups based on{' '}
              <TextLink
                link="/campaign-mapping"
                label="Campaign Mapping"
                style={TextLinkStyle.INTERNAL}
                size={TextLinkFontSize.SMALL}
              />{' '}
              when your conditions are met.
            </span>
          </div>
        </div>
      </div>
      {campaignLinkContent()}
    </>
  );
}
