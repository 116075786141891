import { useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'components/Modals/Modal';
import { Template, Tab } from 'shared/legacy-types';
import { CrossIcon } from 'components/Icons';
import Tabs from 'components/Tabs/Tabs';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import useUserSelector from 'state/user/useUserSelector';
import StepSummary from 'ui/components/StepSummary/StepSummary';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  template: Template;
}

export default function TemplateModal({ open, setOpen, template }: Props) {
  const { isInternalUser, user } = useUserSelector();

  const { rule, id, comingSoon, featureToggle, image: Svg } = template || {};

  const defaultTabs = rule.steps.map((step, index) => {
    return {
      id: `step_${index + 1}`,
      label: `${index + 1}. ${step.name} `,
      current: index === 0,
    };
  });

  const [tabs, setTabs] = useState<Tab[]>(defaultTabs);
  const currentStepIndex = tabs.findIndex((tab) => tab.current);
  const currentRuleStep = rule.steps[currentStepIndex];

  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <div className="w-[42.75rem] px-8 pt-8 ">
          <div className="mb-3 flex justify-between">
            <div className="flex ">
              <div className="mr-4">
                <Svg rounded="rounded-lg" />
              </div>
              <div>
                <div className="border-primary-100 text-primary-100 my-auto h-6 w-fit rounded border px-[0.438rem] text-sm font-bold !leading-[1.375rem]">
                  {template.goal}
                </div>
                <div className="mt-3 max-w-[33rem]">
                  <div className="mb-2 text-2xl font-bold leading-[1.625rem]">
                    {rule.name}
                  </div>
                  <div className="text-neutral-80 text-sm">
                    {template.description}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mt-[0.1875rem] hover:cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <CrossIcon stroke="stroke-neutral-80" />
            </div>
          </div>

          <div className="border-neutral-40 -mx-8 mt-4 border-b">
            <div className="px-8">
              <Tabs tabs={tabs} setTabs={setTabs} />
            </div>
          </div>
        </div>
        <div className="bg-secondary-20 p-8">
          {user && currentRuleStep && (
            <StepSummary
              step={currentRuleStep}
              userEmail={user.email}
              dataType={rule.dataType}
              showAll
            />
          )}
          <div className="mt-24 flex justify-end">
            <Link to={`/rules/${id}`}>
              <PrimaryButton disabled={featureToggle && !isInternalUser}>
                {comingSoon ? <>Coming soon</> : <>Use this template</>}
              </PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}
