import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { AppContext } from 'App';
import { useContext } from 'react';

export default function UpgradeCallToAction() {
  const { openChangePlanModal } = useContext(AppContext);

  return (
    <PrimaryButton onClick={() => openChangePlanModal(true)}>
      <div className="flex">Upgrade plan to start using</div>
    </PrimaryButton>
  );
}
