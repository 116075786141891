import Modal from 'components/Modals/Modal';
import { Folder } from 'shared/legacy-types';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  renameFolder: (folder: Folder) => Promise<void>;
  currentFolder: Folder | undefined;
  updatingFolder: boolean;
  setUpdatedFolderName: (name: string) => void;
  updatedFolderName: string;
};

export default function EditFolderModal({
  open,
  setOpen,
  renameFolder,
  currentFolder,
  updatingFolder,
  setUpdatedFolderName,
  updatedFolderName,
}: Props): JSX.Element {
  async function handleRenameFolder(
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();
    if (currentFolder) {
      await renameFolder({ name: updatedFolderName, id: currentFolder.id });
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <form
        onSubmit={(event) => {
          void handleRenameFolder(event);
        }}
      >
        <div className="bg-white p-6">
          <div className="flex justify-between">
            <h3 className="mb-4 truncate font-bold">Rename a folder </h3>
            <div
              className="mt-[0.1875rem] hover:cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CrossIcon />
            </div>
          </div>
          <input
            className="border-primary-80 focus:border-primary-80 my-0 h-[3.063rem] w-[25rem] rounded border-2 p-4 text-sm focus:border-2 focus:outline-none truncate"
            onChange={(event) => {
              setUpdatedFolderName(event.target.value);
            }}
            aria-label="Folder name input"
            value={updatedFolderName}
          />

          <div className="flex justify-end pt-4">
            <div className="ml-4">
              <PrimaryButton disabled={updatedFolderName.length === 0}>
                {updatingFolder ? (
                  <Spinner colour="stroke-white" />
                ) : (
                  <>Confirm</>
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
