import { ReactComponent as PercentIcon } from 'icons/generic/percent.svg';
import ExpressionInput from '../Expressions/ExpressionInput';
import ChooseInputDropdown from './ChooseInputDropdown';
import { Condition, ExpressionTypes, Rule } from 'shared/legacy-types';
import Dropdown from 'components/Dropdowns/Dropdown';
import { DROPDOWN_INPUT } from 'ui/constants/rules';
import useConditionValue from './useConditionValue';

interface Props {
  condition: Condition;
  handleInputChange: (value: string, isMetric: boolean) => void;
  getInputType: () => string;
  toggleEnabled: boolean;
  showExpressionToggle: () => boolean;
  updateExpression: (value: string, type: ExpressionTypes) => void;
  inputType: string;
  setInputType: (value: string) => void;
  isCampaignSpend: boolean;
  rule: Omit<Rule, 'id'>;
  error?: boolean;
}
export default function ConditionValue({
  toggleEnabled,
  showExpressionToggle,
  condition,
  updateExpression,
  handleInputChange,
  getInputType,
  inputType,
  setInputType,
  isCampaignSpend,
  rule,
  error,
}: Props) {
  const {
    updateInputValue,
    updateValueType,
    fieldOptions,
    isValueCondition,
    disable,
    showChooseInputDropdown,
    conditionValue,
    showDropdown,
    errorCheck,
    errorStyles,
    showPercentIcon,
    selectedField,
  } = useConditionValue(
    condition,
    rule,
    handleInputChange,
    setInputType,
    error,
    isCampaignSpend,
  );

  if (toggleEnabled && showExpressionToggle() && isValueCondition) {
    return (
      <ExpressionInput
        condition={condition}
        updateExpression={updateExpression}
        aria-label="Expression input field"
      />
    );
  }

  // if user has selected field in ChooseInputDropdown
  // or the condition field inputType is dropdown then show dropdown
  // default/fallback is always custom input
  return (
    <>
      {inputType === DROPDOWN_INPUT || showDropdown ? (
        <Dropdown
          label="Select field"
          position="absolute"
          options={fieldOptions()}
          selectedValue={conditionValue}
          selectValue={(value) => updateInputValue(value)}
          styles={`${
            showChooseInputDropdown
              ? 'pl-[3.125rem] focus:!pl-[3.125rem]'
              : 'focus:!pl-4'
          } font-normal focus:p-[0.625rem] focus:!pt-[0.751rem] pt-[0.751rem] focus:!border-none pl-4 
          !rounded-tl-none !rounded-bl-none
           ${!error && 'border-none '} 
          `}
          disableOptions={disable}
          allowScroll={false}
          error={error}
        />
      ) : (
        <>
          <input
            required
            className={`block w-full pl-4 focus:!pl-[0.875rem] focus:pr-[0.625rem] focus:outline-none focus:border-primary-100 focus:border-2 relative
            !rounded-tl-none !rounded-bl-none truncate mt-0 mb-0 pr-3 h-[2.5625rem] text-sm [appearance:textfield]
            ${showChooseInputDropdown && 'pl-[3.125rem] focus:!pl-[3rem]'} 
            ${showChooseInputDropdown && errorCheck && '!pl-[3.063rem]'} 
            ${errorCheck ? errorStyles : 'border-0 border-l-0.5 border-neutral-40  bg-white'}
            ${disable ? 'text-neutral-60 placeholder:text-neutral-60' : 'placeholder:text-neutral-80'}`}
            aria-label="Input field"
            value={conditionValue}
            placeholder="Enter value"
            type={getInputType()}
            onWheel={() => {
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
            onChange={(event) => {
              handleInputChange(event.target.value, false);
            }}
            disabled={disable}
          />
        </>
      )}
      {showChooseInputDropdown && (
        <ChooseInputDropdown
          selectedValue={inputType}
          selectValue={(value) => updateValueType(value)}
        />
      )}
      <span
        className={`absolute right-3 p-1 top-2.5 my-auto bg-white ${
          showPercentIcon(selectedField) ? 'block' : 'hidden'
        }`}
      >
        <PercentIcon />
      </span>
    </>
  );
}
