import { useContext } from 'react';
import { ReactComponent as PlusIcon } from 'icons/generic/plus.svg';
import { RuleContext } from '../../RuleContainer';

interface Props {
  setOpen: (open: boolean) => void;
}

export default function AddActionCard({ setOpen }: Props) {
  const { highlightInvalidFields } = useContext(RuleContext);
  return (
    <>
      <div
        className={`mt-2 rounded group h-[5rem] flex justify-center items-center hover:cursor-pointer 
        ${highlightInvalidFields ? 'bg-error bg-opacity-20 border-error border border-opacity-40' : 'bg-secondary-20'}
        `}
        onClick={() => setOpen(true)}
      >
        <div className="">
          <>
            <div className="flex justify-center">
              <PlusIcon />
            </div>
            <div className="text-sm font-bold text-neutral-100 mt-1">
              Add action
            </div>
          </>
        </div>
      </div>
    </>
  );
}
