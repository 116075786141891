import useRequest from 'api/hooks/useRequest';
import { Optional, Rule } from 'shared/legacy-types';

export default function useRuleRequests() {
  const { request } = useRequest();

  const getRule = async (ruleId: string) => {
    try {
      const response = await request('get', `/v1/rules/${ruleId}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      throw error;
    }
  };

  const getRulesByFolder = async (folderId?: string) => {
    const folderQuery = folderId ? `?folderId=${folderId}` : '';
    try {
      const response = await request('get', `/v1/rules${folderQuery}`, {});
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getAllRules = async () => {
    try {
      const response = await request('get', `/v1/rules?all=true`, {});
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };

  const getArchivedRules = async () => {
    return await request('get', '/v1/rules?archived=true', {});
  };

  const getRulePreview = async (ruleId: string) => {
    return await request('get', `/v1/rules/${ruleId}/results`, {});
  };

  const getRulePreviewStep = async (id: string, stepId: number) => {
    return await request('get', `/v1/rules/${id}/steps/${stepId}/preview`, {});
  };

  const archiveRule = async (id: string) => {
    return await request('post', `/v1/rules/${id}/archive`, {});
  };

  const updateRule = async (rule: Rule) => {
    return await request('put', `/v1/rules/${rule?.id}`, rule);
  };

  const createRule = async (rule: Optional<Rule, 'id'>): Promise<Rule> => {
    const response = await request('post', `/v1/rules/`, rule);
    return response.data;
  };

  const ruleEnabled = async (id: string) => {
    return await request('post', `/v1/rules/${id}/enable`, {});
  };

  const ruleDisabled = async (id: string) => {
    return await request('post', `/v1/rules/${id}/disable`, {});
  };

  return {
    getRulesByFolder,
    getAllRules,
    getRule,
    updateRule,
    createRule,
    ruleEnabled,
    ruleDisabled,
    archiveRule,
    getArchivedRules,
    getRulePreview,
    getRulePreviewStep,
  };
}
