export function LargePauseIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H21C22.6569 0.5 24 1.84315 24 3.5V21.5C24 23.1569 22.6569 24.5 21 24.5H3C1.34315 24.5 0 23.1569 0 21.5V3.5Z"
        className="fill-primary-100"
      />
      <path
        d="M7.5 7.5C7.5 6.94771 7.94772 6.5 8.5 6.5H9.5C10.0523 6.5 10.5 6.94772 10.5 7.5V17.5C10.5 18.0523 10.0523 18.5 9.5 18.5H8.5C7.94772 18.5 7.5 18.0523 7.5 17.5V7.5Z"
        fill="white"
      />
      <path
        d="M13.5 7.5C13.5 6.94771 13.9477 6.5 14.5 6.5H15.5C16.0523 6.5 16.5 6.94772 16.5 7.5V17.5C16.5 18.0523 16.0523 18.5 15.5 18.5H14.5C13.9477 18.5 13.5 18.0523 13.5 17.5V7.5Z"
        fill="white"
      />
    </svg>
  );
}
