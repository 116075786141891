import { useState, ReactElement } from 'react';
import {
  GROUPED_CONDITION_FIELDS,
  GROUPED_CONDITION_FIELDS_EXTERNAL,
} from 'ui/constants/rules';
import useUserSelector from 'state/user/useUserSelector';
import { Config } from 'shared/legacy-types';
import useUser from 'hooks/useUser';

export interface Option {
  label: string;
  value: string;
  source?: string;
  dataTypes?: string[];
  tooltip?: { content: ReactElement; position: string };
}

export default function useFieldDropdown(
  currentDataType: string | undefined,
  selectedProfileIds: number[],
  selectValue: (value: string) => void,
  error: boolean,
) {
  const [open, setOpen] = useState<boolean>(false);
  const [openHeadings, setOpenHeadings] = useState<string[]>([]);
  const [disabledTooltipOpen, setDisabledTooltipOpen] =
    useState<boolean>(false);

  const { isInternalUser, userConfig } = useUserSelector();
  const { profilesWithSpendTarget } = userConfig || ({} as Config);
  const { allProfilesHaveSpendTargets } = useUser();

  const dropdownStyles = `focus:border-primary-100 flex h-[2.5625rem] w-full rounded-l pb-[0.688rem] pl-4 pr-0.5 pt-[0.688rem]  
  focus:border-2 focus:pl-[0.875rem] focus:pr-px focus:pt-2.5
  ${
    error && !open
      ? 'bg-error bg-opacity-[.2] focus:bg-white border border-error border-opacity-[.4] pl-[0.935rem]'
      : 'border-r pl-4 bg-white border-neutral-40'
  }
 `;

  const ruleHasSpendTargets =
    selectedProfileIds &&
    profilesWithSpendTarget &&
    selectedProfileIds.length > 0 &&
    profilesWithSpendTarget.length > 0 &&
    allProfilesHaveSpendTargets(selectedProfileIds);

  const options = isInternalUser
    ? GROUPED_CONDITION_FIELDS[
        currentDataType as keyof typeof GROUPED_CONDITION_FIELDS
      ]
    : GROUPED_CONDITION_FIELDS_EXTERNAL[
        currentDataType as keyof typeof GROUPED_CONDITION_FIELDS_EXTERNAL
      ];

  function select(value: string, prefix?: string) {
    if (prefix) {
      selectValue(`${prefix}:${value}`);
      setOpen(false);
      return;
    }

    selectValue(value);
    setOpen(false);
  }

  return {
    options,
    open,
    setOpen,
    openHeadings,
    setOpenHeadings,
    disabledTooltipOpen,
    setDisabledTooltipOpen,
    ruleHasSpendTargets,
    select,
    dropdownStyles,
  };
}
