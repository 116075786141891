import useUserSelector from 'state/user/useUserSelector';

export default function UserDetails() {
  const { user } = useUserSelector();

  const disabledInput = (label: string, value: string) => {
    return (
      <div className="">
        <label htmlFor="input" className="text-xs text-neutral-80">
          {label}
        </label>
        <br />
        <input
          name="input"
          id="input"
          className="w-full h-[2.563rem] p-3 bg-white rounded border border-neutral-40 text-neutral-60 text-sm"
          value={value}
          disabled
        />
      </div>
    );
  };

  return (
    <div
      className="bg-white rounded-md w-fit p-5"
      style={{ boxShadow: '0rem 0.25rem 1rem -0.25rem rgba(5, 26, 22, 0.08)' }}
    >
      <h5 className="font-medium">Details</h5>
      <div className="text-xs text-neutral-80 mb-5 mt-1">
        If you need any of the below details changed please contact{' '}
        <span className="underline font-bold">support@meetfern.ai</span>
      </div>
      <div>
        <div className="mb-4">
          {disabledInput('First name', user?.firstName || '')}
        </div>
        <div className="mb-4">
          {disabledInput('Last name', user?.lastName || '')}
        </div>
        {disabledInput('Email', user?.email || '')}
      </div>
    </div>
  );
}
