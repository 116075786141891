import TextArea from 'ui/TextArea';
import TextField from 'ui/TextField';

export default function TextAreaComponents() {
  return (
    <section
      id="forms"
      className="py-20 pl-10 border-neutral-40 border-t border-1"
    >
      <div id="text-field">
        <h4 className="font-bold">Text field</h4>
        <div className="flex space-x-20 pt-3">
          <TextField />
          <TextField value="andy@meetfern.ai" />
          <TextField value="andy@meetfern.ai" error={true} />
        </div>
      </div>
      <div id="text-area" className="pt-20">
        <h4 className="font-bold">Text area</h4>
        <div className="flex space-x-20 pt-3 pb-4 ">
          <TextArea width="[14.5rem]" />
          <TextArea
            width="[14.5rem]"
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              At quisque lorem pharetra elit..."
          />
          <TextArea width="[14.5rem]" error={true} />
        </div>
      </div>
      <div id="text-area-behave" className="pt-20">
        <h4 className="font-bold">Textarea behaviour</h4>
        <div className="flex space-x-20 pt-3 pb-4 ">
          <TextArea
            width="[14.5rem]"
            value="Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit.
                Adipiscing consectetur sed eget fringilla potenti bibendum. Viverra neque nunc risus velit quis. Ullamcorper in sagittis non donec pharetra. Faucibus pellentesque turpis aliquam sollicitudin dignissim quis urna. Donec aenean pellentesque blandit massa eu ante lorem sed mi. 
                Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit."
          />
          <TextArea
            width="[14.5rem]"
            value="Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit.
                Adipiscing consectetur sed eget fringilla potenti bibendum. Viverra neque nunc risus velit quis. Ullamcorper in sagittis non donec pharetra. Faucibus pellentesque turpis aliquam sollicitudin dignissim quis urna. Donec aenean pellentesque blandit massa eu ante lorem sed mi. 
                Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit."
          />
          <TextArea
            width="[14.5rem]"
            value="Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit.
                Adipiscing consectetur sed eget fringilla potenti bibendum. Viverra neque nunc risus velit quis. Ullamcorper in sagittis non donec pharetra. Faucibus pellentesque turpis aliquam sollicitudin dignissim quis urna. Donec aenean pellentesque blandit massa eu ante lorem sed mi. 
                Lorem ipsum dolor sit amet consectetur. Aenean mauris risus quis diam velit. Molestie fusce ac proin dignissim auctor pulvinar diam volutpat posuere. Ac at viverra id dolor dictum lacus elementum. Integer morbi neque laoreet risus suspendisse. Amet risus morbi nec eget. Pellentesque lorem morbi sed blandit."
          />
        </div>
      </div>
    </section>
  );
}
