const SINGLE_RULE = 1;

export function removeFolderMessage(
  folderName: string,
  archiveRules: boolean,
  rulesCount: number,
): string {
  const plural = rulesCount === SINGLE_RULE ? '' : 's';
  const text = rulesCount === SINGLE_RULE ? 'has' : 'have';
  const homeFolderText = archiveRules ? '' : 'the Home folder';
  const message = `& ${rulesCount} rule${plural} ${text} been moved to ${homeFolderText}`;
  const archivedRulesMessage = rulesCount > 0 ? message : '';

  return `Your folder ${folderName} has been removed ${archivedRulesMessage}`;
}

export function moveRulesToastMessage(ruleCount: number): string {
  const plural = ruleCount === SINGLE_RULE ? '' : 's';
  const text = ruleCount === SINGLE_RULE ? 'has' : 'have';

  return `${ruleCount} rule${plural} ${text} been moved to`;
}
