import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { CampaignLink } from 'shared/legacy-types';

interface props {
  totalCampaignLinks: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  paginatedCampaignLinks: CampaignLink[];
  disableNextButton: boolean;
  disablePrevButton: boolean;
}

export default function CampaignLinkPaginationComponent({
  paginatedCampaignLinks,
  totalCampaignLinks,
  pageNumber,
  setPageNumber,
  disableNextButton,
  disablePrevButton,
}: props) {
  return (
    <div className="w-full xl:px-[8.875rem] lg:px-20 md:px-10 sm:px-10 justify-center flex h-[5rem] bg-secondary-20 flex-auto bottom-0 left-0 fixed self-center pb-6 pt-2">
      <div className="flex flex-auto basis-[57.5rem] ml-[14.75rem]">
        <div className="flex flex-auto self-center">
          <SecondaryButton
            disabled={disablePrevButton}
            colour="border-primary-100 disabled:text-white disabled:bg-neutral-40"
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <>Prev</>
          </SecondaryButton>
          <div className="self-center items-center w-auto basis-[11.5rem] flex-auto content-center">
            <div className="text-center text-bold text-sm">
              Page {pageNumber}
            </div>
            <div className="text-center text-xs">
              {paginatedCampaignLinks && paginatedCampaignLinks.length} results
              | {totalCampaignLinks} total
            </div>
          </div>
          <div className="flex-end">
            <SecondaryButton
              disabled={disableNextButton}
              colour="border-primary-100 disabled:text-white disabled:bg-neutral-40 "
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <>Next</>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
