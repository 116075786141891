export function LargePlusSquareIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM7.32292 3.66406C7.17909 3.66406 7.0625 3.78065 7.0625 3.92448V7.05078H3.93229C3.78847 7.05078 3.67188 7.16737 3.67188 7.3112V8.35287C3.67188 8.49669 3.78847 8.61328 3.93229 8.61328H7.0625V11.737C7.0625 11.8808 7.17909 11.9974 7.32292 11.9974H8.36458C8.50841 11.9974 8.625 11.8808 8.625 11.737V8.61328H11.7448C11.8886 8.61328 12.0052 8.49669 12.0052 8.35287V7.3112C12.0052 7.16737 11.8886 7.05078 11.7448 7.05078H8.625V3.92448C8.625 3.78065 8.50841 3.66406 8.36458 3.66406H7.32292Z"
        className="fill-primary-100"
      />
    </svg>
  );
}
