import useUserSelector from 'state/user/useUserSelector';
import { capitalizeFirstLetter } from 'helpers/textFormat';

export default function PlanBadge() {
  const { userPlan } = useUserSelector();

  const plan = userPlan && capitalizeFirstLetter(userPlan);

  return (
    <div className=" flex h-[1.375rem] font-medium  text-xs my-auto">
      <div className={`rounded bg-secondary-100 text-white p-1`}>
        {plan} plan
      </div>
    </div>
  );
}
