import { browserLocale } from 'ui/helpers/localisation/localisation';
import { SymbolType } from './GridTable';
import { formatCurrency } from 'ui/helpers/currency/currency';
import { REPORTING_NUMBERS_DECIMALS } from 'shared/constants';

export class UnexpectedMetricMissing extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnexpectedMetricMissing';
  }
}

export class UnexpectedMetricType extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UnexpectedMetricType';
  }
}

export function formatCell(
  value: number,
  symbol: SymbolType | undefined,
  currencyCode: string | undefined,
): string {
  if (value === 0) {
    return '-';
  }

  const showCurrency = symbol === SymbolType.CURRENCY;
  if (showCurrency && currencyCode != null) {
    return formatCurrency(
      Number(value),
      currencyCode,
      REPORTING_NUMBERS_DECIMALS,
    );
  }

  const showPercentage = symbol === SymbolType.PERCENTAGE;
  const formattedValue = new Intl.NumberFormat(browserLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: REPORTING_NUMBERS_DECIMALS,
  }).format(Number(value));
  return formattedValue + (showPercentage ? '%' : '');
}

export function getValueAsNumber(
  value: any,
  fieldName: string,
  isGroupRow: boolean,
): number {
  if (value == null) {
    if (isGroupRow) {
      // We couldn't figure out why but when
      // filters return zero results this function
      // is called with null value for some fields and
      // we do not want to throw as this is a valid
      // scenario for our users
      return 0;
    }

    throw new UnexpectedMetricMissing(`Metric missing for field ${fieldName}`);
  }

  const valueAsNumber = parseFloat(value.toString());
  if (isNaN(valueAsNumber)) {
    throw new UnexpectedMetricType(
      `Metric for field ${fieldName} has value ${value}`,
    );
  }

  return valueAsNumber;
}
