import { ValueGetterParams } from 'ag-grid-enterprise';
import { CampaignTableHeader } from '../tableHeaders';
import { getValueAsNumber } from '../utils';
import { ratioPercentageCalculation } from './ratioPercentage';
import { percentageChangeCalculation } from './percentageChange';

export type PercentageChangeForRatioPercentageValue = {
  antecedent: number;
  consequent: number;
  previousAntecedent: number;
  previousConsequent: number;
  toString: () => string;
};

export function percentageChangeForRatioPercentageValueGetter(
  params: ValueGetterParams,
  antecedentField: CampaignTableHeader,
  consequentField: CampaignTableHeader,
  previousAntecedentField: CampaignTableHeader,
  previousConsequentField: CampaignTableHeader,
): PercentageChangeForRatioPercentageValue {
  const antecedent = getValueAsNumber(
    params.getValue(antecedentField),
    antecedentField,
    params.node?.group ?? false,
  );

  const consequent = getValueAsNumber(
    params.getValue(consequentField),
    consequentField,
    params.node?.group ?? false,
  );

  const previousAntecedent = getValueAsNumber(
    params.getValue(previousAntecedentField),
    previousAntecedentField,
    params.node?.group ?? false,
  );

  const previousConsequent = getValueAsNumber(
    params.getValue(previousConsequentField),
    previousConsequentField,
    params.node?.group ?? false,
  );

  let percentageChangeValue = 0;
  if (params.node && !params.node.group) {
    const ratioPercentage = ratioPercentageCalculation(antecedent, consequent);
    const previousRatioPercentage = ratioPercentageCalculation(
      previousAntecedent,
      previousConsequent,
    );
    percentageChangeValue = percentageChangeCalculation(
      ratioPercentage,
      previousRatioPercentage,
    );
  }

  return {
    antecedent,
    consequent,
    previousAntecedent,
    previousConsequent,
    toString: () => `${percentageChangeValue}`,
  };
}

export function percentageChangeForRatioPercentageAggregationFunction(
  columnValues: PercentageChangeForRatioPercentageValue[],
): PercentageChangeForRatioPercentageValue {
  const antecedentSum = columnValues.reduce((sum, value) => {
    return sum + value.antecedent;
  }, 0);

  const consequentSum = columnValues.reduce((sum, value) => {
    return sum + value.consequent;
  }, 0);

  const previousAntecedentSum = columnValues.reduce((sum, value) => {
    return sum + value.previousAntecedent;
  }, 0);

  const previousConsequentSum = columnValues.reduce((sum, value) => {
    return sum + value.previousConsequent;
  }, 0);

  return {
    antecedent: antecedentSum,
    consequent: consequentSum,
    previousAntecedent: previousAntecedentSum,
    previousConsequent: previousConsequentSum,
    toString: () =>
      `${percentageChangeCalculation(
        ratioPercentageCalculation(antecedentSum, consequentSum),
        ratioPercentageCalculation(
          previousAntecedentSum,
          previousConsequentSum,
        ),
      )}`,
  };
}
