import useUserSelector from 'state/user/useUserSelector';

export default function UserInfo() {
  const { user } = useUserSelector();

  const firstName = user && user.firstName;
  const firstNameInitial = firstName && firstName[0] ? firstName[0] : '•';

  return (
    <div
      className="border border-neutral-20 p-3 rounded my-5 flex"
      style={{ boxShadow: '0rem 0.125rem 0.75rem 0rem rgba(5, 26, 22, 0.08)' }}
    >
      <div
        className="bg-primary-80 mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full"
        title={user?.email}
      >
        <span className="bg-primary-80  font-medium leading-none text-neutral-100">
          {firstNameInitial.toUpperCase()}
        </span>
      </div>
      <div className={`mr-3 `}>
        <div className="mt-[-0.125rem] text-sm font-bold">
          {user?.firstName} {user?.lastName}
        </div>
        <div className="text-neutral-80 mt-1 text-xs ">{user?.email}</div>
      </div>
    </div>
  );
}
