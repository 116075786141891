import { Tab } from 'shared/legacy-types';

interface Props {
  tabs: Tab[];
  setTabs: (tabs: Tab[]) => void;
}

export default function Tabs({ tabs, setTabs }: Props) {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const defaultTab = tabs && tabs.find((tab) => tab.current)?.id;

  function setCurrentTab(tabId: string) {
    const updatedTabs = tabs.map((tab) => {
      if (tab.id === tabId) {
        return { ...tab, current: true };
      }

      return { ...tab, current: false };
    });

    setTabs(updatedTabs);
  }

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
            defaultValue={defaultTab}
          >
            {tabs.map((tab) => (
              <option key={tab.id}>{tab.id}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="">
            <nav className="-mb-px flex space-x-3" aria-label="Tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => setCurrentTab(tab.id)}
                  className={classNames(
                    tab.current
                      ? 'border-primary-100 text-sm'
                      : 'border-transparent text-neutral-80 hover:text-gray-700 hover:border-gray-300 text-sm',
                    'whitespace-nowrap text-sm font-bold cursor-pointer py-4 px-2 border-b-2 w-content max-w-[12.5rem] truncate',
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.label}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
