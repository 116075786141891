import {
  preventNonNumericInput,
  removeNonNumericChars,
} from 'helpers/currency';

interface Props {
  value: string;
  onChange: (value: string) => void;
  currencyCode?: string;
  styles?: string;
  ariaLabel?: string;
}

export default function CurrencyInput({
  value,
  onChange,
  currencyCode,
  styles,
  ariaLabel,
}: Props) {
  // convert incoming value to a value with currency symbol
  const amountWithCurrency =
    currencyCode &&
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(+value);

  // if value is zero only show currency symbol
  const isZero =
    amountWithCurrency && removeNonNumericChars(amountWithCurrency) === '0';

  const inputValue = isZero
    ? amountWithCurrency.replace(/[0-9]/g, '')
    : amountWithCurrency;

  function formatInputValue(value: string) {
    const updatedValue = removeNonNumericChars(value);
    onChange(updatedValue);
  }

  return (
    <input
      className={`${styles} border-neutral-40 focus:border-primary-80 focus:border-1 text- my-0 h-[2.563rem] w-full rounded p-3 text-sm font-bold focus:outline-none`}
      onChange={(event) => formatInputValue(event.target.value.toString())}
      value={inputValue}
      onKeyDown={(evt) =>
        currencyCode && preventNonNumericInput(evt, currencyCode)
      }
      type="text"
      aria-label={ariaLabel}
    />
  );
}
