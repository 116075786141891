import { TickIcon } from 'components/Icons';
import Spinner from 'ui/Spinner/Spinner';
import { Optional, Rule } from 'shared/legacy-types';
import { format } from 'date-fns';
import LastUpdate from 'pages/Rule/Header/LastUpdate';
import { sendEvent } from 'helpers/analytics';

interface Props {
  onSave: () => void;
  loading: boolean;
  success: boolean;
  disabled: boolean;
  rule: Optional<Rule, 'id'>;
  isEditing?: boolean;
}

export default function SaveButton({
  loading,
  success,
  onSave,
  disabled,
  rule,
  isEditing,
}: Props) {
  const createdDate = rule.createdAt && new Date(rule.createdAt);
  const ruleDate = rule.updatedAt && new Date(rule.updatedAt);
  const previousSave =
    (ruleDate && format(ruleDate, 'do MMM , H:mm')) ||
    (createdDate && format(createdDate, 'do MMM , H:mm'));

  function handleSave() {
    sendEvent('save_rule');
    onSave();
  }

  if (loading) {
    return (
      <div
        className={`!bg-transparent  ${
          success && '!border-0 mt-0.5'
        } w-fit !border-neutral-80 text-neutral-80 flex h-[2.563rem] py-auto`}
      >
        <div className="text-neutral-80 my-auto text-sm w-auto">
          {loading && (
            <>
              <div className="flex text-neutral-80 my-auto text-sm w-auto mt-0.5">
                <span className="flex space-x-2">
                  <Spinner />
                  <span className="w-auto"> Saving changes...</span>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  if (isEditing) {
    return (
      <div
        className="!bg-transparent text-neutral-80 disabled:text-neutral-40 text-sm my-auto w-auto transition-opacity ease-in-out duration-150"
        onClick={() => !disabled && handleSave()}
      >
        <div className="w-auto text-right">
          <span className="font-thin cursor-default">Unsaved changes </span>
          <span
            className={`font-bold underline  ml-px ${disabled ? 'text-neutral-80' : 'text-neutral-100 cursor-pointer'}`}
          >
            Save now
          </span>
        </div>
      </div>
    );
  }
  if (previousSave || success)
    return (
      <div className="text-neutral-80 my-auto text-sm w-auto">
        <div
          className={` flex transition-opacity ease-in duration-150 w-full my-auto`}
        >
          <div className={`ml-4 flex w-fit my-auto `}>
            <TickIcon colour={'stroke-neutral-80'} size={'17'} />
            <div className="w-auto text-neutral-80 text-sm ml-0.5">
              <LastUpdate rule={rule as Rule} />
            </div>
          </div>
        </div>
      </div>
    );
  return <div />;
}
