import Region from 'pages/Onboarding/Region/Region';
import ProfileSetupContainer from 'pages/Onboarding/Profile/ProfileSetupContainer';
import { SetupStep } from 'shared/legacy-types';
import NewSubscription from 'pages/Subscribe/NewSubscription';

export const SETUP_STEPS = {
  REGION: { step: 'region', component: Region },
  PROFILE: { step: 'profile', component: ProfileSetupContainer },
  SUBSCRIBE: { step: 'subscribe', component: NewSubscription },
};

export const STARTER_FLOW = [
  SETUP_STEPS.REGION,
  SETUP_STEPS.PROFILE,
] as SetupStep[];

export const MONITORING_FLOW = [
  SETUP_STEPS.REGION,
  SETUP_STEPS.PROFILE,
] as SetupStep[];

export const PRO_FLOW = [
  SETUP_STEPS.SUBSCRIBE,
  SETUP_STEPS.REGION,
  SETUP_STEPS.PROFILE,
] as SetupStep[];

// So we can use plan to find correct flow
export const SETUP_FLOWS = {
  // starter: STARTER_FLOW,
  // monitoring: MONITORING_FLOW,
  pro: PRO_FLOW,
};

export const TRIAL_DAYS = 14;
