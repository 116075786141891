import { ChangeEventHandler, useState, useRef } from 'react';
import Checkbox from 'ui/components/Checkbox/Checkbox';
import useClickOutside from 'ui/hooks/useClickOutside';
import Label from './Label';
import Chevrons from 'ui/components/Dropdowns/Chevrons';

export interface Option {
  label: string;
  value: number | string;
}

interface Props {
  label: string;
  options: Option[];
  defaultValue?: string;
  selectedValues: string[] | number[] | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  width?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export default function MultiDropdown({
  label,
  options,
  defaultValue,
  selectedValues,
  onChange,
  width = 'w-[12.0625rem]',
  required,
  disabled,
  error,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  const dropdownStyles = `${
    !disabled && 'hover:border-neutral-80'
  } block h-[3.6875rem] w-full appearance-none rounded-lg border pr-[2.125rem] 
  ${error && !open ? 'bg-error bg-opacity-20 border-error border-opacity-40' : 'border-neutral-40'}
  ${width} 
  ${open && 'bg-secondary-100 border-0 text-white mr-[0.063rem]'} 
  ${defaultValue && !open && 'bg-primary-40 border-primary-60 border-1 hover:border-primary-80'}
  ${disabled && !error && 'border-neutral-40 hover:border-neutral-40 bg-neutral-20'}
  ${selectedValues && selectedValues!.length > 0 && !open && !disabled && 'bg-primary-40 border-primary-60'}`;

  const firstLabel =
    selectedValues &&
    options?.find((option) => option.value === selectedValues[0])?.label;

  function renderDropdownMenu() {
    function renderOptions() {
      return options.map((option) => {
        const { label, value } = option;
        const isSelected = selectedValues?.some(
          (selValue) => selValue === value,
        );

        return (
          <li key={value}>
            <Checkbox
              id={value.toString()}
              select={onChange}
              checked={isSelected}
              label={label}
            />
          </li>
        );
      });
    }
    return (
      <div
        ref={ref}
        className="border-light-border absolute !z-40 mt-2 w-80 rounded-xl border bg-white"
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
      >
        <ul
          className="space-y-4 p-6 text-sm"
          aria-labelledby="dropdownRadioButton"
        >
          {renderOptions()}
        </ul>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`relative inline-block text-sm text-black hover:cursor-pointer ${
          disabled && 'hover:cursor-not-allowed'
        }`}
        onClick={() => !disabled && setOpen(!open)}
      >
        <div className={dropdownStyles}>
          <Label
            defaultValue={defaultValue}
            label={label}
            open={open}
            required={required}
            selectedValues={selectedValues}
            firstLabel={firstLabel}
            disabled={disabled}
          />
        </div>
        <div className="pointer-events-none absolute right-4 top-[1.7188rem] flex items-center ">
          <Chevrons open={open} disabled={disabled} />
        </div>
      </div>
      {open && options && options.length > 0 && renderDropdownMenu()}
    </div>
  );
}
