import { Fragment } from 'react';
import { COUNTRY_URLS } from 'shared/countries';
import TableCell from '../ResultsTable/TableCell';
import { Column } from '../../../constants/runs';
import ApproveRejectCheckbox from '../../Checkbox/ApproveRejectCheckbox';
import KeywordTableCell from '../KeywordApprovalTable/KeywordTableCell';
import useTableColumn from '../ResultsTable/useTableColumn';
import { PRODUCT } from '../../../constants/rules';
import { TickCircle, NeutralCircle } from 'components/Icons';
import { mapResultToKeywordApproval } from '../ResultsTable/helpers';
import TextLink, {
  TextLinkFontSize,
  TextLinkStyle,
} from 'design-system/components/TextLink';
import { KeywordApproval, Result } from 'shared/legacy-types';
import * as Sentry from '@sentry/react';

type Props = {
  column: Column;
  columnIndex: number;
  isFirstRow: boolean;
  isLastRow: boolean;
  currency: string;
  result: Result;
  preset: Column[];
  selectedResults: Partial<KeywordApproval>[] | undefined;
  keywordApprovals: KeywordApproval[] | undefined;
  isEmailResults: boolean;
  isKeywordHarvesting?: boolean;
  enableKeywordApproval?: boolean;
  selectResult?: (result: Omit<KeywordApproval, 'status'>) => void;
};

export default function TableColumn({
  column,
  columnIndex,
  isFirstRow,
  isLastRow,
  currency,
  result,
  preset,
  selectedResults,
  keywordApprovals,
  isEmailResults,
  isKeywordHarvesting,
  enableKeywordApproval,
  selectResult,
}: Props): JSX.Element {
  const { value, showCurrency, showPercent } = column;
  const noDestinationCampaign =
    column.value === 'destination' && result.destinationCampaignId == null;

  const {
    cellStyle,
    numberFormatting,
    brandCurrency,
    cellData,
    keywordApproved,
    keywordRejected,
    resultSelected,
  } = useTableColumn(
    columnIndex,
    preset,
    isFirstRow,
    isLastRow,
    isEmailResults,
    currency,
    showCurrency ?? false,
    value,
    result,
    noDestinationCampaign,
    selectedResults,
    keywordApprovals,
    isKeywordHarvesting,
  );

  const percent =
    (showPercent ?? false) &&
    cellData != null &&
    typeof cellData === 'number' &&
    cellData > 0 ? (
      <span className="mr-0">%</span>
    ) : (
      <span></span>
    );

  function tableCellContent(): JSX.Element {
    if ((enableKeywordApproval ?? false) && columnIndex === 0) {
      return (
        <div className="flex h-5">
          <div className="mr-6 my-auto w-5">
            <ApproveRejectCheckbox
              ariaLabel={'Select search term'}
              checked={!!resultSelected}
              select={() => {
                if (selectResult != null) {
                  selectResult(mapResultToKeywordApproval(result));
                }
              }}
              size="5"
              approved={keywordApproved}
              rejected={keywordRejected}
              disabled={result.destinationCampaignId === null}
            />
          </div>
          <div className="pt-1">
            {brandCurrency}
            {numberFormatting(cellData)}
            {percent}
          </div>
        </div>
      );
    }

    if (Array.isArray(cellData)) {
      return <>{cellData.join(', ')}</>;
    }

    // Add link for Search terms
    if (column.value === 'searchTerm') {
      const urlExtension =
        COUNTRY_URLS[
          result.countryCode.toLowerCase() as keyof typeof COUNTRY_URLS
        ];

      const keyword = cellData?.toString();
      if (keyword == null) {
        Sentry.captureMessage('Keyword missing in table cell');
      }

      const isProduct = result.targetingType === PRODUCT;
      const link = isProduct
        ? `https://www.amazon.${urlExtension}/dp/${cellData}`
        : `https://www.amazon.${urlExtension}/s?k=${encodeURIComponent(keyword ?? '')}`;

      return (
        <TextLink
          link={link}
          label={keyword ?? 'Unknown'}
          style={TextLinkStyle.EXTERNAL}
          size={TextLinkFontSize.SMALL}
          openInNewTab
          truncateLabelFromWidthInRem={10}
        />
      );
    }
    return (
      <>
        {brandCurrency}
        {numberFormatting(cellData)}
        {percent}
      </>
    );
  }
  // if no campaign linked show source campaign and ad group
  if (result.destinationCampaignId === null && column.value === 'source') {
    return (
      <Fragment key={columnIndex}>
        <KeywordTableCell
          style={cellStyle}
          campaignName={result.campaignName}
          adGroups={result.adGroupNames ? result.adGroupNames.join(', ') : ''}
        />
      </Fragment>
    );
  }

  // if no campaign linked show copy in destination column
  if (noDestinationCampaign) {
    return (
      <Fragment key={columnIndex}>
        <KeywordTableCell
          style={cellStyle}
          campaignName={'No linked campaign'}
          adGroups={'No linked ad groups'}
        />
      </Fragment>
    );
  }

  // if source or destination column then use keyword table cell
  if (column.value === 'source' || column.value === 'destination') {
    const campaignName =
      column.value === 'source'
        ? result.sourceCampaignName
        : result.destinationCampaignName;
    const adGroups =
      column.value === 'source'
        ? result.sourceAdGroupNames
        : result.destinationAdGroupNames;
    const allAdGroups =
      column.value === 'source'
        ? result.sourceAdGroupNames === null
        : result.destinationAdGroupNames === null;
    const adGroupsNames =
      (adGroups && adGroups.join(', ')) ||
      (allAdGroups && 'All Ad Groups') ||
      '';

    return (
      <Fragment key={columnIndex}>
        <KeywordTableCell
          style={cellStyle}
          campaignName={campaignName}
          adGroups={adGroupsNames}
        />
      </Fragment>
    );
  }

  if (column.value === 'negateKeywords') {
    return (
      <TableCell style={cellStyle} value={cellData} showTooltip={false}>
        {result.negateKeywords ? (
          <TickCircle
            size="20"
            iconColour="stroke-white"
            background="fill-primary-100"
          />
        ) : (
          <NeutralCircle fill="fill-neutral-40" />
        )}
      </TableCell>
    );
  }

  return (
    <Fragment key={columnIndex}>
      <TableCell
        style={cellStyle}
        value={cellData}
        showTooltip={column.value !== 'countryCode'}
      >
        <>{tableCellContent()}</>
      </TableCell>
    </Fragment>
  );
}
