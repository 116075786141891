interface Props {
  stroke?: string;
}

export function EditIcon({ stroke = 'stroke-neutral-80' }: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.832H14"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
      <path
        d="M11 2.83218C11.2652 2.56697 11.6249 2.41797 12 2.41797C12.1857 2.41797 12.3696 2.45455 12.5412 2.52562C12.7128 2.59669 12.8687 2.70086 13 2.83218C13.1313 2.9635 13.2355 3.11941 13.3066 3.29099C13.3776 3.46257 13.4142 3.64647 13.4142 3.83218C13.4142 4.0179 13.3776 4.2018 13.3066 4.37338C13.2355 4.54496 13.1313 4.70086 13 4.83218L4.66667 13.1655L2 13.8322L2.66667 11.1655L11 2.83218Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
    </svg>
  );
}
