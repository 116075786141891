import Checkbox from '../../Checkbox/Checkbox';
import { TickCircle, CrossCircle } from 'components/Icons/';
import { useState } from 'react';
import SmallSpinner from '../../../Spinner/SmallSpinner';

interface Props {
  allSelected: boolean;
  allApprovedOrRejected: boolean;
  loadingSearchTerms: boolean;
  selectAllResults: (allSelected: boolean) => void;
  hasSelections: boolean;
  approve: () => void;
  reject: () => void;
}

export default function ApprovalToolbar({
  allSelected,
  allApprovedOrRejected,
  loadingSearchTerms,
  selectAllResults,
  hasSelections,
  approve,
  reject,
}: Props) {
  const [hovering, setHovering] = useState<string>('');

  return (
    <div
      className="w-full h-[2.75rem] bg-white rounded px-4 py-3"
      style={{ boxShadow: '0rem 0.125rem 0.25rem rgba(0, 18, 51, 0.05)' }}
    >
      <div className="flex">
        <div className="mr-2">
          {!allApprovedOrRejected && (
            <Checkbox
              checked={allSelected}
              select={(event) => selectAllResults(event.target.checked)}
              size="5"
            />
          )}
          {allApprovedOrRejected && (
            <svg
              className="hover:cursor-not-allowed"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Frame 533">
                <rect
                  y="0.5"
                  width="20"
                  height="20"
                  rx="3.33333"
                  fill="#E6E8E8"
                />
                <path
                  id="Line 3"
                  d="M5.39562 10.456L8.66033 14.2648L13.6229 7.3683"
                  stroke="white"
                  strokeWidth="1.66667"
                />
              </g>
            </svg>
          )}
        </div>
        <div
          className={`text-neutral-80 text-xs !leading-5  pr-4 border-r border-neutral-40`}
        >
          Select all
        </div>
        {hasSelections && !allApprovedOrRejected && (
          <div className="ml-6 my-auto flex">
            {loadingSearchTerms ? (
              <SmallSpinner />
            ) : (
              <>
                <div className="group relative hover:cursor-pointer">
                  <div
                    onMouseEnter={() => setHovering('approve')}
                    onMouseDown={() => setHovering('')} // This returns the icon to default when clicked
                    onMouseLeave={() => setHovering('')}
                    onClick={() => approve()}
                  >
                    <TickCircle
                      iconColour="stroke-white"
                      background={
                        hovering === 'approve'
                          ? 'fill-primary-100'
                          : 'fill-neutral-80'
                      }
                      ariaLabel="Approve keyword"
                    />
                  </div>
                  <span className="absolute h-fit w-[7.1rem] flex z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-5 left-[-2.688rem] group-hover:visible invisible">
                    Approve selected
                  </span>
                </div>
                <div
                  className="ml-6 group relative hover:cursor-pointer"
                  onMouseEnter={() => setHovering('reject')}
                  onMouseDown={() => setHovering('')} // This returns the icon to default when clicked
                  onMouseLeave={() => setHovering('')}
                  onClick={() => reject()}
                >
                  <CrossCircle
                    background={
                      hovering === 'reject'
                        ? 'fill-primary-100'
                        : 'fill-neutral-80'
                    }
                    ariaLabel="Reject keyword"
                  />
                  <span className="absolute h-fit w-[6.254rem] flex z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-5 left-[-2.488rem] group-hover:visible invisible">
                    Reject selected
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
