import { useState } from 'react';

import Modal from 'components/Modals/Modal';
import { SpendTarget } from 'shared/legacy-types';
import ReviewSpendTarget from 'components/SpendTargets/ReviewSpendTarget';
import CreateSpendTargetForm from './CreateSpendTargetForm';
import { nextTwelveMonths } from 'helpers/dates/dates';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  newSpendTarget: Partial<SpendTarget>;
  setNewSpendTarget: (spendTarget: Partial<SpendTarget>) => void;
  addSpendTarget: (brandName: string) => void;
  creatingSpendTarget: boolean;
  spendTargetProfileIds: string[];
}

export default function CreateSpendTargetModal({
  open,
  setOpen,
  newSpendTarget,
  setNewSpendTarget,
  addSpendTarget,
  creatingSpendTarget,
  spendTargetProfileIds,
}: Props) {
  const modalSteps = {
    create: 'create',
    review: 'review',
  };

  const [modalStep, setModalStep] = useState<string>(modalSteps.create);
  const [selectedBrand, setSelectedBrand] = useState<string>('');
  const [recurringAmount, setRecurringAmount] = useState<string>('');

  function updateSpendTarget(value: string | number, type: string) {
    //if change brand, reset profileId
    if (type === 'brand') {
      delete newSpendTarget.profileId;
      setSelectedBrand(value.toString());
      return;
    }
    setNewSpendTarget({ ...newSpendTarget, [type]: value });
  }

  function updateAllAmounts(value: string) {
    const monthsWithAmounts = nextTwelveMonths().map((month) => {
      const monthWithZeroPrefix =
        month.numericMonth.length === 1
          ? `0${month.numericMonth}`
          : month.numericMonth;
      return { month: monthWithZeroPrefix, spend: value };
    });
    setRecurringAmount(value);
    setNewSpendTarget({
      ...newSpendTarget,
      monthlySpendTargets: monthsWithAmounts,
    });
  }

  function updateAmount(value: string, month: string) {
    const monthlySpendTargets =
      newSpendTarget.monthlySpendTargets &&
      newSpendTarget.monthlySpendTargets.map((spendTargetMonth) => {
        if (spendTargetMonth.month === month)
          return { ...spendTargetMonth, spend: value };
        return spendTargetMonth;
      });
    setNewSpendTarget({ ...newSpendTarget, monthlySpendTargets });
  }

  function createSpendTarget() {
    addSpendTarget(selectedBrand);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <>
        {modalStep === modalSteps.create && (
          <CreateSpendTargetForm
            setOpen={setOpen}
            newSpendTarget={newSpendTarget}
            updateSpendTarget={updateSpendTarget}
            updateAmounts={updateAllAmounts}
            setModalStep={setModalStep}
            reviewStep={modalSteps.review}
            selectedBrand={selectedBrand}
            spendTargetProfileIds={spendTargetProfileIds}
            recurringAmount={recurringAmount}
          />
        )}
        {modalStep === modalSteps.review && (
          <ReviewSpendTarget
            newSpendTarget={newSpendTarget}
            saveSpendTarget={createSpendTarget}
            savingSpendTarget={creatingSpendTarget}
            goBack={() => setModalStep(modalSteps.create)}
            updateAmount={updateAmount}
            showMessage
            spendTargetProfileId={newSpendTarget.profileId as string}
          />
        )}
      </>
    </Modal>
  );
}
