import { useEffect } from 'react';
import { Profile } from 'shared/legacy-types';
import { getUniqueBrands } from 'helpers/setup';

export default function useFilterProfiles(
  profiles: Profile[] | undefined,
  setBrandProfiles: (profiles: Profile[]) => void,
) {
  useEffect(
    function filterDuplicateBrands() {
      if (profiles && profiles.length > 0) {
        const filtered = getUniqueBrands(profiles);
        setBrandProfiles(filtered);
      }
    },
    [profiles, setBrandProfiles],
  );
}
