import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { getDate, format } from 'date-fns';
import './dates.css';
import DateContainer from './DateContainer';
import Header from './Header';
import useDatePicker from './useDatePicker';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  minimumDate?: Date;
}

export default function Dates({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minimumDate,
}: Props) {
  const {
    updatePresetDateRange,
    calendarOpen,
    presetDateRange,
    setCalendarOpen,
    setPresetDateRange,
    disableMonthNavigation,
  } = useDatePicker({
    setStartDate,
    setEndDate,
    startDate,
  });

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
    const dates = value.split(' - ');
    const start = dates[0] || 'Start date';
    const end = dates[1] || 'End date';

    return (
      <div className="flex">
        <button
          className={`w-[6.813rem] example-custom-input border h-8 text-xs pl-3 !leading-8 border-primary-60  bg-primary-40 rounded-l-lg border-r-0 font-medium flex relative
          ${calendarOpen && 'bg-secondary-100 text-white border-secondary-100'}`}
          onClick={onClick}
          ref={ref}
          aria-label="Select date range start"
        >
          <span className="flex">
            {start}
            <div
              className={`pointer-events-none absolute right-3 flex items-center ${
                calendarOpen ? 'top-3' : 'top-[0.813rem]'
              }`}
            >
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {!calendarOpen && (
                  <path
                    d="M1 0.5L5 4.5L9 0.5"
                    stroke="black"
                    strokeWidth="1.33333"
                    strokeLinejoin="round"
                  />
                )}
                {calendarOpen && (
                  <path
                    d="M1 5.5L5 1.5L9 5.5"
                    stroke={calendarOpen ? 'white' : 'black'}
                    strokeWidth="1.33333"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </div>
          </span>
        </button>
        <button
          className={`w-[6.813rem] example-custom-input border h-8 text-xs px-3 !leading-8 border-primary-60  bg-primary-40 rounded-r-lg font-medium flex ${
            calendarOpen &&
            'bg-secondary-100 text-white border-l-1 border-t-0 border-b-0 border-r-0 border-secondary-80 pt-px'
          }`}
          onClick={onClick}
          ref={ref}
        >
          <>
            {end}{' '}
            <div
              className={`pointer-events-none absolute right-3 flex items-center ${
                calendarOpen ? 'top-[0.813rem]' : 'top-[0.938rem]'
              }`}
            >
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {!calendarOpen && (
                  <path
                    d="M1 0.5L5 4.5L9 0.5"
                    stroke="black"
                    strokeWidth="1.33333"
                    strokeLinejoin="round"
                  />
                )}
                {calendarOpen && (
                  <path
                    d="M1 5.5L5 1.5L9 5.5"
                    stroke={calendarOpen ? 'white' : 'black'}
                    strokeWidth="1.33333"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </div>
          </>
        </button>
      </div>
    );
  });

  const renderDayContents = (_: any, date: any) => {
    const currentDate = format(date, 'dd/MM/yyyy');
    const today = format(new Date(), 'dd/MM/yyyy') === currentDate;
    const futureDate = new Date() < date;
    const start = startDate && format(startDate, 'dd/MM/yyyy');
    const end = endDate && format(endDate, 'dd/MM/yyyy');
    const selectedStart = currentDate && start && currentDate === start;
    const selectedEnd = currentDate && end && currentDate === end;
    const singleDay = start && end && start === end;
    const isBeforeMinimumDate = minimumDate && minimumDate > date;

    return (
      <span
        className={` relative block w-full h-full
        ${singleDay && 'rounded-full'} 
        ${selectedStart && 'bg-primary-100 rounded-l-full hover:brightness-90 text-white font-bold'}
        ${selectedEnd && 'bg-primary-100 rounded-r-full hover:brightness-90 text-white font-bold'}
        ${(today || futureDate || isBeforeMinimumDate) && 'text-neutral-40 hover:cursor-not-allowed'} '}
        `}
      >
        {getDate(date)}
      </span>
    );
  };

  function renderCustomHeader({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
  }: any) {
    return (
      <Header
        customHeaderCount={customHeaderCount}
        decreaseMonth={decreaseMonth}
        increaseMonth={disableMonthNavigation(increaseMonth, monthDate)}
        monthDate={monthDate}
      />
    );
  }

  const Container = ({ children }: any) => {
    return (
      <DateContainer
        children={children}
        updatePresetDateRange={updatePresetDateRange}
        presetDateRange={presetDateRange}
      />
    );
  };

  const onChange = (dates: any) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    setPresetDateRange('');
  };

  return (
    <DatePicker
      dateFormat="dd MMM yyyy"
      selected={startDate}
      onChange={onChange}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      openToDate={startDate || new Date()}
      customInput={<CustomInput />}
      renderDayContents={renderDayContents}
      renderCustomHeader={renderCustomHeader}
      monthsShown={2}
      focusSelectedMonth={true}
      onCalendarClose={() => setCalendarOpen(false)}
      onCalendarOpen={() => setCalendarOpen(true)}
      showPopperArrow={false}
      calendarContainer={Container}
      wrapperClassName="datepicker"
      shouldCloseOnSelect={false}
      minDate={minimumDate}
      maxDate={new Date()}
      disabledKeyboardNavigation
    />
  );
}
