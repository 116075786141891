import useUserSelector from 'state/user/useUserSelector';
import useSubscriptionRequests from 'api/hooks/requests/useSubscriptionRequests';
import { useState } from 'react';
import useSubscription from 'hooks/useSubscriptions';
import { useSearchParams } from 'react-router-dom';
import { PRO } from 'constants/plans';
import BillingSubscribe from './Components/BillingSubscribe';

export default function NewSubscription() {
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const { userPlan } = useUserSelector();
  const { createSubscription } = useSubscriptionRequests();
  const { hasUsedFreeTrial } = useSubscription();

  const [params] = useSearchParams();
  const priceId = params.get('priceId');

  const usedFreeTrial = userPlan && hasUsedFreeTrial(userPlan);

  async function subscribe() {
    if (!userPlan) return;
    setLoading(true);

    await createSubscription(PRO, priceId, { country: selectedCountry });
    setLoading(false);
    window.location.replace(`/?subscription=${PRO}`);
  }

  const buttonText = usedFreeTrial ? 'Subscribe' : 'Start free trial';

  return (
    <BillingSubscribe
      plan={PRO}
      subscribe={subscribe}
      buttonText={buttonText}
      loading={loading}
      disableButton={!selectedCountry}
      selectedCountry={selectedCountry}
      setSelectedCountry={setSelectedCountry}
    />
  );
}
