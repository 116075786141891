import { useEffect, useState, useContext } from 'react';
import { KeywordApproval, RunStep } from 'shared/legacy-types';
import { mapResultToKeywordApproval } from '../ResultsTable/helpers';
import {
  approvalsMatch,
  resultsWaitingApproval,
  filterDuplicates,
} from './keywordApprovals';
import useLoadApprovals from './useLoadApprovals';
import { HistoryDetailContext } from '../../History/RuleHistoryDetail';
import * as Sentry from '@sentry/react';

export function useKeywordApproval(step: RunStep) {
  const { results } = step || {};
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedResults, setSelectedResults] = useState<
    Omit<KeywordApproval, 'status'>[]
  >([]);
  const [approvedTermsCount, setApprovedTermsCount] = useState<number>(0);
  const [rejectedTermsCount, setRejectedTermsCount] = useState<number>(0);
  const [openNotice, setOpenNotice] = useState<boolean>(false);
  const [loadingSearchTerms, setLoadingSearchTerms] = useState<boolean>(false);
  const [waitingApprovalCount, setWaitingApprovalCount] = useState<number>(0);
  const [allApprovedOrRejected, setAllApprovedOrRejected] =
    useState<boolean>(false);
  const [approvalsLoadedFirstRender, setApprovalsLoadedFirstRender] =
    useState<boolean>(false);

  const { refetchResults, approveKeywordsRequest, getKeywordApprovalsRequest } =
    useContext(HistoryDetailContext) || {};

  const { keywordApprovals, approvalsLoading, setKeywordApprovals } =
    useLoadApprovals(
      results,
      setApprovalsLoadedFirstRender,
      getKeywordApprovalsRequest,
    );

  const resultsWaitingForApproval = resultsWaitingApproval(
    results,
    keywordApprovals,
  );

  useEffect(() => {
    if (!results || !keywordApprovals) return;
    if (keywordApprovals) {
      const waitingCount = resultsWaitingForApproval
        ? resultsWaitingForApproval.length
        : 0;
      setWaitingApprovalCount(waitingCount);
      // checks how many keywords have been approved or rejected against the total number of unique results
      setAllApprovedOrRejected(waitingCount === 0);
    }
    // eslint-disable-next-line
  }, [keywordApprovals]);

  function selectResult(keywordApproval: Omit<KeywordApproval, 'status'>) {
    const { adGroupIds } = keywordApproval;

    // if approval is already selected, remove from selected results
    const approvalExists = selectedResults.find((result) => {
      return approvalsMatch(result, keywordApproval);
    });

    if (approvalExists) {
      const filteredResults = selectedResults.filter((result) => {
        return !approvalsMatch(result, keywordApproval);
      });

      setSelectedResults(filteredResults);
      return;
    }

    const newSelectedResult = {
      ...keywordApproval,
      adGroupIds: adGroupIds ? adGroupIds : null,
    };
    setSelectedResults([...selectedResults, newSelectedResult]);
  }

  function selectAllResults(allSelected: boolean) {
    setAllSelected(allSelected);
    if (allSelected) {
      const filteredResults = filterDuplicates(results, keywordApprovals);
      const formattedResults =
        filteredResults && filteredResults.map(mapResultToKeywordApproval);
      setSelectedResults(formattedResults || []);
      return;
    }
    setSelectedResults([]);
  }

  async function approve() {
    setLoadingSearchTerms(true);
    setRejectedTermsCount(0);
    setOpenNotice(false);

    try {
      const approvedResults =
        selectedResults &&
        selectedResults.map((result) => {
          return {
            ...result,
            status: 'APPROVED',
          };
        });
      setSelectedResults(approvedResults);
      approveKeywordsRequest &&
        (await approveKeywordsRequest({
          approvals: approvedResults,
        }));
      setOpenNotice(true);
      refetchResults && refetchResults(true);
      setApprovedTermsCount(selectedResults.length);
      setKeywordApprovals([...keywordApprovals, ...approvedResults]);
      setSelectedResults([]);
      setLoadingSearchTerms(false);
      setTimeout(() => {
        setOpenNotice(false);
        setApprovedTermsCount(0);
      }, 5000);
    } catch (error) {
      Sentry.captureException(error);
      setSelectedResults([]);
      setLoadingSearchTerms(false);
    }
  }

  async function reject() {
    setLoadingSearchTerms(true);
    setApprovedTermsCount(0);
    setOpenNotice(false);
    const rejectedResults =
      selectedResults &&
      selectedResults.map((result) => {
        return {
          ...result,
          status: 'REJECTED',
        };
      });
    setSelectedResults(rejectedResults);
    try {
      approveKeywordsRequest &&
        (await approveKeywordsRequest({
          approvals: rejectedResults,
        }));
      setOpenNotice(true);
      refetchResults && refetchResults(true);
      setRejectedTermsCount(selectedResults.length);
      setKeywordApprovals([...keywordApprovals, ...rejectedResults]);
      setSelectedResults([]);
      setLoadingSearchTerms(false);
      setTimeout(() => {
        setOpenNotice(false);
        setRejectedTermsCount(0);
      }, 5000);
    } catch (error) {
      Sentry.captureException(error);
      setSelectedResults([]);
      setLoadingSearchTerms(false);
    }
  }

  return {
    allSelected,
    selectResult,
    selectedResults,
    selectAllResults,
    approve,
    reject,
    approvedTermsCount,
    rejectedTermsCount,
    openNotice,
    setOpenNotice,
    approvalsLoading,
    waitingApprovalCount,
    loadingSearchTerms,
    allApprovedOrRejected,
    keywordApprovals,
    mapResultToKeywordApproval,
    approvalsLoadedFirstRender,
  };
}
