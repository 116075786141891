export function FilledDownChevron() {
  return (
    <svg
      width="7"
      height="5"
      viewBox="0 0 7 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 4.5L6.09808 1.5H0.901924L3.5 4.5Z"
        className="fill-primary-100"
      />
    </svg>
  );
}
