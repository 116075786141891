import { ValueGetterParams } from 'ag-grid-enterprise';
import { CampaignTableHeader } from '../tableHeaders';
import { getValueAsNumber } from '../utils';
import { ratioCalculation } from './ratio';
import { percentageChangeCalculation } from './percentageChange';

export type PercentageChangeForRatioValue = {
  antecedent: number;
  consequent: number;
  previousAntecedent: number;
  previousConsequent: number;
  toString: () => string;
};

export function percentageChangeForRatioValueGetter(
  params: ValueGetterParams,
  antecedentField: CampaignTableHeader,
  consequentField: CampaignTableHeader,
  previousAntecedentField: CampaignTableHeader,
  previousConsequentField: CampaignTableHeader,
): PercentageChangeForRatioValue {
  const antecedent = getValueAsNumber(
    params.getValue(antecedentField),
    antecedentField,
    params.node?.group ?? false,
  );

  const consequent = getValueAsNumber(
    params.getValue(consequentField),
    consequentField,
    params.node?.group ?? false,
  );

  const previousAntecedent = getValueAsNumber(
    params.getValue(previousAntecedentField),
    previousAntecedentField,
    params.node?.group ?? false,
  );

  const previousConsequent = getValueAsNumber(
    params.getValue(previousConsequentField),
    previousConsequentField,
    params.node?.group ?? false,
  );

  let percentageChangeValue = 0;
  if (params.node && !params.node.group) {
    const ratio = ratioCalculation(antecedent, consequent);
    const previousRatio = ratioCalculation(
      previousAntecedent,
      previousConsequent,
    );
    percentageChangeValue = percentageChangeCalculation(ratio, previousRatio);
  }

  return {
    antecedent,
    consequent,
    previousAntecedent,
    previousConsequent,
    toString: () => `${percentageChangeValue}`,
  };
}

export function percentageChangeForRatioAggregationFunction(
  columnValues: PercentageChangeForRatioValue[],
): PercentageChangeForRatioValue {
  const antecedentSum = columnValues.reduce((sum, value) => {
    return sum + value.antecedent;
  }, 0);

  const consequentSum = columnValues.reduce((sum, value) => {
    return sum + value.consequent;
  }, 0);

  const previousAntecedentSum = columnValues.reduce((sum, value) => {
    return sum + value.previousAntecedent;
  }, 0);

  const previousConsequentSum = columnValues.reduce((sum, value) => {
    return sum + value.previousConsequent;
  }, 0);

  return {
    antecedent: antecedentSum,
    consequent: consequentSum,
    previousAntecedent: previousAntecedentSum,
    previousConsequent: previousConsequentSum,
    toString: () =>
      `${percentageChangeCalculation(
        ratioCalculation(antecedentSum, consequentSum),
        ratioCalculation(previousAntecedentSum, previousConsequentSum),
      )}`,
  };
}
