import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import { toast } from 'react-toastify';
import TextLink, { TextLinkStyle } from '../TextLink';
import { CrossIcon } from 'components/Icons';
import { CloseButtonProps } from 'react-toastify/dist/components';

export enum ToastStyle {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type ToastLink = {
  link: string;
  label: string;
};

export function showToast(
  message: string,
  style: ToastStyle,
  link?: ToastLink,
): void {
  const component = (
    <>
      {message}
      {link && (
        <>
          {' '}
          <TextLink
            link={link.link}
            label={link.label}
            style={TextLinkStyle.INTERNAL}
          />
        </>
      )}
    </>
  );
  toast[style](component);
}

function CloseButton(closeButtonProps: CloseButtonProps): JSX.Element {
  const { closeToast } = closeButtonProps;
  return (
    <span
      className="cursor-pointer"
      onClick={closeToast}
      data-testid="close-toast-icon"
    >
      <CrossIcon stroke={'stroke-neutral-80'} />
    </span>
  );
}

export default function Toast(): JSX.Element {
  return (
    <ToastContainer
      stacked
      hideProgressBar
      closeButton={(props) => CloseButton(props)}
    />
  );
}
