import { isToday } from 'date-fns';

interface props {
  date: string;
}

export default function ForamttedDate({ date }: props) {
  const browserLocale = navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language || 'en-US'; // default to US so we can spot it easily when testing
  const locale = browserLocale === 'en-US' ? 'en-US' : 'en-GB';

  return (
    <div>
      {date &&
        !isToday(Date.parse(date)) &&
        new Intl.DateTimeFormat(locale, {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
        }).format(Date.parse(date))}
      {date && isToday(Date.parse(date)) && (
        <span>
          Today at{' '}
          {new Intl.DateTimeFormat(locale, {
            hour: 'numeric',
            minute: 'numeric',
          }).format(Date.parse(date))}
        </span>
      )}
    </div>
  );
}
