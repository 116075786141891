import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  remove: (keepRules: boolean) => Promise<void>;
  removing: boolean;
  archivingRules: boolean;
  hasRules: boolean;
};

export default function RemoveFolderModal({
  open,
  setOpen,
  remove,
  removing,
  hasRules,
  archivingRules,
}: Props): JSX.Element {
  const title = hasRules
    ? 'You have rules in this folder'
    : 'Remove this folder';

  const description = hasRules
    ? 'If you want to keep your rules you can choose to do so below and fern will move them to the Home folder for you.'
    : "Please confirm you want to remove this folder. This action can't be undone.";

  const secondaryButton = hasRules ? (
    <SecondaryButton
      onClick={() => {
        void remove(false);
      }}
      disabled={archivingRules}
    >
      {removing && !archivingRules ? (
        <Spinner colour="stroke-primary-100" />
      ) : (
        <>Keep rules</>
      )}
    </SecondaryButton>
  ) : (
    <SecondaryButton
      onClick={() => {
        setOpen(false);
      }}
    >
      <>Cancel</>
    </SecondaryButton>
  );

  const primaryButton = hasRules ? (
    <PrimaryButton
      onClick={() => {
        void remove(true);
      }}
      disabled={removing && !archivingRules}
    >
      {removing && archivingRules ? (
        <Spinner colour="stroke-white" />
      ) : (
        <>Archive rules</>
      )}
    </PrimaryButton>
  ) : (
    <PrimaryButton
      onClick={() => {
        void remove(false);
      }}
    >
      {removing ? <Spinner colour="stroke-white" /> : <>Remove folder</>}
    </PrimaryButton>
  );

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-[24.313rem] bg-white p-6">
        <div className="flex justify-between">
          <h5 className="truncate font-bold">{title}</h5>
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="text-neutral-80 mt-1">{description}</div>
        <div className="flex justify-end pt-4">
          <div className="flex">
            {secondaryButton}
            <div className="ml-3">{primaryButton}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
