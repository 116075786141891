import { useState } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import TemplateModal from 'pages/Templates/TemplateModal';
import { Template } from 'shared/legacy-types';
import { templatesList } from 'constants/templates/templates';
import TemplateCard from 'pages/Templates/TemplateCard';
import TemplateHeader from './TemplateHeader';

export default function Templates() {
  const [open, setOpen] = useState<boolean>(false);
  const [currentTemplate, setCurrentTemplate] = useState<Template>();
  const [selection, setSelection] = useState<string>('all');

  const handleModal = (template: Template) => {
    setOpen(true);
    setCurrentTemplate(template);
  };

  const templateCards = (templates: Template[]) => {
    return templates.map((template: Template, index) => {
      return (
        <TemplateCard
          key={index}
          template={template}
          handleModal={handleModal}
        />
      );
    });
  };

  const templateSections = () => {
    return templatesList.map((section, index) => {
      if (selection === 'all' || section.name === selection) {
        return <div key={index}>{templateCards(section.templates)}</div>;
      }
      return null;
    });
  };

  return (
    <>
      <DashboardLayout>
        <div className={`bg-secondary-20`}>
          <TemplateHeader selection={selection} setSelection={setSelection} />
          <div className="pt-[8rem]">{templateSections()}</div>
        </div>
      </DashboardLayout>
      {open && (
        <TemplateModal
          open={open}
          setOpen={setOpen}
          template={currentTemplate!}
        />
      )}
    </>
  );
}
