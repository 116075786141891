import { ChevronRightCircle } from 'components/Icons';
import { AccountType, Brand } from 'codegen/graphql';
import Pill, { PillStyle } from 'design-system/components/Pill/Pill';
import useUserSelector from 'state/user/useUserSelector';

type Props = {
  brand: Brand;
};

enum BrandRetailDataStatus {
  CONNECTED = 'Connected',
  NOT_AVAILABLE = 'Not available',
  NOT_CONNECTED = 'Not connected',
}

export default function BrandStatus({ brand }: Props): JSX.Element {
  const { isInternalUser } = useUserSelector();
  const {
    marketplacesCount,
    accountType,
    marketplacesConnectedForAdsDataCount,
    marketplacesConnectedForRetailDataCount,
  } = brand;

  const adsDataConnected =
    marketplacesConnectedForAdsDataCount != null
      ? marketplacesConnectedForAdsDataCount > 0
      : false;

  const isVendor = accountType === AccountType.Vendor;

  const retailDataConnected =
    marketplacesConnectedForRetailDataCount != null
      ? marketplacesConnectedForRetailDataCount > 0
      : false;

  function connectedLabel(connectedCount: number): string {
    if (connectedCount === marketplacesCount) {
      return 'Connected';
    }

    return `${connectedCount} connected`;
  }

  function retailDataPillStyle(): PillStyle {
    if (retailDataConnected) {
      return PillStyle.PRIMARY;
    }

    return PillStyle.NEUTRAL;
  }

  function retailDataStatus(): string {
    if (isVendor) {
      return BrandRetailDataStatus.NOT_AVAILABLE;
    }

    if (retailDataConnected) {
      return connectedLabel(marketplacesConnectedForRetailDataCount ?? 0);
    }

    return BrandRetailDataStatus.NOT_CONNECTED;
  }

  return (
    <div>
      <div className="flex justify-between mt-5">
        <div className="flex">
          <div className="mr-8 w-[7.85rem]">
            <div className="text-xs text-neutral-80 mb-0.5">Ads data</div>
            <Pill
              label={
                adsDataConnected
                  ? connectedLabel(marketplacesConnectedForAdsDataCount ?? 0)
                  : 'Not connected'
              }
              style={adsDataConnected ? PillStyle.PRIMARY : PillStyle.NEUTRAL}
            />
          </div>
          {isInternalUser && (
            <div>
              <div className="text-xs text-neutral-80 mb-0.5">Retail data</div>
              <Pill label={retailDataStatus()} style={retailDataPillStyle()} />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-end">
          <ChevronRightCircle
            colour={'stroke-neutral-80 group-hover:stroke-neutral-100'}
          />
        </div>
      </div>
    </div>
  );
}
