import { useState } from 'react';
import { SpendTarget } from 'shared/legacy-types';
import useSpendTargetRequests from 'api/hooks/requests/useSpendTargetRequests';
import { addDecimalsToSpend } from './useCreateSpendTarget';
import * as Sentry from '@sentry/react';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

const defaultSpendTarget: SpendTarget = {
  profileId: '',
  monthlySpendTargets: [],
};

type UseEditSpendTargetHook = {
  openEditModal: (profileId: string) => void;
  editModalOpen: boolean;
  updatedSpendTarget: Partial<SpendTarget>;
  updateSpendTarget: () => Promise<void>;
  updatingSpendTarget: boolean;
  setEditModalOpen: (isOpen: boolean) => void;
  setUpdatedSpendTarget: (updatedSpendTarget: Partial<SpendTarget>) => void;
  spendTargetToEdit: string;
};

export default function useEditSpendTarget(): UseEditSpendTargetHook {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [updatingSpendTarget, setUpdatingSpendTarget] =
    useState<boolean>(false);
  const [updatedSpendTarget, setUpdatedSpendTarget] =
    useState<Partial<SpendTarget>>(defaultSpendTarget);
  const [spendTargetToEdit, setSpendTargetToEdit] = useState<string>('');

  const { updateSpendTargetRequest } = useSpendTargetRequests();

  function openEditModal(profileId: string): void {
    setEditModalOpen(true);
    setSpendTargetToEdit(profileId);
  }

  async function updateSpendTarget(): Promise<void> {
    setUpdatingSpendTarget(true);

    updatedSpendTarget.profileId = spendTargetToEdit;
    // format spend to 2 decimal places for system
    const formattedSpendTarget = addDecimalsToSpend(updatedSpendTarget);
    const editedSpendTarget = formattedSpendTarget as SpendTarget;

    try {
      await updateSpendTargetRequest(editedSpendTarget);
      setEditModalOpen(false);
      setUpdatingSpendTarget(false);
      showToast('Your changes have been saved', ToastStyle.SUCCESS);
    } catch (error) {
      Sentry.captureException(error);
      setEditModalOpen(false);
      setUpdatingSpendTarget(false);
    }
  }

  return {
    openEditModal,
    editModalOpen,
    updatedSpendTarget,
    updateSpendTarget,
    updatingSpendTarget,
    setEditModalOpen,
    setUpdatedSpendTarget,
    spendTargetToEdit,
  };
}
