export function showPercentIcon(selectedField: string) {
  if (
    selectedField === 'click_through_rate' ||
    selectedField === 'ad_conversion_rate' ||
    selectedField === 'acos' ||
    selectedField === 'marketplace_acos' ||
    selectedField === 'tacos'
  ) {
    return true;
  }
  return false;
}
