import { useContext } from 'react';

import { Rule, Step, IsValid } from 'shared/legacy-types';
import { ReactComponent as TrashIcon } from 'icons/generic/trash.svg';
import {
  DATE_OPTIONS,
  CAMPAIGNS,
  SEARCH_TERMS,
  PRODUCT_SIGNALS,
  LONG_PERIOD,
  SHORT_PERIOD,
} from 'ui/constants/rules';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import ActionContainer from 'pages/Rule/Steps/Action/ActionsContainer';
import Tooltip from 'ui/Tooltips/Tooltip';
import { AsteriskCircle, TickCircle, PlusIcon } from 'components/Icons';
import Dropdown from 'components/Dropdowns/Dropdown';
import ConditionGroups from './ConditionGroups/ConditionGroups';

interface Props {
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
  step: Step;
  isValid: IsValid;
  addNewGroup: (stepIndex: number) => void;
  removeStep: (stepIndex: number) => void;
  updatePeriod: (value: string, stepIndex: number) => void;
  updateStepName: (value: string, stepIndex: number) => void;
  onSave: () => void;
}

export default function StepComponent({
  rule,
  onChange,
  step,
  isValid,
  addNewGroup,
  removeStep,
  updatePeriod,
  updateStepName,
  onSave,
}: Props) {
  const { stepIndex } = useContext(StepContext) || {};
  const canRemoveStep = rule.steps.length > 1;
  const dataType = rule.dataType;
  const isProductSignals = dataType === PRODUCT_SIGNALS;

  const dateOptions = DATE_OPTIONS.filter((option) => {
    if (dataType !== CAMPAIGNS && option.value === SHORT_PERIOD) {
      return false;
    }

    if (dataType === SEARCH_TERMS && option.value !== LONG_PERIOD) {
      return false;
    }

    return true;
  });

  function saveStepName(event: any) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      document.getElementById('step-title')?.blur();
      onSave();
    }
  }

  const stepNameDefault = stepIndex + 1;

  return (
    <>
      <div className="border-neutral-40 mb-4 rounded-lg border bg-white pb-6">
        <div className="bg-secondary-20 flex justify-between rounded-t-lg px-6 py-4">
          <div className="flex">
            <h4 className="font-bold">
              <input
                size={(step.name && step.name?.length) || 6}
                id="step-title"
                placeholder={`Step ${stepNameDefault.toString()}`}
                className={`h-[1.625rem] my-0 bg-secondary-20 focus:outline-none w-full border-0 max-w-[300px] overflow-hidden truncate underline decoration-1	underline-offset-4  decoration-neutral-40 focus:decoration-primary-100 rounded-none`}
                onChange={(event) =>
                  updateStepName(event.target.value, stepIndex)
                }
                value={step.name || ''}
                onKeyDown={(event) => saveStepName(event)}
                aria-label="Update step title"
              />
            </h4>
            {isValid.steps[stepIndex] ? (
              <TickCircle />
            ) : (
              <AsteriskCircle background="fill-neutral-40" />
            )}
          </div>
          {canRemoveStep && (
            <div
              className="mt-0.5 hover:cursor-pointer"
              onClick={() => removeStep(stepIndex)}
            >
              <TrashIcon />
            </div>
          )}
        </div>
        <div className="mt-6 flex flex-col px-6">
          {!isProductSignals && (
            <div className="mt-2 flex">
              <div className="mr-4">
                <div className="flex">
                  <div className="text-neutral-80 mb-[0.3125rem] mr-1 text-xs">
                    Look back
                  </div>
                  <Tooltip
                    content={
                      <div>
                        fern will re-calculate this date-range every time the
                        rule is applied
                      </div>
                    }
                  />
                </div>
                <Dropdown
                  label="Select range"
                  options={dateOptions}
                  selectedValue={step.period}
                  selectValue={(value) => updatePeriod(value, stepIndex)}
                  styles="min-w-[11.25rem]"
                  position="absolute"
                />
              </div>
            </div>
          )}
          <h5 className={`${!isProductSignals && 'mt-6'} mb-2 font-bold`}>
            If
          </h5>
          <ConditionGroups step={step} rule={rule} onChange={onChange} />
          <div
            className="mb-6 mt-4 flex p-1 text-sm font-bold hover:cursor-pointer"
            onClick={() => addNewGroup(stepIndex)}
          >
            <div className="mr-1">
              <PlusIcon />
            </div>
            Condition group
          </div>
          <div className="border-neutral-40 mx-[-1.5rem] mb-6 border-t" />
          <h5 className="font-bold">Then</h5>
          <ActionContainer />
        </div>
      </div>
    </>
  );
}
