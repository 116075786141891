import { useState, createContext } from 'react';
import {
  useNavigate,
  useSearchParams,
  useMatch,
  Outlet,
} from 'react-router-dom';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import BrowserWidthModal from 'components/Modals/BrowserWidthModal';
import SomethingWentWrong from 'pages/Errors/SomethingWentWrong';
import useUserSelector from 'state/user/useUserSelector';
import useBannerSelector from 'state/banners/useBannerSelector';
import useBannerDispatch from 'state/banners/useBannerDispatch';
import useUserRequests from 'api/hooks/requests/useUserRequests';
import useUserDispatch from 'state/user/useUserDispatch';
import PlanPayment from 'pages/Subscribe/PlanPayment';
import ChangePlanModal from 'components/Modals/ChangePlan/ChangePlanModal';
import { PAUSED_STATUS } from 'ui/constants/subscriptions';
import VerifyEmailBanner from 'components/Banners/VerifyEmailBanner';
import { BannerType } from 'components/Banners/shared';
import Toast from 'design-system/components/Toast/Toast';
import Modal from 'components/Modals/Modal';
import Spinner from 'ui/Spinner/Spinner';
import useBrandRedirect from 'ui/hooks/useBrandRedirect';

type ContextData = {
  openChangePlanModal: (open: boolean) => void;
};

export const AppContext = createContext<ContextData>({
  openChangePlanModal: () => {},
});

export default function App(): JSX.Element | null {
  const [changePlanModalOpen, setChangePlanModalOpen] =
    useState<boolean>(false);

  const { user, userStatus, subscriptionStatus } = useUserSelector();
  const { updateUserStatus } = useUserDispatch();
  const { emailVerified } = user ?? {};
  const { getUser } = useUserRequests();

  const { banners } = useBannerSelector();
  const { addBanner } = useBannerDispatch();
  const [params] = useSearchParams();
  const token = params.get('token');
  const priceId = params.get('priceId');

  const match = useMatch('/change-password/*');
  const tokenExpired = useMatch('/token-expired');
  const storedAuthToken = localStorage.getItem('token');
  const isEmailVerified = emailVerified ?? false;

  const verifyBannerOpen = banners.length > 0;

  const isResetPasswordPage = match !== null;

  const navigate = useNavigate();
  const { redirectModalOpen } = useBrandRedirect();

  useEffect(() => {
    // eslint-disable-next-line
    !isResetPasswordPage && getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(
    function resetStatus() {
      if (userStatus === 'SUCCESS') {
        updateUserStatus('');
      }
    },
    [userStatus, updateUserStatus],
  );

  // Redirect to setup if user has no accounts or profiles
  useEffect(() => {
    // force user to connect at least 1 Amazon Ads Account and 1 Profile
    if (
      userStatus === 'SUCCESS' &&
      user &&
      (user.amazonAccounts.length === 0 || user.selectedProfiles.length === 0)
    ) {
      const priceIdParam = priceId != null ? `?priceId=${priceId}` : '';
      navigate(`setup${priceIdParam}`);
    }
  }, [user, userStatus, navigate, priceId]);

  // Token redirects
  useEffect(
    function redirect(): void {
      if (token != null) {
        return;
      }
      if (tokenExpired) {
        window.location.replace('/token-expired.html');
      }
      if (storedAuthToken == null) {
        window.location.replace('/login.html');
      }
    },
    [token, tokenExpired],
  );

  useEffect(() => {
    if (userStatus === 'SUCCESS' && !isEmailVerified) {
      addBanner({
        type: BannerType.VERIFY_EMAIL,
        message: '',
      });
    }
    // eslint-disable-next-line
  }, [userStatus, isEmailVerified]);

  if (
    userStatus === 'SUCCESS' &&
    user &&
    (user.amazonAccounts.length === 0 || user.selectedProfiles.length === 0)
  ) {
    const priceIdParam = priceId != null ? `?priceId=${priceId}` : '';
    navigate(`setup${priceIdParam}`);
  }

  if (redirectModalOpen) {
    return (
      <Modal open={true} setOpen={() => {}}>
        <div className="w-[40rem] bg-white p-6">
          <div className="flex justify-between">
            <h5 className="font-bold">Redirecting you back to your brand</h5>
            <Spinner />
          </div>
        </div>
      </Modal>
    );
  }

  // Should not show app if we have no user
  // unless reset password page
  if (!isResetPasswordPage && (userStatus === 'LOADING' || !user)) {
    return null;
  }

  // When free trial ends without payment
  if (subscriptionStatus === PAUSED_STATUS) {
    return (
      <>
        {verifyBannerOpen && <VerifyEmailBanner />}
        <PlanPayment />
      </>
    );
  }

  return (
    <AppContext.Provider
      value={{ openChangePlanModal: setChangePlanModalOpen }}
    >
      <BrowserWidthModal />
      <Sentry.ErrorBoundary
        fallback={({ resetError }) => (
          <SomethingWentWrong resetError={resetError} />
        )}
      >
        {verifyBannerOpen && <VerifyEmailBanner />}
        <Toast />
        <Outlet />
        {changePlanModalOpen && (
          <ChangePlanModal
            open={changePlanModalOpen}
            setOpen={setChangePlanModalOpen}
          />
        )}
      </Sentry.ErrorBoundary>
    </AppContext.Provider>
  );
}
