export function FilledRightChevron() {
  return (
    <svg
      width="4"
      height="9"
      viewBox="0 0 4 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8.5L4 4.5L0 0.5" className="fill-neutral-80" />
    </svg>
  );
}
