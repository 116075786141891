interface Props {
  colour?: string;
  size?: string;
}

export function TickIcon({
  colour = 'stroke-primary-100',
  size = '20',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91967 11.6403L10.1697 17.7653L18.15 6.675"
        className={`${colour}`}
        strokeWidth="2"
      />
    </svg>
  );
}
