interface Props {
  operator: string | undefined;
  operators?: string[];
  handleOperator?: (newOperator: string) => void;
  disabled?: boolean;
}

export default function InlineOperatorToggle({
  operator,
  operators,
  handleOperator,
  disabled,
}: Props) {
  function toggleOperator() {
    if (disabled) {
      return;
    }
    const operatorIndex = operators?.indexOf(operator!);

    let nextOperator;
    if (operatorIndex === operators?.length! - 1) {
      nextOperator = operators?.[0];
    } else {
      nextOperator = operators?.[operatorIndex! + 1];
    }
    handleOperator?.(nextOperator!);
  }

  const operatorText = () => {
    switch (operator) {
      case '&&':
        return 'AND';
      default:
        return 'OR';
    }
  };

  return (
    <div
      className={`bg-white border-r border-neutral-40 rounded-l focus:outline-none focus:shadow-outline pt-3 pb-[11px] pl-2 pr-[7px] text-sm font-bold min-w-[56px] flex justify-between text-neutral-80 ${
        !disabled && 'hover:cursor-pointer'
      }`}
      onClick={toggleOperator}
    >
      <div className="leading-[18px]">{operatorText()}</div>
      {!disabled && (
        <div className="ml-1 mt-[0.25rem]">
          <div
            className="w-0 h-0 mb-0.5 !border-b-primary-100"
            style={{
              borderLeft: '3px solid transparent',
              borderRight: '3px solid transparent',
              borderBottom: '4px solid',
            }}
          />
          <div
            className="w-0 h-0 !border-t-primary-100"
            style={{
              borderLeft: '3px solid transparent',
              borderRight: '3px solid transparent',
              borderTop: '4px solid',
            }}
          />
        </div>
      )}
    </div>
  );
}
