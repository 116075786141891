interface Props {
  strokeColour?: string;
  background?: string;
  iconColour?: string;
  size?: string;
}

export function SelectedActionTickIcon({
  strokeColour = 'stroke-white',
  background = 'fill-secondary-80',
  iconColour = 'stroke-primary-20',
  size = '16px',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        className={`${background} ${iconColour}`}
        strokeWidth="2"
      />
      <path
        d="M4.99737 7.69868L7.24476 10.3206L10.6609 5.57317"
        className={`${strokeColour}`}
        strokeWidth="1.49826"
      />
    </svg>
  );
}
