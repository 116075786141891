import { ChevronRightCircle, LargeLockIcon } from 'components/Icons';
import { Template } from 'shared/legacy-types';
import ComingSoonBadge from 'components/Images/ComingSoonBadge';

interface Props {
  handleModal: (template: Template) => void;
  template: Template;
}

export default function TemplateCard({ handleModal, template }: Props) {
  const { rule, description, comingSoon, goal, image: Svg } = template;

  const permissionGranted = true;

  return (
    <div
      className="border border-neutral-40 flex cursor-pointer mb-4 relative rounded"
      onClick={() => handleModal(template)}
    >
      <div className={`relative`}>
        <div className={`${!permissionGranted && 'brightness-50'}`}>
          <Svg rounded="rounded-l" height="100%" />
        </div>
        {!permissionGranted && (
          <div className="absolute left-[1.75rem] top-[3.875rem]">
            <LargeLockIcon />
          </div>
        )}
      </div>
      <div className="w-full min-h-[8.188rem] p-5 rounded flex flex-col justify-between bg-white">
        <div className="pr-4">
          <h5 className="font-bold mr-8">{rule.name}</h5>
          <div className="text-sm mt-1 text-neutral-80 line-clamp-2">
            {description}
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <div className="border border-primary-100 h-6 rounded text-primary-100 text-sm !leading-[1.375rem] px-[0.438rem] font-bold my-auto">
            {goal}
          </div>
          <div>
            <ChevronRightCircle size="32px" />
          </div>
        </div>
      </div>
      {comingSoon && (
        <div className="absolute right-0">
          <ComingSoonBadge />
        </div>
      )}
    </div>
  );
}
