import { useState, useRef } from 'react';
import { TickIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';

interface Option {
  label: string;
  value: string;
  source?: string;
  dataTypes?: string[];
}

interface Props {
  label?: string;
  options: Option[];
  selectedValue: string | undefined;
  selectValue: (value: string) => void;
  showOperator?: boolean;
  type?: string;
  ariaLabel?: string;
  error?: boolean;
}

export default function ConditionDropdown({
  label,
  options,
  selectedValue,
  selectValue,
  showOperator,
  type,
  ariaLabel,
  error,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const isCenter = type === 'center';
  const isRegular = type === 'regular';

  useClickOutside(setOpen, ref, dropdownRef);

  function select(value: string) {
    selectValue(value);
    setOpen(false);
  }

  function getLabel() {
    const selectedLabel = options.find(
      (option) => option.value === selectedValue,
    )?.label;

    if (selectedLabel) {
      return selectedLabel;
    }

    return <div className="text-neutral-80 truncate">{label}</div>;
  }

  function renderDropdownMenu() {
    function renderOptions() {
      function getOptions(options: Option[]) {
        return options.map((option, index) => {
          const { label, value } = option;
          const isSelected = value === selectedValue;
          const isFirst = index === 0;
          const isLast = index + 1 === options.length;

          return (
            <div
              key={index}
              className={`bg-white p-3 rounded text-sm hover:cursor-pointer flex justify-between
              ${isSelected && 'py-[0.563rem] font-bold !leading-5'} 
              ${isFirst && 'pt-0 pb-[0.375rem]'} 
              ${isLast && 'pb-0'} 
              `}
              onClick={() => select(value)}
            >
              {label}
              {isSelected && <TickIcon />}
            </div>
          );
        });
      }

      return <div className="hover:cursor-pointer">{getOptions(options)}</div>;
    }

    return (
      <div
        aria-label="List of metrics"
        className="absolute !z-50 w-80  bg-white rounded-xl mt-1"
        style={{ boxShadow: '0rem 0.5rem 1rem -0.625rem rgba(0, 0, 0, 0.08)' }}
      >
        <div
          ref={ref}
          className="py-6 px-4 text-sm border rounded-xl border-neutral-40 text-left hover:cursor-pointer overflow-y-auto scrollbar-hide  max-h-[25.25rem]"
        >
          {renderOptions()}
        </div>
      </div>
    );
  }

  return (
    <div aria-label={ariaLabel}>
      <div
        ref={dropdownRef}
        className="text-sm text-left text-neutral-100 hover:cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div
          tabIndex={0}
          className={`w-full focus:border-primary-100 focus:border-2 focus:pl-[0.875rem] focus:pt-2.5 pt-[0.688rem] pl-4 h-[2.5625rem] flex   
          pb-[0.688rem] pr-0.5 focus:pr-px 
          ${
            error && !open
              ? 'bg-error bg-opacity-[.2] border-error border border-opacity-[.4] focus:bg-white pl-[0.935rem]'
              : 'border-r pl-4 bg-white border-neutral-40'
          }
          ${!showOperator && !isCenter && 'rounded-l'} 
          ${isRegular && 'border rounded w-[11.25rem] font-bold focus:pl-[0.94rem] focus:pt-[0.612rem]'}
          `}
        >
          <span className="truncate my-auto mr-1">{getLabel()}</span>
          <span
            className={`pointer-events-none relative justify-end mr-4 mx-auto my-auto`}
          >
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {open ? (
                <path
                  d="M1 5.5L5 1.5L9 5.5"
                  className="stroke-black"
                  strokeWidth="1.33333"
                  strokeLinejoin="round"
                />
              ) : (
                <path
                  d="M1 0.5L5 4.5L9 0.5"
                  className="stroke-neutral-80"
                  strokeWidth="1.33333"
                  strokeLinejoin="round"
                />
              )}
            </svg>
          </span>
        </div>
      </div>
      {open && options.length > 0 && renderDropdownMenu()}
    </div>
  );
}
