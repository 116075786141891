import { RoundedSquareIcon, DiamondIcon } from 'components/Icons';
import { AdGroup } from 'shared/legacy-types';

interface Props {
  campaignName: string;
  adGroups: AdGroup[] | null;
}

export default function Campaign({ campaignName, adGroups }: Props) {
  function formatAdGroupsLabel(adGroups: AdGroup[] | null) {
    // Set to return all ad groups until we can request to get ad group names
    if (adGroups === null) {
      return 'All ad groups';
    }

    const firstAdGroup = adGroups[0];

    if (!firstAdGroup) {
      throw new Error('No ad group found');
    }

    return firstAdGroup.adGroupName;
  }

  return (
    <div className="basis-[7rem] flex-auto mr-6 mt-0.5 truncate self-center">
      <div className="text-sm flex pr-1">
        <span className="mr-2 mt-1">
          <RoundedSquareIcon />
        </span>
        <span className="truncate text-neutral-100">{campaignName}</span>
      </div>
      <div className="text-xs flex mt-1">
        <span className="mr-2 mt-[0.188rem] ml-4">
          <DiamondIcon />
        </span>
        <span className="truncate text-neutral-80">
          {formatAdGroupsLabel(adGroups)}
        </span>
      </div>
    </div>
  );
}
