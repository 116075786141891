interface props {
  fill?: string;
  disabled?: boolean;
}

export function PlusIcon({ fill = 'fill-primary-100', disabled }: props) {
  disabled && (fill = 'fill-neutral-60');
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" y="2" width="2" height="12" className={`${fill}`} />
      <rect
        x="2"
        y="9"
        width="2"
        height="12"
        transform="rotate(-90 2 9)"
        className={`${fill}`}
      />
    </svg>
  );
}
