import { createContext } from 'react';

import { Rule, Step, IsValid } from 'shared/legacy-types';
import StepComponent from 'pages/Rule/Steps/StepComponent';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { DEFAULT_RULE, DEFAULT_STEP } from 'ui/constants/rules';
import useSteps from './useSteps';

interface Props {
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
  isValid: IsValid;
  onSave: () => void;
}

type ContextData = {
  stepIndex: number;
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
  step: Step;
};

export const StepContext = createContext<ContextData>({
  stepIndex: 0,
  rule: DEFAULT_RULE,
  onChange: () => {},
  step: DEFAULT_STEP,
});

export default function Steps({ rule, onChange, isValid, onSave }: Props) {
  const { steps } = rule || {};
  const { addStep, removeStep, addNewGroup, updatePeriod, updateStepName } =
    useSteps(rule, onChange);

  function renderSteps() {
    return steps?.map((step, index) => {
      return (
        <StepContext.Provider
          value={{ stepIndex: index, rule, step, onChange }}
          key={index}
        >
          <StepComponent
            rule={rule}
            step={step}
            onChange={onChange}
            isValid={isValid}
            removeStep={removeStep}
            addNewGroup={addNewGroup}
            updatePeriod={updatePeriod}
            updateStepName={updateStepName}
            onSave={onSave}
          />
        </StepContext.Provider>
      );
    });
  }

  return (
    <>
      {renderSteps()}
      <div className="mb-6">
        {steps && steps.length < 10 && (
          <SecondaryButton onClick={() => addStep()}>
            <>Add step</>
          </SecondaryButton>
        )}
      </div>
    </>
  );
}
