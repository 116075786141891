import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  removeAction: () => void;
}

export default function RemoveActionModal({
  open,
  setOpen,
  removeAction,
}: Props) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6">
        <h5 className="font-bold">Remove action</h5>
        <div className="text-neutral-80 mr-4 mt-1">
          Are you sure you want to remove this action?
        </div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton onClick={() => setOpen(false)}>
            <>Cancel</>
          </SecondaryButton>
          <div className="ml-4">
            <PrimaryButton onClick={() => removeAction()}>
              <>Remove</>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
