import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { User, Access, Config } from 'shared/legacy-types';

export interface UserState {
  user: User | null;
  access: Access | null;
  status: string;
  config: Config | null;
}

const initialState: UserState = {
  user: null,
  access: null,
  status: '',
  config: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<UserState>) {
      state.user = action.payload.user;
      state.access = action.payload.access;
      state.config = action.payload.config;
      Sentry.setUser({
        email: action.payload.user?.email,
        username: `${action.payload.user?.firstName} ${action.payload.user?.lastName}`,
      });
    },
    updateUserStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
  },
});

export const { updateUser, updateUserStatus } = userSlice.actions;
export default userSlice.reducer;
