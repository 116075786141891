import DashboardLayout from 'layouts/DashboardLayout';
import Chart from './Chart/Chart';
import useReporting from './useReporting';
import ControlBar from './ControlBar';
import useDates from 'pages/Reporting/useDates';
import ReportingDashboard from './Dashboard/ReportingDashboard';
import ReportingSetup from './ReportingSetup';
import PermissionsGate from 'components/Permissions/PermissionsGate';
import { FEATURES } from 'components/Permissions/PermissionsGate';
import useDashboard from './Dashboard/useDashboard';

export default function Reporting(): JSX.Element {
  const { updateEndDate, updateStartDate, startDate, endDate, minimumDate } =
    useDates();

  const {
    brandFilter,
    marketplaceFilter,
    setMarketplaceFilter,
    updateBrandFilter,
    marketplaceOptions,
    brandOptions,
    reportingProfileData,
    dataLoading,
    currencyCode,
    profileTotals,
    totalsLoading,
  } = useReporting(startDate, endDate);

  const {
    dashboardLoading,
    addVisualisation,
    deleteVisualisation,
    deletingVisualisation,
    addDashboardLoading,
    saveDashboard,
    dashboardError,
    visualisations,
    updatingVisualisationRunning,
  } = useDashboard(marketplaceFilter);

  return (
    <DashboardLayout>
      <PermissionsGate
        scopes={[FEATURES.reporting]}
        renderNoAccess={<ReportingSetup />}
      >
        <div className="max-w-full h-full">
          <div className="mt-[-8px] flex">
            <ControlBar
              brandFilter={brandFilter}
              marketplaceFilter={marketplaceFilter}
              setBrandFilter={updateBrandFilter}
              marketplaceOptions={marketplaceOptions}
              brandOptions={brandOptions}
              setEndDate={updateEndDate}
              setStartDate={updateStartDate}
              startDate={startDate}
              endDate={endDate}
              minimumDate={minimumDate}
              setMarketplaceFilter={setMarketplaceFilter}
              saveDashboard={saveDashboard}
              dashboardLoading={dashboardLoading}
            />
          </div>
          <div className="my-2 w-full h-full mb-4">
            <Chart
              dashboardLegend
              data={reportingProfileData}
              loading={dataLoading || totalsLoading}
              currencyCode={currencyCode}
              totals={profileTotals}
            />
          </div>
          <div className="border-t border-neutral-40 sm:px-[-1.5rem] md:mx-[-1.5rem] lg:mx-[-1.5rem] xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem]" />
          <ReportingDashboard
            startDate={startDate}
            endDate={endDate}
            profileId={marketplaceFilter}
            currencyCode={currencyCode}
            dashboardLoading={dashboardLoading}
            dashboardError={dashboardError}
            saveDashboard={saveDashboard}
            addVisualisation={addVisualisation}
            deleteVisualisation={deleteVisualisation}
            deletingVisualisation={deletingVisualisation}
            addDashboardLoading={addDashboardLoading}
            visualisations={visualisations}
            updatingVisualisationRunning={updatingVisualisationRunning}
          />
        </div>
      </PermissionsGate>
    </DashboardLayout>
  );
}
