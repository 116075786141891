import Subscribe from './Subscribe';
import useSubscriptionRequests from 'api/hooks/requests/useSubscriptionRequests';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Plan } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import NewSubscription from './NewSubscription';
import {
  planDoesNotExist,
  movingBetweenPaidPlans,
  movingToStarterPlan,
  movingToPaidPlan,
} from './helpers/plans';

export default function ChangePlan() {
  const [loading, setLoading] = useState(false);
  const [loadingPortal, setLoadingPortal] = useState(false);

  const { plan: selectedPlan } = useParams<{ plan: Plan }>();
  const { isInternalUser, userPlan } = useUserSelector();
  const navigate = useNavigate();
  const { createStripePortalSession, updateSubscription } =
    useSubscriptionRequests();

  const alreadyOnPlan = selectedPlan === userPlan;
  const noPlan = selectedPlan && planDoesNotExist(selectedPlan);
  const movingToStarter = movingToStarterPlan(selectedPlan, userPlan);
  const movingToPaid = movingToPaidPlan(selectedPlan, userPlan);
  const changingPlan = movingBetweenPaidPlans(
    selectedPlan,
    userPlan,
    alreadyOnPlan,
  );

  const buttonText = 'Change plan';

  async function subscribe() {
    if (!selectedPlan) return;
    setLoading(true);
    await updateSubscription(selectedPlan);
    setLoading(false);
    navigate(`/?subscription=${selectedPlan}`);
  }

  // 1. if user is moving to starter plan, they need to visit stripe portal to cancel their current paid subscription
  // 2. if user is moving to a paid plan from the starter plan, they need to create a new subscription
  // 3. if user is moving between paid plans, they need to update their current subscription

  useEffect(() => {
    async function visitPortal() {
      if (movingToStarter) {
        setLoadingPortal(true);
        const response = await createStripePortalSession('subscription_cancel');
        const url = response.headers['location'];
        window.location.replace(url);
      }
    }
    isInternalUser && visitPortal();
    // eslint-disable-next-line
  }, []);

  if (alreadyOnPlan || noPlan) {
    navigate('/');
  }

  if (loadingPortal) {
    return (
      <div className="h-screen flex">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  if (!isInternalUser) {
    return null;
  }

  if (movingToPaid) {
    return <NewSubscription />;
  }

  if (changingPlan) {
    return (
      <Subscribe
        plan={selectedPlan}
        subscribe={subscribe}
        buttonText={buttonText}
        loading={loading}
      />
    );
  }

  return null;
}
