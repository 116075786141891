import { AdGroup } from 'shared/legacy-types';

interface Props {
  open: boolean;
  adGroupSelections: AdGroup[] | null;
}

export default function Chevron({ open, adGroupSelections }: Props) {
  const firstAdGroup = adGroupSelections && adGroupSelections[0];

  function chevronPosition() {
    // extra conditions added to position chevron if initial empty ad group is present in array
    if (
      open &&
      adGroupSelections &&
      adGroupSelections.length >= 1 &&
      firstAdGroup &&
      firstAdGroup.adGroupId !== ''
    ) {
      return 'top-8';
    }
    if (
      (open && adGroupSelections && adGroupSelections.length === 0) ||
      (adGroupSelections &&
        adGroupSelections.length === 1 &&
        firstAdGroup &&
        firstAdGroup.adGroupId === '')
    ) {
      return 'top-[1.375rem]';
    }
    if (
      (!open && adGroupSelections && adGroupSelections.length >= 1) ||
      adGroupSelections === null
    ) {
      return 'top-[2.125rem]';
    }
    if (
      (!open && adGroupSelections && adGroupSelections.length === 0) ||
      (adGroupSelections && firstAdGroup && firstAdGroup.adGroupId === '')
    ) {
      return 'top-6';
    }

    return null;
  }

  return (
    <div
      className={`pointer-events-none absolute my-auto ${chevronPosition()} right-4 flex items-center`}
    >
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!open && (
          <path
            d="M1 0.5L5 4.5L9 0.5"
            className="stroke-neutral-80"
            strokeWidth="1.33333"
            strokeLinejoin="round"
          />
        )}
        {open && (
          <path
            d="M1 5.5L5 1.5L9 5.5"
            className={`${open ? 'stroke-black' : 'stroke-neutral-80'}`}
            strokeWidth="1.33333"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </div>
  );
}
