import useVerifyEmail from 'hooks/setup/useVerifyEmail';
import { InfoIcon } from 'components/Icons';
import useUserSelector from 'state/user/useUserSelector';

export default function Subheader() {
  const { resendEmail, verifySent, sending } = useVerifyEmail();
  const { user } = useUserSelector();
  const emailVerified = user?.emailVerified;

  if (emailVerified) {
    return (
      <div className="text-sm">
        Enter your team's emails below to share your workspace with them
      </div>
    );
  }

  return (
    <div className="bg-primary-100/20 border-primary-100/30 mb-3 mt-2 flex rounded border px-[0.688rem] py-[0.4rem]">
      <span className="mr-1.5 ">
        <InfoIcon />
      </span>
      <div>
        <div className="mt-[0.032rem] text-xs">
          Verify your email using the link we’ve sent you to start sharing your
          workspace with your team.{' '}
          {verifySent ? (
            <span className="ml-1 font-bold underline">Email sent</span>
          ) : (
            <span
              className={`${sending && 'animate-pulse'} ml-1 cursor-pointer font-bold underline`}
              onClick={resendEmail}
            >
              Resend email
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
