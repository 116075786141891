import { useContext, useEffect, useState } from 'react';

import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import {
  Action,
  ActionGroup,
  ActionTab,
  CampaignLink,
} from 'shared/legacy-types';
import {
  ACTION_GROUPS,
  CHANGE_STATUS,
  ACTION_DEFAULTS,
} from 'ui/constants/actions';
import { getFirstActionDefault } from 'ui/helpers/actions';
import useUserSelector from 'state/user/useUserSelector';
import { CAMPAIGNS, SEARCH_TERMS } from 'ui/constants/rules';
import useCampaignLinkRequests from 'api/hooks/requests/useCampaignLinksRequests';
import { deepClone } from 'ui/helpers/deepClone';

export default function useActionModal(
  setSelectedAction: (action: string) => void,
  setSelection: (selection: Action) => void,
  selection: Action,
  selectedAction: string,
  open: boolean,
) {
  const [hasCampaignLink, setHasCampaignLink] = useState<boolean>(false);
  const [campaignLinksLoading, setCampaignLinksLoading] =
    useState<boolean>(false);
  const { step, rule, stepIndex, onChange } = useContext(StepContext);
  const { isInternalUser } = useUserSelector();
  const { getCampaignLinksRequest } = useCampaignLinkRequests();

  useEffect(() => {
    async function fetchCampaignLinks() {
      setHasCampaignLink(false);
      setCampaignLinksLoading(true);
      const response = await getCampaignLinksRequest();
      setCampaignLinksLoading(false);
      const hasLink = response.data.some((campaignLink: CampaignLink) =>
        rule.profileIds.includes(parseInt(campaignLink.profileId)),
      );
      setHasCampaignLink(hasLink);
    }
    rule.dataType === SEARCH_TERMS && open && fetchCampaignLinks();
    // eslint-disable-next-line
  }, [open]);

  const showPauseEnable =
    !isInternalUser &&
    rule.dataType === CAMPAIGNS &&
    (selectedAction === 'paused' || selectedAction === 'enabled');

  // when user changes action tab update selection to default
  // or if action exists set selection to action.
  function updateAction(selectedActionType: string) {
    setSelectedAction(selectedActionType);

    if (selectedActionType === 'enabled' || selectedActionType === 'paused') {
      setSelection({
        ...ACTION_DEFAULTS[CHANGE_STATUS],
        properties: { entityType: 'campaigns', status: selectedActionType },
      });
      return;
    }

    setSelection(
      ACTION_DEFAULTS[selectedActionType as keyof typeof ACTION_DEFAULTS],
    );
  }

  function save(setOpen: (open: boolean) => void) {
    const copiedRule = deepClone(rule);

    copiedRule!.steps[stepIndex!].actions = [
      ...copiedRule!.steps[stepIndex!].actions,
      selection,
    ];

    onChange?.({ ...copiedRule });

    setSelection(
      getFirstActionDefault(rule.dataType!, step.actions!, isInternalUser),
    );
    setOpen(false);
  }

  const getActionTab = () => {
    let foundAction = {} as ActionTab;
    ACTION_GROUPS.forEach((group: ActionGroup) => {
      const found = group.actions.find((action: any) => {
        return action.type === selectedAction;
      });
      if (found) {
        foundAction = found as ActionTab;
      }
    });

    return foundAction;
  };

  return {
    updateAction,
    save,
    getActionTab,
    showPauseEnable,
    hasCampaignLink,
    campaignLinksLoading,
  };
}
