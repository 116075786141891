import { useContext } from 'react';

import { PAUSE_ENABLE_OPTIONS } from 'ui/constants/actions';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import { TickCircle } from 'components/Icons';

interface Props {
  actionType: 'paused' | 'enabled';
}

export default function PauseEnable({ actionType }: Props) {
  const { rule } = useContext(StepContext);
  const dataType = PAUSE_ENABLE_OPTIONS.find(
    (option) => option.value === rule.dataType,
  )?.label;

  const text = () => {
    if (actionType === 'paused') {
      return 'Pause';
    } else {
      return 'Enable';
    }
  };

  return (
    <>
      <div className="mt-2 text-sm">
        <div className="text-neutral-80 pb-6 leading-6 border-b border-neutral-40 mb-6">
          {text()}
          <>
            <span className="font-bold"> {dataType}</span> when your conditions
            are met.
          </>
        </div>
      </div>
      <div className="flex justify-center mt-[11.813rem]">
        <div>
          <div className="flex justify-center mb-2">
            <TickCircle
              iconColour="stroke-white"
              background="fill-secondary-40"
              size="20"
            />
          </div>
          <div className="text-neutral-60">
            This action has no other settings
          </div>
        </div>
      </div>
    </>
  );
}
