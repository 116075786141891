export type StepProgress = {
  name: string;
  currentStep: boolean;
};

interface Props {
  stepsProgress: StepProgress[];
}

export default function ProgressBar({ stepsProgress }: Props) {
  // current -> Empty green circle
  // completed & previous -> small filled circle
  // future -> empty grey circle
  const current = (
    <div className="w-4 h-4 border-2 rounded-full border-primary-100 z-50" />
  );

  const complete = (
    <div className="w-2 h-2 border-2 rounded-full border-primary-100 bg-primary-100 mt-1 relative z-50" />
  );

  const future = (isLast: boolean) => (
    <div
      className={`${
        isLast ? 'bg-white' : 'bg-neutral-40'
      } w-2 h-2 border rounded-full border-neutral-40 bg-neutral-40 mt-1 z-50`}
    />
  );

  const currentStepIndex = stepsProgress
    .map((step) => step.currentStep)
    .indexOf(true);

  const renderProgressBar = () => {
    // Add step to the end of the progress bar which will always show
    const progress = [
      ...stepsProgress,
      { name: 'complete', currentStep: false },
    ];

    return progress.map((step, index) => {
      const futureStep = currentStepIndex < index;
      const { currentStep } = step;
      const isFirst = index === 0;
      const isLast = index === progress.length - 1;

      const line = (position: string, state: string) => {
        const isPre = position === 'pre';
        const isPost = position === 'post';

        if ((isFirst && isPre) || (isLast && isPost)) {
          return null;
        }

        // Active green line
        if (state === 'active') {
          return (
            <div
              className={`w-[0.625rem] h-0.5 bg-primary-100 leading-6 mt-[0.438rem] ${isPre ? 'mr-1' : 'ml-1'}`}
            />
          );
        }

        // Inactive grey line
        return (
          <div
            className={`w-[0.625rem] h-0.5 bg-neutral-40 leading-6 mt-[0.438rem]  ${isPre ? 'mr-1' : 'ml-1'}`}
          />
        );
      };

      if (currentStep) {
        return (
          <>
            {line('pre', 'active')}
            {current}
            {line('post', 'inactive')}
          </>
        );
      }

      if (futureStep) {
        return (
          <>
            {line('pre', 'inactive')}
            {future(isLast)}
            {line('post', 'inactive')}
          </>
        );
      }

      return (
        <>
          {line('pre', 'active')}
          {complete}
          {line('post', 'active')}
        </>
      );
    });
  };

  return <div className="flex relative">{renderProgressBar()}</div>;
}
