interface Props {
  height?: string;
  rounded?: string;
}

export default function CirclesImage({ height = '131', rounded = '' }: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_5373_39110)">
        <rect width="76" height="131" className="fill-secondary-80" />
        <g>
          <circle
            cx="37.9982"
            cy="64.0001"
            r="32.8667"
            className="stroke-primary-100"
            strokeWidth="5.00519"
          />
        </g>
        <g>
          <circle
            cx="37.9975"
            cy="63.9995"
            r="43.3465"
            className="stroke-primary-100"
            strokeWidth="5.00519"
          />
        </g>
        <g>
          <circle
            cx="38"
            cy="64"
            r="53.2722"
            className="stroke-primary-100"
            strokeWidth="1.45553"
          />
        </g>
        <g>
          <circle
            cx="38.0011"
            cy="63.9991"
            r="23.2602"
            className="stroke-primary-100"
            strokeWidth="5.00519"
          />
        </g>
        <g>
          <circle
            cx="38.0009"
            cy="63.9999"
            r="9.86937"
            className="stroke-primary-100"
            strokeWidth="5.00519"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5373_39110">
          <rect width="76" height="131" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
