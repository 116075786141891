import { TickIcon } from 'components/Icons';
import Tooltip from 'ui/Tooltips/Tooltip';
import { FIELD_HEADERS } from 'ui/constants/rules';
import { Option } from './useFieldDropdown';

interface Props {
  option: Option;
  prefix: string | undefined;
  selectedValue: string | undefined;
  ruleHasSpendTargets: boolean;
  select: (value: string, prefix?: string) => void;
  index: number;
  optionsCount: number;
}

export default function FieldOption({
  option,
  prefix,
  selectedValue,
  ruleHasSpendTargets,
  select,
  index,
  optionsCount,
}: Props) {
  const { label, value } = option;
  const isSelected = `${prefix}:${value}` === selectedValue;
  const isFirst = index === 0;
  const isLast = index + 1 === optionsCount;

  const tooltip = option.tooltip;
  const disableOption =
    prefix === FIELD_HEADERS.marketplace && !ruleHasSpendTargets;

  const styles = `bg-white 
  ${isSelected && 'py-[0.563rem] font-bold !leading-5'} 
  ${isFirst && 'pt-0 h-[1.7rem]'} 
  ${isLast && 'pb-0'} flex justify-between rounded p-3 text-sm hover:cursor-pointer 
  ${disableOption && 'text-neutral-60 hover:cursor-not-allowed'}
  `;

  return (
    <div
      key={index}
      className={styles}
      onClick={() => !disableOption && select(value, prefix)}
    >
      <div className="flex">
        {label}
        {tooltip && !disableOption && (
          <span className="ml-1 mt-0.5">
            <Tooltip
              content={tooltip.content}
              useHover
              width="w-fit"
              position={tooltip.position}
            />
          </span>
        )}
      </div>
      {isSelected && <TickIcon />}
    </div>
  );
}
