export const browserLocale = () => {
  return navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language || 'en-US';
};

export const getInternationalNumber = (
  value: number,
  currency: string,
  style: string,
  minFractionDigits: number,
  maxFractionDigits: number,
) => {
  return new Intl.NumberFormat(browserLocale(), {
    style: style,
    currency: currency,
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(value);
};
