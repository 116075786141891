import { TickCircle } from 'components/Icons';
import { CrossIcon } from 'components/Icons';

interface Props {
  approvedTermsCount: number;
  rejectedTermsCount: number;
  setOpenNotice: (value: boolean) => void;
}

export default function KeywordNotice({
  approvedTermsCount,
  rejectedTermsCount,
  setOpenNotice,
}: Props) {
  return (
    <div>
      <span className={`absolute left-[40%] bottom-[-1rem] `}>
        <span className="h-[1.875rem] max-w-fit p-1.5 rounded bg-neutral-100 text-white text-xs flex">
          <span className="mb-1">
            <TickCircle
              iconColour="stroke-white"
              background="fill-primary-100"
            />
          </span>
          <span className="ml-2 mt-px">
            {approvedTermsCount > 0 && (
              <span>
                {approvedTermsCount} keyword{approvedTermsCount > 1 && 's'}{' '}
                added
              </span>
            )}
            {rejectedTermsCount > 0 && (
              <span>
                {rejectedTermsCount} keyword{rejectedTermsCount > 1 && 's'}{' '}
                rejected
              </span>
            )}
          </span>
          <div
            className="ml-2 justify-end cursor-pointer"
            onClick={() => setOpenNotice(false)}
          >
            <CrossIcon stroke={'stroke-neutral-60'} />
          </div>
        </span>
      </span>
    </div>
  );
}
