import { useState } from 'react';
import { Rule, Optional } from 'shared/legacy-types';
import useUser from 'hooks/useUser';

export default function useSpendTargetAlert(rule: Optional<Rule, 'id'>) {
  const [showSpendTargetAlert, setShowSpendTargetAlert] = useState(false);

  const { allProfilesHaveSpendTargets } = useUser();

  function spendTargetCheck(profileIds?: number[]) {
    // If rule has no marketplace fields then return early
    const hasMarketplaceFields =
      rule &&
      rule.steps.some((step) => {
        return step.conditionGroups.some((group) => {
          return group.conditions.some((condition) => {
            return condition.field.includes('marketplace');
          });
        });
      });

    if (!hasMarketplaceFields) return;
    // if rule has no profile ids then open modal
    if (!profileIds || profileIds.length === 0) {
      setShowSpendTargetAlert(true);
      return;
    }

    // if not all profiles have SpendTargets then open modal
    if (!allProfilesHaveSpendTargets(profileIds)) setShowSpendTargetAlert(true);
  }

  return { setShowSpendTargetAlert, showSpendTargetAlert, spendTargetCheck };
}
