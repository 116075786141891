export default function ColourSchemeComponent() {
  return (
    <section id="typography" className="p-5 flex flex-col max-w-4xl">
      <div className="flex flex-row">
        <div className="flex-1">
          <div className="bg-primary-100 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Primary-100</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-primary-80 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Primary-80</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-primary-60 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Primary-60</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-primary-40 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Primary-40</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-primary-20 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Primary-20</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-10">
        <div className="flex-1">
          <div className="bg-secondary-100 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Secondary-100</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-secondary-80 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Secondary-80</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-secondary-60 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Secondary-60</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-secondary-40 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Secondary-40</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-secondary-20 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Secondary-20</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-10">
        <div className="flex-1">
          <div className="bg-neutral-100 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Neutral-100</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-neutral-80 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Neutral-80</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-neutral-60 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Neutral-60</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-neutral-40 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Neutral-40</span>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="bg-neutral-20 rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">Neutral-20</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-10">
        <div className="w-1/5">
          <div className="bg-white border-neutral-40 border rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm">White</span>
            </div>
          </div>
        </div>
        <div className="w-1/5">
          <div className="bg-error rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Error</span>
            </div>
          </div>
        </div>
        <div className="w-1/5">
          <div className="bg-success rounded-lg w-40 h-20 relative">
            {' '}
            <div className="absolute bottom-2 left-2">
              <span className="text-sm text-white">Success</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
