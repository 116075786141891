import useBannerSelector from 'state/banners/useBannerSelector';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { Plan } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';
import UserInfo from './Components/UserInfo';
import PlanSection from './Components/PlanSection';

interface Props {
  subscribe: () => void;
  buttonText: string;
  plan?: Plan;
  trialEnded?: boolean;
  loading?: boolean;
}

export default function Subscribe({
  plan,
  trialEnded,
  subscribe,
  buttonText,
  loading,
}: Props) {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  return (
    <div>
      <div className={`${bannerOpen && 'mt-[1.5rem]'} flex`}>
        <PlanSection trialEnded={trialEnded} plan={plan} />
        <div className="w-1/2 h-full min-h-screen px-20 flex flex-col justify-center">
          <div className="">
            <h2 className="font-bold mb-3">Continue as</h2>
            <UserInfo />
            <div className="flex justify-end mt-5">
              <PrimaryButton onClick={subscribe}>
                {loading ? (
                  <Spinner colour="stroke-white" />
                ) : (
                  <>{buttonText}</>
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
