import OperatorToggle from 'components/OperatorToggle';
import ConditionGroup from 'pages/Rule/Steps/ConditionGroups/ConditionGroup';
import { Rule, ConditionGroupOperator, Step } from 'shared/legacy-types';
import { useContext } from 'react';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';

interface Props {
  step: Step;
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
}

export default function ConditionGroups({ step, rule, onChange }: Props) {
  const { stepIndex } = useContext(StepContext) || {};

  function handleOperator(newOperator: string) {
    const step = rule.steps[stepIndex];
    if (!step) {
      throw new Error('No step found, so cannot update operator');
    }

    step.operator = newOperator as ConditionGroupOperator;
    onChange({ ...rule });
  }

  function renderConditionGroups() {
    const { conditionGroups, operator } = step || {};

    return conditionGroups?.map((group, index) => {
      const isLast = index === conditionGroups.length - 1;
      const showFirst = index === 0 && conditionGroups.length > 1;

      return (
        <div key={index}>
          <ConditionGroup
            conditions={group.conditions}
            operator={group.operator}
            rule={rule}
            onChange={onChange}
            groupIndex={index}
          />
          {showFirst && (
            <div className="my-2">
              <OperatorToggle
                operator={operator}
                operators={['&&', '||']}
                handleOperator={handleOperator}
              />
            </div>
          )}
          {!isLast && !showFirst && (
            <div className="my-2">
              <OperatorToggle operator={operator} disabled />
            </div>
          )}
        </div>
      );
    });
  }

  return <>{renderConditionGroups()}</>;
}
