import TextLink, {
  TextLinkFontSize,
  TextLinkStyle,
} from 'design-system/components/TextLink';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';

type Props = {
  setOpenModal: (open: boolean) => void;
  brandName?: string;
};

export default function Header({
  setOpenModal,
  brandName,
}: Props): JSX.Element {
  return (
    <div className="mb-3 flex justify-between">
      <h5 className="font-medium leading-[2.5625rem] text-neutral-100 ">
        <span className={'mr-1'} data-testid={'brands-breadcrumb-link'}>
          {brandName != null ? (
            <TextLink
              link="/settings"
              label="Brands"
              style={TextLinkStyle.BREADCRUMB}
              size={TextLinkFontSize.LARGE}
            />
          ) : (
            'Brands'
          )}
        </span>
        {brandName != null && (
          <>
            &gt;<span className="ml-1">{brandName}</span>
          </>
        )}
      </h5>
      {brandName == null && (
        <PrimaryButton
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <>Add marketplaces</>
        </PrimaryButton>
      )}
    </div>
  );
}
