import { createContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import RuleCard from 'pages/Dashboard/Rules/RuleCard';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import useRules from 'pages/Dashboard/Rules/useRules';
import ArchiveModal from 'pages/Dashboard/Archived/ArchiveModal';
import Dashboard from 'pages/Dashboard/Dashboard';
import MoveRulesModal from '../Modals/MoveRulesModal';
import EditFolderModal from '../Modals/EditFolderModal';
import RuleToolbar from './RuleToolbar';
import useFoldersSelector from 'state/folders/useFoldersSelector';
import RemoveFolderModal from '../Modals/RemoveFolderModal';
import useFolders from '../Folders/useFolders';
import useRenameFolder from '../Folders/useRenameFolder';
import useInitRules from './useInitRules';
import useSubscribedAlert from 'hooks/subscription/useSubscribedAlert';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type ContextData = {
  openRemoveModal: (open: boolean) => void;
  openEditModal: (open: boolean) => void;
};

export const RulesPageContext = createContext<ContextData>({
  openRemoveModal: () => {},
  openEditModal: () => {},
});

export default function RulesPage(): JSX.Element {
  const {
    rules,
    setRules,
    duplicateRule,
    openArchiveModal,
    archiveModalOpen,
    ruleToDelete,
    setArchiveModalOpen,
    removeRule,
    archiving,
  } = useRules();
  const { rulesLoading, rulesSuccess } = useInitRules(setRules);
  const {
    removeFolder,
    removeFolderModalOpen,
    setRemoveFolderModalOpen,
    rulesToMove,
    setRulesToMove,
    setMoveRulesModalOpen,
    moveRulesModalOpen,
    moveRulesToNewFolder,
    moveFolder,
    setMoveFolder,
    movingRules,
    removingFolder,
  } = useFolders(rules, setRules);

  const {
    editFolderModalOpen,
    setEditFolderModalOpen,
    updatedFolderName,
    setUpdatedFolderName,
    updatingFolder,
    editFolder,
    currentFolder,
  } = useRenameFolder();

  const { folders } = useFoldersSelector();
  const [searchParams, setSearchParams] = useSearchParams();
  const isVerified = searchParams.get('verified') === 'true';
  const isNotVerified = searchParams.get('verified') === 'false';

  useEffect(() => {
    if (isVerified) {
      showToast('Email verified', ToastStyle.SUCCESS);
    }
    if (isNotVerified) {
      showToast('Verification email expired', ToastStyle.WARNING);
    }
    searchParams.delete('verified');
    setSearchParams(searchParams);
    // eslint-disable-next-line
  }, []);

  useSubscribedAlert();

  const allSelected = !rulesLoading && rulesToMove.length === rules.length;
  const hasSelections = rulesToMove.length > 0;
  const showToolbar = folders.length > 0 && rules.length > 0;

  function selectRule(ruleId: string): void {
    if (rulesToMove.includes(ruleId)) {
      setRulesToMove(rulesToMove.filter((id) => id !== ruleId));
    } else {
      setRulesToMove([...rulesToMove, ruleId]);
    }
  }

  function selectAllRules(): void {
    if (rulesToMove.length === rules.length) {
      setRulesToMove([]);
    } else {
      setRulesToMove(rules.map((rule) => rule.id));
    }
  }

  function renderRules(): JSX.Element | JSX.Element[] {
    if (rulesLoading) {
      return (
        <div className="h-screen flex">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      );
    }

    if (rulesSuccess && rules.length === 0) {
      return (
        <div className="mt-[2.75rem] text-center">
          <h4 className="font-bold">You don't have any rules here</h4>
        </div>
      );
    }

    return rules.map((rule) => {
      const isSelected = rulesToMove.includes(rule.id);
      return (
        <RuleCard
          data-testid="ruleCard"
          key={rule.id}
          rule={rule}
          duplicateRule={duplicateRule}
          openArchiveModal={openArchiveModal}
          isSelected={isSelected}
          selectRule={selectRule}
        />
      );
    });
  }

  return (
    <RulesPageContext.Provider
      value={{
        openRemoveModal: setRemoveFolderModalOpen,
        openEditModal: setEditFolderModalOpen,
      }}
    >
      <Dashboard>
        <div className="">
          {showToolbar && !rulesLoading && (
            <RuleToolbar
              allSelected={allSelected}
              hasSelections={hasSelections}
              setMoveRulesModalOpen={setMoveRulesModalOpen}
              selectAllRules={selectAllRules}
            />
          )}
          <div className={`${showToolbar && 'mt-6'}`}>{renderRules()}</div>
          {archiveModalOpen && (
            <ArchiveModal
              open={archiveModalOpen}
              setOpen={setArchiveModalOpen}
              rule={ruleToDelete}
              archiveRule={removeRule}
              archiving={archiving}
            />
          )}
          {moveRulesModalOpen && (
            <MoveRulesModal
              open={moveRulesModalOpen}
              setOpen={setMoveRulesModalOpen}
              moveRules={moveRulesToNewFolder}
              moveFolder={moveFolder}
              setMoveFolder={setMoveFolder}
              rulesCount={rulesToMove.length}
              movingRules={movingRules}
            />
          )}
          {removeFolderModalOpen && (
            <RemoveFolderModal
              open={removeFolderModalOpen}
              setOpen={setRemoveFolderModalOpen}
              remove={removeFolder}
              removing={removingFolder.removingFolder}
              archivingRules={removingFolder.archiveRules}
              hasRules={rules.length > 0}
            />
          )}
          {editFolderModalOpen && (
            <EditFolderModal
              open={editFolderModalOpen}
              setOpen={setEditFolderModalOpen}
              renameFolder={editFolder}
              setUpdatedFolderName={setUpdatedFolderName}
              currentFolder={currentFolder}
              updatedFolderName={updatedFolderName}
              updatingFolder={updatingFolder}
            />
          )}
        </div>
      </Dashboard>
    </RulesPageContext.Provider>
  );
}
