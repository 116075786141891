interface Props {
  label: string | undefined;
  options: { value: string; label: string }[];
  selectedValues: string[];
}

export default function Label({ label, options, selectedValues }: Props) {
  const selectedLabel = options.find(
    (option) => option.value === selectedValues[0],
  )?.label;

  // if one only option is selected, show the label of the option
  if (selectedValues?.length === 1) {
    return <>{selectedLabel}</>;
  }

  // if multiple options are selected, show the number of selected options
  if (selectedValues?.length > 1) {
    return <>{selectedValues.length} selected</>;
  }

  return <div className={`text-neutral-80 font-normal`}>{label}</div>;
}
