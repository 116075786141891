import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';

export default function useInitRules(setRules: (rules: any) => void) {
  const [rulesLoading, setRulesLoading] = useState<boolean>(false);
  const [rulesSuccess, setRulesSuccess] = useState<boolean>(false);
  const { folderId } = useParams();

  const { getRulesByFolder } = useRuleRequests();

  useEffect(() => {
    const fetchRules = async () => {
      setRulesLoading(true);
      const rules = await getRulesByFolder(folderId);
      setRules(rules);
      setRulesLoading(false);
      setRulesSuccess(true);
    };
    fetchRules();
    // eslint-disable-next-line
  }, [folderId]);

  return { rulesLoading, rulesSuccess };
}
