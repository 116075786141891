import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { PlusIcon } from 'components/Icons';
import RadioDropdown from 'ui/components/Dropdowns/RadioDropdown';
import useSpendTargets from 'pages/SpendTargets/hooks/useSpendTargets';
import SpendTargetSetup from 'pages/SpendTargets/Setup/SpendTargetSetup';
import SpendTargets from 'pages/SpendTargets/SpendTargets';
import DeleteSpendTargetModal from 'pages/SpendTargets/Modals/DeleteSpendTargetModal';
import useEditSpendTarget from 'pages/SpendTargets/hooks/useEditSpendTarget';
import EditSpendTargetModal from './Modals/Edit/EditSpendTargetModal';
import useCreateSpendTarget from 'pages/SpendTargets/hooks/useCreateSpendTarget';
import CreateSpendTargetModal from 'components/SpendTargets/CreateSpendTargetModal';
import DashboardLayout from 'layouts/DashboardLayout';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import useFetchUser from './hooks/useFetchUser';
import PermissionsGate from 'components/Permissions/PermissionsGate';
import AddTargetButton from './Setup/AddTargetButton';
import UpgradeCallToAction from '../../components/Buttons/UpgradeCallToAction';
import { FEATURES } from 'components/Permissions/PermissionsGate';

export default function SpendTargetsPage() {
  const {
    brandFilter,
    setBrandFilter,
    openDeleteModal,
    deleteModalOpen,
    setDeleteModalOpen,
    deletingSpendTarget,
    deleteSpendTarget,
    filterSpendTargets,
    spendTargetToDelete,
    spendTargetProfileIds,
    setSpendTargetProfileIds,
    filterBrandOptions,
    showDeleteFailedMessage,
  } = useSpendTargets();
  const {
    updateCreateModalOpen,
    createModalOpen,
    newSpendTarget,
    setNewSpendTarget,
    addSpendTarget,
    creatingSpendTarget,
  } = useCreateSpendTarget(spendTargetProfileIds, setSpendTargetProfileIds);
  const {
    editModalOpen,
    openEditModal,
    updateSpendTarget,
    updatingSpendTarget,
    setEditModalOpen,
    updatedSpendTarget,
    setUpdatedSpendTarget,
    spendTargetToEdit,
  } = useEditSpendTarget();

  const { userLoaded, loadingUser } = useFetchUser(spendTargetProfileIds);

  if (loadingUser) {
    return (
      <DashboardLayout>
        <div className="flex">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  function renderSpendTargets() {
    if (
      spendTargetProfileIds &&
      spendTargetProfileIds.length === 0 &&
      userLoaded
    ) {
      return (
        <SpendTargetSetup
          callToAction={<AddTargetButton openModal={updateCreateModalOpen} />}
        />
      );
    }

    return (
      <>
        <div className="mb-4 flex justify-between">
          <h2 className="font-bold">Spend targets</h2>
          <PrimaryButton onClick={() => updateCreateModalOpen(true)}>
            <div className="flex">
              <span className="mr-1">
                <PlusIcon fill="fill-white" />
              </span>
              Add target
            </div>
          </PrimaryButton>
        </div>
        <div className="mb-6 flex">
          <RadioDropdown
            label="Brand"
            options={filterBrandOptions}
            selectedValue={brandFilter || 'all'}
            onChange={(event) => setBrandFilter(event.target.value)}
            width="w-[8.75rem]"
          />
        </div>
        <div className="border-neutral-40 mb-6 border-b" />
        <SpendTargets
          openDeleteModal={openDeleteModal}
          openEditModal={openEditModal}
          filterSpendTargets={filterSpendTargets}
        />
      </>
    );
  }

  return (
    <DashboardLayout>
      <PermissionsGate
        scopes={[FEATURES.spendTargets]}
        renderNoAccess={
          <SpendTargetSetup callToAction={<UpgradeCallToAction />} />
        }
      >
        <>
          {renderSpendTargets()}
          {deleteModalOpen && (
            <DeleteSpendTargetModal
              open={deleteModalOpen}
              setOpen={setDeleteModalOpen}
              deleting={deletingSpendTarget}
              deleteSpendTarget={deleteSpendTarget}
              spendTargetToDelete={spendTargetToDelete}
              showDeleteFailedMessage={showDeleteFailedMessage}
            />
          )}
          {editModalOpen && (
            <EditSpendTargetModal
              setOpen={setEditModalOpen}
              open={editModalOpen}
              spendTarget={updatedSpendTarget}
              setSpendTarget={setUpdatedSpendTarget}
              updateSpendTarget={updateSpendTarget}
              updatingSpendTarget={updatingSpendTarget}
              spendTargetToEdit={spendTargetToEdit}
            />
          )}
          {createModalOpen && (
            <CreateSpendTargetModal
              open={createModalOpen}
              setOpen={updateCreateModalOpen}
              newSpendTarget={newSpendTarget}
              setNewSpendTarget={setNewSpendTarget}
              addSpendTarget={addSpendTarget}
              creatingSpendTarget={creatingSpendTarget}
              spendTargetProfileIds={spendTargetProfileIds}
            />
          )}
        </>
      </PermissionsGate>
    </DashboardLayout>
  );
}
