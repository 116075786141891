import './LargeSpinner.css';

export default function LargeSpinner() {
  return (
    <svg className="largeSpinner stroke-primary-100" viewBox="0 0 50 50">
      <circle
        className="spinnerPath"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
}
