import { TickIcon } from 'components/Icons';
import { AdGroup } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';
import Tooltip from 'ui/Tooltips/Tooltip';

interface Props {
  adGroups: AdGroup[];
  adGroupSelections: AdGroup[] | null;
  setAdGroupSelections: (value: AdGroup[] | null) => void;
  position?: string;
  adGroupsLoading: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  setSearchTerm: (value: string) => void;
}

export default function AdGroupDropdownMenu({
  adGroups,
  adGroupSelections,
  setAdGroupSelections,
  position,
  adGroupsLoading,
  open,
  setOpen,
  setSearchTerm,
}: Props) {
  function selectAdGroup(adGroup: AdGroup) {
    // remove searched characters when ad group selected
    setSearchTerm('');

    const allAdGroupsSelectedIndividually =
      adGroupSelections && adGroups.length === adGroupSelections.length;

    const adGroupAlreadySelected =
      adGroupSelections &&
      adGroupSelections.find(
        (adGroupFromSelections) =>
          adGroupFromSelections.adGroupId === adGroup.adGroupId,
      );

    const allAdGroupsSelectedWithOptionAll = adGroupSelections === null;

    // if all ad groups selected, deselect all ad groups and clear state
    if (
      adGroup.adGroupId === 'all' &&
      (allAdGroupsSelectedIndividually || allAdGroupsSelectedWithOptionAll)
    ) {
      setAdGroupSelections([]);
      return;
    }
    // select all ad groups
    if (adGroup.adGroupId === 'all' && !allAdGroupsSelectedIndividually) {
      setAdGroupSelections(null);
      setOpen(!open);
      return;
    }
    //if all ad groups already selected, remove selected ad group from array
    if (allAdGroupsSelectedWithOptionAll) {
      const filteredAdGroups = adGroups.filter(
        (adGroups) => adGroups.adGroupId !== adGroup.adGroupId,
      );
      return setAdGroupSelections(filteredAdGroups);
    }

    if (adGroupAlreadySelected) {
      const filteredAdGroupSelection = adGroupSelections?.filter(
        (adGroupSelection) => adGroupSelection.adGroupId !== adGroup.adGroupId,
      );
      return setAdGroupSelections(filteredAdGroupSelection);
    }

    if (adGroupSelections) {
      setOpen(true);
      return setAdGroupSelections([...adGroupSelections, adGroup]);
    }
  }

  const renderAdGroups = () => {
    const selectAll = [{ adGroupName: 'Select all', adGroupId: 'all' }];
    const allAdGroups = selectAll.concat(adGroups);

    return allAdGroups.map((adGroup, index) => {
      if (adGroup.adGroupId === '') {
        return null;
      }
      const selected =
        adGroupSelections &&
        adGroupSelections.some(
          (selection) => selection.adGroupId === adGroup.adGroupId,
        );
      const allSelected =
        (adGroupSelections && adGroups.length === adGroupSelections.length) ||
        adGroupSelections === null;
      return (
        <>
          <div
            key={index}
            className="p-3 flex justify-between hover:cursor-pointer group relative hover:bg-neutral-20 hover:rounded"
            onClick={() => selectAdGroup(adGroup)}
          >
            {adGroup.adGroupId === 'all' && (
              <span className="absolute left-[4.8rem] bottm-[0.125rem]">
                <Tooltip
                  content={
                    <div>
                      Includes all future ad groups that are added to this
                      campaign
                    </div>
                  }
                  useHover
                />
              </span>
            )}
            {adGroups !== null && (
              <div
                className={`truncate max-w-[18rem] ${(selected || allSelected) && 'font-bold'}`}
              >
                {adGroup.adGroupName}
              </div>
            )}
            {(selected || allSelected) && (
              <div className="mt-[-0.188rem]">
                <TickIcon />
              </div>
            )}
            {adGroup.adGroupId !== 'all' && (
              <span className="absolute top-[-2rem] z-50 p-2 rounded bg-neutral-100 text-white text-xs group-hover:visible invisible">
                {adGroup.adGroupName}
              </span>
            )}
          </div>
        </>
      );
    });
  };

  if (adGroups.length === 0) {
    return null;
  }

  if (adGroupsLoading) {
    return (
      <div
        className={`absolute w-[6rem] !z-50 mt-1 top-[0rem] right-[-4.75rem] rounded-lg bg-white`}
      >
        <div
          className={`rounded-xl border p-4 text-sm border-neutral-20 scrollbar-hide max-h-[14.75rem] overflow-scroll text-left`}
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          <div className="w-4 m-auto">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`absolute w-[20rem] !z-50 mt-1 top-[0rem] right-[-18.75rem] rounded-lg bg-white ${position} `}
      aria-label="Ad group dropdown list"
    >
      <div
        className={`rounded-xl border p-4 text-sm border-neutral-20 scrollbar-hide max-h-[14.75rem] overflow-scroll text-left`}
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
      >
        <div className="text-xs font-bold mb-2 text-secondary-100">
          Ad groups
        </div>
        <span>{renderAdGroups()}</span>
      </div>
    </div>
  );
}
