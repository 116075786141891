import { useState, useEffect } from 'react';
import { subDays, format } from 'date-fns';

interface Props {
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  startDate: Date | null;
}
export default function useDatePicker({
  setStartDate,
  setEndDate,
  startDate,
}: Props) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [presetDateRange, setPresetDateRange] = useState<string>('30');
  const today = new Date();
  const yesterday = subDays(new Date(), 1);
  const currentMonth = today.getMonth();

  // update presetDateRange based on start date
  useEffect(() => {
    if (
      startDate &&
      format(startDate, 'dd MM yyyy') === format(yesterday, 'dd MM yyyy')
    ) {
      setPresetDateRange('yesterday');
      return;
    }
    if (
      startDate &&
      format(startDate, 'dd MM yyyy') ===
        format(subDays(new Date(), 7), 'dd MM yyyy')
    ) {
      setPresetDateRange('7');
      return;
    }
    if (
      startDate &&
      format(startDate, 'dd MM yyyy') ===
        format(subDays(new Date(), 30), 'dd MM yyyy')
    ) {
      setPresetDateRange('30');
      return;
    }

    setPresetDateRange('');
    //eslint-disable-next-line
  }, [startDate]);

  function updatePresetDateRange(dateRangeValue: string) {
    // sets range to yesterday as single date
    setPresetDateRange(dateRangeValue);
    if (dateRangeValue === 'yesterday') {
      setStartDate(yesterday);
      setEndDate(yesterday);
      return;
    }
    // sets start date to date range value
    setStartDate(subDays(new Date(), parseInt(dateRangeValue)));
    setEndDate(yesterday);
    return;
  }

  // function to disable navigation to future months as future dates are not available
  function disableMonthNavigation(
    functionToDisable: () => void,
    monthDate: Date,
  ) {
    if (
      monthDate.getMonth() === currentMonth ||
      monthDate.getMonth() === currentMonth + 1
    ) {
      return () => {};
    }
    return functionToDisable;
  }

  return {
    updatePresetDateRange,
    calendarOpen,
    setCalendarOpen,
    presetDateRange,
    setPresetDateRange,
    disableMonthNavigation,
  };
}
