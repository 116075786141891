interface props {
  background?: string;
  iconColour?: string;
}

export function SendEmailIcon({
  background = 'fill-primary-80',
  iconColour = 'fill-white',
}: props) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" className={background} />
      <path
        d="M26.8511 4.51517L18.9688 28.1622L13.2759 18.0903L3.20405 12.3975L26.8511 4.51517Z"
        className={iconColour}
      />
      <path
        d="M20.2825 11.0833L13.7138 20.2793L12.1812 19.1846L11.0864 17.6519L20.2825 11.0833Z"
        className={background}
      />
    </svg>
  );
}
