import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { PlusIcon } from 'components/Icons/';

interface Props {
  openAddModal: (open: boolean) => void;
}

export default function AddLinkButton({ openAddModal }: Props) {
  return (
    <PrimaryButton onClick={() => openAddModal(true)}>
      <div className="flex">
        Add campaigns
        <span className="ml-1">
          <PlusIcon fill="fill-white" />
        </span>
      </div>
    </PrimaryButton>
  );
}
