import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import useUser from 'hooks/useUser';
import Spinner from 'ui/Spinner/Spinner';
import { CampaignLink } from 'shared/legacy-types';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  campaignLinkToDelete: CampaignLink;
  deleteCampaignLink: () => void;
  deletingLink: boolean;
}

export default function DeleteCampaignLinkModal({
  open,
  setOpen,
  campaignLinkToDelete,
  deleteCampaignLink,
  deletingLink,
}: Props) {
  const { getProfileData } = useUser();
  const profileData = getProfileData(parseInt(campaignLinkToDelete.profileId));
  const { countryCode, accountName } = profileData || {};

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-[24.5rem] bg-white p-6">
        <h5 className="font-bold">Delete link between campaigns</h5>
        <div className="text-neutral-80 mt-1">
          Are you sure you want to delete this link between campaigns for{' '}
          <span className="font-bold">
            {accountName} {countryCode}
          </span>
          ?
        </div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton onClick={() => setOpen(false)}>
            <>Cancel</>
          </SecondaryButton>
          <div className="ml-3">
            <PrimaryButton onClick={() => deleteCampaignLink()}>
              <>
                {deletingLink && (
                  <div className="w-[2.718rem] pl-[0.728rem]">
                    <Spinner colour={'stroke-white'} />
                  </div>
                )}
                {!deletingLink && <span>Delete</span>}
              </>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
