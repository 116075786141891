import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { PlayIcon } from 'components/Icons';
import { sendEvent } from 'helpers/analytics';
import { PRODUCT_SIGNALS } from 'ui/constants/rules';
import { Optional, Rule } from 'shared/legacy-types';
import HoverTooltip from 'ui/Tooltips/HoverTooltip';

interface Props {
  rule: Optional<Rule, 'id'>;
  isRuleValid: boolean | undefined;
  checkInvalidFields: () => void;
  isEditing: boolean;
}

export default function PreviewButton({
  rule,
  isRuleValid,
  checkInvalidFields,
  isEditing,
}: Props) {
  const navigate = useNavigate();

  // New rules must be saved before they can be previewed
  const newRule = rule?.id === undefined;
  const disablePreview = rule?.dataType === PRODUCT_SIGNALS || newRule;
  const isInstantRule = rule?.dataType === PRODUCT_SIGNALS;

  function handlePreview() {
    if (!isRuleValid) {
      checkInvalidFields();
      return;
    }
    sendEvent('click_preview');
    navigate(`/rules/${rule.id}/preview`);
  }

  return (
    <div
      onClick={() => !disablePreview && handlePreview()}
      className="relative group"
    >
      <SecondaryButton disabled={disablePreview}>
        <div className={`flex ${disablePreview && 'text-neutral-60'}`}>
          Preview{' '}
          <div className="my-auto ml-2 inline">
            <PlayIcon
              colour={disablePreview ? 'fill-neutral-40' : 'fill-primary-100'}
            />
          </div>
        </div>
      </SecondaryButton>
      {isInstantRule && (
        <HoverTooltip message="You can't use the Preview with instant rules" />
      )}
      {newRule && isEditing && !isInstantRule && (
        <HoverTooltip message="Save rule to preview" />
      )}
    </div>
  );
}
