import { useState, useEffect } from 'react';
import { ExpressionIcon } from 'components/Icons';

interface Props {
  enable?: boolean;
  toggle?: (enabled: boolean) => void;
}

export default function ExpressionToggle({ enable, toggle }: Props) {
  const [enabled, setEnabled] = useState<boolean>();

  useEffect(() => {
    setEnabled(enable);
  }, [enable]);

  function handleToggle() {
    setEnabled(!enabled);
    toggle && toggle(!enabled);
  }

  return (
    <button
      type="button"
      className={`relative inline-flex h-6 w-[2.625rem] flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent
        transition-colors duration-200 ease-in-out focus:outline-none
        ${enabled ? 'bg-secondary-100' : 'bg-neutral-40'}`}
      role="switch"
      aria-checked={enabled}
      onClick={handleToggle}
      aria-label="Use expression"
    >
      {enabled && (
        <span className="-mt-0.5">
          <ExpressionIcon fill="fill-white" stroke="stroke-white" />
        </span>
      )}
      <span
        aria-hidden="true"
        className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow transition duration-100 ease-in-out 
          ${enabled ? 'translate-x-0.5' : 'translate-x-0'}`}
      />
      {!enabled && (
        <span className="-mt-0.5 ml-0.5">
          <ExpressionIcon />
        </span>
      )}
    </button>
  );
}
