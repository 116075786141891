import useBannerSelector from 'state/banners/useBannerSelector';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { Plan } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';
import PlanSection from './PlanSection';
import SearchDropdown from 'components/Dropdowns/SearchDropdown/SearchDropdown';
import { countries } from 'countries-list';

interface Props {
  plan?: Plan;
  subscribe: () => void;
  buttonText: string;
  disableButton: boolean;
  loading: boolean;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
}

export default function BillingSubscribe({
  plan,
  subscribe,
  buttonText,
  loading,
  disableButton,
  selectedCountry,
  setSelectedCountry,
}: Props) {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  const countriesList = Object.entries(countries)
    .map(([key, value]) => {
      return { label: value.name, value: key };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <div className={`${bannerOpen && 'mt-[1.5rem]'} flex`}>
        <PlanSection plan={plan} />
        <div className="w-1/2 h-full min-h-screen px-20 flex flex-col justify-center">
          <div className="max-w-[31.25rem]">
            <h2 className="font-bold mb-3">Continue</h2>
            <div
              className="mb-8 text-neutral-80 text-sm"
              data-testid="billing-subtitle"
            >
              Please select the country your business is registered in.
            </div>
            <SearchDropdown
              label="Select country"
              selectValue={setSelectedCountry}
              selectedValue={selectedCountry}
              options={countriesList}
            />
            <div className="flex justify-end mt-5">
              <PrimaryButton onClick={subscribe} disabled={disableButton}>
                {loading ? (
                  <Spinner colour="stroke-white" />
                ) : (
                  <>{buttonText}</>
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
