import { CampaignLink } from 'shared/legacy-types';
import CampaignSearchDropdown from 'components/Dropdowns/CampaignSearch/CampaignSearchDropdown';
import useCampaignLinkDropdown from 'pages/CampaignMapping/hooks/UseCampaignLinkDropdown';

interface Props {
  campaignLink: CampaignLink;
  open: boolean;
  setCampaignLink: (campaignLink: CampaignLink) => void;
}

export default function CampaignDropdowns({
  campaignLink,
  open,
  setCampaignLink,
}: Props) {
  const {
    sourceCampaigns,
    destinationCampaigns,
    loadingCampaigns,
    sourceAdGroupOptions,
    destinationAdGroupOptions,
    adGroupsLoading,
    sourceSearchTerm,
    setSourceSearchTerm,
    destinationSearchTerm,
    setDestinationSearchTerm,
  } = useCampaignLinkDropdown(campaignLink, open);

  return (
    <div className={`mt-6 relative min-h-[6.375rem]`}>
      <CampaignSearchDropdown
        ariaLabel="Select source campaign"
        dropdownType="source"
        placeholder="Choose source"
        styles={
          'rounded-b-none border-b-[0.031rem] border-neutral-40 m-0 focus:pt-px'
        }
        campaigns={sourceCampaigns}
        searchTerm={sourceSearchTerm}
        setSearchTerm={setSourceSearchTerm}
        adGroups={sourceAdGroupOptions}
        selectedCampaign={campaignLink.source}
        setSelectedCampaign={(campaign) =>
          setCampaignLink({
            ...campaignLink,
            source: {
              campaignName: campaign.campaignName,
              campaignId: campaign.campaignId,
              adGroups: [],
            },
          })
        }
        adGroupSelections={campaignLink.source.adGroups}
        setAdGroupSelection={(adGroups) =>
          setCampaignLink({
            ...campaignLink,
            source: {
              campaignName: campaignLink.source.campaignName,
              campaignId: campaignLink.source.campaignId,
              adGroups: adGroups,
            },
          })
        }
        disabled={!campaignLink.profileId}
        loadingCampaigns={loadingCampaigns}
        adGroupsLoading={adGroupsLoading}
      />
      <CampaignSearchDropdown
        ariaLabel="Select destination campaign"
        dropdownType="destination"
        placeholder="Choose destination"
        styles={'rounded-t-none border-t-[0.031rem] border-neutral-40 m-0'}
        campaigns={destinationCampaigns}
        searchTerm={destinationSearchTerm}
        setSearchTerm={setDestinationSearchTerm}
        adGroups={destinationAdGroupOptions}
        selectedCampaign={campaignLink.destination}
        setSelectedCampaign={(campaign) =>
          setCampaignLink({
            ...campaignLink,
            destination: {
              campaignName: campaign.campaignName,
              campaignId: campaign.campaignId,
              adGroups: [],
            },
          })
        }
        adGroupSelections={campaignLink.destination.adGroups}
        setAdGroupSelection={(adGroups) =>
          setCampaignLink({
            ...campaignLink,
            destination: {
              campaignName: campaignLink.destination.campaignName,
              campaignId: campaignLink.destination.campaignId,
              adGroups: adGroups,
            },
          })
        }
        disabled={!campaignLink.profileId}
        loadingCampaigns={loadingCampaigns}
        adGroupsLoading={adGroupsLoading}
      />
    </div>
  );
}
