import {
  CHANGE_STATUS,
  UPDATE_BID,
  UPDATE_BUDGET,
  BID_OPTIONS,
  BUDGET_OPTIONS,
  SEND_EMAIL,
  HARVEST_KEYWORDS,
  ADD_NEGATIVE_KEYWORDS,
  PAUSE_ENABLE_OPTIONS,
  SEND_SLACK,
} from '../constants/actions';
import { Action } from 'shared/legacy-types';
import { UpdateBidAction, ChangeStatusAction } from 'shared/legacy-types';
import { getActionTab } from '../helpers/actions';

export default function useAction(
  action: Action | undefined,
  dataType: string | undefined,
) {
  const options = BID_OPTIONS.concat(BUDGET_OPTIONS);

  const bidAction = action as UpdateBidAction;
  const statusAction = action as ChangeStatusAction;

  const isIncrease = bidAction?.properties?.value! > 0;
  const conditionLabel = isIncrease ? 'Increase' : 'Decrease';
  const isPauseOrEnable = action?.type === CHANGE_STATUS;
  const isEmail = action?.type === SEND_EMAIL;
  const isSlack = action?.type === SEND_SLACK;
  const isBidOrBudget =
    action?.type === UPDATE_BID || action?.type === UPDATE_BUDGET;
  const isKeywordHarvesting = action?.type === HARVEST_KEYWORDS;
  const isAddNegativeKeywords = action?.type === ADD_NEGATIVE_KEYWORDS;
  const metricLabel = options.find(
    (option) => option.value === bidAction.properties?.metric,
  )?.label;

  // Originally pause/enable actions always matched datatype
  // Templates step summary can now match any pause/enable option ie ads
  const pauseEnablelabel = PAUSE_ENABLE_OPTIONS.find(
    (option) =>
      option.value === dataType ||
      (statusAction.properties &&
        option.value === statusAction.properties.entityType),
  )?.label.toLowerCase();

  const bidBudgetValue =
    isIncrease && bidAction?.properties?.value
      ? bidAction?.properties?.value?.toString()
      : bidAction?.properties?.value?.toString().substring(1);

  const currentActionTab = getActionTab(
    isPauseOrEnable,
    statusAction.properties?.status,
    action?.type,
  );

  return {
    isPauseOrEnable,
    isBidOrBudget,
    metricLabel,
    isEmail,
    conditionLabel,
    pauseEnablelabel,
    isKeywordHarvesting,
    isAddNegativeKeywords,
    bidBudgetValue,
    currentActionTab,
    isSlack,
  };
}
