interface Props {
  open: boolean;
  disabled: boolean | undefined;
  openColour?: string;
  closedColour?: string;
}

export default function Chevrons({
  open,
  disabled,
  openColour = 'stroke-white',
  closedColour = 'stroke-black',
}: Props) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!open && disabled && (
        <path
          d="M1 0.5L5 4.5L9 0.5"
          className="stroke-neutral-60"
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      )}
      {!open && !disabled && (
        <path
          d="M1 0.5L5 4.5L9 0.5"
          className={closedColour}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      )}
      {open && (
        <path
          d="M1 5.5L5 1.5L9 5.5"
          className={open ? openColour : closedColour}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
