import { Optional, Profile, Rule } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';

export const getProfile = (profiles: Profile[], rule: Optional<Rule, 'id'>) => {
  if (
    !rule ||
    !rule.profileIds ||
    (rule.profileIds && rule.profileIds.length === 0)
  ) {
    return null;
  }

  return profiles.find((profile) => profile.id === rule.profileIds![0]);
};

export default function useRuleProfiles() {
  const { user } = useUserSelector();
  const { selectedProfiles } = user || {};
  const profiles = selectedProfiles as Profile[];

  const getRuleProfile = (rule: Optional<Rule, 'id'>) => {
    return getProfile(profiles, rule);
  };

  return { getRuleProfile };
}
