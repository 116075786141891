import { ChangeEvent, useEffect } from 'react';
import { Brand, SetupError } from 'shared/legacy-types';

export default function useBrandSetup(
  setSelectedBrands: (brands: Brand[]) => void,
  selectedBrands: Brand[],
  error: SetupError | undefined,
  setDisableButton: (disable: boolean) => void,
  userProfileLimit: number | undefined,
) {
  useEffect(
    function errorCheck() {
      if (error?.takenProfileIds && error?.takenProfileIds.length > 0) {
        const selected = selectedBrands.flatMap(
          (brand) => brand.marketplaceIds,
        );
        const match = selected.filter((brand) =>
          error.takenProfileIds.includes(brand),
        );

        if (match.length === 0) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      }
    },
    [error, selectedBrands, setDisableButton],
  );

  function selectBrand(id: number, name: string) {
    const newBrand = { id, name: name, marketplaceIds: [] };
    const exists = selectedBrands.find((brand) => brand.name === name);
    if (exists) {
      return;
    }
    setSelectedBrands([...selectedBrands, { ...newBrand }]);
  }

  function updateMarketplaces(
    marketplaceIds: number[],
    newMarketplace: number,
  ) {
    const foundMarketplace = marketplaceIds.includes(newMarketplace);
    if (userProfileLimit === marketplaceIds.length && !foundMarketplace) {
      return [...marketplaceIds];
    }
    if (foundMarketplace) {
      const filteredPlaces = marketplaceIds.filter(
        (market) => market !== newMarketplace,
      );
      return filteredPlaces;
    }

    return [...marketplaceIds, newMarketplace];
  }

  function selectMarketplace(
    event: ChangeEvent<HTMLInputElement>,
    brandName: string,
  ) {
    const updatedBrands = selectedBrands.map((brand) => {
      if (brand.name === brandName) {
        return {
          ...brand,
          marketplaceIds: updateMarketplaces(
            brand.marketplaceIds,
            +event.target.value,
          ),
        };
      }
      return brand;
    });

    setSelectedBrands(updatedBrands);
  }

  return { selectBrand, selectMarketplace, selectedBrands };
}
