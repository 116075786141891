import { ApolloError, useQuery } from '@apollo/client';
import {
  UseBrandsGetBrandsQuery,
  UseBrandsGetBrandsDocument,
} from 'codegen/graphql';

type UseBrandsHook = {
  brands: UseBrandsGetBrandsQuery['brands'];
  loading: boolean;
  error: ApolloError | undefined;
};

export default function useBrands(): UseBrandsHook {
  const { data, loading, error } = useQuery<UseBrandsGetBrandsQuery>(
    UseBrandsGetBrandsDocument,
  );
  const brands = data?.brands ?? [];

  return { brands, loading, error };
}
