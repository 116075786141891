import DashboardLayout from 'layouts/DashboardLayout';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import { LOADING } from 'constants/requests';
import useSettings from 'pages/Settings/useSettings';
import Tabs from './Tabs';
import SellingPartnerAccounts from './SellingPartner/SellingPartnerAccounts';
import Integrations from './Integrations/Integrations';
import BrandsTab from './BrandsTab/BrandsTab';
import {
  BRANDS,
  INTEGRATIONS,
  SELLING_PARTNER_ACCOUNTS,
} from 'pages/Settings/useSettings';

export default function SettingsComponent() {
  const { userStatus, tabs, tabSelection, updateTab } = useSettings();

  const isSellingPartner = tabSelection === SELLING_PARTNER_ACCOUNTS;
  const isIntegrations = tabSelection === INTEGRATIONS;
  const isBrandsTab = tabSelection === BRANDS;

  if (userStatus === LOADING) {
    return (
      <DashboardLayout>
        <div className="flex h-screen">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  const headerStyles =
    'sm:px-[-1.5rem] md:mx-[-1.5rem]  xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem]';

  return (
    <DashboardLayout>
      <>
        <div>
          <div
            className={`${headerStyles}`}
            style={{ boxShadow: '0px 2px 8px 0px rgba(5, 26, 22, 0.03)' }}
          >
            <h4 className="pb-4 mt-2 ml-[1.5rem] xl:ml-[4.875rem] min-[1440px]:ml-[7.5rem] font-medium text-neutral-100">
              Settings
            </h4>
            <div className={`border-b border-neutral-40`} />
          </div>
          <div
            className={`${headerStyles}`}
            style={{ boxShadow: '0px 5px 16px 0px rgba(0, 18, 51, 0.05)' }}
          >
            <div className="ml-[1.5rem] xl:ml-[4.875rem] min-[1440px]:ml-[7.5rem]">
              <Tabs
                setTabSelection={updateTab}
                tabSelection={tabSelection}
                tabs={tabs}
              />
            </div>
          </div>
        </div>
        <div className="relative max-w-[35.375rem] mt-6">
          {isSellingPartner && <SellingPartnerAccounts />}
          {isIntegrations && <Integrations />}
        </div>
        {isBrandsTab && <BrandsTab />}
      </>
    </DashboardLayout>
  );
}
