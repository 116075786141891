import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useSetupRequests from 'api/hooks/requests/useSetupRequests';
import { Profile } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import {
  getSetupFlow,
  setupComponent,
  currentStep,
} from 'pages/Onboarding/setupFlow';
import useSubscribedAlert from 'hooks/subscription/useSubscribedAlert';
import * as Sentry from '@sentry/react';

export default function useSetup() {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useUserSelector();
  const { amazonAccounts, hasSubscription } = user || {};
  const { getProfiles } = useSetupRequests();
  const navigate = useNavigate();

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    const profiles = await getProfiles();
    setLoading(false);
    setProfiles(profiles);
  }, [getProfiles]);

  useEffect(() => {
    async function initProfiles() {
      if (user && user.amazonAccess && user.selectedProfiles.length) {
        navigate('/');
        return;
      }
      if (
        user &&
        user.amazonAccess === true &&
        amazonAccounts &&
        amazonAccounts.length > 1
      ) {
        navigate('/settings');
        return;
      }
      if (
        user &&
        user.amazonAccess === true &&
        user.selectedProfiles.length === 0
      ) {
        try {
          await fetchProfiles();
        } catch (error) {
          Sentry.captureException(error);
          window.location.replace('/login.html');
        }
      }
    }
    initProfiles();
    // eslint-disable-next-line
  }, [user]);

  // show subscribed alert
  useSubscribedAlert();

  // Get the setup flow for the user's plan
  const setupFlow = getSetupFlow();

  // Find current step within flow
  const currentSetupStep = currentStep(
    user,
    profiles,
    loading,
    hasSubscription || false,
  );

  // Find component to display
  const componentToDisplay = setupComponent(setupFlow, currentSetupStep);

  const stepsProgress = setupFlow.map((step) => {
    return { name: step.step, currentStep: step.step === currentSetupStep };
  });

  return { componentToDisplay, loading, profiles, stepsProgress };
}
