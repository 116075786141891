import './SmallSpinner.css';

interface Props {
  loading?: boolean;
}

export default function SmallSpinner({ loading }: Props) {
  return (
    <svg
      className={`smallSpinner stroke-secondary-100 ${loading && 'stroke-neutral-100'}`}
      viewBox="0 0 50 50"
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="3"
      ></circle>
    </svg>
  );
}
