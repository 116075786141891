import { useContext } from 'react';
import {
  CHANGE_STATUS,
  HARVEST_KEYWORDS,
  ADD_NEGATIVE_KEYWORDS,
} from 'ui/constants/actions';

import { CAMPAIGNS } from 'ui/constants/rules';
import useUserSelector from 'state/user/useUserSelector';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import { ActionTab } from 'shared/legacy-types';

export default function useActionTabs(action: ActionTab) {
  const { isInternalUser } = useUserSelector();
  const { step, rule } = useContext(StepContext);

  const { type, enabled } = action as ActionTab;

  const actionApplied = step.actions.find((action) => {
    if (action.type === CHANGE_STATUS && action.properties.status === type) {
      return true;
    }

    return action.type === type;
  });

  const actionsUsed = step?.actions.map((action) => action.type);
  const searchTermsActions = [HARVEST_KEYWORDS, ADD_NEGATIVE_KEYWORDS];

  const disableAction =
    (actionsUsed?.includes(CHANGE_STATUS) &&
      (action.type === 'paused' || action.type === 'enabled')) ||
    // Disable harvest kw or add negative kw if either of them already used
    (searchTermsActions.includes(action.type) &&
      (actionsUsed?.includes(HARVEST_KEYWORDS) ||
        actionsUsed?.includes(ADD_NEGATIVE_KEYWORDS)));

  const isEnabled =
    enabled === 'all' ||
    (enabled === 'internal' && isInternalUser) ||
    (!isInternalUser &&
      rule.dataType === CAMPAIGNS &&
      (action.type === 'paused' || action.type === 'enabled'));

  const getColour = (
    selected: boolean,
    selectedColour: string,
    enabled: boolean,
    disabledColour: string,
    defaultColour: string,
  ) => {
    if (selected) return selectedColour;
    if (!enabled) return disabledColour;
    return defaultColour;
  };

  const getColourProps = (selected: boolean, enabled: boolean) => {
    return {
      background: getColour(
        selected,
        'fill-white',
        enabled,
        'fill-neutral-60',
        'fill-primary-80',
      ),
      iconColour: getColour(
        selected,
        'fill-primary-100',
        enabled,
        'fill-white',
        'fill-white',
      ),
    };
  };

  const getAsteriskProps = (selected: boolean, enabled: boolean) => {
    return {
      background: getColour(
        selected,
        'fill-white',
        enabled,
        'fill-neutral-80',
        'fill-primary-80',
      ),
      iconColour: getColour(
        selected,
        'fill-primary-100',
        enabled,
        'fill-white',
        'fill-white',
      ),
      strokeColour: getColour(
        selected,
        'stroke-primary-100',
        enabled,
        'stroke-white',
        'stroke-white',
      ),
    };
  };

  return {
    getColourProps,
    getAsteriskProps,
    actionApplied,
    disableAction,
    isEnabled,
  };
}
