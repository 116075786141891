import useSetEnvironment from 'hooks/useSetEnvironment';
import { useState } from 'react';
import useBannerSelector from 'state/banners/useBannerSelector';

export default function useMenu() {
  const [userEnv, setUserEnv] = useState('');
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  useSetEnvironment(setUserEnv);

  return { userEnv, bannerOpen };
}
