export enum PillStyle {
  PRIMARY = 'PRIMARY',
  NEUTRAL = 'NEUTRAL',
}

export enum PillStyleClasses {
  PRIMARY = 'bg-primary-60 text-secondary-100',
  NEUTRAL = 'bg-neutral-40 text-neutral-80',
}

type Props = {
  label: string;
  style: PillStyle;
};

export default function Pill({ label, style }: Props): JSX.Element {
  return (
    <div
      className={`font-bold rounded-xl px-3 py-1 text-sm w-fit ${PillStyleClasses[style]}`}
    >
      {label}
    </div>
  );
}
