import {
  getOperatorLabel,
  getFieldLabel,
  getGroupOperator,
} from '../../helpers/steps';
import {
  ConditionExpression,
  MetricCondition,
  ValueCondition,
  Condition,
  ConditionGroupOperator,
} from 'shared/legacy-types';
import { EXPRESSION_OPTIONS, MARKETPLACE_FIELDS } from '../../constants/rules';
import { getField, getPrefixLabel } from '../../helpers/rules/fields';

export default function useCondition(
  condition: Condition,
  groupOperator: ConditionGroupOperator,
) {
  const metricCondition = condition as MetricCondition;
  const valueCondition = condition as ValueCondition;
  const isValueCondition = condition && condition.hasOwnProperty('value');

  const { field, operator } = condition;

  const value = isValueCondition
    ? valueCondition.value
    : metricCondition.metric;

  const conditionValue = value as ConditionExpression;
  const metric = conditionValue?.metric;
  const operatorLabel = getOperatorLabel(field, operator);
  const fieldLabel = getFieldLabel(field);
  const fieldConfig = getField(field);
  const groupOperatorLabel = getGroupOperator(groupOperator);
  const prefixLabel = getPrefixLabel(field);

  // for condition values that have prefixes ie products:dynamic_acos
  const conditionValuePrefixLabel =
    isValueCondition && typeof value == 'string' && getPrefixLabel(value);
  const conditionValueFieldLabel =
    isValueCondition && typeof value == 'string' && getFieldLabel(value);

  const expressionLabel = Object.values(EXPRESSION_OPTIONS)
    .flatMap((option) => option)
    .find((option) => option.value === metric)?.label;

  // if marketplace field and condition dropdown selected get label
  const marketplaceConditionLabel = MARKETPLACE_FIELDS.find(
    (field) => field.value === value,
  )?.label;

  return {
    operatorLabel,
    fieldLabel,
    groupOperatorLabel,
    prefixLabel,
    conditionValuePrefixLabel,
    conditionValueFieldLabel,
    expressionLabel,
    marketplaceConditionLabel,
    fieldConfig,
    metric,
    field,
    value,
  };
}
