import {
  CURRENT_BID,
  CHANGE_STATUS,
  CURRENT_BUDGET,
  HARVEST_KEYWORDS,
  ADD_NEGATIVE_KEYWORDS,
} from './actions';
import {
  CAMPAIGNS,
  TARGETS,
  AD_GROUPS,
  SEARCH_TERMS,
  NEGATIVE_SEARCH_TERMS,
} from './rules';

export interface Column {
  value: string;
  label: string;
  showCurrency?: boolean;
  showPercent?: boolean;
}

export const COLUMNS = {
  dateFrom: { value: 'dateFrom', label: 'Date from' } as Column,
  dateTo: { value: 'dateTo', label: 'Date to' } as Column,
  campaign: { value: 'campaignName', label: 'Campaign' } as Column,
  adGroupName: { value: 'adGroupName', label: 'Ad group' } as Column,
  targeting: { value: 'text', label: 'Targeting' } as Column,
  // Targets
  currentBid: {
    value: 'currentBid',
    label: 'Bid',
    showCurrency: true,
  } as Column,
  newBid: {
    value: 'simulatedBid',
    label: 'New bid',
    showCurrency: true,
  } as Column,
  // Search Terms
  bid: { value: 'bid', label: 'Bid', showCurrency: true } as Column,
  status: { value: 'status', label: 'Status' } as Column,
  newStatus: { value: 'adjustedStatus', label: 'New status' } as Column,
  impressions: { value: 'impressions', label: 'Impressions' } as Column,
  clicks: { value: 'clicks', label: 'Clicks' } as Column,
  spend: { value: 'cost', label: 'Spend', showCurrency: true } as Column,
  sales: { value: 'sales', label: 'Sales', showCurrency: true } as Column,
  orders: { value: 'orders', label: 'Orders' } as Column,
  ctr: { value: 'ctr', label: 'CTR', showPercent: true } as Column,
  acos: { value: 'acos', label: 'ACOS', showPercent: true } as Column,
  roas: { value: 'roas', label: 'ROAS' } as Column,
  cr: { value: 'cr', label: 'CR', showPercent: true } as Column,
  cpc: { value: 'cpc', label: 'CPC', showCurrency: true } as Column,
  deliveryDays: { value: 'delivery_days', label: 'Delivery days' } as Column,
  asin: { value: 'asin', label: 'ASIN' } as Column,
  countryCode: { value: 'countryCode', label: 'Marketplace' } as Column,
  profileCrClicks: {
    value: 'profileCrClicks',
    label: 'Mktp CR Clicks',
  } as Column,
  profileCr: {
    value: 'profileCr',
    label: 'Mktp CR',
    showPercent: true,
  } as Column,
  averageDailyCost: {
    value: 'averageDailyCost',
    label: 'Avg Daily Spend',
    showCurrency: true,
  } as Column,
  budget: { value: 'budget', label: 'Budget', showCurrency: true } as Column,
  adjustedBudget: {
    value: 'adjustedBudget',
    label: 'Adjusted budget',
    showCurrency: true,
  } as Column,
  // Keyword columns
  source: { value: 'source', label: 'Source' } as Column,
  searchTerm: { value: 'searchTerm', label: 'New keyword' } as Column,
  searchTermNegative: {
    value: 'searchTerm',
    label: 'Negative Keyword',
  } as Column,
  destination: { value: 'destination', label: 'Destination' } as Column,
  matchTypes: { value: 'matchTypes', label: 'Match types' } as Column,
  targetingType: { value: 'targetingType', label: 'Targeting type' } as Column,
  mtdSpend: {
    value: 'profileActualSpendToDate',
    label: 'MTD Spend',
    showCurrency: true,
  } as Column,
  mtdSpendTarget: {
    value: 'profileExpectedSpendToDate',
    label: 'MTD Spend Target',
    showCurrency: true,
  } as Column,
  targetingText: { value: 'targetingText', label: 'Targeting text' } as Column,
  negateKeywords: {
    value: 'negateKeywords',
    label: 'Negate',
  } as Column,
  matchType: { value: 'matchType', label: 'Match type' } as Column,
};

export const ALL_TABLES = [
  COLUMNS.dateFrom,
  COLUMNS.dateTo,
  COLUMNS.countryCode,
  COLUMNS.campaign,
];

export const SEARCH_TERMS_TABLE = [
  COLUMNS.countryCode,
  COLUMNS.source,
  COLUMNS.searchTerm,
  COLUMNS.destination,
  COLUMNS.negateKeywords,
];

export const NEGATIVE_SEARCH_TERMS_TABLE = [COLUMNS.adGroupName];

export const SHARED_EXTENDED_COLUMNS = [
  COLUMNS.impressions,
  COLUMNS.clicks,
  COLUMNS.spend,
  COLUMNS.sales,
  COLUMNS.orders,
  COLUMNS.ctr,
  COLUMNS.acos,
  COLUMNS.roas,
  COLUMNS.cr,
  COLUMNS.cpc,
  COLUMNS.profileCrClicks,
  COLUMNS.profileCr,
  COLUMNS.mtdSpend,
  COLUMNS.mtdSpendTarget,
  // COLUMNS.deliveryDays,
  // COLUMNS.asin,
];

// Data type columns
export const TARGETS_COLUMNS = [
  COLUMNS.adGroupName,
  COLUMNS.targeting,
  COLUMNS.currentBid,
];
export const CAMPAIGN_COLUMNS = [COLUMNS.status];
export const AD_GROUP_COLUMNS = [COLUMNS.adGroupName];

export const SEARCH_TERMS_COLUMNS = [
  COLUMNS.matchTypes,
  COLUMNS.targetingType,
  COLUMNS.targetingText,
  COLUMNS.bid,
];

export const NEGATIVE_SEARCH_TERMS_COLUMNS = [
  COLUMNS.targetingType,
  COLUMNS.targetingText,
];

// Action columns
export const ACTION_PRESETS = {
  [CURRENT_BID]: [COLUMNS.currentBid, COLUMNS.newBid],
  cpc: [COLUMNS.currentBid, COLUMNS.newBid],
  [CHANGE_STATUS]: [COLUMNS.status, COLUMNS.newStatus],
  [CURRENT_BUDGET]: [
    COLUMNS.averageDailyCost,
    COLUMNS.budget,
    COLUMNS.adjustedBudget,
  ],
  [ADD_NEGATIVE_KEYWORDS]: [COLUMNS.searchTermNegative],
};

// Only show these columns for selected action when table extended
export const EXTENDED_ACTION_PRESETS = {
  [CURRENT_BUDGET]: [],
  [HARVEST_KEYWORDS]: [
    COLUMNS.matchTypes,
    COLUMNS.targetingType,
    COLUMNS.currentBid,
  ],
  [ADD_NEGATIVE_KEYWORDS]: [],
};

// Regular table presets
export const DEFAULT_PRESETS = {
  [TARGETS]: [...ALL_TABLES, ...TARGETS_COLUMNS],
  [CAMPAIGNS]: [...ALL_TABLES, ...CAMPAIGN_COLUMNS],
  [AD_GROUPS]: [...ALL_TABLES, ...AD_GROUP_COLUMNS],
  [SEARCH_TERMS]: [...SEARCH_TERMS_TABLE],
  [NEGATIVE_SEARCH_TERMS]: [...ALL_TABLES, ...NEGATIVE_SEARCH_TERMS_TABLE],
};

// Extended table presets
export const EXTENDED_PRESETS = {
  [TARGETS]: [
    ...DEFAULT_PRESETS[TARGETS],
    ...SHARED_EXTENDED_COLUMNS,
    // COLUMNS.status
  ],
  [CAMPAIGNS]: [...DEFAULT_PRESETS[CAMPAIGNS], ...SHARED_EXTENDED_COLUMNS],
  [AD_GROUPS]: [
    ...DEFAULT_PRESETS[AD_GROUPS],
    ...SHARED_EXTENDED_COLUMNS,
    COLUMNS.currentBid,
  ],
  [SEARCH_TERMS]: [
    ...[COLUMNS.dateFrom, COLUMNS.dateTo],
    ...SEARCH_TERMS_TABLE,
    ...SHARED_EXTENDED_COLUMNS,
    ...SEARCH_TERMS_COLUMNS,
  ],
  [NEGATIVE_SEARCH_TERMS]: [
    ...DEFAULT_PRESETS[NEGATIVE_SEARCH_TERMS],
    ...SHARED_EXTENDED_COLUMNS,
    ...NEGATIVE_SEARCH_TERMS_COLUMNS,
  ],
};
