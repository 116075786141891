import { ReactElement } from 'react';
import { Menu } from 'components/Menu/Menu';
import useBannerSelector from 'state/banners/useBannerSelector';

type Props = {
  children: ReactElement;
};

export default function DashboardLayout({ children }: Props): JSX.Element {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  return (
    <div className="min-h-full">
      <Menu />
      <main
        className={`h-full min-h-screen flex flex-1 flex-col pl-[14.75rem] bg-secondary-20`}
      >
        <div
          className={`min-[1440px]:px-[7.5rem] xl:px-[4.875rem] lg:px-6 md:px-6 sm:px-6 pt-6 mb-32 `}
        >
          <div className={`${bannerOpen && 'mt-[3.063rem]'}`}>{children}</div>
        </div>
      </main>
    </div>
  );
}
