import { daysBetween } from 'helpers/dates/dates';

export const getSvgFillActiveStyle = (active: boolean): string => {
  if (active) {
    return 'fill-primary-100';
  }

  return 'fill-neutral-60';
};

export const getSvgStrokeActiveStyle = (active: boolean): string => {
  if (active) {
    return 'stroke-primary-100';
  }

  return 'stroke-neutral-60';
};

export const daysLeftInTrial = (trialEndDate: string, startDate: Date) => {
  return daysBetween(startDate, new Date(trialEndDate));
};

export const isTrialing = (trialEndDate: string, startDate: Date) => {
  const trialDays = daysLeftInTrial(trialEndDate, startDate);
  return typeof trialDays === 'number' && trialDays >= 0;
};
