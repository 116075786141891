import 'index.css';
import { ReactComponent as FernLogo } from 'images/logo.svg';
import ToggleDesignComponent from './DesignComponents/ToggleDesignComponent';
import ColourSchemeComponent from 'pages/DesignSystem/DesignComponents/ColourSchemeComponent';
import TypographyComponent from 'pages/DesignSystem/DesignComponents/TypographyComponent';
import ButtonDesignComponent from 'pages/DesignSystem/DesignComponents/ButtonDesignComponent';
import TextAreaComponents from 'pages/DesignSystem/DesignComponents/TextAreaComponent';
import DateFormatting from 'pages/DesignSystem/DesignComponents/DateFormatting';
import CurrencyFormatting from './DesignComponents/CurrencyFormatting';

function DesignSystem() {
  return (
    <div>
      <aside className="fixed inset-y-0 flex w-64 flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-neutral-40 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-10">
            <div className="flex justify-center">
              <FernLogo />
            </div>
            <div className="flex justify-center pt-10">
              <h4>Design System</h4>
            </div>
            <nav className="pt-10 flex-1 space-y-1 bg-white">
              <a
                href="#colours"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Colours
              </a>
              <a
                href="#typography"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Typography
              </a>
              <a
                href="#buttons"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Buttons
              </a>
              <a
                href="#forms"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Forms
              </a>
              <a
                href="#dates"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Date Formatting
              </a>
              <a
                href="#currency"
                className="hover:text-neutral-60 group flex items-center px-2 pl-6 py-2 text-sm"
              >
                Currency Formatting
              </a>
            </nav>
          </div>
        </div>
      </aside>
      <main className="flex flex-1 flex-col pl-64 pb-40">
        <ColourSchemeComponent />
        <TypographyComponent />
        <ButtonDesignComponent />
        <TextAreaComponents />
        <ToggleDesignComponent />
        <DateFormatting />
        <CurrencyFormatting />
      </main>
    </div>
  );
}

export default DesignSystem;
