import {
  Rule,
  Optional,
  ConditionExpression,
  ValueCondition,
  MetricCondition,
} from 'shared/legacy-types';

export const ruleHasValues = (rule: Optional<Rule, 'id'>) => {
  return rule.steps.some((step) => {
    return (
      step.conditionGroups.some((group) => {
        return group.conditions.some((condition) => {
          const valueCondition = condition as ValueCondition;
          const metricCondition = condition as MetricCondition;
          const expressionValue = valueCondition.value as ConditionExpression;
          const isValueCondition =
            condition && condition.hasOwnProperty('value');

          const regularValue = isValueCondition
            ? (valueCondition.value as string)
            : (metricCondition.metric as string);

          if (isValueCondition && expressionValue.hasOwnProperty('metric')) {
            return (
              condition.field ||
              condition.operator ||
              expressionValue?.metric ||
              expressionValue?.value
            );
          }

          return condition.field || condition.operator || regularValue.length;
        });
      }) || step.actions.length > 0
    );
  });
};
