import FeatureItem from './FeatureItem';
import { InfoIcon } from 'components/Icons/';
import { Plan, PlanTier } from 'shared/legacy-types';
import { PLAN_DETAILS } from 'constants/plans';
import { getCurrencySymbol } from 'ui/helpers/currency/currency';
import useSubscription from 'hooks/useSubscriptions';
import { useSearchParams } from 'react-router-dom';

interface Props {
  plan?: Plan;
  trialEnded?: boolean;
}

export default function PlanDetails({ plan, trialEnded }: Props) {
  const planCurrency = getCurrencySymbol('GBP');
  const planDetails = PLAN_DETAILS[
    plan as keyof typeof PLAN_DETAILS
  ] as PlanTier;
  const [params] = useSearchParams();
  const isCustomPlan = params.get('priceId');

  const { hasUsedFreeTrial } = useSubscription();
  const usedFreeTrial = plan && hasUsedFreeTrial(plan);

  const { features, pricing } = planDetails;

  function renderFeatureItems() {
    return features.map((feature, index) => {
      const isLast = index === features.length - 1;
      return (
        <FeatureItem
          key={index}
          text={feature.feature}
          starFeature={feature.starFeature}
          isLast={isLast}
        />
      );
    });
  }

  function noticeText() {
    if (trialEnded) {
      return (
        <>
          Your 14-day trial has ended. You will need to add a payment method in
          the <span className="font-bold">Stripe Portal</span> to continue using
          this plan.
        </>
      );
    }

    if (usedFreeTrial) {
      return (
        <>
          <div className="text-xs mt-[0.2rem]">
            No commitment, cancel anytime.
          </div>
        </>
      );
    }

    return (
      <>
        Enjoy a <span className="font-bold">14-day trial period</span> to try
        out the platform.
        <div className="text-xs mt-[0.022rem]">
          No commitment, cancel anytime.
        </div>
      </>
    );
  }

  function paymentText() {
    if (trialEnded || usedFreeTrial) {
      return (
        <>Future payments will be taken at the end of your billing period</>
      );
    }

    return <>Add payment method before the trial ends to stay on this plan</>;
  }

  return (
    <div>
      <div className="flex w-full h-[3.25rem] mb-4 relative z-50">
        <div>
          <span className="text-sm">You're about to subscribe to our</span>
          <div className="text-2xl min-w-[16rem] pr-6 font-bold leading-[1.65rem]">
            <span className="text-primary-100 w-fit">
              {isCustomPlan && <>Custom </>}
              {planDetails.label}{' '}
            </span>
            plan
          </div>
        </div>
        {!isCustomPlan && (
          <div className="text-xs my-auto justify-end w-full pt-1 pb-1 ">
            <div className="text-2xl leading-[1.9rem] w-fit flex ml-auto">
              {planCurrency}
              {pricing && pricing.monthly}
              <span className="text-xs mt-auto ml-1 !leading-[1.9rem]">
                <span className="mr-1">/</span>month
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-primary-100/20 border-primary-100/30 mb-3 mt-2 flex rounded border px-[0.688rem] py-[0.4rem]">
        <span className="mr-1.5 my-auto">
          <InfoIcon />
        </span>
        <div>
          <div className="text-xs">{noticeText()}</div>
        </div>
      </div>
      <div className={`rounded-lg border-b-none border p-5 border-neutral-80`}>
        <div className="border-b border-neutral-80 pb-3 mb-3 font-medium">
          Includes
        </div>
        {renderFeatureItems()}
      </div>
      <div className="relative justify-between w-full flex h-10 z-50 mt-4">
        <div className="flex-none">
          <div className="my-auto text-sm mb-1.5">Due today</div>
          <div className="text-neutral-60 text-xs ">{paymentText()}</div>
        </div>
        <div
          className={`text-xl font-bold pt-1 my-auto`}
          aria-label="Amount due"
        >
          <div>
            {planCurrency}{' '}
            {(trialEnded || usedFreeTrial) && pricing ? pricing.monthly : 0}
          </div>
        </div>
      </div>
    </div>
  );
}
