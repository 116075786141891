import { useState, useRef } from 'react';
import useClickOutside from 'ui/hooks/useClickOutside';
import Chevrons from 'ui/components/Dropdowns/Chevrons';
import Label from '../MultiDropdown/Label';
import AsinOption from './AsinOption';
import { Asin } from 'pages/Rule/Scope/ProductSignals/useProductSignals';

interface Props {
  label: string;
  options: Asin[];
  defaultValue?: string;
  selectedValues: string[];
  selectValue: (value: string) => void;
  width?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  loading?: boolean;
}

export default function AsinMultiSelect({
  label,
  options,
  defaultValue,
  selectedValues,
  selectValue,
  width = 'w-[12.0625rem]',
  required,
  disabled,
  error,
  loading,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  const dropdownStyles = `${loading && 'animate-pulse'} ${
    !disabled && 'hover:border-neutral-80'
  } block h-[3.6875rem] w-full appearance-none rounded-lg border pr-[2.125rem] 
  ${error && !open ? 'bg-error bg-opacity-20 border-error border-opacity-40' : 'border-neutral-40'}
  ${width} 
  ${open && 'bg-secondary-100 border-0 text-white mr-[0.063rem]'} 
  ${defaultValue && !open && !disabled && 'bg-primary-40 border-primary-60 border-1 hover:border-primary-80'}
  ${disabled && !error && 'border-neutral-40 hover:border-neutral-40 bg-neutral-20'}
  ${selectedValues && selectedValues!.length > 0 && !open && !disabled && 'bg-primary-40 border-primary-60'}`;

  const firstLabel =
    selectedValues &&
    options?.find((option) => option.asin === selectedValues[0]);

  function selectAsin(value: string) {
    selectValue(value);
  }

  function renderDropdownMenu() {
    function renderOptions() {
      return options.map((asin) => {
        const selected = selectedValues
          ? !!selectedValues.find((value) => value === asin.asin)
          : false;

        return (
          <AsinOption selectAsin={selectAsin} selected={selected} asin={asin} />
        );
      });
    }
    return (
      <div className={`absolute rounded-xl !z-50 mt-1 w-80 bg-white`}>
        <div
          ref={ref}
          className={`rounded-xl  p-4 text-sm  text-left`}
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          <div className="scrollbar-hide max-h-[15rem]  overflow-scroll">
            {renderOptions()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`relative inline-block text-sm text-black hover:cursor-pointer ${
          disabled && 'hover:cursor-not-allowed'
        }`}
        onClick={() => !disabled && setOpen(!open)}
      >
        <div className={dropdownStyles}>
          <Label
            defaultValue={defaultValue}
            label={label}
            open={open}
            required={required}
            selectedValues={selectedValues}
            firstLabel={firstLabel && firstLabel.asin}
            disabled={disabled}
          />
        </div>
        <div className="pointer-events-none absolute right-4 top-[1.7188rem] flex items-center ">
          <Chevrons open={open} disabled={disabled} />
        </div>
      </div>
      {open && options && options.length > 0 && renderDropdownMenu()}
    </div>
  );
}
