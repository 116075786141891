import { convertToPascalCase } from 'pages/Reporting/helpers';

interface Props {
  payload: any[];
  selectedMetric: string[];
  setSelectedMetric: (selectedMetric: string[]) => void;
}

export default function SimpleLegend({
  payload,
  selectedMetric,
  setSelectedMetric,
}: Props) {
  const legends = () => {
    return payload.map((item: any) => {
      const metric = convertToPascalCase(item.value);

      const selectLegend = () => {
        if (selectedMetric.includes(item.value)) {
          setSelectedMetric(selectedMetric.filter((i) => i !== item.value));
          return;
        }
        setSelectedMetric([...selectedMetric, item.value]);
      };

      const isSelected = selectedMetric.includes(item.value);

      return (
        <div
          key={item.name}
          className="flex mr-4 hover:cursor-pointer"
          onClick={() => selectLegend()}
        >
          <div
            className="h-0.5 w-4 mr-2 mt-[8px]"
            style={{ background: isSelected ? item.color : 'lightgrey' }}
          />
          <div className={`text-sm ${!isSelected && 'text-neutral-60'}`}>
            {metric}
          </div>
        </div>
      );
    });
  };
  return <div className="pt-3 px-3 flex justify-end mt-2">{legends()}</div>;
}
