import { OPERATORS, GROUP_OPERATORS } from '../constants/rules';
import { getField } from './rules/fields';

export const getOperatorLabel = (field: string, rawOperator: string) => {
  return OPERATORS.find(
    (operator) =>
      operator.type === getField(field)?.type && operator.value === rawOperator,
  )?.label;
};

export const getFieldLabel = (field: string) => {
  return getField(field)?.label;
};

export const getGroupOperator = (rawOperator: string) => {
  return GROUP_OPERATORS.find((operator) => operator.value === rawOperator)
    ?.label;
};
