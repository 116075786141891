import { Profile } from 'shared/legacy-types';

export const brandOptions = (profiles: Profile[]) => {
  const uniqueBrands = new Set();

  const filteredProfiles = profiles.filter((profile) => {
    const isDuplicate = uniqueBrands.has(profile.accountName);
    uniqueBrands.add(profile.accountName);

    if (isDuplicate) {
      return false;
    }

    return profile;
  });

  return filteredProfiles.map((profile) => {
    return { value: profile.accountName, label: profile.accountName };
  });
};

export const getMarketplaces = (
  profiles: Profile[],
  selectedBrandName: string,
) => {
  const filteredProfiles = profiles.filter((profile) => {
    if (selectedBrandName && profile.accountName === selectedBrandName) {
      return profile;
    }
    return null;
  });

  return filteredProfiles.map((profile) => {
    return { value: profile.id.toString(), label: profile.country };
  });
};
