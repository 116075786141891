interface Props {
  stroke?: string;
  size?: string;
}

export function HistoryIcon({
  stroke = 'stroke-neutral-60',
  size = '20',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5.7915V10.7915L14.3333 12.4582"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
      <path
        d="M6.82137 18.0078C7.74654 18.543 8.7907 18.908 9.91678 19.0546C14.4806 19.6488 18.662 16.4306 19.2561 11.8668C19.8503 7.30291 16.6322 3.12154 12.0683 2.52742C7.50443 1.93329 3.32306 5.1514 2.72893 9.71527C2.57576 10.8919 2.67597 12.0431 2.9873 13.1139"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${stroke} fill-none`}
      />
      <path
        d="M0.615728 13.2916L3.62116 14.7913L5.11982 11.7914"
        strokeWidth="1.5"
        strokeLinejoin="round"
        className={`${stroke} `}
      />
    </svg>
  );
}
