import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircleLogo, ShowHideIcon } from 'components/Icons';
import Spinner from 'ui/Spinner/Spinner';

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const validPasswords = newPassword.length >= 8;
  const disableButton = loading || !validPasswords;

  const [showPass, setShowPass] = useState(false);

  const [params] = useSearchParams();

  function inputType() {
    if (showPass) {
      return 'text';
    }
    return 'password';
  }

  useEffect(
    function validation() {
      if (newPassword && newPassword.length >= 8) {
        setNewPasswordValid(true);
      }
    },
    [newPassword],
  );

  async function submitNewPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const token = params.get('token');
    setLoading(true);
    const response = await fetch('/v1/auth/password-change', {
      method: 'POST',
      headers: { Authorization: `${token}` },
      body: JSON.stringify({
        password: newPassword,
      }),
    });
    if (response.status === 200) {
      const authToken = await response.text();
      localStorage.setItem('token', authToken);
      window.location.href = '/';
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-full min-h-screen relative bg-secondary-20">
      <div className="flex-1 flex flex-col justify-center m-auto max-w-[27.938rem]">
        <div
          className="m-auto w-[27.938rem] min-h-[19.813rem] bg-white px-8 pt-8 pb-12 rounded-lg h-fit"
          style={{ boxShadow: '0rem 0.125rem 1rem rgba(0, 18, 51, 0.04)' }}
        >
          <div className="w-[1.688rem] relative mx-auto mb-8">
            <CircleLogo />
          </div>
          <h2 className="text-8 leading-[2.2rem] w-max relative mx-auto font-bold mb-8">
            Reset your password
          </h2>
          <div className={`max-w-[30.6rem] max-h-[20rem]`}>
            <form onSubmit={(event) => submitNewPassword(event)}>
              <div className="relative max-h-[3.25rem]">
                <input
                  id="password"
                  name="password"
                  type={inputType()}
                  className={`
                  ${!newPasswordValid && newPassword.length > 0 && 'focus:border-error border-error'} 
                   w-full rounded border border-neutral-40 focus:border-primary-100 focus:border-2 placeholder-transparent peer focus:outline-none pt-5 pb-1.5 pl-4 mt-0.5 focus:mt-0 focus:mt-[0.188rem]`}
                  placeholder="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />

                <label
                  htmlFor="password"
                  className="absolute left-4 top-1.5 text-neutral-80 text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-neutral-80 peer-placeholder-shown:top-5 peer-focus:top-1.5 peer-focus:text-neutral-80 peer-focus:text-xs"
                >
                  <span className="flex">New password</span>
                </label>
                <span
                  onClick={() => setShowPass(!showPass)}
                  className="relative top-[-2.3rem] max-w-1 left-[90%]"
                >
                  <ShowHideIcon showPass={showPass} />
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  disabled={disableButton}
                  className={` button bg-primary-100 text-white mt-8 rounded-full h-[3.25rem] w-[7.938rem] disabled:cursor-not-allowed font-bold hover:bg-${
                    disableButton && 'bg-neutral-40'
                  }`}
                >
                  {loading ? (
                    <div className="flex justify-center">
                      <Spinner colour="stroke-white" />
                    </div>
                  ) : (
                    <div>Continue</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
