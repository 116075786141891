import { CustomCellRendererProps } from 'ag-grid-react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line
interface LinkCellParams extends CustomCellRendererProps {
  link: string;
}

export default function LinkCell(params: LinkCellParams): JSX.Element {
  if (params.node.group) {
    return <>-</>;
  }

  const rowIndex = params.node.rowIndex;

  return (
    <Link
      data-testid={`link-cell-${rowIndex}`}
      className="underline"
      to={`${params.link}/${params.data?.id}`}
    >
      {params.value}
    </Link>
  );
}
