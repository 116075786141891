import { useState } from 'react';
import { CampaignLink, MatchType } from 'shared/legacy-types';
import { deepClone } from 'ui/helpers/deepClone';
import { DEFAULT_CAMPAIGN_LINK } from 'ui/constants/rules';

export default function useCampaignLinkModals(
  initialCampaignLink: CampaignLink,
  initialBrand: string,
) {
  const [submittingCampaignLink, setSubmittingCampaignLink] =
    useState<boolean>(false);
  const [brand, setBrand] = useState<string>(initialBrand);
  const [campaignLink, setCampaignLink] =
    useState<CampaignLink>(initialCampaignLink);

  const copiedDefaultCampaignLink = deepClone(DEFAULT_CAMPAIGN_LINK);

  function updateCampaignLinkForm(value: string | number, type: string) {
    if (type === 'brand') {
      setBrand(value.toString());
      setCampaignLink({
        ...campaignLink,
        profileId: '',
        source: copiedDefaultCampaignLink.source,
        destination: copiedDefaultCampaignLink.destination,
      });
      return;
    }
    if (type === 'profileId') {
      value = parseInt(value.toString());
      setCampaignLink({
        ...campaignLink,
        profileId: value.toString(),
        source: copiedDefaultCampaignLink.source,
        destination: copiedDefaultCampaignLink.destination,
      });
      return;
    }
    // Sets matchType to exact as default if targetingType is changed to product
    if (type === 'targetingType' && value === 'product') {
      setCampaignLink({
        ...campaignLink,
        targetingType: value,
        matchTypes: ['exact'],
        negateKeywords: { negate: campaignLink.negateKeywords.negate },
      });
      return;
    }

    if (type === 'matchType') {
      const matchTypeExists =
        campaignLink.matchTypes &&
        campaignLink.matchTypes.includes(value as MatchType);
      // remove match type if it already exists
      if (matchTypeExists) {
        const matchTypes = campaignLink.matchTypes.filter(
          (matchType) => matchType !== value,
        );
        setCampaignLink({ ...campaignLink, matchTypes });
        return;
      }
      setCampaignLink({
        ...campaignLink,
        matchTypes: [...(campaignLink.matchTypes || []), value as MatchType],
      });
      return;
    }

    setCampaignLink({ ...campaignLink, [type]: value });
  }

  return {
    updateCampaignLinkForm,
    brand,
    setBrand,
    submittingCampaignLink,
    setSubmittingCampaignLink,
    campaignLink,
    setCampaignLink,
  };
}
