import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ENABLED, PRODUCT_SIGNALS } from 'ui/constants/rules';
import { LeftArrow } from 'components/Icons/';
import ToggleButton from 'components/Toggles/ToggleButton';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import useRule from 'pages/Rule/useRule';
import { addToResults, getResultsPage } from 'ui/helpers/stepResults';
import useRunRequests from 'api/hooks/requests/useRunRequests';
import useRuleProfiles from 'hooks/rules/useRuleProfiles';
import StepResults from 'ui/components/History/StepResults';
import useUserSelector from 'state/user/useUserSelector';
import useStepResults from 'hooks/useStepResults';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import BannerWrapper from 'pages/BannerWrapper';

export default function PreviewPage() {
  const [loadingMoreResults, setLoadingMoreResults] = useState<boolean>(false);
  const { ruleId } = useParams();
  const { getRulePreviewStep } = useRuleRequests();

  const { runSteps, loading, loaded, stepLoading, setRunSteps, rule, setRule } =
    useStepResults(getRulePreviewStep, ruleId);

  const navigate = useNavigate();
  const { toggleRuleStatus, isToggleEnabled } = useRule(rule!);
  const { getStepResults } = useRunRequests();
  const { getRuleProfile } = useRuleProfiles();
  const profile = getRuleProfile(rule);
  const { user } = useUserSelector() || {};

  async function loadMoreResults(stepId: number | undefined) {
    setLoadingMoreResults(true);
    const step = runSteps?.find((step) => step.stepId === stepId);
    const resultsCount = step?.results?.length;
    const resultsPage = getResultsPage(resultsCount!);

    const response = await getStepResults(stepId!, rule?.id, resultsPage!);

    const formattedSteps =
      runSteps && addToResults(stepId!, runSteps, response.data.results);
    setRunSteps(formattedSteps);
    setLoadingMoreResults(false);
  }

  if (rule?.dataType === PRODUCT_SIGNALS) {
    navigate(`/rules/${rule.id}`);
  }

  if (loading) {
    return (
      <div className="flex h-screen">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  return (
    <BannerWrapper>
      <div className={`bg-primary-20 h-full min-h-screen`}>
        <div className="mb-8 flex h-[4.5rem] w-full justify-between bg-neutral-100 px-6 py-4">
          <div
            className="flex items-center justify-start hover:cursor-pointer"
            onClick={() => navigate(`/rules/${rule.id}`)}
            aria-label="Go back to editing"
          >
            <LeftArrow colour="stroke-white" />
          </div>
          <div className="justify center my-auto items-center text-center text-sm text-white ">
            <div className="mb-1 text-sm font-bold text-white">Preview</div>
            <div className="flex">
              <div className="text-neutral-60 text-xs">{rule?.name}</div>
              <div className="text-neutral-60 mx-2 text-xs">•</div>
              <div className="text-neutral-60 text-xs">
                {profile?.accountName}
              </div>
            </div>
          </div>
          <div>
            <ToggleButton
              disabled={!isToggleEnabled}
              onChange={(enabled) => toggleRuleStatus(enabled, setRule)}
              label={['On', 'Off']}
              darkMode={true}
              enable={rule?.status === ENABLED}
            />
          </div>
        </div>
        <div className="mx-auto max-w-[57.5rem] pb-10 sm:pl-10 md:px-10 lg:px-16 xl:px-0">
          {runSteps?.map((step, index) => (
            <StepResults
              index={index}
              step={step}
              dataType={rule.dataType}
              loading={stepLoading !== undefined && stepLoading <= index}
              dataLoaded={loaded}
              loadMoreResults={loadMoreResults}
              loadingMoreResults={loadingMoreResults}
              enablePagination
              isLive={rule.status === ENABLED}
              user={user}
              isPreview
            />
          ))}
        </div>
      </div>
    </BannerWrapper>
  );
}
