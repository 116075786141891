import {
  configureStore,
  PreloadedState,
  combineReducers,
} from '@reduxjs/toolkit';
import userReducer from 'state/user/userSlice';
import bannerReducer from 'state/banners/BannerSlice';
import rulesReducer from 'state/rules/rulesSlice';
import runsReducer from 'state/runs/runsSlice';
import foldersReducer from 'state/folders/FoldersSlice';
import dashboardReducer from 'state/dashboard/dashboardSlice';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

const rootReducer = combineReducers({
  user: userReducer,
  banners: bannerReducer,
  rules: rulesReducer,
  runs: runsReducer,
  folders: foldersReducer,
  dashboard: dashboardReducer,
});

export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
): ToolkitStore => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
