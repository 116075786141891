import Tab from 'components/Tabs/Tab';

type Props = {
  tabSelection: string;
  setTabSelection: (tab: string) => void;
  tabs: { name: string; label: string }[];
};

export default function Tabs({
  tabSelection,
  setTabSelection,
  tabs,
}: Props): JSX.Element {
  const renderTabs = () => {
    return tabs.map((tab, index) => {
      const isLast = index === tabs.length - 1;

      return (
        <div key={index} className={`${!isLast && 'mr-6'}`}>
          <Tab
            selection={tabSelection}
            setSelection={setTabSelection}
            name={tab.name}
            label={tab.label}
          />
        </div>
      );
    });
  };

  return <div className="flex">{renderTabs()}</div>;
}
