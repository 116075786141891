import {
  ConditionGroupOperator,
  ConditionOperator,
  RuleStatus,
  ExpressionMetric,
  ExpressionOperator,
  DataType,
  ChangeStatusAction,
  EmailAction,
} from 'shared/legacy-types';
import { CHANGE_STATUS, SEND_EMAIL } from 'ui/constants/actions';
import { PRODUCTS, SHORT_PERIOD } from 'ui/constants/rules';

import TriangleSquareImage from 'components/Images/Templates/TriangleSquareImage';

export const pauseAdsForAsins = {
  id: 'pause_ads_for_asins',
  description:
    'Pause or re-enable ads for ASINs based on their organic conversion rate and receive an email notification. A drop in organic conversion rate indicates that some sort of issue might have happened on the listing e.g. a competitor winning the buy box, unauthorised content change, etc.',
  goal: 'Reduce wasted spend',
  category: 'Advertising',
  featureToggle: true,
  comingSoon: true,
  image: TriangleSquareImage,
  rule: {
    name: 'Pause ads for ASINs with a drop in conversion rate',
    adTypes: ['SP', 'SD', 'SB'],
    dataType: PRODUCTS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'CR% Drop',
        operator: '&&' as ConditionGroupOperator,
        period: SHORT_PERIOD,
        actions: [
          {
            type: CHANGE_STATUS,
            properties: {
              entityType: 'ads',
              status: 'paused',
            },
          } as ChangeStatusAction,
          {
            type: SEND_EMAIL,
          } as EmailAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:cr`,
                operator: '<' as ConditionOperator,
                value: {
                  metric: `average_cr` as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '0.8',
                },
              },
            ],
          },
        ],
      },
      {
        name: 'CR% Increase',
        operator: '&&' as ConditionGroupOperator,
        period: SHORT_PERIOD,
        actions: [
          {
            type: CHANGE_STATUS,
            properties: {
              entityType: 'ads',
              status: 'enabled',
            },
          } as ChangeStatusAction,
          {
            type: SEND_EMAIL,
          } as EmailAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:cr`,
                operator: '>=' as ConditionOperator,
                value: {
                  metric: `average_cr` as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '1.2',
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
