import {
  ACTION_PRESETS,
  DEFAULT_PRESETS,
  EXTENDED_PRESETS,
  EXTENDED_ACTION_PRESETS,
} from '../../constants/runs';
import {
  UpdateBidAction,
  UpdateBudgetAction,
  Action,
} from 'shared/legacy-types';

import {
  CHANGE_STATUS,
  HARVEST_KEYWORDS,
  SEND_EMAIL,
  ADD_NEGATIVE_KEYWORDS,
} from '../../constants/actions';

import { NEGATIVE_SEARCH_TERMS } from '../../constants/rules';

const actionMetric = (
  isBidOrBudget: boolean,
  isPauseOrEnable: boolean,
  isKeywordHarvesting: boolean,
  isAddNegativeKeywords: boolean,
  action: Action,
) => {
  if (isBidOrBudget) {
    const bidAction = action as UpdateBidAction | UpdateBudgetAction;

    return bidAction.properties.metric;
  }

  if (isPauseOrEnable) {
    return CHANGE_STATUS;
  }
  if (isKeywordHarvesting) {
    return HARVEST_KEYWORDS;
  }

  if (isAddNegativeKeywords) {
    return ADD_NEGATIVE_KEYWORDS;
  }

  if (action.type === SEND_EMAIL) {
    return SEND_EMAIL;
  }

  throw Error(
    `No handler for: ${{ isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, isAddNegativeKeywords, action, actionType: action.type }}`,
  );
};

export const getPreset = (
  dataType: string,
  showExtended: boolean,
  isBidOrBudget: boolean,
  isPauseOrEnable: boolean,
  isKeywordHarvesting: boolean,
  isAddNegativeKeywords: boolean,
  action: Action,
) => {
  if (!dataType) {
    return null;
  }

  // Override dataType for negative keywords (as it's using unaggregated search terms data, which is different
  // from what the dataType searchTerms is using
  // Also override for SEND_EMAIL when ADD_NEGATIVE_KEYWORDS is selected in the step, so that it returns
  // the negative search terms preset
  const effectiveDataType =
    isAddNegativeKeywords || action.type === SEND_EMAIL
      ? NEGATIVE_SEARCH_TERMS
      : dataType;

  // get preset for data type
  const dataTypePreset = showExtended
    ? EXTENDED_PRESETS[effectiveDataType as keyof typeof EXTENDED_PRESETS]
    : DEFAULT_PRESETS[effectiveDataType as keyof typeof DEFAULT_PRESETS];

  if (!dataTypePreset) {
    return null;
  }

  // get preset for action
  const actionPreset =
    ACTION_PRESETS[
      actionMetric(
        isBidOrBudget,
        isPauseOrEnable,
        isKeywordHarvesting,
        isAddNegativeKeywords,
        action,
      ) as keyof typeof ACTION_PRESETS
    ];

  const extendedActionPreset =
    showExtended &&
    EXTENDED_ACTION_PRESETS[
      actionMetric(
        isBidOrBudget,
        isPauseOrEnable,
        isKeywordHarvesting,
        isAddNegativeKeywords,
        action,
      ) as keyof typeof EXTENDED_ACTION_PRESETS
    ];

  // filter out any columns that are already in the action preset
  const filteredDataTypePreset = dataTypePreset.filter((presetColumn) => {
    const found =
      actionPreset &&
      actionPreset.find((column) => column.value === presetColumn.value);
    if (found) {
      return false;
    }
    return true;
  });

  if (!actionPreset) {
    return filteredDataTypePreset;
  }

  const actionPresets = extendedActionPreset
    ? [...extendedActionPreset, ...actionPreset]
    : actionPreset;

  // should always have an action, but might not always have a preset
  // add actions to end of preset
  return [...filteredDataTypePreset, ...actionPresets];
};
