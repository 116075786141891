interface Props {
  colour?: string;
}

export function ChevronRight({ colour = 'stroke-neutral-60' }: Props) {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9L5 5L1 1"
        className={`${colour}`}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
