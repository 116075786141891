import ErrorMessage from 'components/Errors/ErrorMessage';
import { Profile, SetupError } from 'shared/legacy-types';

interface Props {
  error: SetupError;
  brandProfiles: Profile[] | undefined;
}

export default function BrandError({ error, brandProfiles }: Props) {
  const brandErrors = () => {
    return error.takenProfileIds.map((profileId, index) => {
      const isFirst = index === 0;
      const isLast = index + 1 === error.takenProfileIds!.length;
      const secondToLast = index === error.takenProfileIds!.length - 2;

      const profile = brandProfiles?.find((brand) => brand.id === profileId);
      return (
        <span className="font-bold">
          {isLast && !isFirst && <> and </>}
          {profile?.accountName} - {profile?.country}
          {isLast && <>.</>}
          {!isLast && !secondToLast && <>, </>}
        </span>
      );
    });
  };

  function getMessage() {
    return (
      <div>
        You've selected one or more brands that are already in use in your
        chosen marketplaces. You can either contact us at support@meetfern.ai
        for further support or deselect the following to continue:{' '}
        {brandErrors()}
      </div>
    );
  }

  return <ErrorMessage message={getMessage()} styles="max-w-[37rem]" />;
}
