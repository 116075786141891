import { EUROPE, NORTH_AMERICA, ASIA_PACIFIC } from 'constants/flags';

interface Props {
  id: string;
}

type Country = {
  country: string;
  icon: any;
};

export default function Flags({ id }: Props) {
  const generateFlags = (countries: Array<Country>) => {
    return countries.map((country, index) => {
      const { icon: Icon, country: countryName } = country;
      return (
        <div key={index} className="flex mr-5">
          <Icon />
          <div className="leading-4 ml-2 mb-4">{countryName}</div>
        </div>
      );
    });
  };

  const flags = () => {
    switch (id) {
      case 'EU':
        return generateFlags(EUROPE);
      case 'NA':
        return generateFlags(NORTH_AMERICA);
      case 'FE':
        return generateFlags(ASIA_PACIFIC);
      default:
        throw new Error('Invalid region');
    }
  };

  return <div className="text-xs flex flex-wrap">{flags()}</div>;
}
