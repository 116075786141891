import { PlanTier } from 'shared/legacy-types';

const features = {
  workFlowBuilder: { label: 'Workflow builder' },
  templates: { label: 'Templates' },
  workFlowHistory: { label: 'Workflow history' },
  inviteUsers: { label: 'Invite users' },
  adSpendTargets: { label: 'Ad spend targets & pacing' },
  reporting: { label: 'Reporting' },
};

export const STARTER = 'starter';
export const MONITORING = 'monitoring';
export const PRO = 'pro';

export const STARTER_PLAN: PlanTier = {
  plan: STARTER,
  label: 'Starter',
  description:
    'Monitor your ASINs for key retail signals and receive a notification when things change',
  features: [
    { feature: '1 brand', starFeature: false },
    { feature: '1 marketplace', starFeature: false },
    { feature: 'Up to 3 ASINs', starFeature: false },
  ],
  tierFeatures: [
    { label: features.workFlowBuilder.label, available: true },
    { label: features.templates.label, available: true },
    { label: features.workFlowHistory.label, available: true },
    { label: features.inviteUsers.label, available: false },
    { label: features.adSpendTargets.label, available: false },
    { label: features.reporting.label, available: false },
    { label: '1 brand', available: true },
    { label: '1 marketplace', available: true },
    { label: 'Up to 3 ASINs', available: true },
    { label: 'No ad spend included', available: false },
  ],
  pricing: null,
};

// export const MONITORING_PLAN: PlanTier = {
//   plan: MONITORING,
//   label: 'Monitoring',
//   description: 'Monitor your ASINs for key retail signals and receive a notification when things change',
//   features: [
//     { feature: 'Up to 5 brands', starFeature: false },
//     { feature: 'Up to 5 marketplaces', starFeature: false },
//     { feature: 'Up to 50 ASINs', starFeature: false },
//     { feature: 'Send email alerts', starFeature: true },
//     { feature: 'Send Slack alerts', starFeature: true },
//   ],
//   pricing: { monthly: 50 },
// };

export const PRO_PLAN: PlanTier = {
  plan: PRO,
  label: 'Pro',
  description: 'When things change, let fern take action, automatically',
  features: [
    { feature: 'Unlimited brands', starFeature: false },
    { feature: 'Unlimited marketplaces', starFeature: false },
    { feature: 'Up to £10K/month ad spend', starFeature: false },
  ],
  tierFeatures: [
    { label: features.workFlowBuilder.label, available: true },
    { label: features.templates.label, available: true },
    { label: features.workFlowHistory.label, available: true },
    { label: features.inviteUsers.label, available: true },
    { label: features.adSpendTargets.label, available: true },
    { label: features.reporting.label, available: true },
    { label: 'Unlimited brands', available: true },
    { label: 'Unlimited marketplace', available: true },
    { label: 'Up to 50 ASINs', available: true },
    { label: 'Up to £10k/month ad spend', available: true },
  ],
  pricing: { monthly: 150 },
  mostPopular: true,
};

export const PLAN_DETAILS = {
  starter: STARTER_PLAN,
  pro: PRO_PLAN,
};

export const PLAN_TIERS = [STARTER_PLAN, PRO_PLAN];
