interface Props {
  colour?: string;
}

export function LeftArrow({ colour = 'stroke-primary-100' }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${colour}`}
    >
      <path
        d="M17.8307 12H6.16406"
        strokeWidth="1.66667"
        strokeLinejoin="round"
      />
      <path
        d="M11.9974 17.8301L6.16406 11.9967L11.9974 6.16341"
        strokeWidth="1.66667"
        strokeLinejoin="round"
      />
    </svg>
  );
}
