interface Props {
  count: number;
  limit: number | undefined;
  atLimit: boolean;
}

export default function LimitCounter({ count, limit, atLimit }: Props) {
  return (
    <div
      aria-label="limitCounter"
      className={`flex justify-center h-full min-w-[1.5rem] text-sm ${atLimit && 'text-error'}`}
    >
      {count}/{limit}
    </div>
  );
}
