import useUserSelector from 'state/user/useUserSelector';
import useUserRequests from 'api/hooks/requests/useUserRequests';
import { useEffect } from 'react';

export default function useAccount() {
  const { fetchUser } = useUserRequests();
  const { userStatus, isInternalUser } = useUserSelector();

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  return {
    userStatus,
    isInternalUser,
  };
}
