import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import useKeywordApprovalRequests from 'api/hooks/requests/useKeywordApprovalRequests';
import RuleHistoryDetail from 'ui/components/History/RuleHistoryDetail';
import useUserSelector from 'state/user/useUserSelector';
import useRuleProfiles from 'hooks/rules/useRuleProfiles';
import Breadcrumbs from 'components/Breadcrumbs';
import useStepResults from 'hooks/useStepResults';
import useRunRequests from 'api/hooks/requests/useRunRequests';
import Modal from 'components/Modals/Modal';
import Spinner from 'ui/Spinner/Spinner';

export default function HistoryDetailContainer() {
  const { getRunStepResults } = useRunRequests();
  const { user } = useUserSelector() || {};
  const {
    approveKeywordsRequest,
    getKeywordApprovalsRequest,
    isApprovingKeywords,
    isApprovingKeywordsTakingLong,
  } = useKeywordApprovalRequests();

  const { getRuleProfile } = useRuleProfiles();
  const { runId } = useParams();

  const {
    runSteps,
    loading,
    loaded,
    stepLoading,
    rule,
    setRefetchResults,
    refetchResults,
  } = useStepResults(getRunStepResults, runId);
  const { name, id } = rule || {};

  const firstRunStep = runSteps && runSteps[0];

  const completedAt = firstRunStep && firstRunStep.completedAt;
  const lastRun =
    completedAt && format(new Date(completedAt), 'do MMM yyyy, H:mm ');
  const run = {
    ruleName: name,
    completedAt: lastRun,
    ruleId: id,
    steps: runSteps,
  };

  const profile = rule && getRuleProfile(rule);
  const ruleName = run && run.ruleName;
  const accountName = profile && profile.accountName;

  return (
    <DashboardLayout>
      <>
        <Breadcrumbs
          link="/history"
          linkLabel="History"
          currentPage={ruleName || ''}
        />
        <Modal open={isApprovingKeywords} setOpen={() => {}}>
          <div className="w-[40rem] bg-white p-6">
            <div className="flex justify-between">
              <h5 className="font-bold">
                {isApprovingKeywordsTakingLong
                  ? 'We are still processing your request'
                  : 'Processing'}
              </h5>
              <Spinner />
            </div>
            {isApprovingKeywordsTakingLong && (
              <>
                <div className="text-neutral-80 mt-1 pt-3 pb-1">
                  - It can take up to 30 seconds depending on the number of
                  keywords
                </div>
                <div className="text-neutral-80 mt-1">
                  - This box closes automatically once completed
                </div>
              </>
            )}
          </div>
        </Modal>
        <RuleHistoryDetail
          run={run}
          rule={rule}
          loading={loading}
          loaded={loaded}
          user={user}
          accountName={accountName || ''}
          approveKeywordsRequest={approveKeywordsRequest}
          getKeywordApprovalsRequest={getKeywordApprovalsRequest}
          editable
          stepLoading={stepLoading}
          setRefetchResults={setRefetchResults}
          refetchResults={refetchResults}
        />
      </>
    </DashboardLayout>
  );
}
