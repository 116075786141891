import { useState, useEffect } from 'react';

import { Config } from 'shared/legacy-types';
import useSpendTargetRequests from 'api/hooks/requests/useSpendTargetRequests';
import useUser from 'hooks/useUser';
import { brandOptions } from 'helpers/user/userProfiles';
import useUserSelector from 'state/user/useUserSelector';
import * as Sentry from '@sentry/react';

export default function useSpendTargets() {
  const [brandFilter, setBrandFilter] = useState<string>('');
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deletingSpendTarget, setDeletingSpendTarget] =
    useState<boolean>(false);
  const [spendTargetToDelete, setSpendTargetToDelete] = useState<string>('');
  const [spendTargetProfileIds, setSpendTargetProfileIds] = useState<string[]>(
    [],
  );
  const [filterBrandOptions, setFilterBrandOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [showDeleteFailedMessage, setShowDeleteFailedMessage] =
    useState<boolean>(false);

  const { userProfiles, getProfileData } = useUser();
  const { userConfig } = useUserSelector();
  const { profilesWithSpendTarget } = userConfig || ({} as Config);

  const { deleteSpendTargetRequest } = useSpendTargetRequests();

  useEffect(() => {
    setSpendTargetProfileIds(profilesWithSpendTarget);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update filter when spend targets change
    const fullProfiles = spendTargetProfileIds.map((profileId) => {
      return userProfiles.find(
        (profile) => profile.id.toString() === profileId,
      )!;
    });

    const filteredProfiles = fullProfiles.filter(
      (profile) => profile !== undefined,
    );

    const options = brandOptions(filteredProfiles);

    setFilterBrandOptions([{ label: 'All', value: 'all' }, ...options]);
  }, [spendTargetProfileIds, userProfiles]);

  // filter spendTargetProfileIds by brand
  function filterSpendTargets() {
    return spendTargetProfileIds.filter((profileId) => {
      const profileData = getProfileData(+profileId);
      return (
        brandFilter === 'all' ||
        brandFilter === '' ||
        profileData?.accountName === brandFilter
      );
    });
  }

  function openDeleteModal(profileId: string) {
    setDeleteModalOpen(true);
    setSpendTargetToDelete(profileId);
  }

  async function deleteSpendTarget() {
    setDeletingSpendTarget(true);

    try {
      const response = await deleteSpendTargetRequest(spendTargetToDelete);
      if (response.status === 409) {
        setShowDeleteFailedMessage(true);
        setTimeout(() => {
          setShowDeleteFailedMessage(false);
          setDeleteModalOpen(false);
        }, 7000);
        setDeletingSpendTarget(false);
        return;
      }
      setDeleteModalOpen(false);
      setDeletingSpendTarget(false);
      //remove Spend target from state
      const filteredspendTargetProfileIds = spendTargetProfileIds.filter(
        (profileId) => profileId !== spendTargetToDelete,
      );
      setSpendTargetProfileIds(filteredspendTargetProfileIds);
    } catch (error) {
      Sentry.captureException(error);
      setDeleteModalOpen(false);
      setDeletingSpendTarget(false);
    }
  }

  return {
    brandFilter,
    setBrandFilter,
    filterSpendTargets,
    openDeleteModal,
    deleteModalOpen,
    setDeleteModalOpen,
    deletingSpendTarget,
    deleteSpendTarget,
    spendTargetToDelete,
    spendTargetProfileIds,
    setSpendTargetProfileIds,
    filterBrandOptions,
    showDeleteFailedMessage,
  };
}
