import { Column } from '../../../constants/runs';
import { headerStyles } from '../ResultsTable/helpers';

interface Props {
  preset: Column[];
  isEmailResults: boolean;
  enableKeywordApproval?: boolean;
  isKeywordHarvesting?: boolean;
  extended: boolean;
}

export default function TableHeaders({
  preset,
  enableKeywordApproval,
  isEmailResults,
  isKeywordHarvesting,
  extended,
}: Props) {
  function renderHeaders() {
    return preset.map((header, index) => {
      const { cellStyle } = headerStyles(
        header,
        index,
        preset,
        extended,
        isEmailResults,
        isKeywordHarvesting,
        enableKeywordApproval,
      );

      return (
        <div
          key={index}
          className={`border-secondary-60 bg-secondary-40 table-cell truncate border-b p-[0.5rem] flex ${cellStyle}`}
        >
          {header.label}
        </div>
      );
    });
  }

  return (
    <div className="sticky top-0 table-header-group">
      <div className="table-row text-left flex text-xs font-bold">
        {renderHeaders()}
      </div>
    </div>
  );
}
