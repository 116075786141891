import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import useSubscription from 'hooks/useSubscriptions';
import { Plan } from 'shared/legacy-types';

interface Props {
  plan: Plan;
  isCurrentPlan: boolean;
  isStarterPlan: boolean;
  setOpen: (open: boolean) => void;
  mostPopular?: boolean;
}

export default function CallToAction({
  plan,
  mostPopular,
  isCurrentPlan,
  isStarterPlan,
  setOpen,
}: Props) {
  const navigate = useNavigate();
  const { hasUsedFreeTrial } = useSubscription();

  const usedFreeTrial = hasUsedFreeTrial(plan);

  const text = () => {
    if (isCurrentPlan) {
      return <>Current plan</>;
    }
    if (isStarterPlan || usedFreeTrial) {
      return <>Change plan</>;
    }

    return <>Start 14-day trial</>;
  };

  const switchPlan = () => {
    setOpen(false);
    navigate(`/change-plan/${plan}`);
  };

  if (mostPopular) {
    return (
      <PrimaryButton
        disabled={isCurrentPlan}
        width={'w-[15rem]'}
        onClick={switchPlan}
      >
        {text()}
      </PrimaryButton>
    );
  }

  return (
    <SecondaryButton
      disabled={isCurrentPlan}
      styles={'w-[15rem]'}
      onClick={switchPlan}
    >
      {text()}
    </SecondaryButton>
  );
}
