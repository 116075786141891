import Notices from './Components/Notices';
import ActionResultsTab from './Components/ActionResultsTab';
import { Action } from 'shared/legacy-types';
import Toggle from '../Toggles/Toggle';
import { Fragment } from 'react';

interface Props {
  actionTimestamp?: string;
  isLive?: boolean;
  incompleteData?: boolean;
  currentAction?: Action;
  noResultsFound?: boolean;
  actions: Action[];
  selectedAction: string;
  setSelectedAction: (action: string) => void;
  dataType: string;
  showExtended: boolean;
  setShowExtended: (showExtended: boolean) => void;
}

export default function ResultsToolbar({
  actionTimestamp,
  isLive,
  currentAction,
  incompleteData,
  noResultsFound,
  actions,
  selectedAction,
  setSelectedAction,
  dataType,
  showExtended,
  setShowExtended,
}: Props) {
  return (
    <>
      <Notices
        actionTimestamp={actionTimestamp}
        isLive={isLive}
        currentAction={currentAction}
        incompleteData={incompleteData}
      />
      {!noResultsFound && (
        <span
          className={`justify-end ${
            actions && actions.length > 0 && 'justify-between'
          } width-full border-neutral-40 mb-2 mt-6 flex border-b`}
        >
          <div className="w-content flex justify-start">
            {actions.map((action, index) => {
              return (
                <Fragment key={index}>
                  <ActionResultsTab
                    key={index}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                    action={action}
                    dataType={dataType}
                  />
                </Fragment>
              );
            })}
          </div>
          <span className={`flex justify-end`}>
            <span className="text-neutral-80 mr-2 text-xs !leading-6">
              Show more columns
            </span>
            <Toggle
              enable={showExtended}
              toggle={() => setShowExtended(!showExtended)}
            />
          </span>
        </span>
      )}
    </>
  );
}
