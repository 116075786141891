import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

import { RunStep, Rule, Optional } from 'shared/legacy-types';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import { DEFAULT_RULE } from 'ui/constants/rules';
import useRuleProfiles from 'hooks/rules/useRuleProfiles';
import useUserSelector from 'state/user/useUserSelector';
import { deepClone } from 'ui/helpers/deepClone';

export default function useStepResults(
  getResults: (id: string, stepId: number) => Promise<any>,
  runId: string | undefined,
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [stepLoading, setStepLoading] = useState<number | undefined>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [ruleSuccess, setRuleSuccess] = useState<boolean>(false);
  const [rule, setRule] = useState<Optional<Rule, 'id'> | Rule>(
    deepClone(DEFAULT_RULE),
  );
  const [runSteps, setRunSteps] = useState<RunStep[]>();
  const [refetchResults, setRefetchResults] = useState<boolean>(false);

  const { ruleId } = useParams();
  const { getRule } = useRuleRequests();
  const { getRuleProfile } = useRuleProfiles();
  const profile = getRuleProfile(rule);
  const { user } = useUserSelector() || {};

  useEffect(() => {
    const fetchRule = async () => {
      if (ruleId) {
        setLoading(true);
        const rule = await getRule(ruleId);
        setRule(rule);
        setRuleSuccess(true);
      }
    };
    fetchRule();
    // eslint-disable-next-line
  }, []);

  useEffect(
    function initRunSteps() {
      if (ruleSuccess && rule && rule.steps.length) {
        setRule(rule);
        setLoading(false);
        setRunSteps(rule.steps);
        setRuleSuccess(false);
      }
    },
    [rule, ruleSuccess],
  );

  const fetchResults = useCallback(async () => {
    if (runId) {
      let steps = [] as RunStep[] | undefined;
      for (let i = 0; i < runSteps!.length; i++) {
        try {
          setStepLoading(i);
          const response = await getResults(runId, i + 1);

          if (response.status === 200) {
            const stepData = i === 0 ? runSteps : steps;

            const formattedSteps =
              stepData &&
              stepData.map((runStep: RunStep, previewIndex: number) => {
                if (previewIndex === i) {
                  return { ...runStep, ...response.data } as RunStep;
                }

                return runStep;
              });

            steps = formattedSteps;
            setRunSteps(formattedSteps);
            setStepLoading(undefined);
          }
        } catch (error) {
          setStepLoading(undefined);
          throw error;
        }
      }
    }
  }, [getResults, runId, runSteps]);

  useEffect(() => {
    async function fetchRuleStepResults() {
      await fetchResults();
      setLoaded(true);
    }
    runSteps &&
      runSteps.length > 0 &&
      !loaded &&
      stepLoading === undefined &&
      fetchRuleStepResults();
    // eslint-disable-next-line
  }, [runSteps]);

  useEffect(() => {
    async function refetchRuleStepResults() {
      await fetchResults();
      setRefetchResults(false);
    }

    refetchResults &&
      runSteps &&
      runSteps.length > 0 &&
      stepLoading === undefined &&
      refetchRuleStepResults();
    // eslint-disable-next-line
  }, [refetchResults]);

  return {
    loading,
    runSteps,
    setRunSteps,
    rule,
    setRule,
    profile,
    user,
    stepLoading,
    loaded,
    setRefetchResults,
    refetchResults,
  };
}
