import { Rule } from 'shared/legacy-types';
import FormattedDateTime from 'pages/DesignSystem/DesignComponents/FormattedDateTime';

interface Props {
  rule: Rule;
}

export default function LastUpdate({ rule }: Props) {
  const action = rule.updatedAt ? 'Last saved' : 'Created';
  const date = rule.updatedAt ? rule.updatedAt : rule.createdAt;

  return (
    <div>
      {date && (
        <span className="flex inline">
          {action}&nbsp;{<FormattedDateTime date={date} />}
        </span>
      )}
    </div>
  );
}
