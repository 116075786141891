import { useState } from 'react';
import useUserRequests from 'api/hooks/requests/useUserRequests';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type UseVerifyEmailHook = {
  resendEmail: () => Promise<void>;
  verifySent: boolean;
  sending: boolean;
};

const HTTP_ACCEPTED = 202;
const DISABLE_RESEND_BUTTON_DURATION = 20000;

export default function useVerifyEmail(): UseVerifyEmailHook {
  const [verifySent, setVerifySent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const { sendVerifyEmail } = useUserRequests();

  async function resendEmail(): Promise<void> {
    setSending(true);
    const response = await sendVerifyEmail();
    setSending(false);
    if (response.status === HTTP_ACCEPTED) {
      setVerifySent(true);
      showToast(
        "We've sent another verification link to your email",
        ToastStyle.SUCCESS,
      );

      setTimeout(() => {
        setVerifySent(false);
      }, DISABLE_RESEND_BUTTON_DURATION);
    }
  }

  return { resendEmail, verifySent, sending };
}
