import { ReactComponent as SpendTargets } from 'images/spendTargets.svg';

interface Props {
  callToAction: JSX.Element;
}

export default function SpendTargetSetup({ callToAction }: Props) {
  return (
    <div className="mt-[6.25rem] flex justify-center text-center">
      <div>
        <SpendTargets />
        <div className="w-[28rem]">
          <div className="text-[2rem] leading-[2.375rem]">
            Introducing{' '}
            <span className="text-primary-100 font-bold">Spend targets</span>
          </div>
          <div className="text-neutral-80 pt-3">
            Adding spend targets will give you access to new metrics that fern
            calculates for you. Get started by creating your first spend target.
          </div>
          <div className="mt-6">{callToAction}</div>
        </div>
      </div>
    </div>
  );
}
