import DashboardLayout from 'layouts/DashboardLayout';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import { LOADING } from 'constants/requests';
import useAccount from './useAccount';
import UserDetails from './UserDetails';
import PlanInfo from './PlanInfo';

export default function Account() {
  const { userStatus } = useAccount();

  if (userStatus === LOADING) {
    return (
      <DashboardLayout>
        <div className="flex h-screen">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  const headerStyles =
    'sm:px-[-1.5rem] md:mx-[-1.5rem]  xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem]';

  return (
    <DashboardLayout>
      <div className="">
        <div
          className={`${headerStyles}`}
          style={{ boxShadow: '0px 2px 8px 0px rgba(5, 26, 22, 0.03)' }}
        >
          <h4 className="pb-4 mt-2 ml-[1.5rem] xl:ml-[4.875rem] min-[1440px]:ml-[7.5rem] font-medium text-neutral-100">
            Account
          </h4>
          <div className={`border-b border-neutral-40`} />
        </div>
        <div className="flex mt-6">
          <UserDetails />
          <PlanInfo />
        </div>
      </div>
    </DashboardLayout>
  );
}
