import { Fragment, useContext } from 'react';
import ConditionComponent from 'pages/Rule/Steps/Conditions/Condition';
import { Rule, Condition, ConditionGroupOperator } from 'shared/legacy-types';
import { DEFAULT_CONDITION } from 'ui/constants/rules';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import { PlusIcon } from 'components/Icons';

interface Props {
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
  conditions: Condition[];
  groupIndex: number;
  operator: string;
}

export default function ConditionGroup({
  conditions,
  rule,
  onChange,
  groupIndex,
  operator,
}: Props) {
  const { stepIndex } = useContext(StepContext) || {};

  const step = rule.steps[stepIndex];
  const conditionGroup = step && step.conditionGroups[groupIndex];

  function handleOperator(newOperator: string) {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update operator',
      );
    }

    conditionGroup.operator = newOperator as ConditionGroupOperator;

    onChange({ ...rule });
  }

  function addCondition() {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot add condition',
      );
    }

    conditionGroup.conditions = conditions.concat({ ...DEFAULT_CONDITION });

    onChange({
      ...rule,
    });
  }

  function renderConditions() {
    return conditions.map((condition, index) => {
      return (
        <Fragment key={index}>
          <ConditionComponent
            rule={rule}
            onChange={onChange}
            condition={condition}
            index={index}
            groupIndex={groupIndex}
            handleOperator={handleOperator}
            operator={operator}
          />
        </Fragment>
      );
    });
  }

  return (
    <div className="bg-secondary-20 rounded-lg pt-1 px-2 group border border-neutral-40">
      {renderConditions()}
      <div
        className="flex p-1 mb-2 mt-2  w-fit hover:cursor-pointer"
        onClick={() => addCondition()}
      >
        <PlusIcon /> <div className="text-sm ml-1 font-bold ">Condition</div>
      </div>
    </div>
  );
}
