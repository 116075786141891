import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';

import { Rule } from 'shared/legacy-types';
import { DRAFT, ENABLED, DISABLED } from 'ui/constants/rules';
import { MeatballIcon, HistoryIcon, EditIcon } from 'components/Icons/';
import useClickOutside from 'ui/hooks/useClickOutside';
import Checkbox from 'ui/components/Checkbox/Checkbox';
import useFoldersSelector from 'state/folders/useFoldersSelector';
import * as Sentry from '@sentry/react';

type Props = {
  rule: Rule;
  duplicateRule: (ruleId: string) => Promise<void>;
  openArchiveModal: (rule: Rule) => void;
  isSelected: boolean;
  selectRule: (ruleId: string) => void;
};

export default function RuleCard({
  rule,
  duplicateRule,
  openArchiveModal,
  isSelected,
  selectRule,
}: Props): JSX.Element {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);

  const { status, id } = rule;
  const isLive = status === ENABLED;
  const isDraft = status === DRAFT;
  const navRef = useRef(null);
  const meatballRef = useRef(null);

  const { folders } = useFoldersSelector();
  const showCheckbox = folders.length > 0;

  useClickOutside(setNavOpen, navRef, meatballRef);

  async function duplicate(ruleId: string): Promise<void> {
    setNavOpen(false);
    await duplicateRule(ruleId);
  }

  function getStyles(): string {
    switch (status) {
      case ENABLED:
        return 'border-primary-100';
      case DRAFT:
        return 'border-neutral-40 text-neutral-80';
      case DISABLED:
        return 'border-neutral-40 bg-neutral-20 ';
      default:
        Sentry.captureMessage(`Rule status not found for styles ${status}`);
        return '';
    }
  }

  function getText(): string {
    switch (status) {
      case ENABLED:
        return 'Live';
      case DRAFT:
        return 'Draft';
      case DISABLED:
        return 'Paused';
      default:
        Sentry.captureMessage(`Rule status not found for text ${status}`);
        return '';
    }
  }

  function statusButton(): JSX.Element {
    return (
      <div
        className={`${getStyles()} border px-[0.4375rem] py-[0.1875rem] text-sm font-bold rounded h-[1.5625rem]`}
      >
        {getText()}
      </div>
    );
  }

  return (
    <div
      className={`w-full bg-white mb-2 pr-6 rounded border border-l-4 border-neutral-40 h-[3.5rem] z-40 ${
        isDraft && 'border-l-neutral-40'
      } ${isLive && 'border-l-primary-100'}`}
      key={id}
      onMouseEnter={() => {
        setShowActions(true);
      }}
      onMouseLeave={() => {
        setShowActions(false);
      }}
      data-cy="rule-card"
    >
      <div className="flex justify-between">
        <div className="flex">
          {showCheckbox && (
            <div
              className="ml-3 py-[0.9063rem] mt-[0.188rem]"
              aria-label={`Select ${rule.name} to move`}
            >
              <Checkbox
                id={rule.id}
                checked={isSelected}
                select={() => {
                  selectRule(rule.id);
                }}
                size="5"
              />
            </div>
          )}
          <Link to={`/rules/${id}`}>
            <div className="flex py-[0.9063rem] hover:cursor-pointer h-full">
              <div className="flex">
                <div className="text-sm font-bold !leading-6 mr-4 pl-3 max-w-[6.25rem] min-[980px]:max-w-[9.375rem] min-[1023px]:max-w-[15.625rem] min-[1115px]:max-w-[21.875rem] 2xl:max-w-[28.125rem] truncate">
                  <label className="hover:cursor-pointer h-full">
                    {rule.name || 'Untitled rule'}
                  </label>
                </div>
              </div>
              {!isDraft && <div>{statusButton()}</div>}
            </div>
          </Link>
        </div>
        <div
          className={`flex items-center text-sm font-bold text-neutral-80 h-[2.0625rem] mt-[0.6563rem] !leading-[2.0625rem] relative `}
        >
          {!showActions && <div className="w-[5.625rem]" />}
          {showActions && (
            <>
              {!isDraft && (
                <Link
                  to={`/history/${id}`}
                  className="flex items-center p-3 h-[2.0625rem] !leading-[2.0625rem] hover:bg-neutral-20 hover:rounded-2xl relative group"
                  aria-label="View history"
                >
                  <div>
                    <span className="absolute h-fit w-[5.6rem] flex-wrap z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-[2.4rem] left-[-1.5rem] group-hover:visible invisible">
                      View history
                    </span>
                    <HistoryIcon
                      size="16"
                      stroke={
                        'stroke-neutral-80 group-hover:stroke-primary-100'
                      }
                    />
                  </div>
                </Link>
              )}
              <Link
                className="flex items-center p-3 mr-3 h-[2.0625rem] !leading-[2.0625rem] hover:bg-neutral-20 hover:rounded-2xl relative group"
                to={`/rules/${id}`}
                aria-label="Edit rule"
              >
                <div>
                  <span className="absolute h-fit w-fit flex-wrap z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-[2.4rem] group-hover:visible invisible right-[0.12rem]">
                    Edit
                  </span>
                  <EditIcon
                    stroke={'stroke-neutral-80 group-hover:stroke-primary-100'}
                  />
                </div>
              </Link>
            </>
          )}
          <div
            ref={meatballRef}
            className="flex items-center pl-4 border-l border-neutral-40 h-[2.0625rem] !leading-[2.0625rem] hover:cursor-pointer relative"
            onClick={() => {
              setNavOpen(!navOpen);
            }}
            data-cy="open-nav"
          >
            <MeatballIcon />
            {navOpen && (
              <div
                ref={navRef}
                className="absolute bg-white py-[0.6875rem] px-[0.9375rem] w-[13.75rem] rounded-md top-[2.1875rem] right-[-0.25rem]  border border-neutral-40 z-50"
                style={{
                  boxShadow:
                    '0rem 0.25rem 1rem, -0.25rem rgba(0, 18, 51, 0.08)',
                }}
                data-cy="nav-popup"
              >
                <div
                  className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded"
                  onClick={() => {
                    void duplicate(id);
                  }}
                >
                  Duplicate
                </div>
                <div className="border-b border-neutral-40 my-1" />
                <div
                  className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded"
                  onClick={() => {
                    openArchiveModal(rule);
                  }}
                >
                  Archive
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
