import { Link } from 'react-router-dom';
import {
  KeywordApprovals,
  Rule,
  Run,
  User,
  Optional,
} from 'shared/legacy-types';
import SecondaryButton from '../Buttons/SecondaryButton';
import LargeSpinner from '../../Spinner/LargeSpinner';
import StepResults from './StepResults';
import { createContext } from 'react';

type ContextData = {
  refetchResults?: (open: boolean) => void;
  approveKeywordsRequest?: (keywordApprovals: KeywordApprovals) => Promise<any>;
  getKeywordApprovalsRequest?: (searchTerms: string[]) => Promise<any>;
  refetchingResults?: boolean;
};

export const HistoryDetailContext = createContext<ContextData>({
  refetchResults: () => {},
  approveKeywordsRequest: () => Promise.resolve(),
  getKeywordApprovalsRequest: () => Promise.resolve(),
  refetchingResults: false,
});

interface Props {
  run: Run | undefined;
  rule: Optional<Rule, 'id'>;
  loading: boolean;
  loaded: boolean;
  accountName: string;
  user: User | null;
  editable?: boolean;
  approveKeywordsRequest?: (keywordApprovals: KeywordApprovals) => Promise<any>;
  getKeywordApprovalsRequest?: (searchTerms: string[]) => Promise<any>;
  stepLoading?: number | undefined;
  setRefetchResults?: (refetch: boolean) => void;
  refetchResults?: boolean;
}

export default function RuleHistoryDetail({
  run,
  rule,
  loading,
  loaded,
  accountName,
  user,
  editable,
  approveKeywordsRequest,
  getKeywordApprovalsRequest,
  stepLoading,
  setRefetchResults,
  refetchResults,
}: Props) {
  const { ruleName, completedAt, steps, ruleId } = run || {};

  if (loading) {
    return (
      <div className="flex h-screen">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  return (
    <HistoryDetailContext.Provider
      value={{
        refetchResults: setRefetchResults,
        approveKeywordsRequest,
        getKeywordApprovalsRequest,
        refetchingResults: refetchResults,
      }}
    >
      <div className={`pb-12 `}>
        <div className="mt-4 flex justify-between">
          <div>
            <h3 className="mb-2 font-bold">{ruleName}</h3>
            <div className="flex">
              <div className="text-neutral-80 text-sm">{accountName}</div>
              {completedAt && (
                <>
                  <div className="text-neutral-80 mx-2 text-sm">•</div>
                  <div className="text-neutral-80 text-sm">{completedAt}</div>
                </>
              )}
            </div>
          </div>
          {editable && (
            <Link to={`/rules/${ruleId}`}>
              <SecondaryButton>
                <>Edit rule</>
              </SecondaryButton>
            </Link>
          )}
        </div>
        {rule?.status === 'ARCHIVED' && (
          <div className="bg-primary-20 text-neutral-80 rounded py-5 text-center text-sm">
            This rule has been archived
          </div>
        )}
        {rule &&
          rule.status !== 'ARCHIVED' &&
          steps?.map((step, index) => (
            <StepResults
              dataType={rule.dataType}
              index={index}
              key={index}
              step={step}
              dataLoaded={loaded}
              actionTimestamp={completedAt}
              user={user}
              loading={stepLoading !== undefined && stepLoading <= index}
            />
          ))}
      </div>
    </HistoryDetailContext.Provider>
  );
}
