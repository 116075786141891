interface Props {
  fill?: string;
}

export function LockIcon({ fill = 'fill-neutral-60' }: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1292 7.81396H3.87323C2.71725 7.81396 1.78125 8.75196 1.78125 9.90596V15.9059C1.78125 17.0619 2.71725 17.9999 3.87323 17.9999H14.1292C15.2852 17.9999 16.2212 17.0619 16.2212 15.9059V9.90596C16.2212 8.75196 15.2852 7.81396 14.1292 7.81396ZM9.95724 13.262V14.9679C9.95724 15.4939 9.53123 15.9239 9.00123 15.9239C8.47123 15.9239 8.04523 15.4939 8.04523 14.9679V13.262C7.52324 12.942 7.17724 12.366 7.17724 11.7119C7.17724 10.706 7.99522 9.88996 9.00123 9.88996C10.0072 9.88996 10.8252 10.706 10.8252 11.7119C10.8252 12.366 10.4792 12.942 9.95724 13.262Z"
        className={fill}
      />
      <path
        d="M6.36544 4.72597C6.36544 3.27399 7.54744 2.09398 8.99943 2.09398C10.4514 2.09398 11.6334 3.27399 11.6334 4.72597V7.21398H13.7254V4.72597C13.7254 2.11997 11.6054 0 8.99943 0C6.39343 0 4.27344 2.11997 4.27344 4.72597V7.21398H6.36544V4.72597Z"
        className={fill}
      />
    </svg>
  );
}
