import { REPORTING_NUMBERS_DECIMALS } from 'shared/constants';

export enum ChangeColour {
  GREEN = 'GREEN',
  RED = 'RED',
  NEUTRAL = 'NEUTRAL',
}

export enum ChangeColourStyles {
  GREEN = 'text-primary-100',
  RED = 'text-error',
  NEUTRAL = 'text-neutral-80',
}

export default function ChangeCell(
  changeValue: number,
  positiveChangeColour: ChangeColour,
  negativeChangeColour: ChangeColour,
): JSX.Element {
  if (changeValue === 0) {
    return <span className={ChangeColourStyles[ChangeColour.NEUTRAL]}>-</span>;
  }

  const style =
    changeValue > 0
      ? ChangeColourStyles[positiveChangeColour]
      : ChangeColourStyles[negativeChangeColour];

  return (
    <span className={style}>
      {changeValue > 0 && '+'}
      {changeValue.toFixed(REPORTING_NUMBERS_DECIMALS)}%
    </span>
  );
}
