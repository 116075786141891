import { useState } from 'react';
import {
  RightArrow,
  EditIcon,
  TrashIcon,
  NeutralCircle,
  TickCircle,
  CopyIcon,
} from 'components/Icons';
import { CampaignLink } from 'shared/legacy-types';
import Campaign from './Campaign';
import Bid from './Bid';
import { formatLabel } from '../helpers/campaignLinks';
import MatchTypes from './MatchTypes';

interface props {
  brand: string;
  marketplace: string;
  campaign: CampaignLink;
  currency: string;
  openDeleteLinkModal: (campaign: CampaignLink) => void;
  openEditLinkModal: (campaign: CampaignLink) => void;
  openAddModalToCopyCampaignLink: (campaign: CampaignLink) => void;
}

export default function CampaignLinkCard({
  brand,
  marketplace,
  campaign,
  currency,
  openDeleteLinkModal,
  openEditLinkModal,
  openAddModalToCopyCampaignLink,
}: props) {
  const [showActions, setShowActions] = useState(false);
  const isNegate = campaign.negateKeywords && campaign.negateKeywords.negate;

  return (
    <>
      <div
        className="border-neutral-40 mt-3 h-[3.688rem] rounded border px-4 py-3 flex  w-full"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        aria-label={campaign.destination.campaignName}
      >
        <div className="w-[2rem] mr-6 leading-8 group/item">
          <div className="bg-primary-60 text-secondary-80 mr-6 h-8 w-8 flex-auto max-w-16 rounded-full text-center font-bold">
            {brand && brand.charAt(0).toUpperCase()}
          </div>
          <span
            className={`w-content group/edit absolute hidden h-[1.875rem] rounded bg-neutral-100 p-2 text-xs text-white group-hover/item:block group-hover/item:translate-y-[-4.2rem] 
          `}
          >
            {brand}
          </span>
        </div>
        <div className="basis-[3.5rem] text-sm mr-6 my-auto">{marketplace}</div>
        <Campaign
          campaignName={campaign.source.campaignName}
          adGroups={campaign.source.adGroups}
        />
        <div className="mr-6 w-4 self-center">
          <RightArrow />
        </div>
        <Campaign
          campaignName={campaign.destination.campaignName}
          adGroups={campaign.destination.adGroups}
        />
        <div className="w-[5rem] mr-6 self-center">
          <MatchTypes campaign={campaign} />
        </div>
        <div className="w-[6.35rem] mr-6 text-sm self-center flex">
          {formatLabel(campaign.targetingType)}
        </div>
        <div className="w-[3rem] mr-6 text-sm self-center flex">
          {isNegate ? (
            <TickCircle
              size="20"
              iconColour="stroke-white"
              background="fill-primary-100"
            />
          ) : (
            <NeutralCircle fill="fill-neutral-40" />
          )}
        </div>
        <div className="self-center w-[4.5rem]">
          <Bid
            campaign={campaign}
            currency={currency}
            showActions={showActions}
          />
          {showActions && (
            <div className="w-[1.75rem] flex mr-6">
              <div
                className="mr-3 group group/item flex relative group z-60 hover:cursor-pointer"
                aria-label="Edit campaign link"
              >
                <span onClick={() => openEditLinkModal(campaign)}>
                  <EditIcon
                    stroke={'stroke-neutral-80 group-hover:stroke-primary-100'}
                  />
                </span>
                <span className="absolute bottom-[1.8rem] left-[-0.6rem] h-fit w-fit flex-wrap z-80 p-2 mr-8 rounded bg-neutral-100 text-white group-item text-xs group-hover:block hidden">
                  Edit
                </span>
              </div>
              <div
                className="mr-3 group group/item flex relative group z-60 hover:cursor-pointer"
                aria-label="Edit campaign link"
              >
                <span onClick={() => openAddModalToCopyCampaignLink(campaign)}>
                  <CopyIcon
                    stroke={'stroke-neutral-80 group-hover:stroke-primary-100'}
                  />
                </span>
                <span className="absolute bottom-[1.8rem] left-[-0.6rem] h-fit w-fit flex-wrap z-80 p-2 mr-8 rounded bg-neutral-100 text-white group-item text-xs group-hover:block hidden">
                  Copy
                </span>
              </div>
              <div
                className="group hover:cursor-pointer"
                aria-label="Delete campaign link"
              >
                <span onClick={() => openDeleteLinkModal(campaign)}>
                  <TrashIcon
                    stroke={'stroke-neutral-80 group-hover:stroke-neutral-60'}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
