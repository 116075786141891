import { useState, useContext } from 'react';
import Spinner from '../../../Spinner/Spinner';
import { RunStep } from 'shared/legacy-types';
import ApprovalToolbar from './ApprovalToolbar';
import ResultsToolbar from '../ResultsToolbar';
import { useKeywordApproval } from './useKeywordApproval';
import KeywordNotice from './KeywordNotice';
import Table from './Table';
import { Column } from 'ui/constants/runs';
import useSortApprovals from './useSortApprovals';
import MinimalRadioDropdown from '../../Dropdowns/MinimalRadioDropdown';
import { HistoryDetailContext } from '../../History/RuleHistoryDetail';

interface Props {
  step: RunStep;
  dataType: string;
  loading: boolean;
  loadMoreResults?: (stepid: number | undefined) => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  actionTimestamp?: string;
  isLive?: boolean;
  selectedAction: string;
  setSelectedAction: (action: string) => void;
  preset: Column[] | null;
  showExtended: boolean;
  setShowExtended: (show: boolean) => void;
}

const NEW = 'new';
const ACTIONED = 'actioned';

const filterOptions = [
  { label: 'New', value: NEW },
  { label: 'Actioned', value: ACTIONED },
];

export default function KeywordApprovalTable({
  step,
  dataType,
  loading,
  actionTimestamp,
  loadMoreResults,
  loadingMoreResults,
  enablePagination,
  isLive,
  selectedAction,
  setSelectedAction,
  preset,
  showExtended,
  setShowExtended,
}: Props) {
  const [resultsFilter, setResultsFilter] = useState<string>(NEW);

  const { actions, results, incompleteData, stepId } = step || {};
  const currentAction = actions.find(
    (action) => action.type === selectedAction,
  );
  const noResults = results && results.length === 0;

  const {
    waitingApprovalCount,
    approvedTermsCount,
    rejectedTermsCount,
    selectResult,
    selectedResults,
    selectAllResults,
    allSelected,
    approve,
    reject,
    openNotice,
    setOpenNotice,
    approvalsLoading,
    loadingSearchTerms,
    allApprovedOrRejected,
    keywordApprovals,
    approvalsLoadedFirstRender,
  } = useKeywordApproval(step);

  const { actionedResults, resultsToBeActioned } = useSortApprovals(
    step,
    keywordApprovals,
  );

  const { refetchingResults } = useContext(HistoryDetailContext) || {};

  const resultsToDisplay =
    resultsFilter === NEW ? resultsToBeActioned : actionedResults;

  // Third condition fills the loading gap between results and approvals
  const resultsOrDataLoading =
    loading ||
    approvalsLoading ||
    (waitingApprovalCount === 0 && !allApprovedOrRejected && !noResults);

  const refetchingResultsAndApprovals =
    refetchingResults || (approvalsLoading && approvalsLoadedFirstRender);

  const showResults =
    (results && results.length > 0 && !resultsOrDataLoading) ||
    refetchingResultsAndApprovals;

  function loadMore() {
    loadMoreResults && loadMoreResults(stepId);
  }

  return (
    <div className="border-neutral-40 border-t p-6 relative">
      <div className="mb-[0.625rem] flex">
        <h5 className="mb-2 font-bold">Results</h5>
        {waitingApprovalCount > 0 && !allApprovedOrRejected && (
          <div className="ml-1 bg-primary-60 rounded-3xl px-2 py-1 text-sm h-6 text-secondary-100 mt-[-2px]">
            <span className="font-bold">{waitingApprovalCount} </span>
            <span>waiting approval</span>
          </div>
        )}
      </div>
      <>
        {!loading && noResults && (
          <div className="bg-primary-20 text-neutral-80 rounded py-5 text-center text-sm">
            No results were found
          </div>
        )}
      </>
      {resultsOrDataLoading && !refetchingResultsAndApprovals && <Spinner />}
      {showResults && (
        <>
          <ResultsToolbar
            actionTimestamp={actionTimestamp}
            isLive={isLive}
            currentAction={currentAction}
            incompleteData={incompleteData}
            noResultsFound={noResults}
            actions={actions}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            dataType={dataType}
            showExtended={showExtended}
            setShowExtended={setShowExtended}
          />
          {waitingApprovalCount > 0 && (
            <div className="mb-4 mt-2">
              <ApprovalToolbar
                allSelected={allSelected}
                allApprovedOrRejected={allApprovedOrRejected}
                loadingSearchTerms={loadingSearchTerms}
                selectAllResults={selectAllResults}
                hasSelections={selectedResults.length > 0}
                approve={approve}
                reject={reject}
              />
            </div>
          )}
          {preset && (
            <>
              <div className="mb-1">
                <MinimalRadioDropdown
                  label="Result filter"
                  options={filterOptions}
                  selectedValue={resultsFilter}
                  onChange={(event) => setResultsFilter(event.target.value)}
                  width="w-fit"
                />
              </div>
              <Table
                results={resultsToDisplay}
                preset={preset}
                extended={showExtended}
                loadMoreResults={loadMore}
                loadingMoreResults={loadingMoreResults}
                enablePagination={enablePagination}
                noResultsFound={noResults}
                selectedAction={selectedAction}
                selectResult={selectResult}
                selectedResults={selectedResults}
                keywordApprovals={keywordApprovals}
              />
            </>
          )}
        </>
      )}
      {openNotice && (
        <KeywordNotice
          approvedTermsCount={approvedTermsCount}
          rejectedTermsCount={rejectedTermsCount}
          setOpenNotice={setOpenNotice}
        />
      )}
    </div>
  );
}
