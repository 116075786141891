import {
  AdGroup,
  Bid,
  CampaignLink,
  FixedBid,
  MatchType,
  MetricBid,
  SearchTermCampaign,
} from 'shared/legacy-types';
import { isEqual } from 'lodash';

export const addZeroes = (value: string) => {
  return parseFloat(value).toFixed(2);
};

export const formatBid = (bid: Bid) => {
  const isfixedBid = bid.hasOwnProperty('fixedBid');
  const fixedBid = bid as FixedBid;
  const metricBid = bid as MetricBid;

  if (isfixedBid) {
    return { fixedBid: addZeroes(fixedBid.fixedBid) };
  }

  if (metricBid.multiplier) {
    //convert string to number
    const multiplier = parseFloat(metricBid.multiplier);
    return { metric: metricBid.metric, multiplier };
  }

  return bid;
};

export const formatLabel = (label: string | undefined) => {
  const firstLetter = label && label.charAt(0).toUpperCase();
  const restOfLabel = label && label.slice(1);
  return firstLetter && restOfLabel ? firstLetter + restOfLabel : '';
};

export function sortCampaignLinksDescending(
  campaignLinks: CampaignLink[],
): CampaignLink[] {
  return campaignLinks.sort((a: CampaignLink, b: CampaignLink) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
}

export function profileIdsMatch(
  campaignLink: CampaignLink,
  campaignLinkToCheck: CampaignLink,
): boolean {
  return campaignLink.profileId === campaignLinkToCheck.profileId;
}

export function campaignIdsMatch(
  campaign: SearchTermCampaign,
  campaignToCheck: SearchTermCampaign,
): boolean {
  return campaign.campaignId === campaignToCheck.campaignId;
}

export function targetingTypesMatch(
  campaignLink: CampaignLink,
  campaignLinkToCheck: CampaignLink,
): boolean {
  return campaignLink.targetingType === campaignLinkToCheck.targetingType;
}

export function campaignAdGroupIdsMatch(
  adGroups: AdGroup[] | null,
  adGroupsToCheck: AdGroup[] | null,
): boolean {
  if (adGroups === null && adGroupsToCheck === null) {
    return true;
  }

  return isEqual(
    adGroups?.map((adGroup) => adGroup.adGroupId).sort(),
    adGroupsToCheck?.map((adGroup) => adGroup.adGroupId).sort(),
  );
}

export function matchTypesMatch(
  matchTypes: MatchType[],
  matchTypesToCheck: MatchType[],
): boolean {
  return isEqual(matchTypes.sort(), matchTypesToCheck.sort());
}

function metricBidsMatch(
  metricBid: MetricBid,
  metricBidToCheck: MetricBid,
): boolean {
  return (
    metricBid.metric === metricBidToCheck.metric &&
    metricBid.multiplier === metricBidToCheck.multiplier
  );
}

function fixedBidsMatch(
  fixedBid: FixedBid,
  fixedBidToCheck: FixedBid,
): boolean {
  return fixedBid.fixedBid === fixedBidToCheck.fixedBid;
}

export function bidsMatch(bid: Bid, bidToCheck: Bid): boolean {
  if (bid.hasOwnProperty('fixedBid')) {
    return fixedBidsMatch(bid as FixedBid, bidToCheck as FixedBid);
  }

  return metricBidsMatch(bid as MetricBid, bidToCheck as MetricBid);
}

export function keywordNegationMatch(
  campaignLink: CampaignLink,
  campaignLinkToCheck: CampaignLink,
): boolean {
  if (
    campaignLink.negateKeywords.negate !==
    campaignLinkToCheck.negateKeywords.negate
  ) {
    return false;
  }

  const matchTypes = campaignLink.negateKeywords.matchTypes;
  const matchTypesToCheck = campaignLinkToCheck.negateKeywords.matchTypes;

  if (!matchTypes && !matchTypesToCheck) {
    return true;
  }

  return isEqual(
    matchTypes && matchTypes.sort(),
    matchTypesToCheck && matchTypesToCheck.sort(),
  );
}

export function campaignLinksMatch(
  campaignLink: CampaignLink,
  campaignLinkToCheck: CampaignLink,
): boolean {
  return (
    allFieldsRequiredForValidationCheckMatch(
      campaignLink,
      campaignLinkToCheck,
    ) &&
    bidsMatch(campaignLink.bid, campaignLinkToCheck.bid) &&
    keywordNegationMatch(campaignLink, campaignLinkToCheck)
  );
}

export function allFieldsRequiredForValidationCheckMatch(
  campaignLink: CampaignLink,
  campaignLinkToCheck: CampaignLink,
): boolean {
  return (
    profileIdsMatch(campaignLink, campaignLinkToCheck) &&
    campaignIdsMatch(campaignLink.source, campaignLinkToCheck.source) &&
    campaignIdsMatch(
      campaignLink.destination,
      campaignLinkToCheck.destination,
    ) &&
    targetingTypesMatch(campaignLink, campaignLinkToCheck) &&
    campaignAdGroupIdsMatch(
      campaignLink.source.adGroups,
      campaignLinkToCheck.source.adGroups,
    ) &&
    campaignAdGroupIdsMatch(
      campaignLink.destination.adGroups,
      campaignLinkToCheck.destination.adGroups,
    ) &&
    matchTypesMatch(campaignLink.matchTypes, campaignLinkToCheck.matchTypes)
  );
}

export function allFieldsRequiredForValidationCheckPopulated(
  campaignLink: CampaignLink,
): boolean {
  return (
    !!campaignLink.profileId &&
    !!campaignLink.targetingType &&
    campaignLink.matchTypes.length > 0 &&
    !!campaignLink.source.campaignId &&
    !!campaignLink.destination.campaignId &&
    (campaignLink.source.adGroups === null ||
      (campaignLink.source.adGroups &&
        campaignLink.source.adGroups.length > 0)) &&
    (campaignLink.destination.adGroups === null ||
      (campaignLink.destination.adGroups &&
        campaignLink.destination.adGroups.length > 0))
  );
}

function keywordNegationPopulated(campaignLink: CampaignLink): boolean {
  const isNegateKeywords = campaignLink.negateKeywords.negate;
  const isProductTargetingType = campaignLink.targetingType === 'product';
  const isKeywordTargetingType = campaignLink.targetingType === 'keyword';

  if (!isNegateKeywords) {
    return true;
  }

  if (isProductTargetingType && isNegateKeywords) {
    return true;
  }

  return (
    (isKeywordTargetingType &&
      campaignLink.negateKeywords.matchTypes &&
      campaignLink.negateKeywords.matchTypes.length > 0) ||
    false
  );
}

export function allCampaignLinkFieldsPopulated(
  campaignLink: CampaignLink,
): boolean {
  const fixedBid = campaignLink.bid as FixedBid;
  const metricBid = campaignLink.bid as MetricBid;

  const hasBid =
    (campaignLink.bid.hasOwnProperty('fixedBid') && fixedBid.fixedBid) ||
    (campaignLink.bid.hasOwnProperty('metric') && metricBid.metric);

  return (
    allFieldsRequiredForValidationCheckPopulated(campaignLink) &&
    !!hasBid &&
    keywordNegationPopulated(campaignLink)
  );
}
