import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import Spinner from 'ui/Spinner/Spinner';
import useUser from 'hooks/useUser';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  deleteSpendTarget: () => void;
  deleting: boolean;
  spendTargetToDelete: string;
  showDeleteFailedMessage: boolean;
}

export default function DeleteSpendTargetModal({
  open,
  setOpen,
  deleteSpendTarget,
  deleting,
  spendTargetToDelete,
  showDeleteFailedMessage,
}: Props) {
  const { getProfileData } = useUser();
  const profileData = getProfileData(parseInt(spendTargetToDelete));
  const { countryCode, accountName } = profileData || {};

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-[25rem] bg-white p-6">
        <h5 className="truncate font-bold">Delete spend target</h5>
        <div className="text-neutral-80 mr-4 mt-1 ">
          Are you sure you want to delete this spend target for{' '}
          <span className="font-bold">
            {accountName} {countryCode}
          </span>
          ?
        </div>
        {showDeleteFailedMessage && (
          <div className="mt-5 font-bold">
            Live rules are using this spend target. Turn them off to delete it.
          </div>
        )}
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton onClick={() => setOpen(false)} disabled={deleting}>
            <>Cancel</>
          </SecondaryButton>
          <div className="ml-4" aria-label={`Delete ${accountName}`}>
            <PrimaryButton
              onClick={() => deleteSpendTarget()}
              disabled={deleting || showDeleteFailedMessage}
            >
              {deleting ? <Spinner colour="stroke-white" /> : <>Delete</>}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
