import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from 'components/Icons';

export enum TextLinkFontSizeClasses {
  EXTRA_SMALL = 'text-xs',
  SMALL = 'text-sm',
  MEDIUM = 'text-base',
  LARGE = 'text-lg',
}

export enum TextLinkFontSize {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum TextLinkStyleClasses {
  EXTERNAL = 'text-black',
  INTERNAL = 'text-primary-100',
  BREADCRUMB = 'text-neutral-80 font-bold',
}

export enum TextLinkStyle {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  BREADCRUMB = 'BREADCRUMB',
}

type Props = {
  link: string;
  label: string;
  style: TextLinkStyle;
  size?: TextLinkFontSize;
  openInNewTab?: boolean;
  truncateLabelFromWidthInRem?: number;
};

export default function TextLink({
  link,
  label,
  style,
  size = TextLinkFontSize.MEDIUM,
  openInNewTab = false,
  truncateLabelFromWidthInRem,
}: Props): JSX.Element {
  const labelMaxWidth = `max-w-[${truncateLabelFromWidthInRem}rem]`;

  return (
    <Link
      to={link}
      className={`${TextLinkFontSizeClasses[size]} ${TextLinkStyleClasses[style]} underline inline-flex hover:brightness-[0.8]`}
      target={openInNewTab ? '_blank' : ''}
      rel="noopener"
    >
      <span
        className={`${truncateLabelFromWidthInRem != null && `truncate ${labelMaxWidth}`}`}
      >
        {label}
      </span>
      {openInNewTab && (
        <div className="ml-1 my-auto">
          <ExternalLinkIcon />
        </div>
      )}
    </Link>
  );
}
