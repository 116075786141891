import { format, getDaysInMonth } from 'date-fns';

const days = format(new Date(), 'd');
const daysInMonth = getDaysInMonth(new Date());

export const monthToDateGoalTooltip = (
  <div className="w-[15.75rem] font-normal">
    How much you should have spent so far ={' '}
    <span className="text-primary-100">
      (Spend Target / {daysInMonth}) * {parseInt(days) - 1}
    </span>
  </div>
);

export const monthtoDateActualTooltip = (
  <div className="w-[8.75rem] font-normal">This month's spend so far</div>
);
