import { InfoIcon } from 'components/Icons';

export default function AddNegativeKeywords(): JSX.Element {
  return (
    <>
      <div className="mt-2 text-sm">
        <div className="border-b border-neutral-40">
          <div className="text-neutral-80 pb-6 leading-[16.8px]  max-w-[30.188rem]">
            <span>
              Create negative keywords in your source campaigns when your
              conditions are met.
            </span>
          </div>
        </div>
      </div>
      <div className="bg-primary-40 border-primary-80 flex h-fit w-fit mt-6 rounded border px-[0.688rem] py-[0.4rem]">
        <span className="mr-1">
          <InfoIcon />
        </span>
        <span className="mt-px text-sm !leading-5">
          This action will create exact match type negative keywords in your
          source campaigns at the ad group level.
        </span>
      </div>
    </>
  );
}
