import { CampaignLink, Bid } from 'shared/legacy-types';
import { DEFAULT_CAMPAIGN_LINK } from 'ui/constants/rules';
import useCampaignLinksRequests from 'api/hooks/requests/useCampaignLinksRequests';
import { deepClone } from 'ui/helpers/deepClone';
import { formatBid } from 'pages/CampaignMapping/helpers/campaignLinks';
import * as Sentry from '@sentry/react';
import useCampaignLinkValidation from 'pages/CampaignMapping/hooks/useCampaignLinkValidation';
import useUser from 'hooks/useUser';
import useCampaignLinkModals from 'pages/CampaignMapping/hooks/useCampaignLinkModals';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type UseAddCampaignLinkHook = {
  submittingCampaignLink: boolean;
  setSubmittingCampaignLink: (submitting: boolean) => void;
  addBid: (bid: Bid) => void;
  updateCampaignLinkForm: (key: string, value: string) => void;
  brand: string;
  addNewCampaignLink: () => Promise<void>;
  campaignLink: CampaignLink;
  setCampaignLink: (campaignLink: CampaignLink) => void;
  isDuplicateCampaignLink: boolean;
  isDuplicateCampaignLinkValidationRunning: boolean;
  initialCampaignLink: CampaignLink;
};

const HTTP_CONFLICT = 409;

export default function useAddCampaignLink(
  campaignLinks: CampaignLink[],
  setCampaignLinks: (campaigns: CampaignLink[]) => void,
  setAddModalOpen: (open: boolean) => void,
  campaignLinkToCopy: CampaignLink | null,
): UseAddCampaignLinkHook {
  const { createCampaignLinkRequest } = useCampaignLinksRequests();
  const initialCampaignLink =
    campaignLinkToCopy ?? (deepClone(DEFAULT_CAMPAIGN_LINK) as CampaignLink);

  const { getProfileData } = useUser();

  const initialBrand = campaignLinkToCopy
    ? getProfileData(parseInt(campaignLinkToCopy.profileId))?.accountName ?? ''
    : '';

  const {
    updateCampaignLinkForm,
    brand,
    setBrand,
    submittingCampaignLink,
    setSubmittingCampaignLink,
    campaignLink,
    setCampaignLink,
  } = useCampaignLinkModals(initialCampaignLink, initialBrand);

  const { isDuplicateCampaignLink, isDuplicateCampaignLinkValidationRunning } =
    useCampaignLinkValidation(campaignLink);

  function addBid(bid: Bid): void {
    setCampaignLink({ ...campaignLink, bid });
  }

  function reset(): void {
    setSubmittingCampaignLink(false);
    setBrand('');
    setAddModalOpen(false);
  }

  async function addNewCampaignLink(): Promise<void> {
    setSubmittingCampaignLink(true);
    campaignLink.bid = formatBid(campaignLink.bid) as Bid;
    try {
      const response = await createCampaignLinkRequest(campaignLink);
      if (response.status === HTTP_CONFLICT) {
        showToast('Cannot create duplicate campaign links.', ToastStyle.ERROR);
        reset();
        return;
      }
      setCampaignLinks([...campaignLinks, campaignLink]);
      showToast('Your campaign link has been added', ToastStyle.SUCCESS);
      reset();
    } catch (error) {
      Sentry.captureException(error);
      setCampaignLink(initialCampaignLink);
      reset();
    }
  }

  return {
    submittingCampaignLink,
    setSubmittingCampaignLink,
    addBid,
    updateCampaignLinkForm,
    brand,
    addNewCampaignLink,
    campaignLink,
    setCampaignLink,
    isDuplicateCampaignLink,
    isDuplicateCampaignLinkValidationRunning,
    initialCampaignLink,
  };
}
