import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { Rule } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  rule: Rule | undefined;
  archiveRule: () => Promise<void>;
  archiving: boolean;
};

export default function ArchiveModal({
  open,
  setOpen,
  rule,
  archiveRule,
  archiving,
}: Props): JSX.Element {
  const { name, status } = rule ?? {};

  const isLive = status === 'ENABLED';

  function title(): JSX.Element {
    if (isLive) {
      return <>This rule is live</>;
    }
    return <>Archive {name}</>;
  }

  function description(): JSX.Element {
    if (isLive) {
      return (
        <>
          Are you sure you want to archive it.
          <div className="font-bold">This can't be undone.</div>
        </>
      );
    }
    return <>Are you sure you want to archive this rule?</>;
  }

  function buttonText(): JSX.Element {
    if (isLive) {
      return <>Archive anyway</>;
    }
    return <>Archive</>;
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6 w-[24.313rem]" aria-label="Archive alert">
        <h5 className="truncate font-bold">{title()}</h5>
        <div className="text-neutral-80 mr-4 mt-1 truncate">
          {description()}
        </div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton
            onClick={() => {
              setOpen(false);
            }}
            disabled={archiving}
          >
            <>Cancel</>
          </SecondaryButton>
          <div className="ml-3">
            <PrimaryButton
              onClick={() => {
                void archiveRule();
              }}
              disabled={archiving}
            >
              {archiving ? (
                <Spinner colour="stroke-white" />
              ) : (
                <>{buttonText()}</>
              )}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
