import { useState, useRef, ReactElement, useEffect } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import useClickOutside from 'ui/hooks/useClickOutside';
import { ChevronDownIcon, PlusFolderIcon } from 'components/Icons/';
import CreateFolderModal from './Modals/CreateFolderModal';
import Folders from './Folders/Folders';
import CreateRuleDropdown from './CreateRuleDropdown';
import useFolderRequests from 'api/hooks/requests/useFolderRequests';
import useFoldersDispatch from 'state/folders/useFoldersDispatch';
import useCreateFolder from './Folders/useCreateFolder';
import { Folder } from 'shared/legacy-types';

type Props = {
  children: ReactElement;
};

function Dashboard({ children }: Props): JSX.Element {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);

  const { updateFolders } = useFoldersDispatch();
  const { getFolders } = useFolderRequests();

  useClickOutside(setOpenDropdown, dropDownRef, buttonRef);
  const {
    setCreateFolderModalOpen,
    createFolderModalOpen,
    createNewFolder,
    setNewFolderName,
    newFolderName,
    creatingFolder,
  } = useCreateFolder();

  useEffect(() => {
    async function fetchFolders(): Promise<void> {
      const response = await getFolders();
      const folders = response.data as Folder[];
      updateFolders(folders);
    }
    // eslint-disable-next-line
    fetchFolders();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DashboardLayout>
        <section>
          <div className="relative mb-6 flex justify-between">
            <h2 className="font-bold leading-[2.5625rem]">Rules</h2>
            <div className="flex">
              <div
                className=" border-primary-100 mr-4 h-10 w-10 rounded-full border-2 p-2 hover:cursor-pointer"
                onClick={() => {
                  setCreateFolderModalOpen(true);
                }}
                aria-label="Create a folder"
              >
                <PlusFolderIcon />
              </div>
              <div
                ref={buttonRef}
                onClick={() => {
                  setOpenDropdown(!openDropdown);
                }}
              >
                <PrimaryButton>
                  <div className="flex">
                    <div>Create a rule</div>
                    <div className="ml-1 mt-0.5">
                      <ChevronDownIcon colour="stroke-white" />
                    </div>
                  </div>
                </PrimaryButton>
              </div>
            </div>
            <CreateRuleDropdown
              dropDownRef={dropDownRef}
              openDropdown={openDropdown}
            />
          </div>
          <div className="flex">
            <Folders />
            <div className="drop-sm w-full">{children}</div>
          </div>
        </section>
      </DashboardLayout>
      {createFolderModalOpen && (
        <CreateFolderModal
          open={createFolderModalOpen}
          setOpen={setCreateFolderModalOpen}
          createFolder={createNewFolder}
          setNewFolderName={setNewFolderName}
          newFolderName={newFolderName}
          creatingFolder={creatingFolder}
        />
      )}
    </>
  );
}

export default Dashboard;
