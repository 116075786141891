export function Clock() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99479 15.1663C11.6767 15.1663 14.6615 12.1816 14.6615 8.49967C14.6615 4.81778 11.6767 1.83301 7.99479 1.83301C4.31289 1.83301 1.32812 4.81778 1.32812 8.49967C1.32812 12.1816 4.31289 15.1663 7.99479 15.1663Z"
        fill="#28C585"
        stroke="#28C585"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.5V8.5L10.6667 9.83333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
