import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import * as Sentry from '@sentry/react';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';
import {
  HTTP_STATUS_CONFLICT,
  HTTP_STATUS_FORBIDDEN,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_UNPROCESSABLE_ENTITY,
} from 'constants/statusCodes';

type Methods = 'delete' | 'get' | 'head' | 'options' | 'patch' | 'post' | 'put';

type UseRequestHook = {
  request: (type: Methods, url: string, data?: any) => Promise<AxiosResponse>;
};

export default function useRequest(): UseRequestHook {
  axiosRetry(axios, { retries: 3 });

  async function request(
    type: Methods,
    url: string,
    data?: any,
  ): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      url,
      method: type,
      data,
    };

    try {
      return await axios.request(config);
    } catch (error: any) {
      const { status } = error.response ?? {};

      // Not an error - expected when token expires or not present
      if ([HTTP_STATUS_FORBIDDEN, HTTP_STATUS_UNAUTHORIZED].includes(status)) {
        Sentry.captureException(error);
        localStorage.setItem('timedOut', 'true');
        window.location.href = '/login.html';
        return error.response;
      }

      // handle these specific codes with a local error component
      if (status === HTTP_STATUS_CONFLICT) {
        Sentry.captureException(error);
        return error.response;
      }

      // internet connection failure
      const offline = !window.navigator.onLine;

      if (offline) {
        showToast(
          'You have lost internet connection. Please retry again when you are back online.',
          ToastStyle.ERROR,
        );
        throw error;
      }
      // incomplete form submission
      if (status === HTTP_STATUS_UNPROCESSABLE_ENTITY) {
        showToast(
          'There is a problem with your form. Please check all fields are correct and try again.',
          ToastStyle.ERROR,
        );
        throw error;
      }
      // for all else set banner error message
      showToast(
        'There was a problem on our side. Please reload/retry now or in a few minutes.',
        ToastStyle.ERROR,
      );

      throw error;
    }
  }

  return { request };
}
