import { Action } from 'shared/legacy-types';
import { SlackAction } from 'shared/legacy-types';
import { InfoIcon } from 'components/Icons';
import Dropdown from 'components/Dropdowns/Dropdown';
import useSlack from './useSlack';
import { ReactComponent as SlackScreenshot } from './slackScreenshot.svg';
import Spinner from 'ui/Spinner/Spinner';
import TextLink, {
  TextLinkFontSize,
  TextLinkStyle,
} from 'design-system/components/TextLink';

type Props = {
  setSelection: (selection: Action) => void;
  selection: Action;
  showPreview?: boolean;
};

export default function Slack({
  setSelection,
  selection,
  showPreview = true,
}: Props): JSX.Element {
  const { formattedChannels, slackIntegration, loading, updateSelection } =
    useSlack(setSelection);
  const slackAction = selection as SlackAction;

  function renderSlack(): JSX.Element {
    const hasSlackIntegration = slackIntegration ?? false;

    if (loading || (hasSlackIntegration && formattedChannels == null)) {
      return (
        <div className=" w-full">
          <div className="flex justify-center">
            <div className="mt-2">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    if (!hasSlackIntegration) {
      return (
        <div className="bg-error-bg border-error flex h-fit w-fit rounded border px-3 py-2">
          <span className="mr-1 my-auto">
            <InfoIcon fill={'fill-error'} />
          </span>
          <span>
            <div className="mt-px pr-2 text-sm !leading-5 ">
              No slack integration found{' - '}
              <TextLink
                link={'/settings/integrations'}
                label={'add now'}
                style={TextLinkStyle.INTERNAL}
                size={TextLinkFontSize.SMALL}
              />{' '}
              in Settings
            </div>
          </span>
        </div>
      );
    }

    if (formattedChannels && formattedChannels.length === 0) {
      return (
        <div className="bg-error-bg border-error flex h-fit w-fit rounded border px-3 py-2">
          <span className="mr-1 my-auto">
            <InfoIcon fill={'fill-error'} />
          </span>
          <span>
            <div className="mt-px pr-2 text-sm !leading-5 ">
              No slack channels found
            </div>
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className="text-neutral-80 text-xs mb-1">Channel</div>
        <Dropdown
          label="Select channel"
          options={formattedChannels ?? []}
          selectedValue={slackAction.properties.channelId}
          selectValue={(value) => {
            updateSelection(value);
          }}
          ariaLabel="Select condition"
        />
        {showPreview && (
          <div>
            <div className="text-neutral-80 text-xs mb-1 mt-6">Preview</div>
            <div className="bg-neutral-20 px-4 py-10">
              <div className="rounded border border-neutral-40 pb-2 pt-1 bg-white">
                <SlackScreenshot />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="mt-2 text-sm">
        <div className="text-neutral-80 pb-6 mb-6 leading-6 border-b border-neutral-40">
          <span>Get notified when your conditions are met.</span>
        </div>
        {renderSlack()}
      </div>
    </>
  );
}
