import ResizeImage from 'images/resize.png';
import { useEffect, useState } from 'react';

export default function BrowserWidthModal() {
  const [showNotice, setShowNotice] = useState(false);
  const [size, setSize] = useState<number>();

  useEffect(function getWidth() {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
  }, []);

  useEffect(
    function initNotice() {
      if (size && size < 1023) setShowNotice(true);
      if (size && size >= 1023) setShowNotice(false);
    },
    [size],
  );

  return (
    <div>
      {showNotice === true && (
        <div
          className={`w-screen h-screen bg-neutral-100 opacity-[0.95] z-50 fixed`}
        >
          <div className={`relative inset-y-[40%] w-10/12 mx-auto`}>
            <img
              alt="Browser resize"
              src={ResizeImage}
              className="relative h-[3.75rem] w-[3.75rem] mx-auto"
            />
            <h3 className="text-white text-center mx-auto my-3">
              Your Browser is too small
            </h3>
            <div className="text-sm text-white text-center mx-auto my-3">
              Resize your browser to be at least 1023px wide to continue using
              fern
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
