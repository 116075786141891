import { Rule, IsValid, Optional } from 'shared/legacy-types';
import StepsContainer from 'pages/Rule/Steps/StepsContainer';
import ScopeContainer from 'pages/Rule/Scope/ScopeContainer';
import Header from 'pages/Rule//Header/Header';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import ErrorMessage from 'components/Errors/ErrorMessage';

interface Props {
  rule: Optional<Rule, 'id'>;
  onChange: (rule: Optional<Rule, 'id'>) => void;
  onSave: () => void;
  loading: boolean;
  ruleLoading: boolean;
  success: boolean;
  isEditing: boolean;
  onToggleChange: (enabled: boolean) => Promise<void>;
  isValid: IsValid;
  isRuleValid: boolean | undefined;
  checkInvalidFields: () => void;
  disableToggle: boolean;
  disableSaveButton: boolean;
  highlightInvalidFields: boolean;
}

export default function RuleComponent({
  rule,
  onChange,
  onSave,
  loading,
  ruleLoading,
  success,
  isEditing,
  onToggleChange,
  isValid,
  isRuleValid,
  checkInvalidFields,
  disableToggle,
  disableSaveButton,
  highlightInvalidFields,
}: Props) {
  if (ruleLoading) {
    return (
      <div className="flex h-full">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <form>
        <Header
          rule={rule}
          onChange={onChange}
          onSave={onSave}
          success={success}
          loading={loading}
          isEditing={isEditing}
          isRuleValid={isRuleValid}
          checkInvalidFields={checkInvalidFields}
          onToggleChange={onToggleChange}
          disableToggle={disableToggle}
          disableSaveButton={disableSaveButton}
        />
        <div className={`${highlightInvalidFields ? 'mt-6' : 'mt-8'}`}>
          {highlightInvalidFields && !isRuleValid && (
            <div className="mb-3">
              <ErrorMessage
                message={
                  <>
                    Please complete all fields before previewing or turning a
                    rule on. You may need to scroll to find empty fields.
                  </>
                }
              />
            </div>
          )}
          <ScopeContainer rule={rule} onChange={onChange} isValid={isValid} />
          <StepsContainer
            rule={rule}
            onChange={onChange}
            isValid={isValid}
            onSave={onSave}
          />
        </div>
      </form>
    </>
  );
}
