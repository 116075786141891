import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { SpendTarget } from 'shared/legacy-types';
import { InfoIcon, LeftArrow } from 'components/Icons/';
import useUser from 'hooks/useUser';
import { nextTwelveMonths } from 'helpers/dates/dates';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import CurrencyInput from 'components/Inputs/CurrencyInput';

interface Props {
  newSpendTarget: Partial<SpendTarget>;
  saveSpendTarget: () => void;
  savingSpendTarget: boolean;
  goBack?: () => void;
  updateAmount: (value: string, month: string) => void;
  buttonText?: string;
  showMessage?: boolean;
  spendTargetProfileId: string;
  loading?: boolean;
  title?: string;
}

export default function ReviewSpendTarget({
  newSpendTarget,
  saveSpendTarget,
  savingSpendTarget,
  goBack,
  updateAmount,
  buttonText = 'Add',
  showMessage,
  spendTargetProfileId,
  loading,
  title = 'Review spend target',
}: Props) {
  const { getProfileData } = useUser();
  const profileData = getProfileData(parseInt(spendTargetProfileId));
  const { countryCode, currencyCode, accountName } = profileData || {};

  const disableButton =
    (newSpendTarget.monthlySpendTargets &&
      newSpendTarget.monthlySpendTargets.some(
        (month) => !month.spend || month.spend === '0',
      )) ||
    loading;

  function renderMonths() {
    if (loading) {
      return (
        <div className="mt-20 flex justify-center">
          <LargeSpinner />
        </div>
      );
    }

    return nextTwelveMonths().map((month) => {
      if (!newSpendTarget.monthlySpendTargets) return null;

      const spendTargetMonth = newSpendTarget.monthlySpendTargets.find(
        (spendTargetMonth) => {
          const monthWithZeroPrefix =
            month.numericMonth.length === 1
              ? `0${month.numericMonth}`
              : month.numericMonth;
          return monthWithZeroPrefix === spendTargetMonth.month;
        },
      );

      if (!spendTargetMonth) return null;

      return (
        <div className="mb-2 flex h-[2.563rem] justify-between ">
          <div className="text-neutral-80 text-xs !leading-[2.563rem]">
            {month.shortDate}
          </div>
          <div
            className="relative"
            aria-label={`Edit ${month.shortDate} spend target`}
          >
            <CurrencyInput
              value={spendTargetMonth.spend}
              onChange={(event) =>
                updateAmount(event.toString(), spendTargetMonth.month)
              }
              currencyCode={currencyCode}
              styles="!w-[6.25rem]"
            />
          </div>
        </div>
      );
    });
  }

  return (
    <div className="w-[28rem] bg-white p-6">
      <div className="relative text-center">
        {goBack && (
          <div className="absolute hover:cursor-pointer" onClick={goBack}>
            <LeftArrow colour="stroke-neutral-80" />
          </div>
        )}
        <h5 className="font-bold">{title}</h5>
        <div className="mt-1 text-xs">
          Next 12 months <span className="text-neutral-80">for</span>{' '}
          {accountName} {countryCode}
        </div>
        {showMessage && (
          <div className="bg-primary-40 border-primary-80 mb-4 mt-3 flex w-fit rounded border px-[0.688rem] py-[0.4rem] text-left text-sm">
            <span className="mr-1 mt-[0.438rem]">
              <InfoIcon />
            </span>
            <span>
              You can customise the spend targets below at any time after
              they've been created.
            </span>
          </div>
        )}
      </div>
      <div
        className={`scrollbar-hide h-[22.75rem] overflow-auto ${!showMessage && 'mt-[1.875rem]'}`}
      >
        {renderMonths()}
      </div>
      <div className="border-neutral-40 mx-[-24px] border-t" />
      <div className="flex  justify-end pt-4">
        <div className="group relative ml-4" aria-label="Add spend target">
          <PrimaryButton
            disabled={disableButton}
            onClick={() => saveSpendTarget()}
          >
            {savingSpendTarget ? (
              <Spinner colour="stroke-white" />
            ) : (
              <>{buttonText}</>
            )}
          </PrimaryButton>
          {disableButton && (
            <span className="invisible absolute bottom-[2.8rem] right-0 z-50 h-fit w-[10.125rem] flex-wrap rounded bg-neutral-100 p-2 text-xs text-white group-hover:visible ">
              Please fill in all fields above
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
