import { useEffect, useState } from 'react';
import { ChartData } from 'shared/legacy-types';

export default function useChartDetail(
  data: ChartData[],
  currencyCode: string,
) {
  const [selectedMetric, setSelectedMetric] = useState<string[]>([]);
  const [lineChartData, setLineChartData] = useState<any>([]);

  useEffect(() => {
    // converts formatted data metrics into object with key used by the chart
    const convertedData = data.map(
      (item: any) => {
        const convertedItem = {} as any;
        convertedItem.date = item.date;
        item.data.forEach((metric: any) => {
          convertedItem[metric.type] = metric.value;
        });
        return convertedItem;
      },
      [data],
    );

    if (convertedData && convertedData.length > 0) {
      const keys = Object.keys(convertedData[0]).filter(
        (key) => key !== 'date',
      );
      setSelectedMetric(keys);
    }
    if (convertedData && convertedData.length === 0) {
      setSelectedMetric([]);
    }

    setLineChartData(convertedData);
    //eslint-disable-next-line
  }, [data]);

  return {
    selectedMetric,
    setSelectedMetric,
    lineChartData,
    setLineChartData,
    currencyCode,
  };
}
