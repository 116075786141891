import { Action } from 'shared/legacy-types';
import { SEND_SLACK } from 'ui/constants/actions';
import UpdateValue from 'pages/Rule/Steps/Action/ModalComponents/UpdateValue';
import Slack from 'pages/Rule/Steps/Action/ModalComponents/Slack/Slack';

interface Props {
  selection: Action;
  setSelection: (selection: Action) => void;
  selectedAction: string;
}

export default function EditActionContent({
  setSelection,
  selection,
  selectedAction,
}: Props) {
  if (selection.type === SEND_SLACK) {
    return (
      <Slack
        selection={selection}
        setSelection={setSelection}
        showPreview={false}
      />
    );
  }

  return (
    <UpdateValue
      selection={selection}
      currentAction={selectedAction}
      setSelection={setSelection}
    />
  );
}
