import ErrorPage from 'pages/Errors/ErrorPage';

interface Props {
  resetError(): void;
}

export default function SomethingWentWrong({ resetError }: Props) {
  return (
    <ErrorPage
      title="We're sorry but something went wrong!"
      subtitle={
        <>
          <h5>
            Our tech team has been notified about this and will investigate it.
          </h5>
          <br />
          <h5>
            Don't hesitate and contact our support team if the error persists.
          </h5>
        </>
      }
      callback={resetError}
    />
  );
}
