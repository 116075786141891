interface Props {
  error?: boolean;
  value?: string;
}

export default function TextField(props: Props) {
  return (
    <div className="relative">
      <input
        id="name"
        name="name"
        type="text"
        value={props.value}
        className={`placeholder-transparent text-base pt-[1.094rem] pb-1 pl-4 focus:pl-[0.938rem] focus:pt-[1.219rem] text-neutral-100 peer h-[3.25rem] w-[14.5rem] border-neutral-40 border focus:border-2 focus:border-primary-80 outline-none ${
          props.error ? 'border-error' : 'border-neutral-40'
        }`}
        placeholder="Email"
      />
      <label
        htmlFor="name"
        className="absolute left-[1.125rem] top-2.5 text-xs text-neutral-80 ease-in-out duration-100 leading-3 peer-placeholder-shown:text-base peer-placeholder-shown:text-neutral-80 peer-placeholder-shown:top-5 peer-focus:top-2.5 peer-focus:text-neutral-80 peer-focus:text-xs focus:top-4 "
      >
        Email
      </label>
    </div>
  );
}
