import { createContext } from 'react';
import { Rule, Step, Action, Optional } from 'shared/legacy-types';
import { DEFAULT_RULE, DEFAULT_STEP } from 'ui/constants/rules';

type ContextData = {
  stepIndex: number;
  actionIndex: number;
  rule: Optional<Rule, 'id'>;
  step: Step;
  onChange: (rule: Rule) => void;
  action: Action;
};

export const ActionContext = createContext<ContextData>({
  stepIndex: 0,
  actionIndex: 0,
  rule: DEFAULT_RULE,
  step: DEFAULT_STEP,
  onChange: () => {},
  action: {} as Action,
});
