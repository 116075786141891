import { useNavigate } from 'react-router-dom';

import ErrorPage from 'pages/Errors/ErrorPage';

export default function NotFound() {
  const navigate = useNavigate();

  function backToHome() {
    navigate('/');
  }
  return (
    <ErrorPage
      title="Uh oh"
      subtitle={<h5>That page you are looking for does not exist.</h5>}
      callback={backToHome}
    />
  );
}
