import { useState, useContext, useEffect } from 'react';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import { Action, ActionTab, ActionGroup } from 'shared/legacy-types';
import { ActionContext } from 'pages/Rule/Steps/Action/ActionContext';
import Modal from 'components/Modals/Modal';
import EditActionContent from 'pages/Rule/Steps/Action/ActionModals/EditModal/EditActionContent';
import {
  UPDATE_BID,
  UPDATE_BUDGET,
  ACTION_GROUPS,
  SEND_SLACK,
} from 'ui/constants/actions';
import { deepClone } from 'ui/helpers/deepClone';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionTitle?: string;
}

export default function EditActionModal({ open, setOpen }: Props) {
  const { action, rule, stepIndex, onChange, actionIndex } =
    useContext(ActionContext);

  const [selection, setSelection] = useState<Action>(action);

  const actionHasValue = () => {
    if (
      (selection.type === UPDATE_BID || selection.type === UPDATE_BUDGET) &&
      selection.properties.value
    ) {
      return true;
    }

    if (selection.type === SEND_SLACK && selection.properties.channelId) {
      return true;
    }

    return false;
  };

  const getAction = () => {
    let foundAction = {} as ActionTab;
    ACTION_GROUPS.forEach((group: ActionGroup) => {
      const found = group.actions.find((action: any) => {
        return action.type === selection.type;
      });
      if (found) {
        foundAction = found as ActionTab;
      }
    });

    return foundAction;
  };

  useEffect(
    function initCurrentAction() {
      setSelection(action);
    }, // eslint-disable-next-line
    [open, action],
  );

  function saveChanges(setOpen: (open: boolean) => void) {
    const copiedRule = deepClone(rule);
    copiedRule!.steps[stepIndex!].actions[actionIndex] = selection;
    onChange?.({ ...copiedRule });
    setOpen(false);
  }

  const Icon = getAction().largeIcon;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex h-[33.125rem] min-h-fit w-[28rem] bg-white">
        <div className="relative mt-3 w-[37rem] py-8 pl-6 pr-8 sm:mt-0">
          <div className="text-neutral-80 mb-2 text-xs tracking-widest">
            <span
              className="mr-0.5 hover:cursor-pointer hover:underline"
              onClick={() => setOpen(false)}
            >
              Step {stepIndex + 1}
            </span>
            <span className="mx-0.5">{`>`}</span>
            <span className="mx-0.5">Editing action</span>
          </div>
          <div className="flex justify-between">
            <div className="my-auto flex">
              <span className="mt-0.5">{Icon && <Icon />}</span>
              <h3 className="mb-1 ml-3 mr-1 font-bold">{getAction().label}</h3>
            </div>
            <div
              className="mt-[-0.5rem] hover:cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <CrossIcon />
            </div>
          </div>
          <EditActionContent
            selectedAction={selection.type}
            selection={selection}
            setSelection={setSelection}
          />
          <div className="absolute bottom-8 right-8 flex justify-end bg-gray-50 pt-3">
            <SecondaryButton onClick={() => setOpen(false)}>
              <>Close</>
            </SecondaryButton>
            <div className="ml-4">
              <PrimaryButton
                onClick={() => saveChanges(setOpen)}
                disabled={!actionHasValue()}
              >
                <>Confirm</>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
