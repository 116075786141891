import { capitalizeFirstLetter } from 'helpers/textFormat';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

export default function useSubscribedAlert(): void {
  const [searchParams, setSearchParams] = useSearchParams();
  const subscription = searchParams.get('subscription');
  const subscriptionLabel =
    subscription != null && capitalizeFirstLetter(subscription);

  useEffect(() => {
    if (subscription != null) {
      showToast(
        `You are now on the ${subscriptionLabel} plan`,
        ToastStyle.SUCCESS,
      );

      searchParams.delete('subscription');
      setSearchParams(searchParams);
    }

    // eslint-disable-next-line
  }, []);
}
