interface props {
  stroke?: string;
}

export function RightArrow({ stroke = 'stroke-secondary-80' }: props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.67188 8.5H13.0052"
        strokeWidth="1.5"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
      <path
        d="M8.33594 3.83317L13.0026 8.49984L8.33594 13.1665"
        strokeWidth="1.5"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
    </svg>
  );
}
