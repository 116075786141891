interface Props {
  fill?: string;
}

export function InfoIcon({ fill = 'fill-primary-100' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10573 15.8918C5.66773 17.4538 7.7884 18.3335 9.9974 18.3335C12.2064 18.3335 14.3271 17.4538 15.8891 15.8918C17.4511 14.3298 18.3307 12.2092 18.3307 10.0002C18.3307 7.79117 17.4511 5.67049 15.8891 4.1085C14.3271 2.5465 12.2064 1.66683 9.9974 1.66683C7.7884 1.66683 5.66773 2.5465 4.10573 4.1085C2.54373 5.67049 1.66406 7.79117 1.66406 10.0002C1.66406 12.2092 2.54373 14.3298 4.10573 15.8918Z"
        className={`${fill}`}
      />
      <path
        d="M10 13.3333V9.99992"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.66675H10.0083"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
