import ToggleButton from 'components/Toggles/ToggleButton';

export default function ToggleDesignComponent() {
  async function changeHandler() {}
  return (
    <section className="p-10 border-neutral-40 border-t border-1 columns-2">
      <h4 className="font-bold mb-4">Toggle Turn on/Turn off Rule</h4>
      <div id="buttons-inline-text" className="w-1/2 p-6 ">
        <div className="pt-2 mb-2">
          <ToggleButton
            enable={false}
            disabled={true}
            onChange={changeHandler}
            label={['On', 'Off']}
          />
        </div>
        <div className="pt-2 mb-2">
          <ToggleButton
            enable={false}
            onChange={changeHandler}
            label={['On', 'Off']}
          />
        </div>
        <div className="pt-2 mb-2">
          <ToggleButton
            enable={true}
            onChange={changeHandler}
            label={['On', 'Off']}
          />
        </div>
      </div>
      <h4 className="font-bold mb-4">Toggle Turn On</h4>
      <div className="bg-neutral-100 p-6 w-32">
        <div id="buttons-inline-text" className="w-1/2">
          <div className="pt-2 mb-2">
            <ToggleButton
              disabled={true}
              darkMode={true}
              onChange={changeHandler}
              label={['On', 'Off']}
            />
          </div>
          <div className="pt-2 mb-2">
            <ToggleButton
              enable={false}
              darkMode={true}
              onChange={changeHandler}
              label={['On', 'Off']}
            />
          </div>
          <div className="pt-2 mb-2">
            <ToggleButton
              enable={true}
              darkMode={true}
              onChange={changeHandler}
              label={['On', 'Off']}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
