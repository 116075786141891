import { AxiosError } from 'axios';

import { User } from 'shared/legacy-types';
import useRequest from 'api/hooks/useRequest';
import useUserDispatch from 'state/user/useUserDispatch';
import { ERROR, LOADING, SUCCESS } from 'constants/requests';
import * as Sentry from '@sentry/react';

interface UserObj {
  user: User;
}
interface UserResponse {
  data: UserObj;
  status: number;
}

export default function useUserRequests() {
  const { request } = useRequest();
  const { updateUser, updateUserStatus } = useUserDispatch();

  async function getUser() {
    try {
      updateUserStatus(LOADING);
      const response = await request('get', '/v1/me');
      if (response.status === 200) {
        updateUserStatus(SUCCESS);
        updateUser(response.data);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      updateUserStatus(ERROR);
      if (error instanceof AxiosError) {
        if (error.code !== 'ECONNABORTED') {
          localStorage.setItem('timedOut', 'true');
          window.location.replace('/login.html');
          return;
        }
      }
    }
  }

  // In app re-fetch user to update data
  async function fetchUser() {
    const response = await request('get', '/v1/me');
    updateUser(response.data);
  }

  async function sendVerifyEmail(): Promise<UserResponse> {
    return await request('post', `/v1/auth/send-verify-email`);
  }

  return {
    getUser,
    sendVerifyEmail,
    fetchUser,
  };
}
