interface Props {
  fill?: string;
  stroke?: string;
  fillBackground?: string;
  strokeBackground?: string;
}

export function ArchiveIcon({
  fill = 'fill-primary-80',
  stroke = 'stroke-primary-80',
  fillBackground = 'fill-white',
  strokeBackground = 'stroke-white',
}: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6049_54579)">
        <path d="M13.3307 5.3335V14.0002H2.66406V5.3335" className={fill} />
        <path
          d="M13.3307 5.3335V14.0002H2.66406V5.3335"
          className={stroke}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6667 2C15.6667 1.63181 15.3682 1.33333 15 1.33333H1C0.63181 1.33333 0.333333 1.63181 0.333333 2V5C0.333333 5.36819 0.63181 5.66667 1 5.66667H15C15.3682 5.66667 15.6667 5.36819 15.6667 5V2Z"
          className={`${fill} ${strokeBackground}`}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66406 10H9.33073"
          className={strokeBackground}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6049_54579">
          <rect width="16" height="16" className={fillBackground} />
        </clipPath>
      </defs>
    </svg>
  );
}
