export function LargeBudgetIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="24"
        height="24"
        rx="3"
        className="fill-primary-100"
      />
      <path
        d="M12.3005 5.59091C12.1146 5.59091 11.9481 5.47631 11.8817 5.30278V5.30278C11.8152 5.12924 11.6487 5.01465 11.4628 5.01465H8.86719L10.1721 8.28067H13.8108L14.8861 5.59091H12.3005Z"
        fill="white"
      />
      <path
        d="M13.7932 9.74023L19.1354 16.9945C19.3647 17.3033 19.5 17.6766 19.5 18.0796C19.5 19.1407 18.5674 20.0008 17.4168 20.0008H6.58318C5.43259 20.0008 4.5 19.1407 4.5 18.0796C4.5 17.6769 4.63499 17.3033 4.86459 16.9945L10.2079 9.74023H13.7932Z"
        fill="white"
      />
    </svg>
  );
}
