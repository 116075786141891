import useRequest from '../useRequest';

export default function useShareWorkspaceRequests() {
  const { request } = useRequest();

  const sendUserInvite = async (email: string) => {
    return await request('post', `v1/invite-user`, { email: email });
  };

  const revokeUserInvite = async (email: string) => {
    return await request('post', `v1/revoke-invite`, { email });
  };

  return {
    sendUserInvite,
    revokeUserInvite,
  };
}
