import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RulesState {
  editingRules: string[];
}

const initialState: RulesState = {
  editingRules: [],
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    updateEditingRule(
      state,
      action: PayloadAction<{ id: string; type: string }>,
    ) {
      if (action.payload.type === 'remove') {
        const filtered = state.editingRules.filter(
          (rule) => rule !== action.payload.id,
        );

        state.editingRules = filtered;
        return;
      }

      const isEditing = state.editingRules.includes(action.payload.id);
      if (!isEditing) {
        state.editingRules = [...state.editingRules, action.payload.id];
      }
    },
  },
});

export const { updateEditingRule } = rulesSlice.actions;
export default rulesSlice.reducer;
