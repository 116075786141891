import usePermissions from './usePermissions';

export const FEATURES = {
  spendTargets: 'spendTargets',
  campaignMapping: 'campaignMapping',
  reporting: 'reporting',
};

interface Props {
  children: JSX.Element;
  scopes: string[];
  renderNoAccess?: JSX.Element;
}

export default function PermissionsGate({
  children,
  scopes = [],
  renderNoAccess,
}: Props) {
  const { hasPermission } = usePermissions();
  const permissionGranted = hasPermission(scopes, 'features');

  if (!permissionGranted && renderNoAccess) return <>{renderNoAccess}</>;
  if (!permissionGranted) return <></>;

  return <>{children}</>;
}
