import { Profile, SetupStep, User } from 'shared/legacy-types';
import { PRO_FLOW } from 'constants/onboarding';

// temporarily return pro plan only
// until we introduce a second plan
export const getSetupFlow = () => {
  return PRO_FLOW;
};

export const setupComponent = (setupFlow: SetupStep[], currentStep: string) => {
  return setupFlow.find((step) => step.step === currentStep) as SetupStep;
};

export const isRegionStep = (
  user: User,
  profiles: Profile[],
  loading: boolean,
) => {
  return (
    (user?.amazonAccess && profiles?.length === 0 && !loading) ||
    !user?.amazonAccess
  );
};

export const isProfileStep = (user: User, profiles: Profile[]) => {
  return (
    user?.amazonAccess &&
    user?.selectedProfiles &&
    !user?.selectedProfiles.length &&
    profiles &&
    profiles?.length > 0
  );
};

export const isSubscribeStep = (hasSubscription: boolean) => {
  return !hasSubscription;
};

export const currentStep = (
  user: User | null,
  profiles: Profile[] | [],
  loading: boolean,
  hasSubscription: boolean,
) => {
  if (!user) return '';
  if (isSubscribeStep(hasSubscription)) return 'subscribe';
  if (isRegionStep(user, profiles, loading)) return 'region';
  if (isProfileStep(user, profiles)) return 'profile';
  return '';
};
