import { ValueGetterParams } from 'ag-grid-enterprise';
import { CampaignTableHeader } from '../tableHeaders';
import { getValueAsNumber } from '../utils';
import { PERCENTAGE_FACTOR } from 'shared/constants';

export type PercentageChangeValue = {
  metric: number;
  metricPrevious: number;
  toString: () => string;
};

export function percentageChangeCalculation(
  metric: number,
  previousMetric: number,
): number {
  if (previousMetric === 0) return 0;

  return ((metric - previousMetric) / previousMetric) * PERCENTAGE_FACTOR;
}

export function percentageChangeValueGetter(
  params: ValueGetterParams,
  metricField: CampaignTableHeader,
  previousMetricField: CampaignTableHeader,
): PercentageChangeValue {
  const metric = getValueAsNumber(
    params.getValue(metricField),
    metricField,
    params.node?.group ?? false,
  );

  const metricPrevious = getValueAsNumber(
    params.getValue(previousMetricField),
    previousMetricField,
    params.node?.group ?? false,
  );

  let percentageChangeValue = 0;
  if (params.node && !params.node.group) {
    percentageChangeValue = percentageChangeCalculation(metric, metricPrevious);
  }

  return {
    metric,
    metricPrevious,
    toString: () => `${percentageChangeValue}`,
  };
}

export function percentageChangeAggregationFunction(
  columnValues: PercentageChangeValue[],
): PercentageChangeValue {
  const metricSum = columnValues.reduce((sum, value) => {
    return sum + value.metric;
  }, 0);

  const metricPreviousSum = columnValues.reduce((sum, value) => {
    return sum + value.metricPrevious;
  }, 0);

  return {
    metric: metricSum,
    metricPrevious: metricPreviousSum,
    toString: () =>
      `${percentageChangeCalculation(metricSum, metricPreviousSum)}`,
  };
}
