import { useState, useEffect } from 'react';
import { Folder } from 'shared/legacy-types';
import { useParams } from 'react-router-dom';
import useFoldersSelector from 'state/folders/useFoldersSelector';
import useFoldersDispatch from 'state/folders/useFoldersDispatch';
import useFolderRequests from 'api/hooks/requests/useFolderRequests';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type EditFolderHook = {
  editFolderModalOpen: boolean;
  setEditFolderModalOpen: (open: boolean) => void;
  updatedFolderName: string;
  setUpdatedFolderName: (name: string) => void;
  updatingFolder: boolean;
  setUpdatingFolder: (updating: boolean) => void;
  editFolder: (folder: Folder) => Promise<void>;
  currentFolder: Folder | undefined;
};

export default function useRenameFolder(): EditFolderHook {
  const { folderId } = useParams();
  const { renameFolder } = useFolderRequests();
  const { folders } = useFoldersSelector();
  const { updateFolders } = useFoldersDispatch();
  const currentFolder = folders.find((folder) => folder.id === folderId);

  const [editFolderModalOpen, setEditFolderModalOpen] =
    useState<boolean>(false);
  const [updatedFolderName, setUpdatedFolderName] = useState<string>('');
  const [updatingFolder, setUpdatingFolder] = useState<boolean>(false);

  useEffect(() => {
    if (currentFolder) {
      setUpdatedFolderName(currentFolder.name);
    }
    // eslint-disable-next-line
  }, [currentFolder]);

  async function editFolder(folder: Folder): Promise<void> {
    setUpdatingFolder(true);
    await renameFolder(folder.id, updatedFolderName);
    const updatedFolders = folders.map((folder) =>
      currentFolder && folder.id === currentFolder.id
        ? { ...folder, name: updatedFolderName }
        : folder,
    );
    updateFolders(updatedFolders);
    showToast(
      `Your folder has been renamed ${updatedFolderName}`,
      ToastStyle.SUCCESS,
    );
    setUpdatedFolderName('');
    setUpdatingFolder(false);
    setEditFolderModalOpen(false);
  }

  return {
    editFolderModalOpen,
    setEditFolderModalOpen,
    updatedFolderName,
    setUpdatedFolderName,
    updatingFolder,
    setUpdatingFolder,
    editFolder,
    currentFolder,
  };
}
