import { useAppDispatch } from 'state/hooks';
import { addBanner, removeBanner } from 'state/banners/BannerSlice';
import { Banner } from 'state/banners/BannerSlice';

type UseBannerDispatch = {
  addBanner: (banner: Banner) => void;
  removeBanner: () => void;
};

export default function useBannerDispatch(): UseBannerDispatch {
  const dispatch = useAppDispatch();

  return {
    addBanner: (banner: Banner) => {
      dispatch(addBanner(banner));
    },
    removeBanner: () => dispatch(removeBanner()),
  };
}
