import { useState, useEffect } from 'react';
import { ReportingProfileData, ChartData } from 'shared/legacy-types';
import { showWeekView } from '../helpers';

export default function useChart(
  data: ReportingProfileData[],
  currencySymbol: string,
) {
  const [selectedMetric, setSelectedMetric] = useState<string[]>([]);
  const [lineChartData, setLineChartData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const hasDate = (data && data[0] && data[0].date !== '') || false;

  useEffect(() => {
    // formats data by adding type, axis and unit to each metric which is required by the chart
    const formattedData = data.map((item) => {
      return {
        date: item.date,
        data: [
          {
            type: 'spend',
            value: parseInt(item.cost),
            axis: 'left',
            unit: currencySymbol,
          },
          { type: 'acos', value: item.acos, axis: 'right', unit: '%' },
          {
            type: 'adRevenue',
            value: parseInt(item.adSales),
            axis: 'left',
            unit: currencySymbol,
          },
        ],
      } as ChartData;
    });
    setChartData(formattedData);
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    // converts formatted data metrics into object with key used by the chart
    const convertedData = chartData.map((item: any) => {
      const convertedItem = {} as any;
      convertedItem.date = item.date;
      item.data.forEach((metric: any) => {
        convertedItem[metric.type] = metric.value;
      });
      return convertedItem;
    });

    if (convertedData && convertedData.length > 0) {
      const keys = Object.keys(convertedData[0]).filter(
        (key) => key !== 'date',
      );
      setSelectedMetric(keys);
    }
    if (convertedData.length === 0) {
      setSelectedMetric([]);
    }
    setLineChartData(convertedData);
    //eslint-disable-next-line
  }, [chartData]);

  return {
    chartData,
    selectedMetric,
    setSelectedMetric,
    lineChartData,
    setLineChartData,
    showWeekView,
    hasDate,
  };
}
