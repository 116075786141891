import {
  ConditionExpression,
  Rule,
  MetricCondition,
  ValueCondition,
  Optional,
  DataType,
} from 'shared/legacy-types';
import {
  CAMPAIGNS,
  PRODUCT_SIGNALS,
  SEARCH_TERMS,
  TARGETS,
  VALID_PERIODS_FOR_CAMPAIGNS,
  VALID_PERIODS_FOR_SEARCH_TERMS,
  VALID_PERIODS_FOR_TARGETS,
} from '../../constants/rules';

export const scopeIsValid = (rule: Partial<Rule>) => {
  const hasDataType = !!rule.dataType;

  // Product signals rule do not have ad types
  const hasAdTypes =
    rule.dataType === PRODUCT_SIGNALS ||
    (rule.adTypes && rule.adTypes.length > 0);

  // Product signals require asins selected
  const hasAsins =
    (rule.dataType === PRODUCT_SIGNALS &&
      rule.asins &&
      rule.asins.length > 0) ||
    rule.dataType !== PRODUCT_SIGNALS;

  // Non product signals rules require profileIds
  const hasProfileIds =
    rule.dataType === PRODUCT_SIGNALS ||
    (rule.profileIds && rule.profileIds.length > 0);

  return rule.adTypes && hasAdTypes && hasDataType && hasAsins && hasProfileIds;
};

export function periodIsValid(
  dataType: DataType | undefined,
  period: string | undefined,
): boolean {
  if (!dataType) {
    return false;
  }

  if (dataType === PRODUCT_SIGNALS) {
    return true;
  }

  if (!period) {
    return false;
  }

  if (dataType === SEARCH_TERMS) {
    return VALID_PERIODS_FOR_SEARCH_TERMS.includes(period);
  }

  if (dataType === TARGETS) {
    return VALID_PERIODS_FOR_TARGETS.includes(period);
  }

  if (dataType === CAMPAIGNS) {
    return VALID_PERIODS_FOR_CAMPAIGNS.includes(period);
  }

  return false;
}

// Returns an array of true/false so we can apply to each step
export const ruleStepsValid = (
  rule: Partial<Rule>,
  allProfilesHaveSpendTargets: boolean,
) => {
  return (rule.steps || []).map((step) => {
    // if step has marketplace fields and not all profiles have budgets then step is invalid
    const hasMarketplaceFields = step.conditionGroups.some((group) => {
      return group.conditions.some((condition) => {
        return condition.field.includes('marketplace');
      });
    });

    if (hasMarketplaceFields && !allProfilesHaveSpendTargets) {
      return false;
    }

    const { actions, conditionGroups, period } = step;
    const hasAction = actions && actions.length > 0;

    const allConditionsValid = conditionGroups.every((group) => {
      return group.conditions.every((condition) => {
        // check if condition is a metric condition or a value condition
        const metricCondition = condition as MetricCondition;
        const valueCondition = condition as ValueCondition;
        const isValueCondition = condition && condition.hasOwnProperty('value');

        const expressionValue = valueCondition.value as ConditionExpression;
        const regularValue = isValueCondition
          ? (valueCondition.value as string)
          : (metricCondition.metric as string);

        if (isValueCondition && expressionValue.hasOwnProperty('metric')) {
          return (
            condition.field &&
            condition.operator &&
            expressionValue?.metric &&
            expressionValue?.value
          );
        }

        return condition.field && condition.operator && regularValue.length;
      });
    });

    const hasCorrectPeriodForDataType = periodIsValid(rule.dataType, period);

    if (hasAction && allConditionsValid && hasCorrectPeriodForDataType) {
      return true;
    }

    return false;
  });
};

export function isRuleValid(
  rule: Optional<Rule, 'id'>,
  allProfilesHaveSpendTargets: boolean,
) {
  return (
    (scopeIsValid(rule) &&
      ruleStepsValid(rule, allProfilesHaveSpendTargets).every(
        (isStepValid) => isStepValid === true,
      )) ||
    false
  );
}
