interface Props {
  height?: string;
  rounded?: string;
}

export default function ArrowAndCircleImage({
  height = '152',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_10718_1654)">
        <rect width="76" height="152" fill="#FFEA9E" />
        <g clipPath="url(#clip1_10718_1654)">
          <rect
            width="76"
            height="152"
            transform="matrix(1 0 0 -1 0 152)"
            fill="#ED6B4D"
          />
          <g clipPath="url(#clip2_10718_1654)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.3533 115C38.3533 127.693 67.6471 152.624 75.977 152.624H0.729492C8.86676 152.624 38.3533 127.53 38.3533 115Z"
              fill="#FFEA9E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.3528 77.3762C38.3528 90.069 67.6466 115 75.9765 115H0.729004C8.86702 115 38.3528 89.9065 38.3528 77.3762Z"
              fill="#FFEA9E"
            />
          </g>
          <g clipPath="url(#clip3_10718_1654)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38 76C17.0126 76 0 58.9866 0 38C0 17.0134 17.0126 0 38 0C58.9866 0 76 17.0134 76 38C76 58.9866 58.9866 76 38 76ZM38 18.3829C27.1829 18.3829 18.3829 27.1829 18.3829 38C18.3829 48.8171 27.1829 57.6171 38 57.6171C48.8171 57.6171 57.6171 48.8171 57.6171 38C57.6171 27.1829 48.8171 18.3829 38 18.3829Z"
              fill="#662466"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.9995 50.0171C31.3632 50.0171 25.9824 44.6371 25.9824 38C25.9824 31.3629 31.3632 25.9829 37.9995 25.9829C44.6366 25.9829 50.0167 31.3629 50.0167 38C50.0167 44.6371 44.6366 50.0171 37.9995 50.0171Z"
              fill="#FFEA9E"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10718_1654">
          <rect width="76" height="152" fill="white" />
        </clipPath>
        <clipPath id="clip1_10718_1654">
          <rect
            width="76"
            height="152"
            fill="white"
            transform="matrix(1 0 0 -1 0 152)"
          />
        </clipPath>
        <clipPath id="clip2_10718_1654">
          <rect
            width="76"
            height="76"
            fill="white"
            transform="matrix(1 0 0 -1 0 152)"
          />
        </clipPath>
        <clipPath id="clip3_10718_1654">
          <rect
            width="76"
            height="76"
            fill="white"
            transform="matrix(1 0 0 -1 0 76)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
