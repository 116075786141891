import { ReactComponent as MappingImage } from 'images/campaign_mapping.svg';

interface props {
  callToAction: JSX.Element;
}

export default function CampaignMappingSetup({ callToAction }: props) {
  return (
    <div className=" flex justify-center text-center mt-[6.25rem]">
      <div>
        <MappingImage />
        <div>
          <div className="text-[2rem] leading-[2.375rem] mx-auto">
            <div>
              <div className="min-w-[28rem]">
                Introducing{' '}
                <span className="text-primary-100 font-bold">
                  Campaign mapping
                </span>
              </div>
              <div> for Keyword Harvesting</div>
            </div>
          </div>
          <div className="text-neutral-80 pt-3">
            Map your campaigns to control where new keywords based on
            <br />
            harvested search terms will be created.
          </div>
          <div className="mt-6">{callToAction}</div>
        </div>
      </div>
    </div>
  );
}
