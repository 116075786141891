import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  resetSteps: () => void;
}

export default function ResetStepsAlert({ open, setOpen, resetSteps }: Props) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6 w-[24.313rem]" aria-label="Archive alert">
        <h5 className="font-bold">Steps will be reset</h5>
        <div className="text-neutral-80 mr-4 mt-1">
          Changing your data source will reset all steps for this rule. Do you
          want to continue?
        </div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton onClick={() => setOpen(false)}>
            <>Cancel</>
          </SecondaryButton>
          <div className="ml-3">
            <PrimaryButton onClick={() => resetSteps()}>
              <>Reset steps</>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
