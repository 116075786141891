import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import useIntegrations from './useIntegrations';
import Spinner from 'ui/Spinner/Spinner';

export default function Integrations() {
  const {
    continueToSlack,
    slackWorkspace,
    loading,
    slackIntegrated,
    isInternalUser,
  } = useIntegrations();

  if (!isInternalUser) {
    return null;
  }

  const slackConnection = () => {
    if (loading) {
      return (
        <div className=" w-full">
          <div className="flex justify-center">
            <div className="mt-2">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    if (slackIntegrated && !loading && !slackWorkspace) {
      return (
        <>
          <div className="text-neutral-80 text-sm font-medium mt-3">
            It looks like your slack connection is no longer working
            <br />
          </div>
          <div className="text-neutral-80 text-sm font-medium mt-1">
            Please try adding your workspace again
          </div>
        </>
      );
    }

    if (!slackIntegrated && !loading) {
      return (
        <div className="text-neutral-80 text-sm font-medium mt-3">
          No account connected
        </div>
      );
    }

    return (
      <div
        className={`border-neutral-40 rounded border border-l-4 bg-white py-[0.9375rem] pl-4 pr-6 flex justify-between`}
      >
        <div className="flex">
          <div className="min-w-[2.125rem] min-h-[2.125rem] my-auto">
            <img src={slackWorkspace?.icon} alt="slack workspace icon" />
          </div>
          <div className="ml-5 w-full">
            <div className="mb-1 font-bold ">{slackWorkspace?.name}</div>
            <div className="text-neutral-80 text-xs">Connected</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <h5 className="font-medium leading-[2.5625rem] text-neutral-100">
          Slack
        </h5>
        {!slackWorkspace && !loading && (
          <PrimaryButton onClick={continueToSlack}>
            <>Add workspace</>
          </PrimaryButton>
        )}
      </div>
      {slackConnection()}
    </div>
  );
}
