import { Link } from 'react-router-dom';
import usePermissions from 'components/Permissions/usePermissions';
import { LockIcon } from 'components/Icons';
import { getSvgFillActiveStyle } from './helpers';

type Label =
  | 'Rules'
  | 'Rule history'
  | 'Reports'
  | 'Templates'
  | 'Spend targets'
  | 'Campaign mapping'
  | 'Reporting';

const activeStyle =
  'border-l-4 border-l-primary-80 pl-5 bg-blend-overlay bg-black hover:brightness-100';

interface Props {
  label: Label;
  icon: JSX.Element;
  link: string;
  isActive?: boolean;
  gatedFeature?: boolean;
  feature?: string;
}

export default function MenuItem({
  label,
  icon,
  isActive = false,
  gatedFeature = false,
  feature,
  link,
}: Props) {
  const { hasPermission } = usePermissions();

  function gatedFeatureIcon(icon: JSX.Element) {
    if (!gatedFeature || !feature || hasPermission([feature], 'features')) {
      return icon;
    }

    return <LockIcon fill={getSvgFillActiveStyle(isActive)} />;
  }

  const textStyles = isActive
    ? 'text-white font-bold'
    : 'text-neutral-60 font-medium';

  return (
    <Link
      to={link}
      className={` ${
        isActive && activeStyle
      } grid w-full grid-cols-[3.25rem,3.125rem] items-center px-6 py-3 font-bold hover:brightness-90`}
      data-testid={`${label}-menu-item`}
    >
      <div className={`w-full grid grid-cols-[2rem,9.5rem] items-center`}>
        {gatedFeatureIcon(icon)}
        <div className={`text-base ${textStyles}`}>{label}</div>
      </div>
    </Link>
  );
}
