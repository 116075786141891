import { ReactElement } from 'react';
type Size = 'lg' | 'md' | 'sm';

interface Props {
  children: ReactElement;
  disabled?: boolean;
  size?: Size;
  onClick?: () => void;
  colour?: string;
  width?: string;
}

export default function PrimaryButton({
  children,
  disabled,
  size = 'md',
  onClick,
  colour = 'bg-primary-100',
  width,
}: Props) {
  const largeStyle = 'px-7 h-[3.25rem] text-base';
  const mediumStyle = 'px-5 h-[2.5625rem] text-sm';
  const smallStyle = 'px-5 h-[2rem] text-sm';

  function getStyle() {
    if (size === 'lg') {
      return largeStyle;
    }
    if (size === 'md') {
      return mediumStyle;
    }
    return smallStyle;
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${getStyle()} disabled:bg-neutral-40 disabled:border-neutral-40 font-bold disabled:cursor-not-allowed disabled:hover:brightness-100 
     ${colour} ${width} rounded-full text-white hover:brightness-90 `}
    >
      {children}
    </button>
  );
}
