import { useState, useRef, ReactElement } from 'react';

import { Question } from 'components/Icons/';
import useClickOutside from '../hooks/useClickOutside';

interface Props {
  content: ReactElement;
  width?: string;
  position?: string;
  useHover?: boolean;
}

export default function Tooltip({
  content,
  width = 'w-[13.5rem]',
  position = 'left-px bottom-[1.375rem]',
  useHover = false,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  useClickOutside(setOpen, tooltipRef, iconRef);

  return (
    <span>
      <div
        className="absolute mt-[-0.0625rem] z-10"
        onClick={() => setOpen(!open)}
        onMouseEnter={() => useHover && setOpen(true)}
        onMouseLeave={() => useHover && setOpen(false)}
      >
        <div
          ref={iconRef}
          className="hover:cursor-pointer z-10"
          onClick={() => setOpen(!open)}
        >
          <Question active={open} />
        </div>
        {open && (
          <div
            ref={tooltipRef}
            className={`absolute z-50 p-2 rounded bg-neutral-100 text-white text-xs ${width} ${position} `}
          >
            {content}
          </div>
        )}
      </div>
    </span>
  );
}
