import { ChangeEventHandler, useState, useRef, ChangeEvent } from 'react';
import { Radio } from '../Radio/Radio';
import useClickOutside from '../../hooks/useClickOutside';
import Chevrons from './Chevrons';

export type Option = {
  label: string;
  value: number | string;
};

type Props = {
  label: string;
  options: Option[];
  selectedValue: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  deSelect?: () => void;
  width?: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export default function RadioDropdown({
  label,
  options,
  selectedValue,
  onChange,
  deSelect,
  width = 'w-[12.0625rem]',
  required = false,
  error = false,
  disabled = false,
  loading = false,
}: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  function selectRadio(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    setOpen(false);
    onChange(event);
  }

  function deSelectRadio(): void {
    setOpen(false);
    if (deSelect != null) {
      deSelect();
    }
  }

  function renderDropdownMenu(): JSX.Element {
    function renderOptions(): JSX.Element[] {
      return options.map((option) => {
        const { label, value } = option;
        const selected = value === selectedValue;

        return (
          <li key={value}>
            <Radio
              id={value.toString()}
              select={(event) => {
                selectRadio(event);
              }}
              checked={selected}
              unCheck={() => {
                deSelectRadio();
              }}
              selected={selected}
              label={label}
            />
          </li>
        );
      });
    }
    return (
      <div
        ref={ref}
        className="border-light-border absolute !z-50 mt-2 w-80 rounded-xl border bg-white max-h-[16rem] overflow-y-auto scrollbar-hide"
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
      >
        <ul className="data-source-dropdown space-y-4 p-6 text-sm">
          {renderOptions()}
        </ul>
      </div>
    );
  }

  function getLabel(): JSX.Element {
    const selected = options.find((option) => option.value === selectedValue);

    if (selectedValue != null) {
      return (
        <div
          className={`py-[0.688rem] pl-[0.938rem] ${open && 'pl-[1.01rem] pt-[0.749rem]'} ${
            disabled && 'text-neutral-80'
          }`}
        >
          <div className="text-xs">
            {label}
            {required && <span className="text-error ml-0.5">*</span>}
          </div>
          <div className="mt-[0.1875rem] truncate font-bold leading-[1.1375rem]">
            {selected?.label}
          </div>
        </div>
      );
    }

    return (
      <div
        className={`py-[1.219rem] pl-4 leading-[1.1375rem] ${open && 'pl-[1.07rem] pt-[1.28rem]'} font-bold ${
          disabled && 'text-neutral-80'
        }`}
      >
        {label} {required && <span className="text-error">*</span>}
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className="relative inline-block text-sm text-black hover:cursor-pointer"
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
      >
        <div
          className={`${loading && 'animate-pulse'} ${!disabled && 'hover:border-neutral-80'}
          block h-[3.6875rem] appearance-none rounded-lg border pr-8 
          ${error && !open ? 'bg-error bg-opacity-20 border-error border-opacity-40' : 'border-neutral-40'}
          ${width} 
          ${open && 'bg-secondary-100 border-0 text-white'} 
          ${selectedValue != null && !open && !disabled && 'bg-primary-40 border-primary-60'}
          ${disabled && !error && 'border-neutral-40 hover:border-neutral-40 bg-neutral-20 cursor-not-allowed'}`}
        >
          <div>{getLabel()}</div>
        </div>
        <div className="pointer-events-none absolute right-4 top-[1.7188rem] flex items-center ">
          <Chevrons open={open} disabled={disabled} />
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
