interface Props {
  stroke?: string;
}

export function ChartIcon({ stroke = 'stroke-neutral-60' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9266_162232)">
        <path
          d="M17.6688 13.2427C17.1387 14.4964 16.3095 15.6012 15.2537 16.4605C14.198 17.3197 12.9478 17.9072 11.6125 18.1717C10.2772 18.4362 8.89751 18.3695 7.59395 17.9775C6.29038 17.5856 5.10268 16.8803 4.13468 15.9233C3.16667 14.9662 2.44784 13.7867 2.04102 12.4877C1.63421 11.1887 1.55179 9.80978 1.80099 8.47157C2.05019 7.13336 2.62341 5.87656 3.47053 4.81107C4.31766 3.74557 5.41289 2.90381 6.66049 2.35938"
          className={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3333 9.9974C18.3333 8.90305 18.1178 7.81941 17.699 6.80837C17.2802 5.79732 16.6664 4.87866 15.8926 4.10484C15.1187 3.33102 14.2001 2.71719 13.189 2.2984C12.178 1.87961 11.0943 1.66406 10 1.66406V9.9974H18.3333Z"
          className={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9266_162232">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
