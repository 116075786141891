import { ReactElement } from 'react';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import PlanBadgeAndCounter from 'components/PlanCounter/PlanBadgeAndCounter';
import OnboardingLayout from '../OnboardingLayout';

interface Props {
  children: ReactElement;
  disableContinue: boolean;
  setBrand: () => void;
  selectedMarketplaces: number[];
  title: string;
  atLimit: boolean;
  userLimit: number;
}

export default function ProfileSetupComponent({
  children,
  disableContinue,
  selectedMarketplaces,
  setBrand,
  title,
  atLimit,
  userLimit,
}: Props) {
  return (
    <OnboardingLayout>
      <div>
        <h3 className="font-bold">{title}</h3>
        <div className="mt-2 text-sm text-neutral-80">
          You can add or remove brands at anytime from{' '}
          <span className="font-bold">Settings</span>
        </div>
        <div className="mt-6 h-full">{children}</div>
        <div className="w-full">
          <div className="border-neutral-40 fixed bottom-0 left-0 h-[5.563rem] w-screen border-t bg-white py-5 z-40">
            <div className="mb-5 w-full pl-2">
              <span className="flex w-[37.5rem] mx-auto justify-between">
                <span className="my-auto">
                  <PlanBadgeAndCounter
                    limit={userLimit}
                    count={selectedMarketplaces.length}
                    atLimit={atLimit}
                  />
                </span>
                <PrimaryButton
                  size="md"
                  disabled={disableContinue}
                  onClick={() => setBrand()}
                >
                  <div className="justify-end">Finish</div>
                </PrimaryButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
}
