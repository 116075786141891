interface Props {
  index: number;
  accountsCount: number;
  account: {
    id: string;
    sellingPartnerId: string;
    asinCount: number;
    platform: string;
  };
}

export default function SellerAccount({
  account,
  index,
  accountsCount,
}: Props) {
  const { sellingPartnerId, id, asinCount, platform } = account;
  const isFirst = index === 0;
  const isLast = index + 1 === accountsCount;
  const moreThanTwo = accountsCount > 2;
  const singleAccount = accountsCount === 1;

  const asinText = () => {
    if (asinCount === 1) {
      return 'ASIN';
    }
    return 'ASINs';
  };

  return (
    <div
      key={index}
      className={`flex justify-between border-neutral-40 border border-l-4 bg-white py-3 pl-5 pr-6  ${
        isFirst && !singleAccount && !isLast && 'rounded-t border-b-0'
      } ${isLast && moreThanTwo && 'rounded-b border-t-0'} ${singleAccount && 'rounded border'} ${
        !isLast && index > 1 && ' border-t-0'
      }`}
    >
      <div>
        <div className="mb-1 font-bold">{id}</div>
        <div className="flex text-xs text-neutral-80">
          {sellingPartnerId} • {platform} • {asinCount} {asinText()}
        </div>
      </div>
    </div>
  );
}
