import { useState } from 'react';
import CampaignMappingComponent from './CampaignMappingComponent';
import DashboardLayout from 'layouts/DashboardLayout';
import CampaignMappingSetup from './Setup/CampaignMappingSetup';
import useCampaignLinks from './hooks/useCampaignLinks';
import DeleteCampaignLinkModal from './Modals/DeleteCampaignLinkModal';
import EditCampaignLinkModal from './Modals/EditCampaignLink/EditCampaignLinkModal';
import AddCampaignLinkModal from './Modals/AddCampaignLink/AddCampaignLinkModal';
import useCampaignPagination from './hooks/useCampaignPagination';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import AddLinkButton from './Setup/AddLinkButton';
import { FEATURES } from 'components/Permissions/PermissionsGate';
import UpgradeCallToAction from 'components/Buttons/UpgradeCallToAction';
import PermissionsGate from 'components/Permissions/PermissionsGate';
import { CampaignLink } from 'shared/legacy-types';

export default function CampaignMappingPage() {
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [campaignLinkToEdit, setCampaignLinkToEdit] =
    useState<CampaignLink | null>(null);
  const [campaignLinkToCopy, setCampaignLinkToCopy] =
    useState<CampaignLink | null>(null);

  const {
    campaignLinks,
    setCampaignLinks,
    deleteModalOpen,
    setDeleteModalOpen,
    openDeleteModal,
    campaignLinkToDelete,
    deleteCampaignLink,
    deletingLink,
    filterCampaigns,
    filterBrandOptions,
    filterMarketplaceOptions,
    brandFilter,
    setBrandFilter,
    marketplaceFilter,
    setMarketplaceFilter,
    loadingCampaignLinks,
    campaignLinksLoaded,
  } = useCampaignLinks();

  const {
    disableNextButton,
    disablePrevButton,
    pageNumber,
    setPageNumber,
    paginatedCampaignLinks,
    filteredCampaignLinks,
  } = useCampaignPagination(filterCampaigns(), brandFilter);

  if (loadingCampaignLinks && !campaignLinksLoaded) {
    return (
      <DashboardLayout>
        <div className="flex h-screen">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  function renderCampaignMapping() {
    if (campaignLinks.length === 0 && campaignLinksLoaded && !addModalOpen) {
      return (
        <CampaignMappingSetup
          callToAction={
            <AddLinkButton openAddModal={() => setAddModalOpen(true)} />
          }
        />
      );
    }

    function openEditModal(campaign: CampaignLink) {
      setEditModalOpen(true);
      setCampaignLinkToEdit(campaign);
    }

    function openAddModal() {
      setCampaignLinkToCopy(null);
      setAddModalOpen(true);
    }

    function openAddModalToCopyCampaignLink(campaign: CampaignLink) {
      setAddModalOpen(true);
      setCampaignLinkToCopy(campaign);
    }

    return (
      <CampaignMappingComponent
        openAddModal={openAddModal}
        openDeleteModal={openDeleteModal}
        openEditModal={openEditModal}
        brandFilter={brandFilter}
        setBrandFilter={setBrandFilter}
        filterBrandOptions={filterBrandOptions}
        marketplaceFilter={marketplaceFilter}
        setMarketplaceFilter={setMarketplaceFilter}
        filterMarketplaceOptions={filterMarketplaceOptions}
        totalCampaignLinks={filteredCampaignLinks.length}
        pageNumber={pageNumber}
        paginatedCampaignLinks={paginatedCampaignLinks}
        setPageNumber={setPageNumber}
        disableNextButton={disableNextButton}
        disablePrevButton={disablePrevButton}
        openAddModalToCopyCampaignLink={openAddModalToCopyCampaignLink}
      />
    );
  }

  return (
    <>
      <DashboardLayout>
        <PermissionsGate
          scopes={[FEATURES.spendTargets]}
          renderNoAccess={
            <CampaignMappingSetup callToAction={<UpgradeCallToAction />} />
          }
        >
          <>
            {renderCampaignMapping()}
            {addModalOpen && (
              <AddCampaignLinkModal
                open={addModalOpen}
                setOpen={setAddModalOpen}
                campaignLinks={campaignLinks}
                setCampaignLinks={setCampaignLinks}
                campaignLinkToCopy={campaignLinkToCopy}
              />
            )}
            {deleteModalOpen && (
              <DeleteCampaignLinkModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                campaignLinkToDelete={campaignLinkToDelete!}
                deleteCampaignLink={deleteCampaignLink}
                deletingLink={deletingLink}
              />
            )}
            {editModalOpen && campaignLinkToEdit && (
              <EditCampaignLinkModal
                open={editModalOpen}
                setOpen={setEditModalOpen}
                campaignLinks={campaignLinks}
                setCampaignLinks={setCampaignLinks}
                campaignLinkToEdit={campaignLinkToEdit}
              />
            )}
          </>
        </PermissionsGate>
      </DashboardLayout>
    </>
  );
}
