import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  createFolder: () => Promise<void>;
  creatingFolder: boolean;
  setNewFolderName: (name: string) => void;
  newFolderName: string;
};

export default function CreateFolderModal({
  open,
  setOpen,
  createFolder,
  creatingFolder,
  setNewFolderName,
  newFolderName,
}: Props): JSX.Element {
  function createNewFolder(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    //eslint-disable-next-line
    createFolder();
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <form
        onSubmit={(event) => {
          createNewFolder(event);
        }}
      >
        <div className="bg-white p-6">
          <div className="flex justify-between">
            <h3 className="mb-4 truncate font-bold">Create a folder </h3>
            <div
              className="mt-[0.1875rem] hover:cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CrossIcon />
            </div>
          </div>
          <input
            className="border-primary-80 focus:border-primary-80 my-0 h-[3.063rem] w-[25rem] rounded border-2 p-4 text-sm focus:border-2 focus:outline-none"
            onChange={(event) => {
              setNewFolderName(event.target.value);
            }}
            aria-label="Folder name input"
          />
          <div className="flex justify-end pt-4">
            <div className="ml-4">
              <PrimaryButton disabled={!newFolderName}>
                {creatingFolder ? (
                  <Spinner colour="stroke-white" />
                ) : (
                  <>Confirm</>
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
