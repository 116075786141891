interface Props {
  background?: string;
  iconColour?: string;
}

export function AsteriskCircle({
  background = 'fill-neutral-20',
  iconColour = 'fill-neutral-80',
}: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" className={background} />
      <path
        d="M7.47059 10.9987L7.67227 8.53809L5.58824 9.93724L5 8.95623L7.33613 7.97521L5.01681 7.01028L5.57143 6.06143L7.68908 7.46058L7.45378 5H8.59664L8.34454 7.46058L10.4454 6.06143L10.9832 6.9942L8.66387 7.99129L11 8.97231L10.4454 9.92116L8.34454 8.53809L8.57983 10.9987H7.47059Z"
        className={iconColour}
      />
    </svg>
  );
}
