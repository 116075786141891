import { Plan } from 'shared/legacy-types';
import useRequest from '../useRequest';
import { Address } from 'shared/legacy-types';

export type PortalType = 'payment_method_update' | 'subscription_cancel';

export default function useSubscriptionRequests() {
  const { request } = useRequest();

  const createSubscription = async (
    plan: Plan,
    priceId: string | null,
    address: Address,
  ) => {
    const body = priceId
      ? { plan, customPriceId: priceId, address }
      : { plan, address };
    return await request('post', `/v1/subscription`, body);
  };

  const updateSubscription = async (plan: Plan) => {
    return await request('put', `/v1/subscription`, { plan });
  };

  const createStripePortalSession = async (portalType?: PortalType) => {
    const portalQuery = portalType ? `?portalType=${portalType}` : '';

    return await request('get', `/v1/subscription/portal${portalQuery}`, {});
  };

  return {
    createStripePortalSession,
    createSubscription,
    updateSubscription,
  };
}
