import { Rule, Optional } from 'shared/legacy-types';
import RadioDropdown from 'ui/components/Dropdowns/RadioDropdown';
import AsinMultiSelect from 'components/Dropdowns/AsinMultiSelect/AsinMultiSelect';
import useProductSignals from './useProductSignals';

interface Props {
  rule: Optional<Rule, 'id'>;
  onChange: (rule: Optional<Rule, 'id'>) => void;
  highlightInvalidFields: boolean;
}

export default function ProductSignalsScope({
  rule,
  onChange,
  highlightInvalidFields,
}: Props) {
  const {
    asins,
    selectAsin,
    loadingAsins,
    loadingMarketplaces,
    selectSellerBrand,
    selectSpApiMarketplaceId,
    sellerBrandOptions,
    productMarketplaces,
    spApiAccounts,
  } = useProductSignals(rule, onChange);

  const disableBrand = !spApiAccounts || spApiAccounts.length === 0;
  const disableAsinDropdown =
    !rule.sellingPartnerId ||
    !rule.spApiMarketplaceId ||
    loadingAsins ||
    disableBrand ||
    !asins ||
    asins.length === 0;
  const disableMarketplaceDropdown =
    !rule.sellingPartnerId || loadingMarketplaces || disableBrand;

  return (
    <>
      <div className="mr-4 mt-4">
        <div className="group relative">
          <RadioDropdown
            label="Brand"
            options={sellerBrandOptions() || []}
            selectedValue={rule.sellingPartnerId || ''}
            onChange={(event) => selectSellerBrand(event.target.value)}
            width="w-[10rem]"
            error={highlightInvalidFields && rule.sellingPartnerId === ''}
            disabled={disableBrand}
          />
          {disableBrand && (
            <span className="absolute h-fit w-max flex-wrap z-50 p-2 rounded bg-neutral-100 text-white text-xs bottom-[-2rem]   invisible group-hover:visible  right-[-0.5rem]">
              <>Connect a seller partner account in settings</>
            </span>
          )}
        </div>
      </div>
      <div className="mr-4 mt-4">
        <RadioDropdown
          label="Marketplace"
          options={productMarketplaces()}
          selectedValue={loadingMarketplaces ? '' : rule.spApiMarketplaceId}
          onChange={(event) => selectSpApiMarketplaceId(event.target.value)}
          width="w-[10rem]"
          error={highlightInvalidFields && rule.spApiMarketplaceId === ''}
          disabled={disableMarketplaceDropdown}
          loading={loadingMarketplaces}
        />
      </div>
      <div
        className={`mr-20 mt-4 ${(!rule.asins || rule.asins.length === 0) && 'mr-[11rem]'}`}
      >
        <AsinMultiSelect
          label="Asins"
          options={asins}
          selectedValues={loadingAsins ? [] : rule.asins || []}
          selectValue={(asin) => selectAsin(asin)}
          width="w-[10rem]"
          disabled={disableAsinDropdown}
          error={
            highlightInvalidFields && (!rule.asins || rule.asins.length === 0)
          }
          loading={loadingAsins}
        />
      </div>
    </>
  );
}
