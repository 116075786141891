import ExpressionDropdown from 'pages/Rule/Steps/Conditions/Expressions/ExpressionDropdown';
import { MultiplyIcon } from 'components/Icons';

interface Props {
  metricSelection: string;
  setMetricSelection: (value: string) => void;
  options: { value: string; label: string }[];
  expressionValue: string;
  setExpressionValue: (value: string) => void;
}

export default function Expression({
  metricSelection,
  setMetricSelection,
  options,
  expressionValue,
  setExpressionValue,
}: Props) {
  return (
    <div className="flex p-0.5 bg-secondary-40 rounded relative">
      <div className="w-[6.25rem]">
        <ExpressionDropdown
          label="Select metric"
          options={options}
          selectedValue={metricSelection}
          selectValue={(value) => setMetricSelection(value)}
        />
      </div>
      <span className="mx-0.5 mt-[10px]">
        <MultiplyIcon />
      </span>
      <div>
        <input
          value={expressionValue}
          onChange={(event) => setExpressionValue(event.target.value)}
          placeholder="Enter value"
          className={`rounded h-[2.313rem] w-[6.75rem] my-0 pl-3 pr-[1.375rem] placeholder:text-neutral-80 text-sm placeholder:text-sm truncate focus:border-primary-100 focus:border-2 focus:outline-none focus:pl-[0.688rem] focus:mr-[-0.063rem]`}
          aria-label="Expression value"
        />
      </div>
    </div>
  );
}
