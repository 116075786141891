interface Props {
  height?: string;
  rounded?: string;
}

export default function SplitTriangleImage({
  height = '152',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_10718_1680)">
        <rect width="76" height="152" fill="#FFEA9E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 76H76V0H0V76ZM19.0228 57.4568H57.4644V18.5432H19.0228V57.4568Z"
          fill="#FF7A5A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9902 50.0176H50.0245V25.9834H25.9902V50.0176Z"
          fill="#FFF9E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 152L38 76L76 152H0Z"
          fill="#FFF9E2"
        />
      </g>
      <defs>
        <clipPath id="clip0_10718_1680">
          <rect width="76" height="152" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
