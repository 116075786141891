import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import useFoldersDispatch from 'state/folders/useFoldersDispatch';
import useFolderRequests from 'api/hooks/requests/useFolderRequests';
import useFoldersSelector from 'state/folders/useFoldersSelector';

export default function useCreateFolder() {
  const [createFolderModalOpen, setCreateFolderModalOpen] =
    useState<boolean>(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);

  const { createFolder } = useFolderRequests();
  const navigate = useNavigate();

  const { updateFolders } = useFoldersDispatch();
  const { folders: foldersState } = useFoldersSelector();

  async function createNewFolder() {
    setCreatingFolder(true);
    try {
      const response = await createFolder(newFolderName);
      setCreatingFolder(false);
      setCreateFolderModalOpen(false);
      setNewFolderName('');
      // add folder to state and redirect to new folder
      const id = response.headers['location'].slice(9);
      updateFolders([{ id, name: newFolderName }, ...foldersState]);
      navigate(`/${id}`);
    } catch (error) {
      Sentry.captureException(error);
      setCreatingFolder(false);
      setCreateFolderModalOpen(false);
      setNewFolderName('');
    }
  }

  return {
    createNewFolder,
    creatingFolder,
    createFolderModalOpen,
    setCreateFolderModalOpen,
    newFolderName,
    setNewFolderName,
  };
}
