interface Props {
  iconColour?: string;
  background?: string;
  ariaLabel?: string;
}

export function CrossCircle({
  iconColour = 'stroke-white',
  background = 'fill-neutral-80',
  ariaLabel,
}: Props) {
  return (
    <div aria-label={ariaLabel}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="16" rx="8" className={background} />
        <path
          d="M10.5 5.5L5.5 10.5"
          className={iconColour}
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 5.5L10.5 10.5"
          className={iconColour}
          strokeWidth="1.2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
