interface Props {
  background?: string;
  iconColour?: string;
}

export function SlackIcon({
  background = 'fill-primary-80',
  iconColour = 'fill-white',
}: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" className={background} />
      <path
        d="M4.54577 9.56994C4.54577 10.2659 3.9833 10.8283 3.28737 10.8283C2.59143 10.8283 2.02896 10.2659 2.02896 9.56994C2.02896 8.874 2.59143 8.31153 3.28737 8.31153H4.54577V9.56994ZM5.17498 9.56994C5.17498 8.874 5.73745 8.31153 6.43338 8.31153C7.12932 8.31153 7.69179 8.874 7.69179 9.56994V12.716C7.69179 13.4119 7.12932 13.9744 6.43338 13.9744C5.73745 13.9744 5.17498 13.4119 5.17498 12.716V9.56994Z"
        className={iconColour}
      />
      <path
        d="M6.42958 4.51681C5.73364 4.51681 5.17117 3.95434 5.17117 3.25841C5.17117 2.56247 5.73364 2 6.42958 2C7.12551 2 7.68798 2.56247 7.68798 3.25841V4.51681H6.42958ZM6.42958 5.15555C7.12551 5.15555 7.68798 5.71802 7.68798 6.41395C7.68798 7.10989 7.12551 7.67236 6.42958 7.67236H3.27403C2.57809 7.67236 2.01562 7.10989 2.01562 6.41395C2.01562 5.71802 2.57809 5.15555 3.27403 5.15555H6.42958Z"
        className={iconColour}
      />
      <path
        d="M11.4765 6.41395C11.4765 5.71802 12.039 5.15555 12.7349 5.15555C13.4308 5.15555 13.9933 5.71802 13.9933 6.41395C13.9933 7.10989 13.4308 7.67236 12.7349 7.67236H11.4765V6.41395ZM10.8473 6.41395C10.8473 7.10989 10.2848 7.67236 9.5889 7.67236C8.89296 7.67236 8.33049 7.10989 8.33049 6.41395V3.25841C8.33049 2.56247 8.89296 2 9.5889 2C10.2848 2 10.8473 2.56247 10.8473 3.25841V6.41395Z"
        className={iconColour}
      />
      <path
        d="M9.5889 11.4575C10.2848 11.4575 10.8473 12.02 10.8473 12.716C10.8473 13.4119 10.2848 13.9744 9.5889 13.9744C8.89296 13.9744 8.33049 13.4119 8.33049 12.716V11.4575H9.5889ZM9.5889 10.8283C8.89296 10.8283 8.33049 10.2659 8.33049 9.56994C8.33049 8.874 8.89296 8.31153 9.5889 8.31153H12.7444C13.4404 8.31153 14.0028 8.874 14.0028 9.56994C14.0028 10.2659 13.4404 10.8283 12.7444 10.8283H9.5889Z"
        className={iconColour}
      />
    </svg>
  );
}
