export default function TypographyComponent() {
  return (
    <section
      id="buttons"
      className="p-5 border-neutral-40 border-t border-1 grid grid-rows-7 gap-y-8"
    >
      <h1 className="space-x-8">
        <span className="font-bold">H1 Bold - 48px / 110%</span>
        <span>H1 Regular - 48px / 110%</span>
      </h1>
      <h2 className="space-x-8">
        <span className="font-bold">H2 Bold - 32px / 110%</span>
        <span>H2 Regular - 32px / 110%</span>
      </h2>
      <h3 className="space-x-8">
        <span className="font-bold">H3 Bold - 24px / 110%</span>
        <span>H3 Regular -24px / 110% </span>
      </h3>
      <h4 className="space-x-8">
        <span className="font-bold">H4 Bold - 20px / 120%</span>
        <span>H4 Regular - 20px / 120%</span>
      </h4>
      <h5 className="space-x-8">
        <span className="font-bold">H5 Bold - 18px / 120%</span>
        <span>H5 Regular - 18px / 120%</span>
      </h5>
      <div className="text-base space-x-8">
        <span className="font-bold">Body 1 Bold - 16px / 120%</span>
        <span>Body 1 regular - 16px / 120%</span>
      </div>
      <div className="text-sm space-x-8">
        <span className="font-bold">Body 2 Bold - 14px / 120%</span>
        <span>Body 2 Regular - 14px / 120%</span>
      </div>
      <div className="text-xs space-x-8">
        <span className="font-bold">Body 3 Bold - 12px / 120%</span>
        <span>Body 3 Regular - 12px / 120%</span>
      </div>
    </section>
  );
}
