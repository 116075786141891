import { useState } from 'react';
import { Profile, Brand, SetupError } from 'shared/legacy-types';
import useFilterProfiles from 'pages/Onboarding/Profile/useFilterProfiles';
import useUserSelector from 'state/user/useUserSelector';
import useSetupRequests from 'api/hooks/requests/useSetupRequests';
import * as Sentry from '@sentry/react';

export default function useProfileSetup(profiles: Profile[] | undefined) {
  const [brandProfiles, setBrandProfiles] = useState<Profile[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [error, setError] = useState<SetupError>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const disableBrandContinue =
    selectedBrands.length === 0 ||
    selectedBrands.every((brand) => brand.marketplaceIds.length === 0) ||
    disableButton;

  const { connectProfiles } = useSetupRequests();
  const { userLimits } = useUserSelector();

  const userProfileLimit = userLimits && userLimits.profiles;
  const selectedMarketplaces = selectedBrands
    .map((brand) => brand.marketplaceIds)
    .flat();
  const atProfileLimit = selectedMarketplaces.length === userProfileLimit;

  useFilterProfiles(profiles, setBrandProfiles);

  async function updateSelectedProfile(selectedProfiles: number[]) {
    setLoading(true);

    try {
      const response = await connectProfiles(selectedProfiles);
      const { status } = response;
      if (status === 204) {
        window.location.replace('/');
      } else if (status === 409) {
        setLoading(false);
        setDisableButton(true);
        setError(response.data);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  function setBrands() {
    const selected = selectedBrands.flatMap((brand) => brand.marketplaceIds);
    updateSelectedProfile(selected);
    return;
  }

  return {
    brandProfiles,
    selectedBrands,
    setSelectedBrands,
    error,
    setDisableButton,
    disableBrandContinue,
    loading,
    updateSelectedProfile,
    selectedMarketplaces,
    atProfileLimit,
    userProfileLimit,
    setBrands,
  };
}
