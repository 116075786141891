import { TickIcon } from 'components/Icons';
import { Option } from './SimpleMultiDropdown';

interface Props {
  select: (value: string) => void;
  disableOptions?: boolean;
  option: Option;
  selectedValues?: string[];
}

export default function DropdownOption({
  disableOptions,
  select,
  option,
  selectedValues,
}: Props) {
  const { label, value } = option;

  const selected = selectedValues
    ? !!selectedValues.find((value) => value === option.value)
    : false;

  return (
    <div
      key={value}
      className={`bg-white ${
        selected && 'font-bold !leading-5'
      } flex justify-between rounded p-3 text-sm hover:cursor-pointer ${
        disableOptions && 'text-neutral-60 hover:cursor-not-allowed'
      }`}
      onClick={() => !disableOptions && select(value)}
    >
      <div className="flex">{label}</div>
      {selected && <TickIcon />}
    </div>
  );
}
