import { useRef } from 'react';
import { Result } from 'shared/legacy-types';
import { Column } from '../../../constants/runs';
import { SEND_EMAIL } from '../../../constants/actions';
import TableHeaders from '../Components/TableHeaders';
import TableRows from '../Components/TableRows';
import LoadMore from '../Components/LoadMore';

interface Props {
  results: Result[];
  preset: Column[];
  extended: boolean;
  loadMoreResults?: () => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  noResultsFound?: boolean;
  selectedAction?: string;
}

export default function ResultsTable({
  results,
  preset,
  extended,
  loadMoreResults,
  loadingMoreResults,
  enablePagination,
  noResultsFound,
  selectedAction,
}: Props) {
  const tableRef = useRef(null);
  const isEmailResults = selectedAction === SEND_EMAIL;

  return (
    <>
      <div ref={tableRef} className="relative max-h-[15.625rem] overflow-auto">
        <div className="table w-full">
          <TableHeaders
            preset={preset}
            isEmailResults={isEmailResults}
            extended={extended}
          />
          <TableRows
            results={results}
            preset={preset}
            isEmailResults={isEmailResults}
          />
        </div>
        <div className="mt-3 flex justify-center">
          <LoadMore
            ref={tableRef}
            results={results}
            loadMoreResults={loadMoreResults}
            loadingMoreResults={loadingMoreResults}
            enablePagination={enablePagination}
            noResultsFound={noResultsFound}
          />
        </div>
      </div>
    </>
  );
}
