interface Props {
  fill?: string;
}

export function TemplateIcon({ fill = 'fill-neutral-60' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.5L16 2.5C16.2761 2.5 16.5 2.72386 16.5 3V17C16.5 17.2761 16.2761 17.5 16 17.5H4C3.72386 17.5 3.5 17.2761 3.5 17L3.5 3C3.5 2.72386 3.72386 2.5 4 2.5ZM2 3C2 1.89543 2.89543 1 4 1L16 1C17.1046 1 18 1.89543 18 3V17C18 18.1046 17.1046 19 16 19H4C2.89543 19 2 18.1046 2 17L2 3ZM6.5 5C6.22386 5 6 5.22386 6 5.5V6C6 6.27614 6.22386 6.5 6.5 6.5L13.5 6.5C13.7761 6.5 14 6.27614 14 6V5.5C14 5.22386 13.7761 5 13.5 5L6.5 5ZM6 8.5C6 8.22386 6.22386 8 6.5 8L13.5 8C13.7761 8 14 8.22386 14 8.5V9V10V14C14 14.5523 13.5523 15 13 15H7C6.44772 15 6 14.5523 6 14L6 10V9V8.5ZM7 10L7 14H13V10H7Z"
        className={fill}
      />
    </svg>
  );
}
