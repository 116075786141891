import { useEffect } from 'react';
import CurrencyInputWithDecimal from 'components/Inputs/CurrencyInputWithDecimal';
import { Bid, CampaignLink, FixedBid, MetricBid } from 'shared/legacy-types';
import useUser from 'hooks/useUser';
import Dropdown from 'components/Dropdowns/Dropdown';
import { useState } from 'react';
import ExpressionToggle from 'components/Toggles/ExpressionToggle';
import Expression from './Expression';

interface Props {
  campaignLink: CampaignLink;
  setLinkBid: (bid: Bid) => void;
}

const options = [
  { label: 'Custom', value: 'custom' },
  { label: 'CPC', value: 'CPC' },
];

const metricOptions = [{ label: 'CPC', value: 'CPC' }];

export default function BidInput({ campaignLink, setLinkBid }: Props) {
  const [bidType, setBidType] = useState<string>('');
  const [toggleEnabled, setToggleEnabled] = useState<boolean>(false);
  const [metricSelection, setMetricSelection] = useState<string>('CPC');

  const { getProfileData } = useUser();

  const linkProfile = getProfileData(parseInt(campaignLink.profileId));
  const { currencyCode } = linkProfile || {};

  const isfixedBid = campaignLink.bid.hasOwnProperty('fixedBid');
  const fixedBid = campaignLink.bid as FixedBid;
  const metricBid = campaignLink.bid as MetricBid;

  // backend stores multiplier as number
  // we use string to make input decimals easier to manage
  const multiplier = metricBid.multiplier
    ? metricBid.multiplier.toString()
    : '';

  useEffect(() => {
    const defaultBidType = isfixedBid ? 'custom' : 'CPC';
    setBidType(defaultBidType);

    if (!toggleEnabled) {
      setToggleEnabled(!!metricBid.multiplier);
    }

    // eslint-disable-next-line
  }, [campaignLink]);

  function updateFixedBid(value: string) {
    setLinkBid({ fixedBid: value });
  }

  function updateMetricBid(value: string) {
    setLinkBid({ metric: 'costPerClick', multiplier: value });
  }

  function updateBidType(type: string) {
    setBidType(type);
    if (type === 'custom') {
      setLinkBid({ fixedBid: '' });
    }
    if (type === 'CPC') {
      setLinkBid({ metric: 'costPerClick' });
    }
  }

  function updateToggle(enabled: boolean) {
    setToggleEnabled(enabled);
    if (!enabled) {
      setLinkBid({ metric: 'costPerClick' });
    }
  }

  return (
    <>
      <div className="text-neutral-80 mb-1 text-xs">Bid</div>
      <div className="mb-4 flex">
        <div className="mr-4">
          <Dropdown
            options={options}
            selectedValue={bidType}
            selectValue={(type) => updateBidType(type)}
            styles="w-[8rem] min-w-[8rem]"
          />
        </div>
        {bidType === 'custom' && isfixedBid && (
          <CurrencyInputWithDecimal
            ariaLabel="Bid input"
            value={fixedBid.fixedBid}
            onChange={updateFixedBid}
            currencyCode={currencyCode}
          />
        )}
        {bidType === 'CPC' && (
          <div className="flex w-full">
            {toggleEnabled && (
              <Expression
                metricSelection={metricSelection}
                setMetricSelection={setMetricSelection}
                options={metricOptions}
                expressionValue={multiplier}
                setExpressionValue={updateMetricBid}
              />
            )}
            {!toggleEnabled && (
              <input
                required
                className={`block rounded w-full pl-3 focus:!pl-[0.875rem] focus:pr-[0.625rem] focus:outline-none focus:border-primary-100 focus:border-2 relative border border-neutral-40  bg-white truncate mt-0 mb-0 pr-3 h-[2.5625rem] text-sm [appearance:textfield]`}
                aria-label="Input field"
                value={'CPC'}
                disabled
              />
            )}
            <div className="my-auto ml-4 h-6">
              <ExpressionToggle enable={toggleEnabled} toggle={updateToggle} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
