export function LeftChevronIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15L7.5 10L12.5 5"
        stroke="#505F5C"
        strokeWidth="1.66667"
        strokeLinejoin="round"
      />
    </svg>
  );
}
