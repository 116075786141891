export function CircleLogo() {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3571 0C5.98072 0 0 5.98042 0 13.3571C0 20.7339 5.98072 26.7137 13.3571 26.7137C20.7342 26.7137 26.7143 20.7339 26.7143 13.3571C26.7143 5.98042 20.7342 0 13.3571 0ZM18.0889 22.9583V14.2964C19.2434 13.9369 20.0813 12.8597 20.0813 11.5873C20.0813 10.0205 18.8116 8.75015 17.2444 8.75015C15.6773 8.75015 14.4076 10.0202 14.4076 11.5873C14.4076 12.8597 15.2455 13.9369 16.3999 14.2964V23.6192C15.4354 23.9053 14.4138 24.059 13.3571 24.059C7.44655 24.059 2.65562 19.2674 2.65562 13.3571C2.65562 9.14535 5.08868 5.50231 8.62594 3.75596V12.4179C7.47151 12.7771 6.63296 13.854 6.63296 15.127C6.63296 16.6932 7.90327 17.9638 9.47043 17.9638C11.0367 17.9638 12.3073 16.6935 12.3073 15.127C12.3073 13.854 11.4688 12.7771 10.3143 12.4179V3.09481C11.2789 2.80925 12.3005 2.65532 13.3571 2.65532C19.2677 2.65532 24.0587 7.44655 24.0587 13.3574C24.0587 17.5689 21.6256 21.2117 18.0889 22.9583Z"
        fill="#28C585"
      />
    </svg>
  );
}
