import doggo from 'images/doggo.png';
import { ReactElement } from 'react';
import { FullLogo } from 'components/Icons';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';

interface Props {
  subtitle: ReactElement;
  title: string;
  callback: () => void;
}

export default function ErrorPage({ subtitle, title, callback }: Props) {
  return (
    <div className="mx-auto flex justify-center pt-10 text-center">
      <div className=" ">
        <div className="flex justify-center">
          <FullLogo />
        </div>
        <div className="flex justify-center">
          <img src={doggo} alt="sad dog" className="pt-[3.5rem]" />
        </div>
        <h2 className="mb-2 mt-6 font-bold text-neutral-100">{title}</h2>
        <h5 className="text-neutral-80 mb-4 pt-[1.5rem] pb-[1rem]">
          {subtitle}
        </h5>
        <PrimaryButton onClick={callback}>
          <>Continue using fern</>
        </PrimaryButton>
      </div>
    </div>
  );
}
