import useRequest from 'api/hooks/useRequest';

export default function useProductsRequests() {
  const { request } = useRequest();

  const getAsins = async (sellerId: string, marketplaceId: string) => {
    return await request('get', `/v1/asins/${sellerId}/${marketplaceId}`, {});
  };

  const getSpApiMarketplaceIds = async (spApiAcountId: string) => {
    return await request('get', `/v1/sp-api-accounts/${spApiAcountId}`, {});
  };

  return {
    getAsins,
    getSpApiMarketplaceIds,
  };
}
