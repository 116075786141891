import DashboardLayout from 'layouts/DashboardLayout';
import useDates from 'pages/Reporting/useDates';
import DetailHeader from 'pages/Reporting/CampaignDetail/DetailHeader';
import CampaignDetail from './CampaignDetail';
import useCampaignDetail from './useCampaignDetail';
import ReportingSetup from '../ReportingSetup';
import PermissionsGate from 'components/Permissions/PermissionsGate';
import { FEATURES } from 'components/Permissions/PermissionsGate';

export default function ReportingCampaignDetail(): JSX.Element {
  const {
    updateEndDate,
    updateStartDate,
    startDate,
    endDate,
    formattedStartDate,
    formattedEndDate,
    minimumDate,
  } = useDates();
  const {
    campaignSummary,
    currencyCode,
    loadingSummary,
    chartData,
    campaignMetricsLoading,
    campaignName,
  } = useCampaignDetail(formattedStartDate, formattedEndDate);

  return (
    <DashboardLayout>
      <PermissionsGate
        scopes={[FEATURES.reporting]}
        renderNoAccess={<ReportingSetup />}
      >
        <>
          <DetailHeader
            startDate={startDate}
            endDate={endDate}
            setStartDate={updateStartDate}
            setEndDate={updateEndDate}
            minimumDate={minimumDate}
          />
          <div className="py-4 border-b border-neutral-40">
            <h5 className="font-medium">{campaignName}</h5>
          </div>
          <div className=" sm:px-[-1.5rem] md:mx-[-1.5rem] lg:mx-[-1.5rem] xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem]" />
          <CampaignDetail
            campaignSummary={campaignSummary}
            currencyCode={currencyCode ?? ''}
            loadingSummary={loadingSummary}
            chartData={chartData}
            campaignMetricsLoading={campaignMetricsLoading}
          />
        </>
      </PermissionsGate>
    </DashboardLayout>
  );
}
