import { ReactElement } from 'react';
import useBannerSelector from 'state/banners/useBannerSelector';

interface Props {
  children: ReactElement;
}

export default function BannerWrapper({ children }: Props) {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  return <div className={`${bannerOpen && 'pt-[3.063rem]'}`}>{children}</div>;
}
