import { TickIcon, StarIcon } from 'components/Icons';

interface props {
  starFeature?: boolean;
  text: string;
  isLast: boolean;
}

export default function FeatureItem({ starFeature, text, isLast }: props) {
  return (
    <div className={`h-5 w-full flex ${!isLast && 'mb-3'}`}>
      <TickIcon />
      <span className="text-xs pl-2 my-auto pt-[0.188rem]">{text}</span>
      <span className={`pl-2 my-auto pt-1 block ${!starFeature && 'hidden'}`}>
        <StarIcon />
      </span>
    </div>
  );
}
