import { Condition, ConditionGroupOperator } from 'shared/legacy-types';
import { showPercentIcon } from '../../helpers/conditions';
import { fieldWithoutPrefix } from '../../helpers/rules/fields';
import useCondition from './useCondition';

interface Props {
  condition: Condition;
  groupOperator: ConditionGroupOperator;
  isLast: boolean;
  index: number;
}

export default function SummaryCondition({
  condition,
  groupOperator,
  isLast,
  index,
}: Props) {
  const {
    fieldConfig,
    fieldLabel,
    field,
    prefixLabel,
    operatorLabel,
    conditionValuePrefixLabel,
    conditionValueFieldLabel,
    marketplaceConditionLabel,
    metric,
    expressionLabel,
    value,
    groupOperatorLabel,
  } = useCondition(condition, groupOperator);

  function valueFormatting(value: any) {
    if (fieldConfig?.type === 'string' && value === '') {
      return <span className="italic">{`Your ${fieldLabel}`}</span>;
    }
    if (value.hasOwnProperty('metric')) {
      return `${value.value}`;
    }

    if (
      fieldConfig?.type === 'number' &&
      showPercentIcon(fieldWithoutPrefix(field))
    ) {
      return `${value}%`;
    }
    return value;
  }

  return (
    <div key={index} className={`flex ${!isLast && 'mb-2'} text-sm`}>
      {/* Condition field */}
      {prefixLabel && (
        <>
          <span className="text-neutral-80">{prefixLabel}</span>
          <span className="text-neutral-60 mx-1">•</span>
        </>
      )}
      {fieldLabel}
      {/* Condition operator */}
      <span className="text-neutral-80 ml-2">
        <>{operatorLabel}</>
      </span>
      {/* Condition value */}
      <span className="ml-2">
        <>
          {/* If there is a condition value with a prefix */}
          {conditionValuePrefixLabel && (
            <>
              <span className="text-neutral-80">
                {conditionValuePrefixLabel}
              </span>
              <span className="text-neutral-60 mx-1">•</span>
              {conditionValueFieldLabel}
            </>
          )}
          {marketplaceConditionLabel && !conditionValuePrefixLabel && (
            <>{marketplaceConditionLabel}</>
          )}
          {!marketplaceConditionLabel && !conditionValuePrefixLabel && (
            <>
              {metric && (
                <>
                  {expressionLabel} <span className="text-neutral-80">x</span>{' '}
                </>
              )}
              {valueFormatting(value)}
            </>
          )}
        </>
      </span>
      <span className="text-neutral-80 ml-2">
        {!isLast && <span className="text-sm">{groupOperatorLabel}</span>}
      </span>
    </div>
  );
}
