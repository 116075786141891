interface props {
  fill?: string;
  stroke?: string;
}

export function FolderIcon({
  fill = 'fill-primary-80',
  stroke = 'stroke-primary-80',
}: props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.83671 4.33282C6.94799 4.49974 7.13533 4.6 7.33594 4.6H13.3359C13.5304 4.6 13.717 4.67726 13.8545 4.81479C13.992 4.95232 14.0693 5.13884 14.0693 5.33333V12.6667C14.0693 12.8612 13.992 13.0477 13.8545 13.1852C13.717 13.3227 13.5304 13.4 13.3359 13.4H2.66927C2.47478 13.4 2.28825 13.3227 2.15073 13.1852C2.0132 13.0477 1.93594 12.8612 1.93594 12.6667V3.33333C1.93594 3.13884 2.0132 2.95231 2.15073 2.81479C2.28825 2.67726 2.47478 2.6 2.66927 2.6H5.68149L6.83671 4.33282Z"
        className={`${fill} ${stroke}`}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
