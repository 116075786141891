export default function ReversedLogo() {
  return (
    <svg
      width="102"
      height="29"
      viewBox="0 0 102 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1725 0C6.34581 0 0 6.34549 0 14.1725C0 21.9996 6.34581 28.3444 14.1725 28.3444C21.9999 28.3444 28.345 21.9996 28.345 14.1725C28.345 6.34549 21.9999 0 14.1725 0ZM19.1932 24.3598V15.1692C20.4181 14.7877 21.3072 13.6447 21.3072 12.2947C21.3072 10.6322 19.96 9.2843 18.2971 9.2843C16.6343 9.2843 15.2871 10.6318 15.2871 12.2947C15.2871 13.6447 16.1762 14.7877 17.4011 15.1692V25.061C16.3776 25.3646 15.2937 25.5276 14.1725 25.5276C7.90112 25.5276 2.81773 20.4436 2.81773 14.1725C2.81773 9.70363 5.39931 5.83819 9.1525 3.98524V13.1759C7.92761 13.5571 7.03787 14.6997 7.03787 16.0504C7.03787 17.7123 8.38572 19.0604 10.0486 19.0604C11.7104 19.0604 13.0586 17.7126 13.0586 16.0504C13.0586 14.6997 12.1689 13.5571 10.944 13.1759V3.28373C11.9674 2.98074 13.0514 2.81742 14.1725 2.81742C20.4439 2.81742 25.5273 7.90112 25.5273 14.1728C25.5273 18.6414 22.9457 22.5065 19.1932 24.3598Z"
        className="fill-primary-100"
      />
      <path
        d="M42.3114 7.30814C42.8311 7.30814 43.4216 7.49711 43.894 7.85141L45.1931 5.20593C44.2955 4.61542 43.0437 4.30836 41.8627 4.30836C39.3117 4.30836 37.1858 5.82006 37.1858 8.6545V9.85914H35.4852V12.3865H37.1858V22H40.7997V12.3865H43.7995V9.85914H40.7997V8.79623C40.7997 7.78055 41.4611 7.30814 42.3114 7.30814ZM50.5149 9.17415C46.6412 9.17415 43.9721 11.7724 43.9721 15.6934C43.9721 19.5435 46.5467 22.1417 50.6094 22.1417C52.9006 22.1417 54.7666 21.315 56.0421 19.8978L54.1288 17.9609C53.2549 18.8349 52.1211 19.3073 50.9164 19.3073C49.2866 19.3073 48.0584 18.3625 47.6332 16.7563H56.7507C57.0577 12.1267 54.9791 9.17415 50.5149 9.17415ZM47.5624 14.6068C47.8458 12.9298 48.9323 11.8433 50.5385 11.8433C52.2156 11.8433 53.3021 12.9298 53.3493 14.6068H47.5624ZM62.7899 9.29225H59.1524V22H62.7899V15.8823C62.7899 13.7329 64.3961 12.3393 66.9943 12.5755V9.15053C65.1047 9.17415 63.6639 10.0245 62.7899 11.5834V9.29225ZM77.0097 9.15053C75.0256 9.17415 73.5139 9.93 72.6163 11.5126V9.29225H68.9788V22H72.6163V15.7406C72.6163 13.8746 73.7029 12.5046 75.5216 12.481C77.0806 12.481 78.0254 13.4731 78.0254 15.0792V22H81.6629V14.0163C81.6629 11.0402 79.8442 9.15053 77.0097 9.15053Z"
        fill="white"
      />
      <path
        d="M93.8244 11.7608L94.774 14H96.5597L92.223 4.07946H90.5081L86.1289 14H87.8721L88.8216 11.7608H93.8244ZM93.1867 10.2727H89.4452L91.3159 5.86515L93.1867 10.2727ZM99.2705 14V4.07946H97.5982V14H99.2705Z"
        fill="white"
      />
    </svg>
  );
}
