import { TickIcon } from 'components/Icons';
import { Asin } from 'pages/Rule/Scope/ProductSignals/useProductSignals';

interface Props {
  asin: Asin;
  selected: boolean;
  selectAsin: (asin: string) => void;
}

export default function AsinOption({ asin, selected, selectAsin }: Props) {
  return (
    <div
      key={asin.asin}
      className={`bg-white flex justify-between rounded p-3 text-sm hover:cursor-pointer`}
      onClick={() => selectAsin(asin.asin)}
    >
      <div>
        <div>
          <img src={asin.imageUrl} alt="" />
        </div>
        <div
          className={` ${selected ? 'max-w-[10.688rem]' : 'max-w-[13.75rem]'}`}
        >
          <div className={`${selected && 'font-bold !leading-5'} flex`}>
            {asin.asin}
          </div>
          <div className="text-xs text-neutral-80 truncate">{asin.name}</div>
        </div>
      </div>
      {selected && <TickIcon />}
    </div>
  );
}
