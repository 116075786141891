export function EditModalCircles() {
  return (
    <svg
      width="86"
      height="88"
      viewBox="0 0 86 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <g className="mix-blend-mode:multiply">
          <circle
            cx="80.0005"
            cy="8.00048"
            r="48.6914"
            stroke="#83B1A9"
            strokeWidth="7.4151"
          />
        </g>
        <g className="mix-blend-mode:multiply">
          <circle
            cx="79.9948"
            cy="7.99972"
            r="64.217"
            stroke="#83B1A9"
            strokeWidth="7.4151"
          />
        </g>
        <g className="mix-blend-mode:multiply">
          <circle
            cx="80"
            cy="8"
            r="78.9218"
            stroke="#83B1A9"
            strokeWidth="2.15633"
          />
        </g>
        <g className="mix-blend-mode:multiply">
          <circle
            cx="80.0031"
            cy="8.00012"
            r="34.4596"
            stroke="#83B1A9"
            strokeWidth="7.4151"
          />
        </g>
        <g className="mix-blend-mode:multiply">
          <circle
            cx="80.0007"
            cy="7.99876"
            r="14.6213"
            stroke="#83B1A9"
            strokeWidth="7.4151"
          />
        </g>
      </g>
    </svg>
  );
}
