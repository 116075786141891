import { useState, useEffect } from 'react';
import useCampaignLinkRequests from 'api/hooks/requests/useCampaignLinksRequests';
import { CampaignLink, SearchTermCampaign, AdGroup } from 'shared/legacy-types';
import useUser from 'hooks/useUser';
import { deepClone } from 'ui/helpers/deepClone';
import escapeStringRegexp from 'escape-string-regexp';

export default function useCampaignLinkDropdown(
  campaignLink: CampaignLink,
  modalOpen: boolean,
) {
  const { getCampaignLinkRequest, getCampaignLinkAdGroupsRequest } =
    useCampaignLinkRequests();

  const { getProfileData } = useUser();
  const linkProfile = getProfileData(parseInt(campaignLink.profileId));
  const [campaignOptions, setCampaignOptions] = useState<SearchTermCampaign[]>(
    [],
  );
  const [sourceAdGroupOptions, setSourceAdGroupOptions] = useState<AdGroup[]>(
    [],
  );
  const [destinationAdGroupOptions, setDestinationAdGroupOptions] = useState<
    AdGroup[]
  >([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);
  const [sourceSearchTerm, setSourceSearchTerm] = useState<string>('');
  const [destinationSearchTerm, setDestinationSearchTerm] =
    useState<string>('');
  const [adGroupsLoading, setAdGroupsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchCampaigns() {
      setLoadingCampaigns(true);
      const response = await getCampaignLinkRequest(
        linkProfile?.id.toString()!,
      );
      const campaigns = response.data.campaigns;
      setCampaignOptions(campaigns);
      setLoadingCampaigns(false);
    }
    linkProfile && fetchCampaigns();
    // eslint-disable-next-line
  }, [linkProfile?.id]);

  useEffect(() => {
    async function fetchSourceAdGroups() {
      setAdGroupsLoading(true);
      const response = await getCampaignLinkAdGroupsRequest(
        campaignLink.profileId,
        campaignLink.source.campaignId,
      );
      const adGroups = response.data.adGroups;
      setSourceAdGroupOptions(adGroups);
      setAdGroupsLoading(false);
    }
    campaignLink.source.campaignId && fetchSourceAdGroups();
    // eslint-disable-next-line
  }, [campaignLink.source.campaignId, modalOpen]);

  useEffect(() => {
    async function fetchDestinationAdGroups() {
      setAdGroupsLoading(true);
      const response = await getCampaignLinkAdGroupsRequest(
        campaignLink.profileId,
        campaignLink.destination.campaignId,
      );
      const adGroups = response.data.adGroups;
      setDestinationAdGroupOptions(adGroups);
      setAdGroupsLoading(false);
    }
    campaignLink.destination.campaignId && fetchDestinationAdGroups();
    // eslint-disable-next-line
  }, [campaignLink.destination.campaignId, modalOpen]);

  useEffect(() => {
    // if modal is closed, reset search terms and ad groups
    if (!modalOpen) {
      setSourceSearchTerm('');
      setDestinationSearchTerm('');
      setSourceAdGroupOptions([]);
      setDestinationAdGroupOptions([]);
    }
  }, [modalOpen]);

  const sortedSourceCampaigns =
    campaignOptions &&
    deepClone(campaignOptions).sort((a: any, b: any) =>
      a.campaignName.localeCompare(b.campaignName),
    );

  const sourceCampaigns = sortedSourceCampaigns.filter(
    (campaign: SearchTermCampaign) => {
      const campaignNameForMatching = campaign.campaignName
        .toLowerCase()
        .replace(/\s/g, '');
      const searchTermForMatching = escapeStringRegexp(
        sourceSearchTerm.toLowerCase(),
      ).replace(/\s/g, '');
      return campaignNameForMatching.match(searchTermForMatching);
    },
  );

  const sortedDestinationCampaigns =
    campaignOptions &&
    deepClone(campaignOptions).sort((a: any, b: any) =>
      a.campaignName.localeCompare(b.campaignName),
    );
  const destinationCampaigns = sortedDestinationCampaigns.filter(
    (campaign: SearchTermCampaign) => {
      const campaignNameForMatching = campaign.campaignName
        .toLowerCase()
        .replace(/\s/g, '');
      const searchTermForMatching = escapeStringRegexp(
        destinationSearchTerm.toLowerCase(),
      ).replace(/\s/g, '');
      return campaignNameForMatching.match(searchTermForMatching);
    },
  );

  return {
    loadingCampaigns,
    sourceAdGroupOptions,
    destinationAdGroupOptions,
    adGroupsLoading,
    sourceSearchTerm,
    setSourceSearchTerm,
    destinationSearchTerm,
    setDestinationSearchTerm,
    sourceCampaigns,
    destinationCampaigns,
  };
}
