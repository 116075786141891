import { ReactElement } from 'react';
import { InfoIcon } from 'components/Icons';

interface Props {
  message: ReactElement;
}

export default function Warning({ message }: Props) {
  return (
    <div className={`relative bg-[#FF9D6640] rounded mx-auto flex-row grow`}>
      <div className="relative flex px-4 py-3 mx-auto">
        <span className="w-[1.563rem] my-auto">
          <InfoIcon fill="fill-tertiary-100" />
        </span>
        <span className=" text-neutral-100 leading-[1.05rem] text-sm w-max text-left pt-[0.063rem] pl-1">
          {message}
        </span>
      </div>
    </div>
  );
}
