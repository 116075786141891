import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import Dropdown from 'components/Dropdowns/Dropdown';
import { TARGETING_TYPES, MATCH_TYPES } from 'ui/constants/rules';
import { EditModalCircles, EditModalArrows } from 'components/Icons';
import { CampaignLink } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';
import SimpleMultiDropdown from 'components/Dropdowns/SimpleMultiDropdown/SimpleMultiDropdown';
import useEditCampaignLink from 'pages/CampaignMapping/Modals/EditCampaignLink/useEditCampaignLink';
import BidInput from '../Components/Bid';
import CampaignDropdowns from '../Components/CampaignDropdowns';
import ProfileSelection from '../Components/ProfileSelection';
import KeywordNegation from '../Components/KeywordNegation';
import ErrorMessage from 'components/Errors/ErrorMessage';
import { IS_DUPLICATE_MESSAGE } from 'constants/keywordHarvesting';
import {
  allFieldsRequiredForValidationCheckMatch,
  allFieldsRequiredForValidationCheckPopulated,
  allCampaignLinkFieldsPopulated,
  campaignLinksMatch,
} from 'pages/CampaignMapping/helpers/campaignLinks';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  campaignLinks: CampaignLink[];
  setCampaignLinks: (campaigns: CampaignLink[]) => void;
  campaignLinkToEdit: CampaignLink;
}

export default function EditCampaignLinkModal({
  open,
  setOpen,
  campaignLinks,
  setCampaignLinks,
  campaignLinkToEdit,
}: Props) {
  const {
    brand,
    submittingCampaignLink,
    editLinkBid,
    updateCampaignLinkForm,
    submitCampaignLink,
    campaignLink,
    setCampaignLink,
    isDuplicateCampaignLink,
    isDuplicateCampaignLinkValidationRunning,
  } = useEditCampaignLink(
    campaignLinks,
    setCampaignLinks,
    setOpen,
    campaignLinkToEdit,
  );

  const fieldsRequiredForValidationHaveChanged =
    !allFieldsRequiredForValidationCheckMatch(campaignLink, campaignLinkToEdit);

  const campaignLinkHasNotChanged = campaignLinksMatch(
    campaignLink,
    campaignLinkToEdit,
  );

  const showDuplicateError =
    allFieldsRequiredForValidationCheckPopulated(campaignLink) &&
    fieldsRequiredForValidationHaveChanged &&
    isDuplicateCampaignLink;

  const disableEditSaveButton =
    !allCampaignLinkFieldsPopulated(campaignLink) ||
    (isDuplicateCampaignLink && fieldsRequiredForValidationHaveChanged) ||
    isDuplicateCampaignLinkValidationRunning ||
    campaignLinkHasNotChanged;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-[31rem] h-fit ">
        <div className="relative h-fit">
          <span className="absolute top-0 right-0">
            <EditModalCircles />
          </span>
          <span className="absolute bottom-0 left-0">
            <EditModalArrows />
          </span>
          <div className="bg-secondary-40 p-8 max-h-[23.688rem]">
            <div>
              <h5 className="font-bold text-2xl mb-2">Edit campaign link</h5>
              <div className="text-neutral-80 w-[24.188rem] text-sm mb-8">
                Choose which campaign/ad group you want new keywords to get
                added to
              </div>
              <ProfileSelection
                brand={brand}
                campaignLink={campaignLink}
                updateProfileInfo={updateCampaignLinkForm}
              />
              <CampaignDropdowns
                campaignLink={campaignLink}
                open={open}
                setCampaignLink={setCampaignLink}
              />
            </div>
          </div>
        </div>
        <div className="pt-10 pb-[1.938rem] px-8">
          <div className="flex">
            <div className="basis-[13rem] flex-auto">
              <div className="text-neutral-80 mb-1 text-xs">Targeting type</div>
              <Dropdown
                label="Select one"
                ariaLabel={'Select targeting type'}
                options={TARGETING_TYPES}
                selectedValue={campaignLink.targetingType}
                selectValue={(value) =>
                  updateCampaignLinkForm(value, 'targetingType')
                }
              />
            </div>
            <div
              className={`w-[13rem] ml-4 ${campaignLink.targetingType === 'product' && 'hidden'}`}
            >
              <div className="text-neutral-80 mb-1 text-xs">Match type</div>
              <SimpleMultiDropdown
                label="Select one"
                ariaLabel="Select match type"
                options={MATCH_TYPES}
                selectedValues={campaignLink.matchTypes || []}
                selectValue={(value) =>
                  updateCampaignLinkForm(value, 'matchType')
                }
              />
            </div>
          </div>

          <div className="mt-6">
            <KeywordNegation
              campaignLink={campaignLink}
              setCampaignLink={setCampaignLink}
            />
          </div>
          <div className="relative flex-1 pt-6">
            <BidInput campaignLink={campaignLink} setLinkBid={editLinkBid} />
          </div>
          {showDuplicateError && (
            <div className="mt-3">
              <ErrorMessage showBorder message={<>{IS_DUPLICATE_MESSAGE}</>} />
            </div>
          )}
          <div className="flex justify-end bg-gray-50 mt-7">
            <SecondaryButton onClick={() => setOpen(false)}>
              <>Cancel</>
            </SecondaryButton>
            <div className="ml-3">
              <PrimaryButton
                onClick={() => submitCampaignLink()}
                disabled={disableEditSaveButton}
              >
                <span>
                  {submittingCampaignLink && (
                    <div className="w-[1.966rem] pl-2">
                      <Spinner colour={'stroke-white'} />
                    </div>
                  )}
                  {!submittingCampaignLink && <span>Save</span>}
                </span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
