interface Props {
  stroke?: string;
  ariaLabel?: string;
}

export function MeatballIcon({
  stroke = 'stroke-neutral-80',
  ariaLabel,
}: Props) {
  return (
    <div aria-label={ariaLabel}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.0026 8.66732C8.37079 8.66732 8.66927 8.36884 8.66927 8.00065C8.66927 7.63246 8.37079 7.33398 8.0026 7.33398C7.63441 7.33398 7.33594 7.63246 7.33594 8.00065C7.33594 8.36884 7.63441 8.66732 8.0026 8.66732Z"
          className={stroke}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0026 3.99935C8.37079 3.99935 8.66927 3.70087 8.66927 3.33268C8.66927 2.96449 8.37079 2.66602 8.0026 2.66602C7.63441 2.66602 7.33594 2.96449 7.33594 3.33268C7.33594 3.70087 7.63441 3.99935 8.0026 3.99935Z"
          className={stroke}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0026 13.3333C8.37079 13.3333 8.66927 13.0349 8.66927 12.6667C8.66927 12.2985 8.37079 12 8.0026 12C7.63441 12 7.33594 12.2985 7.33594 12.6667C7.33594 13.0349 7.63441 13.3333 8.0026 13.3333Z"
          className={stroke}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
