import Modal from 'components/Modals/Modal';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import { PLAN_TIERS } from 'constants/plans';
import Tier from './Tier';
import useUserSelector from 'state/user/useUserSelector';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ChangePlanModal({ open, setOpen }: Props) {
  const { userPlan, isInternalUser } = useUserSelector();

  if (!isInternalUser) return null;

  function plans() {
    return PLAN_TIERS.map((tier, index) => {
      return <Tier tier={tier} index={index} setOpen={setOpen} />;
    });
  }

  const title = () => {
    if (userPlan === 'starter') {
      return 'Upgrade plan';
    }
    return 'Change plan';
  };
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className=" bg-white p-6">
        <div className="flex justify-between">
          <h3 className="font-bold">{title()}</h3>
          <div className="hover:cursor-pointer" onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <div className="flex my-6 gap-x-4">{plans()}</div>
        <a
          href="https://www.meetfern.ai/pricing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="underline text-neutral-60 font-bold text-center text-xs">
            See all features
          </div>
        </a>
      </div>
    </Modal>
  );
}
