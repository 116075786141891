import {
  monthToDateGoalTooltip,
  monthtoDateActualTooltip,
} from '../Tooltips/Content/marketplace';

export const CONDITION_FIELDS = {
  newCampaignName: {
    label: 'Name',
    type: 'string',
    value: 'name',
  },
  campaignName: {
    label: 'Campaign name',
    type: 'string',
    value: 'campaign_name',
  },
  adGroupName: {
    label: 'Ad group name',
    type: 'string',
    value: 'ad_group_name',
  },
  targetingText: {
    label: 'Targeting text',
    type: 'string',
    value: 'targeting_text',
  },
  matchType: {
    label: 'Match type',
    type: 'string',
    value: 'match_type',
  },
  clicks: {
    label: 'Clicks',
    type: 'number',
    value: 'clicks',
  },
  impressions: {
    label: 'Impressions',
    type: 'number',
    value: 'impressions',
  },
  spend: {
    label: 'Spend',
    type: 'number',
    value: 'cost',
    inputType: 'valueOrDropdown',
    inputTypeHeaders: ['campaigns'],
  },
  cpc: {
    label: 'CPC',
    type: 'number',
    value: 'cost_per_click',
  },
  ctr: {
    label: 'CTR',
    type: 'number',
    value: 'click_through_rate',
  },
  adSales: { label: 'Ad sales', type: 'number', value: 'ad_sales' },
  adOrders: { label: 'Ad orders', type: 'number', value: 'ad_orders' },
  acos: {
    label: 'ACOS',
    type: 'number',
    value: 'acos',
  },
  roas: {
    label: 'ROAS',
    type: 'number',
    value: 'roas',
  },
  adConversionRate: {
    label: 'Ad conversion rate',
    type: 'number',
    value: 'ad_conversion_rate',
  },
  asin: { label: 'ASIN', type: 'string', value: 'asin' },
  fbaStockAvailable: {
    label: 'FBA Stock available',
    type: 'number',
    value: 'fbaStockAvailable',
  },
  deliveryDays: {
    label: 'Delivery days',
    type: 'number',
    value: 'delivery_days',
  },
  bid: { label: 'Bid', type: 'number', value: 'bid' },
  adUnitsOrdered: {
    label: 'Ad units ordered',
    type: 'number',
    value: 'ad_units_ordered',
  },
  status: {
    label: 'Status',
    type: 'string',
    value: 'status',
  },
  budget: {
    label: 'Budget',
    type: 'number',
    value: 'budget',
  },
  monthlySpendTarget: {
    label: 'Monthly Spend Target',
    type: 'number',
    value: 'profileMonthlySpendTarget',
    inputType: 'valueOrDropdown',
  },
  monthToDateSpendGoal: {
    label: 'MTD Spend Target',
    type: 'number',
    value: 'profileExpectedSpendToDate',
    tooltip: {
      content: monthToDateGoalTooltip,
      position: 'bottom-[1.375rem] right-[-7.625rem]',
    },
    inputType: 'valueOrDropdown',
  },
  monthToDateSpendActual: {
    label: 'MTD Spend',
    type: 'number',
    value: 'profileActualSpendToDate',
    tooltip: {
      content: monthtoDateActualTooltip,
      position: 'bottom-[1.375rem] right-[-5.313rem]',
    },
    inputType: 'valueOrDropdown',
  },
  searchTerm: {
    label: 'Search term',
    type: 'string',
    value: 'search_term',
  },
  averageDailyCost: {
    label: 'Daily spend average',
    type: 'number',
    value: 'averageDailyCost',
    inputType: 'valueOrDropdown',
    inputTypeHeaders: ['campaigns'],
  },
  dynamicAcos: {
    label: 'Dynamic ACOS',
    type: 'number',
    value: 'dynamic_acos',
  },
  tacos: {
    label: 'TACOS',
    type: 'number',
    value: 'tacos',
  },
  cr: {
    label: 'CR%',
    type: 'number',
    value: 'cr',
  },
  averageCr: {
    label: 'Avg CR%',
    type: 'number',
    value: 'average_cr',
  },
  revenue: {
    label: 'Revenue',
    type: 'number',
    value: 'revenue',
  },
  buyBox: {
    label: 'Buy Box %',
    type: 'number',
    value: 'buy_box',
  },
  totalCr: {
    label: 'Total CR%',
    type: 'number',
    value: 'total_cr',
  },
  organicCr: {
    label: 'Organic CR%',
    type: 'number',
    value: 'organic_cr',
  },
  totalOrders: {
    label: 'Total orders',
    type: 'number',
    value: 'total_orders',
  },
  totalUnits: {
    label: 'Total units',
    type: 'number',
    value: 'total_units',
  },
  sessions: {
    label: 'Sessions',
    type: 'number',
    value: 'sessions',
  },
  pageViews: {
    label: 'Page views',
    type: 'number',
    value: 'page_views',
  },
  winningBuyBox: {
    label: 'Winning buy box',
    type: 'boolean',
    value: 'winningBuyBox',
    inputType: 'dropdown',
  },
  pricingHealth: {
    label: 'Pricing Health',
    type: 'boolean',
    value: 'pricingHealth',
  },
  promotionStatus: {
    label: 'Promotion status',
    type: 'boolean',
    value: 'promotionStatus',
  },
  primeEligibilityStatus: {
    label: 'Prime eligibility status',
    type: 'boolean',
    value: 'primeEligibility',
    inputType: 'dropdown',
  },
  mainCategorySalesRank: {
    label: 'Main Category Sales Rank',
    type: 'number',
    value: 'mainCategorySalesRank',
  },
  subCategorySalesRank: {
    label: 'Sub Category Sales Rank',
    type: 'number',
    value: 'subCategorySalesRank',
  },
  normalDeliveryWindow: {
    label: 'Normal delivery window',
    type: 'boolean',
    value: 'normalDeliveryWindow',
    inputType: 'dropdown',
  },
};
