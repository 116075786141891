import { convertToPascalCase } from 'pages/Reporting/helpers';
import { ReportingProfileTotals } from 'shared/legacy-types';
import { formatCurrency } from 'ui/helpers/currency/currency';

interface Props {
  payload: any[];
  selectedMetric: string[];
  setSelectedMetric: (selectedMetric: string[]) => void;
  currencyCode?: string;
  totals: ReportingProfileTotals;
  hasDate: boolean;
}

export default function DashboardLegend({
  payload,
  selectedMetric,
  setSelectedMetric,
  currencyCode,
  totals,
}: Props) {
  const formattedAdRevenue =
    currencyCode && formatCurrency(parseInt(totals.adSales), currencyCode, 0);
  const formattedSpend =
    currencyCode && formatCurrency(parseInt(totals.cost), currencyCode, 0);
  const formattedAcos = parseFloat(totals.acos).toFixed(2);
  const hasTotals = totals.cost && totals.adSales;

  const dashBoardValue = {
    acos: formattedAcos,
    adRevenue: formattedAdRevenue,
    spend: formattedSpend,
  };

  const legends = () => {
    return payload.map((item: any, index) => {
      const metric = convertToPascalCase(item.value);
      const stat = dashBoardValue[item.value as keyof typeof dashBoardValue];

      const selectLegend = () => {
        if (selectedMetric.includes(item.value)) {
          setSelectedMetric(selectedMetric.filter((i) => i !== item.value));
          return;
        }
        setSelectedMetric([...selectedMetric, item.value]);
      };

      const isSelected = selectedMetric.includes(item.value);
      // to be replaced with locale currency
      const isPercentage = item.value === 'acos';

      return (
        <div
          className="hover:cursor-pointer"
          onClick={() => selectLegend()}
          key={index}
        >
          <div className="flex">
            <div
              className="h-0.5 w-4 mr-2 my-auto"
              style={{ background: isSelected ? item.color : 'lightgrey' }}
            />
            <div className={`text-xs ${!isSelected && 'text-neutral-60'}`}>
              {metric}
            </div>
          </div>
          <h4
            className={`font-bold text-center mt-1 ${!isSelected && 'text-neutral-60'}`}
          >
            {stat}
            {isPercentage && '%'}
          </h4>
        </div>
      );
    });
  };
  return (
    <div className={`px-3 flex justify-evenly pb-5`}>
      {hasTotals && legends()}
    </div>
  );
}
