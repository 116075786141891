import { Result, KeywordApproval } from 'shared/legacy-types';
import { Column } from '../../../constants/runs';
import { formatCurrency } from '../../../helpers/currency/currency';
import { resultMatch } from '../KeywordApprovalTable/keywordApprovals';

export default function useTableColumn(
  columnIndex: number,
  preset: Column[],
  isFirstRow: boolean,
  isLastRow: boolean,
  isEmailResults: boolean,
  currencyCode: string,
  showCurrency: boolean,
  value: string,
  result: Result,
  noDestinationCampaign: boolean,
  selectedResults: Partial<KeywordApproval>[] | undefined,
  keywordApprovals: KeywordApproval[] | undefined,
  isKeywordHarvesting?: boolean,
) {
  const isFirstCell = columnIndex === 0;
  const isLastCell = columnIndex + 1 === preset.length;
  const isSearchTermCell = value === 'searchTerm';
  const isCellHighlighted =
    isLastCell && !isEmailResults && !isKeywordHarvesting;
  const isMarketplace = value === 'countryCode';
  const isSearchTerm = value === 'searchTerm';

  const keywordApproved =
    keywordApprovals &&
    !!keywordApprovals.find((approval) => {
      return resultMatch(result, approval) && approval.status === 'APPROVED';
    });

  const keywordRejected =
    keywordApprovals &&
    !!keywordApprovals.find((approval) => {
      return resultMatch(result, approval) && approval.status === 'REJECTED';
    });

  const resultSelected =
    selectedResults &&
    selectedResults.find((selectedResult) => {
      return resultMatch(result, selectedResult);
    });

  let cellData;

  if (value === 'currentBid') {
    cellData =
      result[value as keyof typeof result] ||
      result['previousBid' as keyof typeof result];
  } else if (value === 'simulatedBid') {
    cellData =
      result[value as keyof typeof result] ||
      result['adjustedBid' as keyof typeof result];
  } else {
    cellData = result[value as keyof typeof result];
  }

  const cellStyle = `
    ${isFirstCell && 'border-l'}
    ${isLastCell && `border-r`}
    ${isFirstRow && `border-t border-b`}
    ${isFirstRow && `${isFirstCell && 'rounded-tl-md'} `}
    ${isFirstRow && `${isLastCell && 'rounded-tr-md !border-r'}`}
    ${isLastRow && `${isFirstCell && 'rounded-bl-md'}`}
    ${isLastRow && `${isLastCell && 'rounded-br-md'}`}
    ${isCellHighlighted && `border-l border-secondary-40 bg-primary-40`}
    ${isSearchTermCell && `border-x border-secondary-40 bg-primary-40`}
    ${isFirstRow && !isSearchTerm && 'border-r-0 border-t'} 
    ${isSearchTerm && keywordApproved && 'italic text-secondary-100'}
    ${isSearchTerm && keywordRejected && 'italic line-through text-neutral-80'}
    ${isMarketplace && 'max-w-[3.25rem] flex-auto'}
    ${noDestinationCampaign && 'text-neutral-80 italic'}`;

  const resultCurrency = currencyCode && showCurrency ? currencyCode : '';

  const brandCurrency =
    currencyCode &&
    showCurrency &&
    typeof cellData === 'string' &&
    cellData &&
    formatCurrency(parseFloat(cellData), resultCurrency, 2);

  function numberFormatting(
    cellData: number | string | undefined | string[] | null | boolean,
  ) {
    if (cellData === 0) {
      return '';
    }
    if (typeof cellData === 'number') {
      return new Intl.NumberFormat().format(cellData);
    }
    if (brandCurrency) {
      return;
    }

    return cellData;
  }

  return {
    cellStyle,
    numberFormatting,
    brandCurrency,
    cellData,
    keywordApproved,
    keywordRejected,
    resultSelected,
  };
}
