interface Props {
  label: string;
  defaultValue?: string;
  selectedValues: string[] | number[] | undefined;
  open: boolean;
  firstLabel?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export default function Label({
  defaultValue,
  label,
  open,
  required,
  selectedValues,
  disabled,
  firstLabel,
}: Props) {
  if (defaultValue && selectedValues?.length === 0) {
    return (
      <div
        className={`py-[0.688rem] pl-[0.938rem] ${open && 'pl-[1.01rem] pt-[0.749rem]'}`}
      >
        <div className="text-xs">
          {label}
          {required && <span className="text-error ml-0.5">*</span>}
        </div>
        <div className="mt-[0.1875rem] truncate font-bold leading-[1.1375rem]">
          {defaultValue}
        </div>
      </div>
    );
  }
  if (selectedValues && selectedValues?.length > 0) {
    let selectedLabel;

    selectedValues?.length === 1
      ? (selectedLabel = firstLabel)
      : (selectedLabel = `${selectedValues.length} selected`);

    return (
      <div
        className={`py-[0.688rem] pl-[0.938rem] ${open && 'pl-[1.01rem] pt-[0.749rem]'} ${
          disabled && 'text-neutral-80'
        }`}
      >
        <div className={`text-xs `}>
          {label}
          {required && <span className="text-error ml-0.5">*</span>}
        </div>
        <div className="mt-[0.1875rem] truncate font-bold leading-[1.1375rem]">
          {selectedLabel}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`py-[1.219rem] pl-4 leading-[1.1375rem] ${open && 'pl-[1.07rem] pt-[1.28rem]'} font-bold ${
        disabled && 'text-neutral-80'
      }`}
    >
      {label} {required && <span className="text-error">*</span>}
    </div>
  );
}
