import { CampaignLink } from 'shared/legacy-types';
import Tooltip from 'ui/Tooltips/Tooltip';
import Checkbox from 'ui/components/Checkbox/Checkbox';
import SimpleMultiDropdown from 'components/Dropdowns/SimpleMultiDropdown/SimpleMultiDropdown';

interface Props {
  campaignLink: CampaignLink;
  setCampaignLink: (campaignLink: CampaignLink) => void;
}

const matchTypeOptions = [
  { value: 'exact', label: 'Exact' },
  { value: 'phrase', label: 'Phrase' },
];

export default function KeywordNegation({
  campaignLink,
  setCampaignLink,
}: Props) {
  const isKeyword = campaignLink.targetingType === 'keyword';
  const isChecked =
    campaignLink.negateKeywords && campaignLink.negateKeywords.negate;
  const showDropdown = isKeyword && isChecked;

  function negateCheck(checked: boolean) {
    setCampaignLink({ ...campaignLink, negateKeywords: { negate: checked } });
  }

  function updateMatchTypes(value: string) {
    if (
      campaignLink.negateKeywords.matchTypes &&
      campaignLink.negateKeywords.matchTypes.includes(value as any)
    ) {
      const matchTypes = campaignLink.negateKeywords.matchTypes?.filter(
        (matchType) => matchType !== value,
      );

      setCampaignLink({
        ...campaignLink,
        negateKeywords: {
          ...campaignLink.negateKeywords,
          matchTypes,
        },
      });
      return;
    }

    setCampaignLink({
      ...campaignLink,
      negateKeywords: {
        ...campaignLink.negateKeywords,
        matchTypes: [...(campaignLink.negateKeywords.matchTypes || []), value],
      },
    });
  }

  return (
    <div className="rounded border border-neutral-40 px-4 py-[0.938rem] bg-secondary-20 text-sm !leading-5">
      <div className="flex">
        <div>
          <Checkbox
            checked={isChecked}
            select={(event) => negateCheck(event.target.checked)}
            size="5"
          />
        </div>
        <div className="ml-2">
          Negate{' '}
          <span className="font-bold">
            {' '}
            {isKeyword ? <>keyword</> : <>product</>}{' '}
          </span>{' '}
          in source campaign
        </div>
        <div className="mt-[0.188rem] ml-2">
          <Tooltip
            content={
              <>
                fern will automatically create the newly harvested target as a
                negative target in the source campaign.
              </>
            }
            position={'left-[-3rem] bottom-[1.375rem]'}
          />
        </div>
      </div>
      {showDropdown && (
        <div className="border-t border-secondary-40 pt-3 mt-4">
          <SimpleMultiDropdown
            label="Select"
            options={matchTypeOptions}
            selectedValues={campaignLink.negateKeywords.matchTypes || []}
            selectValue={(value) => updateMatchTypes(value)}
          />
        </div>
      )}
    </div>
  );
}
