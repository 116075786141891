import { Profile, Brand } from 'shared/legacy-types';

export const getUniqueBrands = (profiles: Profile[]) => {
  const uniqueBrands = new Set();

  return profiles.filter((profile) => {
    const isDuplicate = uniqueBrands.has(profile.accountName);
    uniqueBrands.add(profile.accountName);

    if (isDuplicate) {
      return false;
    }

    return profile;
  });
};

// remove users current profiles from the list of selected profiles
export const getFilteredProfiles = (
  selectedBrands: Brand[],
  usersProfiles: Profile[],
) => {
  return selectedBrands
    .flatMap((brand) => brand.marketplaceIds)
    .filter((profile) => {
      const found = usersProfiles.find(
        (usersProfile) => usersProfile.id === profile,
      );

      return !found;
    });
};
