import { Option } from 'shared/legacy-types';
import escapeStringRegexp from 'escape-string-regexp';

export const isFirstElement = (index: number) => index === 0;
export const isLastElement = (index: number, length: number) =>
  index + 1 === length;

export const filterOptionsBySearchTerm = (
  searchTerm: string,
  options: Option[],
): Option[] => {
  return options.filter((option) => {
    const searchTermForMatching = escapeStringRegexp(
      searchTerm.toLowerCase().replace(/\s/g, ''),
    );
    const optionForMatching = option.label.toLowerCase().replace(/\s/g, '');

    return optionForMatching
      .toLowerCase()
      .includes(searchTermForMatching.toLowerCase());
  });
};
