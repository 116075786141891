interface Props {
  colour?: string;
  size?: string;
}

export function ChevronRightCircle({
  colour = 'stroke-neutral-80',
  size = '24',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-neutral-40"
    >
      <rect width="24" height="24" rx="12" />
      <path
        d="M10 16L14 12L10 8"
        strokeWidth="1.33333"
        strokeLinejoin="round"
        className={`${colour}`}
      />
    </svg>
  );
}
