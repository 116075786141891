import { differenceInCalendarDays } from 'date-fns';

export const nextTwelveMonths = () => {
  return new Array(12).fill(0).map((_, i) => {
    const date = new Date();
    // actual day not required from this function
    // setting to 1 to prevent errors relating to february
    date.setDate(1);
    date.setMonth(date.getMonth() + i);
    return {
      shortDate: date.toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }),
      longDate: date.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      }),
      fullMonth: date.toLocaleString('default', { month: 'long' }),
      numericMonth: date.toLocaleString('default', { month: 'numeric' }),
    };
  });
};

export const daysBetween = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) return null;

  const days = differenceInCalendarDays(endDate, startDate);

  return days;
};

export const TODAY = new Date();
