import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import useFolderRequests from 'api/hooks/requests/useFolderRequests';
import useFoldersSelector from 'state/folders/useFoldersSelector';
import useFoldersDispatch from 'state/folders/useFoldersDispatch';
import { Rule } from 'shared/legacy-types';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';
import {
  removeFolderMessage,
  moveRulesToastMessage,
} from './folderToastMessages';

type RemovingFolder = {
  removingFolder: boolean;
  archiveRules: boolean;
};

type UseFoldersHook = {
  removeFolder: (archiveRules: boolean) => Promise<void>;
  removeFolderModalOpen: boolean;
  setRemoveFolderModalOpen: (open: boolean) => void;
  moveRulesToNewFolder: () => Promise<void>;
  moveRulesModalOpen: boolean;
  moveFolder: string;
  setMoveFolder: (folder: string) => void;
  movingRules: boolean;
  setMovingRules: (moving: boolean) => void;
  removingFolder: RemovingFolder;
  setRemovingFolder: (removing: RemovingFolder) => void;
  rulesToMove: string[];
  setRulesToMove: (rules: string[]) => void;
  setMoveRulesModalOpen: (open: boolean) => void;
};

export default function useFolders(
  rules: Rule[],
  setRules: (rules: Rule[]) => void,
): UseFoldersHook {
  const [removeFolderModalOpen, setRemoveFolderModalOpen] =
    useState<boolean>(false);
  const [moveFolder, setMoveFolder] = useState<string>('');
  const [moveRulesModalOpen, setMoveRulesModalOpen] = useState<boolean>(false);
  const [movingRules, setMovingRules] = useState<boolean>(false);
  const [removingFolder, setRemovingFolder] = useState<RemovingFolder>({
    removingFolder: false,
    archiveRules: false,
  });
  const [rulesToMove, setRulesToMove] = useState<string[]>([]);
  const { removeFolder: removeFolderRequest } = useFolderRequests();
  const { moveRules } = useFolderRequests();
  const { folders } = useFoldersSelector();
  const { updateFolders } = useFoldersDispatch();

  const navigate = useNavigate();
  const { folderId } = useParams();

  const currentFolder = folders.find((folder) => folder.id === folderId);

  async function removeFolder(archiveRules: boolean): Promise<void> {
    if (folderId == null) return;
    setRemovingFolder({ removingFolder: true, archiveRules });

    try {
      await removeFolderRequest(folderId, archiveRules);

      setRemovingFolder({ removingFolder: false, archiveRules: false });

      const folderName = currentFolder ? currentFolder.name : '';
      const link = archiveRules
        ? {
            link: '/archived',
            label: 'Archive',
          }
        : undefined;

      showToast(
        removeFolderMessage(folderName, archiveRules, rules.length),
        ToastStyle.SUCCESS,
        link,
      );

      setRemoveFolderModalOpen(false);
      const filteredFolders = folders.filter(
        (folder) => folder.id !== folderId,
      );
      updateFolders(filteredFolders);
      navigate('/');
    } catch (error) {
      Sentry.captureException(error);
      setRemoveFolderModalOpen(false);
      setRemovingFolder({ removingFolder: false, archiveRules: false });
      navigate('/');
    }
  }

  async function moveRulesToNewFolder(): Promise<void> {
    setMovingRules(true);
    const movedToFolderId = moveFolder === 'home' ? '' : moveFolder;
    const allFolders = [{ id: 'home', name: 'Home' }, ...folders];
    const movedToFolder = allFolders.find(
      (folder) => folder.id === movedToFolderId,
    );
    const movedToFolderName = movedToFolder ? movedToFolder.name : 'Home';
    const folderLink = movedToFolderId === 'home' ? `/` : `/${movedToFolderId}`;

    try {
      await moveRules(movedToFolderId, rulesToMove);
      setMovingRules(false);
      setMoveRulesModalOpen(false);
      showToast(moveRulesToastMessage(rulesToMove.length), ToastStyle.SUCCESS, {
        link: folderLink,
        label: movedToFolderName,
      });

      setMoveFolder('');
      const filteredRules = rules.filter((rule) =>
        rulesToMove.includes(rule.id),
      );
      setRules(filteredRules);
      setRulesToMove([]);
    } catch (error) {
      Sentry.captureException(error);
      setMovingRules(false);
      setMoveRulesModalOpen(false);
      setMoveFolder('');
    }
  }

  return {
    removeFolder,
    removeFolderModalOpen,
    setRemoveFolderModalOpen,
    moveRulesToNewFolder,
    moveRulesModalOpen,
    moveFolder,
    setMoveFolder,
    movingRules,
    setMovingRules,
    removingFolder,
    setRemovingFolder,
    rulesToMove,
    setRulesToMove,
    setMoveRulesModalOpen,
  };
}
