import { ActionTab } from 'shared/legacy-types';
import { AsteriskIcon, SelectedActionTickIcon } from 'components/Icons';

import useActionTabs from './useActionTabs';

interface Props {
  action: ActionTab;
  index: number;
  setSelectedAction: (selected: string) => void;
  selectedAction: string;
  actionsList: ActionTab[];
}

export default function ActionTabComponent({
  action,
  index,
  setSelectedAction,
  selectedAction,
  actionsList,
}: Props) {
  const { label, type, icon: Icon } = action as ActionTab;

  const {
    getColourProps,
    getAsteriskProps,
    actionApplied,
    disableAction,
    isEnabled,
  } = useActionTabs(action);

  const selected = type === selectedAction;
  const isLastSoon = index === actionsList.length - 1;
  const isLast = index === actionsList.length - 1;
  const roundedTop = selected && index === 0;

  // if action is already selected or disabled, do not allow clicks
  if (!!actionApplied || disableAction) {
    return (
      <div
        className={`${selected && 'bg-primary-100 text-white'} ${selected && isLast && 'rounded-b-lg'} ${
          selected && isLastSoon && 'rounded-b-lg'
        } ${roundedTop && 'rounded-t-lg'}
        text-neutral-80 relative flex cursor-pointer px-4 py-3 text-sm font-normal`}
      >
        {!!actionApplied && (
          <div className="absolute left-[0.313rem] top-[0.188rem]">
            <SelectedActionTickIcon />
          </div>
        )}
        {Icon && <Icon {...getColourProps(selected, isEnabled!)} />}
        <span className="ml-3">{label}</span>
      </div>
    );
  }

  // Action tab can be selected
  return (
    <div
      className={`${selected && 'bg-primary-100 text-white'} ${selected && isLast && 'rounded-b-lg'} ${
        selected && isLastSoon && 'rounded-b-lg'
      } ${roundedTop && 'rounded-t-lg'} text-neutral-80 relative flex cursor-pointer px-4 py-3 text-sm font-bold`}
      onClick={() => setSelectedAction(type)}
    >
      {!isEnabled && (
        <div className="absolute left-[0.313rem] top-[0.188rem]">
          <AsteriskIcon {...getAsteriskProps(selected, isEnabled!)} />
        </div>
      )}
      {Icon && <Icon {...getColourProps(selected, isEnabled!)} />}
      <span className="ml-3">{label}</span>
    </div>
  );
}
