import useUserSelector from 'state/user/useUserSelector';
import { TRIAL_DAYS } from 'constants/onboarding';
import { daysLeftInTrial } from '../helpers';
import { TODAY } from 'helpers/dates/dates';

export default function TrialProgress() {
  const { trialEndDate } = useUserSelector();
  const trialDaysLeft = trialEndDate && daysLeftInTrial(trialEndDate, TODAY);
  const daysLeft = trialDaysLeft && (trialDaysLeft as number);

  const percentage =
    daysLeft && Math.floor((daysLeft / TRIAL_DAYS) * 100).toString();
  const width = `${percentage}%`;

  return (
    <div className="bg-secondary-40 mx-[-1rem]">
      <div className={`h-1 bg-primary-100 `} style={{ width }} />
    </div>
  );
}
