export function EditModalArrows() {
  return (
    <svg
      width="137"
      height="167"
      viewBox="0 -41 137 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.1878 91.9266C36.6708 121.699 36.9712 153.461 46.0888 183.093L-45.9853 91.0191C-16.3518 100.14 15.4129 100.443 45.1878 91.9266Z"
          fill="#83B1A9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.3514 46.741C81.8269 76.5208 82.1248 108.293 91.245 137.933L-0.843282 45.8451C28.7978 54.9666 60.5707 55.2652 90.3514 46.741Z"
          fill="#83B1A9"
        />
      </g>
    </svg>
  );
}
