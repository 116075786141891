interface Props {
  colour?: string;
}

export function PlayIcon({ colour = 'fill-neutral-40' }: Props) {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4211 5.58839C11.1228 5.99355 11.1228 7.00645 10.4211 7.41161L1.57895 12.5166C0.877192 12.9218 0 12.4153 0 11.605V1.39501C0 0.584691 0.877193 0.0782445 1.57895 0.483403L10.4211 5.58839Z"
        className={`${colour}`}
      />
    </svg>
  );
}
