import { useState, useEffect } from 'react';
import { CampaignLink } from 'shared/legacy-types';

export default function useCampaignPagination(
  campaignLinks: CampaignLink[],
  brandFilter: string,
) {
  const [disablePrevButton, setDisablePrevButton] = useState<boolean>(false);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const filteredCampaignLinks = campaignLinks;

  useEffect(() => {
    // if more pages available, enable next button
    if (campaignLinks.length > (pageNumber - 1) * 10) {
      setDisableNextButton(false);
    }
    // if page number is greater than 1, enable prev button
    if (pageNumber > 1) {
      setDisablePrevButton(false);
    }
    // if page number is 1, disable prev button
    if (pageNumber === 1) {
      setDisablePrevButton(true);
    }
    // if page number is equal to the number of pages, disable next button
    if (pageNumber === pages) {
      setDisableNextButton(true);
    }
    // eslint-disable-next-line
  }, [pageNumber, campaignLinks.length]);

  useEffect(() => {
    // if brand filter is changed, reset page number to 1
    setPageNumber(1);
    // eslint-disable-next-line
  }, [brandFilter]);

  const pages = Math.ceil(campaignLinks.length / 10);
  const paginatedCampaignLinks = paginatedCampaigns(campaignLinks)[
    pageNumber - 1
  ] as CampaignLink[];

  function paginatedCampaigns(campaigns: CampaignLink[]) {
    const paginatedCampaigns = Array.from({ length: pages }, (_, index) => {
      const start = index * 10;
      return campaigns.slice(start, start + 10);
    });
    return paginatedCampaigns;
  }

  return {
    pageNumber,
    setPageNumber,
    paginatedCampaignLinks,
    disableNextButton,
    disablePrevButton,
    filteredCampaignLinks,
    paginatedCampaigns,
  };
}
