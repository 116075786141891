import { subDays } from 'date-fns';
import { useState, useEffect } from 'react';
import { format, isAfter } from 'date-fns';
import { useParams } from 'react-router-dom';
import useReportingRequests from 'api/hooks/requests/useReportingRequests';

export default function useDates() {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minimumDate, setMinimumDate] = useState<Date>();

  const { profileId } = useParams<{ profileId: string }>();
  const { findProfileMinimumDate } = useReportingRequests();

  const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
  const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

  // if we have stored dates then initialise them
  const storedStartDate = sessionStorage.getItem('reportingStartDate');
  const storedEndDate = sessionStorage.getItem('reportingEndDate');

  useEffect(() => {
    async function getMinDate() {
      if (profileId) {
        const response = await findProfileMinimumDate(profileId);
        let minDate = new Date(response.data.minimumDate);

        minDate.setHours(0, 0, 0, 0);

        setMinimumDate(minDate);
      }
    }
    profileId && getMinDate();
    // eslint-disable-next-line
  }, [profileId]);

  useEffect(() => {
    if (storedStartDate) {
      const storedStart = new Date(storedStartDate);
      setStartDate(storedStart);
    } else {
      setStartDate(subDays(new Date(), 31));
    }

    if (storedEndDate) {
      const endDate = new Date(storedEndDate);
      setEndDate(endDate);
    } else {
      setEndDate(subDays(new Date(), 1));
    }
    // eslint-disable-next-line
  }, []);

  // if minimum date is after start date then set start date to minimum date
  useEffect(() => {
    if (minimumDate && startDate && isAfter(minimumDate, startDate)) {
      setStartDate(minimumDate);
    }
    //eslint-disable-next-line
  }, [minimumDate, startDate]);

  // check if incoming date is after min date
  function updateStartDate(date: Date) {
    if (minimumDate && isAfter(minimumDate, date)) {
      setStartDate(minimumDate);
      const formattedMinimumStartDate =
        minimumDate && format(date, 'yyyy-MM-dd');
      sessionStorage.setItem('reportingStartDate', formattedMinimumStartDate);
      return;
    }
    setStartDate(date);
    const formattedDate = date && format(date, 'yyyy-MM-dd');
    sessionStorage.setItem('reportingStartDate', formattedDate);
  }

  // when selecting start date, end date will be null
  // until an end date is selected
  function updateEndDate(date: Date) {
    setEndDate(date);
    const formattedEndDate = date ? format(date, 'yyyy-MM-dd') : '';
    sessionStorage.setItem('reportingEndDate', formattedEndDate);
  }

  return {
    startDate,
    updateStartDate,
    endDate,
    updateEndDate,
    formattedStartDate,
    formattedEndDate,
    minimumDate,
  };
}
