import * as Sentry from '@sentry/react';
import { AccountType, Brand, RegionCode } from 'codegen/graphql';
import { COUNTRY_CODES } from 'shared/countries';

const SINGLE_MARKETPLACE_BRAND = 1;

export const getRegionName = (regionCode: RegionCode): string => {
  switch (regionCode) {
    case RegionCode.Eu:
      return 'Europe';
    case RegionCode.Fe:
      return 'Far East';
    case RegionCode.Na:
      return 'North America';
    default:
      Sentry.captureMessage(`Region code not found: ${regionCode}`);
      return 'Unknown';
  }
};

export const getAccountTypeName = (accountType: AccountType): string => {
  switch (accountType) {
    case AccountType.Seller:
      return 'Seller';
    case AccountType.Vendor:
      return 'Vendor';
    case AccountType.Agency:
      return 'Agency';
    default:
      Sentry.captureMessage(`Account type not found: ${accountType}`);
      return 'Unknown';
  }
};

export const getCountryName = (countryCode: string): string => {
  if (COUNTRY_CODES[countryCode] == null) {
    Sentry.captureMessage(`Country code not found: ${countryCode}`);
    return countryCode;
  }

  return COUNTRY_CODES[countryCode] ?? countryCode;
};

export function getBrandCardSubtitle(brand: Brand): string {
  const { marketplacesCount, accountType, regionCode, marketplaces } = brand;

  const accountAndRegion = `${getAccountTypeName(accountType)} - ${getRegionName(regionCode)} -`;

  const marketplacesString =
    marketplacesCount != null && marketplacesCount > SINGLE_MARKETPLACE_BRAND
      ? `${marketplacesCount} marketplaces`
      : marketplaces[0]?.countryCode;

  return `${accountAndRegion} ${marketplacesString}`;
}
