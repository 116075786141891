import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Folder } from 'shared/legacy-types';

export interface FoldersState {
  folders: Folder[];
}

const initialState: FoldersState = {
  folders: [],
};

const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    updateFolders(state, action: PayloadAction<Folder[]>) {
      state.folders = action.payload;
    },
  },
});

export const { updateFolders } = foldersSlice.actions;
export default foldersSlice.reducer;
