import { useState, useEffect } from 'react';

import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import { Rule } from 'shared/legacy-types';

export default function useArchived() {
  const [archivedRules, setArchivedRules] = useState<Rule[]>([]);
  const [loadingArchived, setLoadingArchived] = useState<boolean>(true);

  const { getArchivedRules } = useRuleRequests();

  useEffect(() => {
    async function fetchArchivedRules() {
      const response = await getArchivedRules();
      if (response?.status === 200) {
        setArchivedRules(response?.data);
        setLoadingArchived(false);
      }
    }
    fetchArchivedRules();
    // eslint-disable-next-line
  }, []);

  return { archivedRules, loadingArchived };
}
