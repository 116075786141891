import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  deleteVisualisation: () => void;
  deletingVisualisation: boolean;
};

export default function DeleteVisualisationModal({
  open,
  setOpen,
  deleteVisualisation,
  deletingVisualisation,
}: Props): JSX.Element {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6">
        <div className="flex justify-between">
          <h3 className="mb-4 truncate font-bold">Delete Visualisation</h3>
          <div
            className="mt-[0.1875rem] hover:cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="text-neutral-80 mr-4 mt-2 truncate pb-3">
          Are you sure you want to delete this visualisation?
        </div>
        <div className="flex justify-end pt-4">
          <div className="ml-4">
            <PrimaryButton
              disabled={deletingVisualisation}
              onClick={deleteVisualisation}
            >
              {deletingVisualisation ? (
                <Spinner colour="stroke-white" />
              ) : (
                <>Confirm</>
              )}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
