interface Props {
  height?: string;
  rounded?: string;
}

export default function ArrowsDownImage({
  height = '131',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_8869_75179)">
        <rect width="76" height="131" fill="#F7C7FF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.9395 79.5C11.3245 79.5 -11.0605 101.886 -11.0605 129.5C-11.0605 157.114 11.3245 179.5 38.9395 179.5C66.5535 179.5 88.9395 157.114 88.9395 129.5C88.9395 101.886 66.5535 79.5 38.9395 79.5ZM38.9395 155.312C24.7065 155.312 13.1275 143.733 13.1275 129.5C13.1275 115.267 24.7065 103.688 38.9395 103.688C53.1725 103.688 64.7514 115.267 64.7514 129.5C64.7514 143.733 53.1725 155.312 38.9395 155.312Z"
          fill="#182841"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.9399 113.688C30.2079 113.688 23.1279 120.767 23.1279 129.5C23.1279 138.233 30.2079 145.311 38.9399 145.311C47.6729 145.311 54.7519 138.233 54.7519 129.5C54.7519 120.767 47.6729 113.688 38.9399 113.688Z"
          className="fill-tertiary-100"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.9407 29.9595C26.4182 9.19161 9.03218 -7.87343 -11.9816 -19L87.8525 -19C66.8427 -7.87185 49.4605 9.19294 37.9407 29.9595Z"
          fill="#182841"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.9643 78.9071C26.4418 58.1453 9.05873 41.0851 -11.9506 29.9609L87.8896 29.9609C66.8764 41.0836 49.4896 58.1439 37.9643 78.9071Z"
          className="fill-tertiary-100"
        />
      </g>
      <defs>
        <clipPath id="clip0_8869_75179">
          <rect width="76" height="131" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
