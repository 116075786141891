interface Props {
  message: string;
  position?: string;
  backgroundColour?: string;
}

export default function HoverTooltip({
  message,
  position = 'right-[-2rem] bottom-[-2rem]',
  backgroundColour = 'bg-neutral-100',
}: Props) {
  return (
    <span
      className={`absolute h-fit w-max flex-wrap z-50 p-2 rounded  text-white text-xs invisible group-hover:visible ${backgroundColour} ${position}`}
    >
      {message}
    </span>
  );
}
