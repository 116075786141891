import { useState, useRef } from 'react';
import useClickOutside from 'ui/hooks/useClickOutside';
import DropdownOption from './DropdownOption';
import Chevrons from 'ui/components/Dropdowns/Chevrons';
import Label from './Label';

export interface Option {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  options: Option[];
  selectedValues: string[];
  selectValue: (value: string) => void;
  ariaLabel?: string;
  disabled?: boolean;
}

export default function SimpleMultiDropdown({
  label,
  options,
  selectedValues,
  selectValue,
  ariaLabel,
  disabled,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  const dropdownStyles = `relative h-[2.563rem] w-full truncate rounded
  border border-neutral-40 bg-white p-[0.688rem] pr-[1.75rem] font-bold focus:pl-[0.625rem] focus:pr-[1.688rem] focus:pt-[0.625rem]
  ${
    disabled
      ? 'text-neutral-60 focus:border-neutral-40 focus:pl-[0.688rem] focus:pt-[0.688rem] placeholder:text-neutral-60'
      : 'focus:border-primary-100 focus:border-2'
  }`;

  useClickOutside(setOpen, ref, dropdownRef);

  function select(value: string) {
    selectValue(value);
  }

  function renderDropdownMenu() {
    function renderOptions() {
      return options.map((option) => {
        return (
          <DropdownOption
            select={select}
            option={option}
            selectedValues={selectedValues}
          />
        );
      });
    }

    return (
      <div className={`fixed !z-50 mt-1 w-80 rounded-xl bg-white`}>
        <div
          ref={ref}
          aria-label="Dropdown list"
          className={`rounded-xl border px-4 py-6 text-sm border-neutral-20 scrollbar-hide max-h-[11.75rem]  text-left`}
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          {renderOptions()}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`relative text-left text-sm text-neutral-100  ${
          disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
        }`}
        onClick={() => !disabled && setOpen(!open)}
        aria-label={ariaLabel}
      >
        <div tabIndex={0} className={dropdownStyles}>
          <Label
            label={label}
            options={options}
            selectedValues={selectedValues}
          />
        </div>
        <div
          className={`pointer-events-none absolute ${
            open ? 'top-[1.07rem]' : 'top-[1.18rem]'
          }  right-4 flex items-center`}
        >
          <Chevrons
            open={open}
            disabled={disabled}
            openColour="stroke-black"
            closedColour="stroke-neutral-80"
          />
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
