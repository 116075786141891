import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  ReportingProfileData,
  LineData,
  ReportingProfileTotals,
} from 'shared/legacy-types';
import { convertToPascalCase } from 'pages/Reporting/helpers';
import SimpleLegend from './SimpleLegend';
import DashboardLegend from './DashboardLegend';
import useChart from './useChart';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import { getCurrencySymbol } from 'helpers/currency';

interface Props {
  dashboardLegend?: boolean;
  data: ReportingProfileData[];
  loading?: boolean;
  height?: number;
  currencyCode?: string;
  totals: ReportingProfileTotals;
}

export default function Chart({
  dashboardLegend = false,
  data,
  height = 262,
  loading,
  currencyCode,
  totals,
}: Props) {
  const currencySymbol = getCurrencySymbol(currencyCode) || '';
  const {
    chartData,
    selectedMetric,
    setSelectedMetric,
    lineChartData,
    showWeekView,
    hasDate,
  } = useChart(data, currencySymbol);

  function CustomTooltip({ active, payload }: any) {
    if (active && payload && payload.length) {
      // Converts date to a more readable format for the tooltip
      const date = payload[0].payload.date;
      const formattedTooltipDate =
        date &&
        new Date(date).toLocaleDateString('en-GB', {
          weekday: 'short',
          month: 'numeric',
          day: 'numeric',
        });
      const lines = () => {
        return payload.map((item: any) => {
          const metric = convertToPascalCase(item.name);
          return (
            <div key={item.name} className="flex justify-between pb-2 ">
              <div className="flex">
                <div
                  className="h-0.5 w-4 mr-2 mt-2"
                  style={{ background: item.stroke }}
                />
                <div className="text-sm text-white mr-1">{metric}</div>
              </div>
              <div className="text-sm text-white font-bold">
                {item.unit === currencySymbol && currencySymbol}
                {item.value.toFixed(2)}
                {item.unit === '%' && '%'}
              </div>
            </div>
          );
        });
      };
      return (
        <div className="bg-black rounded-lg px-2 pt-2 opacity-80 w-[10.938rem]">
          <div className="text-white text-xs mb-2">
            <>{formattedTooltipDate}</>
          </div>
          {lines()}
        </div>
      );
    }
    return null;
  }

  function renderLegend(props: any) {
    const { payload } = props;
    if (dashboardLegend) {
      return (
        <DashboardLegend
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          payload={payload}
          currencyCode={currencyCode}
          totals={totals}
          hasDate={hasDate}
        />
      );
    }

    return (
      <SimpleLegend
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        payload={payload}
      />
    );
  }

  const colours = [
    '#B0AEFF',
    '#28C585',
    '#FF9D66',
    '#FFC700',
    '#FF5C5C',
    '#FFC700',
    '#FF5C5C',
    '#FFC700',
    '#FF5C5C',
  ];

  function renderLines() {
    const lineData = chartData[0];
    return (
      lineData &&
      lineData.data.map((item: LineData, index: number) => {
        return (
          <Line
            yAxisId={item.axis}
            type="monotone"
            dataKey={item.type}
            stroke={colours[index]}
            dot={false}
            strokeWidth={selectedMetric.includes(item.type) ? 2 : 0}
            unit={item.unit}
            isAnimationActive={chartData.length !== 1}
            key={index}
          />
        );
      })
    );
  }

  return (
    <div className="border border-neutral-40 rounded-lg py-3 relative">
      <div className="absolute mx-auto w-fit top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {loading && <LargeSpinner />}
        {!loading && data.length === 0 && (
          <>
            <div className="mx-auto w-fit font-bold pb-1">
              No data available
            </div>
            <div className="mx-auto w-fit font-sm text-neutral-60">
              Try refreshing if the problem persists
            </div>
          </>
        )}
      </div>
      <div>
        <ResponsiveContainer width="100%" height={height}>
          <LineChart
            data={lineChartData}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
            }}
            height={2}
          >
            <CartesianGrid stroke="lightGrey" vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickFormatter={(date) => showWeekView(date, chartData.length)}
              style={{ fontSize: '0.75rem' }}
              dy={10}
              interval={0}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tickLine={false}
              style={{ fontSize: '0.75rem', marginRight: '0.5rem' }}
              tickFormatter={(value) => `${currencySymbol}${value}`}
            ></YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tickLine={false}
              style={{ fontSize: '0.75rem' }}
              dy={0}
              tickFormatter={(value) => `${value}%`}
            ></YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign={dashboardLegend ? 'top' : 'bottom'}
              content={renderLegend}
              height={60}
            />
            {chartData && chartData.length > 0 && <>{renderLines()}</>}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
