import MultiDropdown from 'components/Dropdowns/MultiDropdown/MultiDropdown';
import { Rule, IsValid, Optional } from 'shared/legacy-types';
import RadioDropdown from 'ui/components/Dropdowns/RadioDropdown';
import { AsteriskCircle, TickCircle } from 'components/Icons';
import { PRODUCT_SIGNALS } from 'ui/constants/rules';
import { Option } from 'components/Dropdowns/MultiDropdown/MultiDropdown';
import ProductSignalsScope from './ProductSignals/ProductSignalsScope';
import Frequency from './Frequency';

interface Props {
  rule: Optional<Rule, 'id'>;
  isValid: IsValid;
  selectDataType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredAdOptions: { label: string; value: string }[];
  selectAdType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  highlightInvalidFields: boolean;
  selectedBrandName: string;
  selectBrand: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deSelectBrand: () => void;
  selectedMarketplaces: () => string[] | null;
  selectMarketplace: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userDataTypes: () => { label: string; value: string }[];
  marketplaceOptions: () => Option[];
  brandOptions: () => Option[];
  onChange: (rule: Optional<Rule, 'id'>) => void;
}

export default function ScopeComponent({
  userDataTypes,
  rule,
  selectDataType,
  filteredAdOptions,
  selectAdType,
  highlightInvalidFields,
  isValid,
  selectedBrandName,
  selectBrand,
  deSelectBrand,
  selectedMarketplaces,
  selectMarketplace,
  marketplaceOptions,
  brandOptions,
  onChange,
}: Props) {
  const isProductSignals = rule.dataType === PRODUCT_SIGNALS;

  return (
    <>
      <div className="border-neutral-40 mb-6 rounded-lg border bg-white p-6">
        <div className="flex">
          <h4 className="mr-1 font-bold">Scope</h4>
          {isValid.scope ? (
            <TickCircle />
          ) : (
            <AsteriskCircle background="fill-neutral-40" />
          )}
        </div>
        <div className="text-neutral-80 mt-1 text-sm">
          Choose what details apply to this rule
        </div>
        <div className="flex flex-row flex-wrap">
          <div className="mr-4 mt-4">
            <RadioDropdown
              label="Data source"
              options={userDataTypes()}
              selectedValue={rule.dataType}
              onChange={(event) => selectDataType(event)}
              width="w-[10rem]"
            />
          </div>
          <div className="mr-4 mt-4">
            <MultiDropdown
              label="Ad types"
              options={filteredAdOptions}
              selectedValues={rule?.adTypes}
              onChange={(event) => selectAdType(event)}
              width="w-[11.875rem]"
              error={
                highlightInvalidFields &&
                rule.adTypes.length === 0 &&
                rule.dataType !== PRODUCT_SIGNALS
              }
              disabled={isProductSignals}
            />
          </div>
          {!isProductSignals && (
            <>
              <div className="mr-4 mt-4">
                <RadioDropdown
                  label="Brand"
                  options={brandOptions()}
                  selectedValue={selectedBrandName}
                  onChange={(event) => selectBrand(event)}
                  deSelect={() => deSelectBrand()}
                  width="w-[10rem]"
                  error={highlightInvalidFields && selectedBrandName === ''}
                />
              </div>
              <div className="mr-4 mt-4">
                <MultiDropdown
                  label="Marketplaces"
                  options={marketplaceOptions()}
                  selectedValues={selectedMarketplaces()!}
                  onChange={(event) => selectMarketplace(event)}
                  width="w-[10rem]"
                  disabled={!selectedBrandName}
                  error={highlightInvalidFields && rule.profileIds.length === 0}
                />
              </div>
            </>
          )}
          {isProductSignals && (
            <ProductSignalsScope
              rule={rule}
              onChange={onChange}
              highlightInvalidFields={highlightInvalidFields}
            />
          )}
        </div>
        <Frequency isProductSignals={isProductSignals} />
      </div>
    </>
  );
}
