import { useAppDispatch } from 'state/hooks';
import { Folder } from 'shared/legacy-types';
import { updateFolders } from './FoldersSlice';

export default function useFoldersDispatch() {
  const dispatch = useAppDispatch();

  return {
    updateFolders: (folders: Folder[]) => dispatch(updateFolders(folders)),
  };
}
