import { useRef, useState, ReactElement } from 'react';

type Props = {
  value: boolean | number | string | string[] | null | undefined;
  style?: string;
  children: ReactElement;
  showTooltip?: boolean;
  extended?: boolean;
};

const TOOLTIP_DURATION = 500;

export default function TableCell({
  value,
  style,
  children,
  showTooltip = true,
}: Props): JSX.Element {
  const [tipOpen, setTipOpen] = useState<boolean>(false);
  const cellRef = useRef(null);

  function toolTipOpen(): void {
    if (!tipOpen) {
      setTipOpen(true);
    }
  }

  function toolTipTimer(): NodeJS.Timeout {
    return setTimeout(toolTipOpen, TOOLTIP_DURATION);
  }

  function toolTipClose(): void {
    if (tipOpen) {
      setTipOpen(false);
    }
  }
  return (
    <div
      ref={cellRef}
      onMouseEnter={() => {
        toolTipTimer();
      }}
      onMouseOut={() => {
        toolTipClose();
      }}
      className={`border-b border-neutral-40 group/item z-20 table-cell align-middle p-2 truncate max-w-[12.5rem] ${style} `}
    >
      {tipOpen && showTooltip && (
        <span
          className={`w-content group/edit absolute hidden h-[1.875rem] rounded bg-neutral-100 p-2 text-xs text-white group-hover/item:block group-hover/item:translate-y-[-2.415rem] 
          `}
        >
          {value}
        </span>
      )}
      {children}
    </div>
  );
}
