import { getCurrencySymbol } from 'helpers/currency';
import { CampaignLink, FixedBid, MetricBid } from 'shared/legacy-types';

interface Props {
  campaign: CampaignLink;
  currency: string;
  showActions: boolean;
}

export default function Bid({ campaign, currency, showActions }: Props) {
  function formatBid(bid: FixedBid) {
    const formattedBid = parseFloat(bid.fixedBid).toFixed(2);
    return formattedBid;
  }

  const bidDisplay = () => {
    const isfixedBid = campaign.bid.hasOwnProperty('fixedBid');
    const fixedBid = campaign.bid as FixedBid;
    const metricBid = campaign.bid as MetricBid;

    if (isfixedBid) {
      return (
        <div className="text-sm w-[3.25rem]">
          {getCurrencySymbol(currency)}
          {formatBid(fixedBid)}
        </div>
      );
    }

    return (
      <div className="text-sm w-[3.25rem] flex">
        CPC{metricBid.multiplier && <> x {metricBid.multiplier}</>}
      </div>
    );
  };

  if (showActions) {
    return null;
  }

  return <>{bidDisplay()}</>;
}
