import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { PlusIcon } from 'components/Icons';

interface Props {
  openModal: (open: boolean) => void;
}

export default function AddTargetButton({ openModal }: Props) {
  return (
    <PrimaryButton onClick={() => openModal(true)}>
      <div className="flex">
        Add target
        <span className="ml-1">
          <PlusIcon fill="fill-white" />
        </span>
      </div>
    </PrimaryButton>
  );
}
