import useUserSelector from 'state/user/useUserSelector';
import { Permissions } from 'shared/legacy-types';

export const userHasPermission = (
  scopes: string[],
  type: string,
  userPermissions: Permissions | undefined | null,
) => {
  if (!userPermissions) return false;

  const userPermissionsType =
    userPermissions[type as keyof typeof userPermissions];

  return userPermissionsType.some((permission) =>
    scopes.find((scope) => scope === permission),
  );
};

export default function usePermissions() {
  const { permissions } = useUserSelector();

  const hasPermission = (scopes: string[], type: string) => {
    return userHasPermission(scopes, type, permissions);
  };

  return { hasPermission };
}
