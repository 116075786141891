import { Plan } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';

export default function useSubscription() {
  const { usedFreeTrials } = useUserSelector();

  const hasUsedFreeTrial = (plan: Plan) => {
    if (!usedFreeTrials) return false;

    return usedFreeTrials.includes(plan);
  };

  return { hasUsedFreeTrial };
}
