import { ReactComponent as ReportingImage } from 'images/reporting.svg';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { AppContext } from 'App';
import { useContext } from 'react';

export default function ReportingSetup() {
  const { openChangePlanModal } = useContext(AppContext);

  return (
    <div className="mt-[15rem] flex justify-center text-center">
      <div>
        <ReportingImage />
        <div className="w-[34.6rem] mt-4">
          <div className="text-[2rem] leading-[2.375rem]">
            Introducing{' '}
            <span className="text-primary-100 font-bold">Reporting</span>
          </div>
          <div className="text-neutral-80 pt-3">
            All your data readily available in 1 location.
          </div>
          <div className="mt-6">
            <PrimaryButton onClick={() => openChangePlanModal(true)}>
              <div className="flex">Upgrade plan to start using</div>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
