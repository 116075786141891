import { CampaignLink, Bid } from 'shared/legacy-types';
import useUser from 'hooks/useUser';
import useCampaignLinksRequests from 'api/hooks/requests/useCampaignLinksRequests';
import { formatBid } from 'pages/CampaignMapping/helpers/campaignLinks';
import useCampaignLinkModals from 'pages/CampaignMapping/hooks/useCampaignLinkModals';
import { allFieldsRequiredForValidationCheckMatch } from 'pages/CampaignMapping/helpers/campaignLinks';
import useCampaignLinkValidation from 'pages/CampaignMapping/hooks/useCampaignLinkValidation';

export default function useEditCampaignLink(
  campaignLinks: CampaignLink[],
  setCampaignLinks: (campaigns: CampaignLink[]) => void,
  setEditModalOpen: (open: boolean) => void,
  campaignLinkToEdit: CampaignLink,
) {
  const { updateCampaignLinkRequest } = useCampaignLinksRequests();
  const { getProfileData } = useUser();

  const initialBrand =
    getProfileData(parseInt(campaignLinkToEdit.profileId))?.accountName || '';

  const {
    updateCampaignLinkForm,
    brand,
    setBrand,
    submittingCampaignLink,
    setSubmittingCampaignLink,
    campaignLink,
    setCampaignLink,
  } = useCampaignLinkModals(campaignLinkToEdit, initialBrand);

  const { isDuplicateCampaignLink, isDuplicateCampaignLinkValidationRunning } =
    useCampaignLinkValidation(
      campaignLink,
      !allFieldsRequiredForValidationCheckMatch(
        campaignLink,
        campaignLinkToEdit,
      ),
    );

  function editLinkBid(bid: Bid) {
    setCampaignLink({ ...campaignLinkToEdit, bid: bid });
  }

  async function submitCampaignLink() {
    setSubmittingCampaignLink(true);
    campaignLink.bid = formatBid(campaignLink.bid) as Bid;
    campaignLink.profileId = campaignLink.profileId.toString();
    await updateCampaignLinkRequest(campaignLink);
    const updatedCampaigns = campaignLinks.map((currentCampaignLink) => {
      if (campaignLink && currentCampaignLink.id === campaignLink.id) {
        return campaignLink;
      }
      return currentCampaignLink;
    });
    setCampaignLinks(updatedCampaigns);
    setEditModalOpen(false);
    setSubmittingCampaignLink(false);
  }

  return {
    submittingCampaignLink,
    brand,
    setBrand,
    editLinkBid,
    updateCampaignLinkForm,
    submitCampaignLink,
    campaignLink,
    setCampaignLink,
    isDuplicateCampaignLink,
    isDuplicateCampaignLinkValidationRunning,
  };
}
