import { Radio } from 'components/Radio/Radio';
import Flags from 'pages/Onboarding/Region/Flags';

interface Props {
  region: string;
  id: string;
  selectRegion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  select?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
}

export default function RegionCard({
  region,
  id,
  selectRegion,
  selected,
  select,
}: Props) {
  return (
    <div>
      <label>
        <div
          className={`rounded-md drop-shadow-sm border border-neutral-40 w-[37rem] m-auto p-6 pb-2 text-left mb-3 hover:cursor-pointer ${
            selected && 'border-primary-100 border'
          }`}
          style={{
            boxShadow: '0rem 0.5rem 1rem 0.125rem rgba(243, 243, 243, 0.1)',
          }}
          onChange={select}
        >
          <div className="flex justify-between">
            <h4 className="mb-4">{region}</h4>
            <span>
              <Radio id={id} select={selectRegion} checked={selected} />
            </span>
          </div>
          <div className="text-xs flex">
            <Flags id={id} />
          </div>
        </div>
      </label>
    </div>
  );
}
