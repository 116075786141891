interface Props {
  open: boolean;
}

export default function Chevrons({ open }: Props) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!open && (
        <path
          d="M1 0.5L5 4.5L9 0.5"
          className="stroke-neutral-80"
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      )}
      {open && (
        <path
          d="M1 5.5L5 1.5L9 5.5"
          className={`${open ? 'stroke-black' : 'stroke-neutral-80'}`}
          strokeWidth="1.33333"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
