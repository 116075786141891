import { useRef } from 'react';
import { Result, KeywordApproval } from 'shared/legacy-types';
import { Column } from '../../../constants/runs';
import { HARVEST_KEYWORDS, SEND_EMAIL } from '../../../constants/actions';
import TableHeaders from '../Components/TableHeaders';
import TableRows from '../Components/TableRows';
import LoadMore from '../Components/LoadMore';

interface Props {
  results: Result[] | undefined;
  preset: Column[];
  extended: boolean;
  loadMoreResults?: () => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  noResultsFound?: boolean;
  selectedAction?: string;
  selectResult?: (keywordApproval: Omit<KeywordApproval, 'status'>) => void;
  selectedResults?: Partial<KeywordApproval>[];
  keywordApprovals?: KeywordApproval[];
}

export default function Table({
  results,
  preset,
  extended,
  loadMoreResults,
  loadingMoreResults,
  enablePagination,
  noResultsFound,
  selectedAction,
  selectResult,
  selectedResults,
  keywordApprovals,
}: Props) {
  const tableRef = useRef(null);
  const isEmailResults = selectedAction === SEND_EMAIL;
  const isKeywordHarvesting = selectedAction === HARVEST_KEYWORDS;

  return (
    <>
      <div
        ref={tableRef}
        className={`relative max-h-[15.625rem] overflow-auto`}
      >
        {results && results.length === 0 && (
          <div className="text-neutral-80 text-center text-sm mt-4">
            No results were found
          </div>
        )}
        <div>
          {results && results.length > 0 && (
            <>
              <div className={`table w-full relative  'max-h-[15.625rem]`}>
                <TableHeaders
                  preset={preset}
                  enableKeywordApproval={true}
                  isEmailResults={isEmailResults}
                  isKeywordHarvesting={isKeywordHarvesting}
                  extended={extended}
                />

                <TableRows
                  results={results}
                  preset={preset}
                  isEmailResults={isEmailResults}
                  isKeywordHarvesting={isKeywordHarvesting}
                  selectedResults={selectedResults}
                  enableKeywordApproval={true}
                  selectResult={selectResult}
                  keywordApprovals={keywordApprovals}
                />
              </div>
              <div className="mt-3 flex justify-center">
                <LoadMore
                  ref={tableRef}
                  results={results}
                  loadMoreResults={loadMoreResults}
                  loadingMoreResults={loadingMoreResults}
                  enablePagination={enablePagination}
                  noResultsFound={noResultsFound}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
