import {
  ConditionGroupOperator,
  ConditionOperator,
  RuleStatus,
  DataType,
  HarvestKeywordsAction,
} from 'shared/legacy-types';
import { HARVEST_KEYWORDS } from 'ui/constants/actions';
import { LONG_PERIOD, SEARCH_TERMS } from 'ui/constants/rules';
import KeyInCircleImage from 'components/Images/Templates/KeyArrowDownImage';

export const harvestKeywordsForCampaign = {
  id: 'harvest_keywords_for_campaign',
  description:
    'Add new converting customer search terms as keywords to your manual campaigns and target new converting ASINs coming from a particular campaign. Use Campaign Mapping to specify Source and Destination Campaigns to harvest keywords and ASINs.',
  goal: 'Keyword Harvesting',
  category: 'Advertising',
  featureToggle: false,
  comingSoon: false,
  image: KeyInCircleImage,
  rule: {
    name: 'Harvest KWs and ASINs for a specific campaign',
    adTypes: ['SP'],
    dataType: SEARCH_TERMS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'Converting STs & ASINs',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: HARVEST_KEYWORDS,
            properties: {
              sendEmail: false,
            },
          } as HarvestKeywordsAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${SEARCH_TERMS}:ad_orders`,
                operator: '>=' as ConditionOperator,
                value: '1',
              },
              {
                field: `${SEARCH_TERMS}:campaign_name`,
                operator: 'contains' as ConditionOperator,
                value: '',
              },
            ],
          },
        ],
      },
    ],
  },
};
