import useRequest from '../useRequest';

export default function useSlackRequests() {
  const { request } = useRequest();

  const fetchSlackWorkspace = async () => {
    return await request('get', `/v1/slack/workspace`, {});
  };

  const fetchSlackChannels = async () => {
    return await request('get', `/v1/slack/channels`, {});
  };

  return {
    fetchSlackWorkspace,
    fetchSlackChannels,
  };
}
