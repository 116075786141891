interface Props {
  background?: string;
  size?: string;
}

export function PlaySquareIcon({ background, size = '16' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM11.6635 8.58373C12.1122 8.32467 12.1122 7.677 11.6635 7.41793L6.00962 4.15368C5.5609 3.89461 5 4.21845 5 4.73658V11.2651C5 11.7832 5.5609 12.107 6.00962 11.848L11.6635 8.58373Z"
        className={background}
      />
    </svg>
  );
}
