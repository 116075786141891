import { Result, RunStep } from 'shared/legacy-types';

export const RESULTS_PAGE_ONE = 10;
export const RESULTS_PAGE_TWO = 40;
export const RESULTS_PAGE_THREE = 70;
export const RESULTS_PAGE_FOUR = 100;

export const addToResults = (
  stepId: number,
  runSteps: RunStep[],
  results: Result[],
) => {
  return runSteps.map((runStep: RunStep) => {
    if (stepId === runStep.stepId) {
      const newResults = runStep.results
        ? [...runStep.results, ...results]
        : results;
      return { ...runStep, results: newResults };
    }
    return runStep;
  });
};

export const getResultsPage = (resultsCount: number) => {
  if (resultsCount === RESULTS_PAGE_ONE) {
    return 2;
  }
  if (resultsCount === RESULTS_PAGE_TWO) {
    return 3;
  }
  if (resultsCount === RESULTS_PAGE_THREE) {
    return 4;
  }

  throw Error(`No handler for results count: ${resultsCount}`);
};

export const showLoadMoreButton = (resultsCount: number) => {
  if (
    resultsCount === RESULTS_PAGE_ONE ||
    resultsCount === RESULTS_PAGE_TWO ||
    resultsCount === RESULTS_PAGE_THREE
  ) {
    return true;
  }
  return false;
};
