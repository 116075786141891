import { ChangeEventHandler, useState, useRef, ChangeEvent } from 'react';

import { Radio } from '../Radio/Radio';
import useClickOutside from '../../hooks/useClickOutside';

interface Option {
  label: string;
  value: string;
}

interface Props {
  label: string;
  options: Option[];
  selectedValue: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  deSelect?: () => void;
  width?: string;
}

export default function MinimalRadioDropdown({
  label,
  options,
  selectedValue,
  onChange,
  deSelect,
  width = 'w-[8.188rem]',
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  function selectRadio(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setOpen(false);
    onChange(event);
  }

  function deSelectRadio() {
    setOpen(false);
    deSelect && deSelect();
  }

  function renderDropdownMenu() {
    function renderOptions() {
      return options.map((option) => {
        const { label, value } = option;
        const selected = value === selectedValue;

        return (
          <li key={value}>
            <Radio
              id={value}
              select={(event) => selectRadio(event)}
              checked={selected}
              unCheck={() => deSelectRadio()}
              selected={selected}
              label={label}
            />
          </li>
        );
      });
    }
    return (
      <div
        ref={ref}
        className="border-light-border absolute !z-50 mt-2 w-80 rounded-xl border bg-white max-h-[16rem] overflow-y-auto scrollbar-hide"
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
      >
        <ul className="data-source-dropdown  space-y-4 p-6 text-sm">
          {renderOptions()}
        </ul>
      </div>
    );
  }

  function getLabel() {
    const selected = options?.find((option) => option.value === selectedValue);

    if (selectedValue) {
      return (
        <div
          className={`text-xs truncate font-medium !leading-[2rem] ${open ? 'pl-3 pt-px' : 'pl-[0.688rem]'}`}
        >
          {selected?.label}
        </div>
      );
    }

    return (
      <div
        className={`text-xs truncate font-medium !leading-[2rem] ${open ? 'pl-3 pt-px' : 'pl-[0.688rem]'}`}
      >
        {label}
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className="relative inline-block text-xs text-black hover:cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div
          className={`border-neutral-40  block h-[2rem] appearance-none rounded-lg border pr-[1.75rem] ${width} ${
            open && 'bg-secondary-100 border-0 text-white'
          } ${selectedValue && !open && 'bg-primary-40 border-primary-60'}`}
        >
          <div>{getLabel()}</div>
        </div>
        <div
          className={`pointer-events-none absolute right-4 flex items-center ${
            open ? 'top-[0.813rem]' : 'top-[0.938rem]'
          }`}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {!open && (
              <path
                d="M1 0.5L5 4.5L9 0.5"
                stroke="black"
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            )}
            {open && (
              <path
                d="M1 5.5L5 1.5L9 5.5"
                stroke={open ? 'white' : 'black'}
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            )}
          </svg>
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
