import { RunStep, DataType, User } from 'shared/legacy-types';
import StepSummary from '../StepSummary/StepSummary';
import Results from '../Results/Results';

interface Props {
  step: RunStep;
  dataType: DataType;
  dataLoaded: boolean;
  index: number;
  loading: boolean;
  allResultsLoading?: boolean;
  loadMoreResults?: (stepId: number | undefined) => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  actionTimestamp?: string;
  isLive?: boolean;
  user: User | null;
  isPreview?: boolean;
}
export default function StepResults({
  step,
  dataType,
  dataLoaded,
  index,
  loading,
  loadMoreResults,
  loadingMoreResults,
  enablePagination,
  actionTimestamp,
  isLive,
  user,
  isPreview,
}: Props) {
  return (
    <div
      key={index}
      className="border-neutral-40 mt-6 rounded-lg border bg-white pb-6"
    >
      <div className="bg-secondary-20 flex justify-between rounded-t-lg px-6 py-4">
        <h4 className="font-bold">{step.name || `Step ${index + 1}`}</h4>
      </div>
      <div className="mt-6 px-6">
        {user && (
          <StepSummary step={step} userEmail={user.email} dataType={dataType} />
        )}
      </div>
      <Results
        dataLoaded={dataLoaded}
        dataType={dataType}
        step={step}
        loading={loading}
        loadingMoreResults={loadingMoreResults}
        loadMoreResults={loadMoreResults}
        enablePagination={enablePagination}
        actionTimestamp={actionTimestamp}
        isLive={isLive}
        isPreview={isPreview}
      />
    </div>
  );
}
