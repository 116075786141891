import {
  removeNonNumericChars,
  getCurrencySymbol,
  setCursor,
  handleKeyDown,
} from 'helpers/currency';

interface Props {
  value: string;
  onChange: (value: string) => void;
  currencyCode?: string;
  styles?: string;
  ariaLabel?: string;
}

export default function CurrencyInputWithDecimal({
  value,
  onChange,
  currencyCode,
  styles,
  ariaLabel,
}: Props) {
  function maxBidDecimalPlaces(value: string) {
    const decimalIndex = value ? value.indexOf('.') : -1;
    if (decimalIndex === -1) {
      return value;
    }

    return value.substring(0, decimalIndex + 3);
  }
  const bidCurrencySymbol = getCurrencySymbol(currencyCode);
  const formattedBid =
    currencyCode && bidCurrencySymbol + maxBidDecimalPlaces(value);

  function formatInputValue(value: string) {
    const updatedValue = removeNonNumericChars(value);
    onChange(updatedValue);
  }

  return (
    <input
      className={`${styles} border-neutral-40 focus:border-primary-80 focus:border-1 text- my-0 h-[2.563rem] w-full rounded p-3 text-sm font-bold focus:outline-none`}
      onChange={(event) => formatInputValue(event.target.value.toString())}
      onFocus={(event) =>
        currencyCode &&
        formattedBid &&
        setCursor(event, currencyCode, formattedBid)
      }
      onKeyDown={(event) =>
        currencyCode &&
        handleKeyDown(event, currencyCode, maxBidDecimalPlaces(value))
      }
      value={formattedBid}
      type="text"
      aria-label={ariaLabel}
    />
  );
}
