import {
  ConditionGroupOperator,
  ConditionOperator,
  UpdateBidAction,
  RuleStatus,
  ExpressionMetric,
  ExpressionOperator,
  DataType,
} from 'shared/legacy-types';
import { CURRENT_BID, UPDATE_BID } from 'ui/constants/actions';
import { TARGETS, PRODUCTS, LONG_PERIOD } from 'ui/constants/rules';
import ArrowAndCircleImage from 'components/Images/Templates/ArrowAndCircleImage';

export const optimiseBidsForTacos = {
  id: 'optimise_bids_for_tacos',
  description:
    'A new dynamic target ACOS will be calculated based on the PPC Share. Bids will be optimised towards this new dynamic target ACOS to optimise TACOS.',
  goal: 'Optimise TACOS',
  category: 'Advertising',
  featureToggle: true,
  comingSoon: true,
  image: ArrowAndCircleImage,
  rule: {
    name: 'Optimise target bids based on TACOS',
    adTypes: ['SP', 'SD', 'SB'],
    dataType: TARGETS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'Underspender',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: CURRENT_BID,
              value: 10,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:tacos`,
                operator: '<' as ConditionOperator,
                value: '10',
              },
              {
                field: `${TARGETS}:acos`,
                operator: '<' as ConditionOperator,
                value: `${PRODUCTS}:dynamic_acos`,
              },
            ],
          },
        ],
      },
      {
        name: 'Overspender',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: CURRENT_BID,
              value: -20,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:tacos`,
                operator: '>' as ConditionOperator,
                value: '10',
              },
              {
                field: `${TARGETS}:acos`,
                operator: '>' as ConditionOperator,
                value: `${PRODUCTS}:dynamic_acos`,
              },
            ],
          },
        ],
      },
      {
        name: 'Non-Converting Targets',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: 'cpc',
              value: -40,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:tacos`,
                operator: '>' as ConditionOperator,
                value: '10',
              },
              {
                field: `${TARGETS}:ad_orders`,
                operator: '=' as ConditionOperator,
                value: '0',
              },
              {
                field: `${TARGETS}:clicks`,
                operator: '>' as ConditionOperator,
                value: {
                  metric: 'profileCrClicks' as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '2',
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
