import { AxiosResponse } from 'axios';
import useRequest from 'api/hooks/useRequest';

type RunRequestsHook = {
  getRun: (userId: string, runId: string) => Promise<AxiosResponse>;
  getStepResults: (
    stepId: number,
    ruleId: string | undefined,
    page: number,
  ) => Promise<AxiosResponse>;
  getRunStepResults: (id: string, stepId: number) => Promise<AxiosResponse>;
};

export default function useRunRequests(): RunRequestsHook {
  const { request } = useRequest();

  const getRun = async (id: string | undefined): Promise<AxiosResponse> => {
    const response = await request('get', `/v1/runs/${id}`, {});
    return response;
  };

  const getStepResults = async (
    stepId: number,
    ruleId: string | undefined,
    page: number,
  ): Promise<AxiosResponse> => {
    const response = await request(
      'get',
      `/v1/rules/${ruleId}/steps/${stepId}/preview?page=${page}`,
      {},
    );
    return response;
  };

  const getRunStepResults = async (
    id: string,
    stepId: number,
  ): Promise<AxiosResponse> => {
    const response = await request('get', `/v1/runs/${id}/steps/${stepId}`, {});
    return response;
  };

  return {
    getRun,
    getStepResults,
    getRunStepResults,
  };
}
