export function RoundedSquareIcon() {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.671875" y="0.5" width="7" height="7" rx="2" fill="#28C585" />
    </svg>
  );
}
