import { EditIcon, TrashIcon } from 'components/Icons';

interface Props {
  brand: string;
  countryCode: string;
  country: string;
  openDeleteModal: () => void;
  isLive: boolean;
  openEditModal: () => void;
}

export default function SpendTarget({
  brand,
  countryCode,
  country,
  openDeleteModal,
  isLive,
  openEditModal,
}: Props) {
  const infoBadgeStyles =
    'mr-1 border border-neutral-40 rounded py-[0.188rem]  px-[0.438rem] h-[1.375rem] text-xs font-bold flex flex-col self-center mt-[0.313rem]   hover:cursor-pointer';

  return (
    <div className="border-neutral-40 mb-2 flex justify-between rounded border px-4 py-3 leading-8">
      <div className="flex">
        <div className="bg-primary-60 text-secondary-80 mr-4 h-8 w-8 rounded-full text-center font-bold">
          {brand && brand.charAt(0).toUpperCase()}
        </div>
        <span className="mr-1 font-bold text-neutral-100">{brand}</span>
        <div className="group relative">
          <div className="invisible absolute bottom-[2rem] left-[-1.5rem] z-50 h-fit w-fit flex-wrap rounded bg-neutral-100 p-2 text-xs text-white group-hover:visible">
            {country}
          </div>
          {countryCode && (
            <div className={`${infoBadgeStyles}`}>{countryCode}</div>
          )}
        </div>
      </div>
      <div className="flex">
        <div
          className="ml-6 mt-[0.438rem] hover:cursor-pointer"
          onClick={() => openEditModal()}
          aria-label={`Edit ${brand} ${country} spend target`}
        >
          <EditIcon />
        </div>
        <div
          className={`group relative ml-[1.75rem] mr-2 mt-[0.438rem] hover:cursor-pointer`}
          onClick={() => !isLive && openDeleteModal()}
          aria-label={`Delete ${brand} ${country} spend target`}
        >
          {isLive && (
            <span className="invisible absolute bottom-[2rem] right-0 z-50 h-fit w-[19.375rem] flex-wrap rounded bg-neutral-100 p-2 text-xs text-white group-hover:visible">
              Live rules are using this spend target. Turn them off to delete
              it.
            </span>
          )}
          <TrashIcon
            stroke={isLive ? 'stroke-neutral-40' : 'stroke-neutral-80'}
          />
        </div>
      </div>
    </div>
  );
}
