import { formatLabel } from '../helpers/campaignLinks';
import { CampaignLink } from 'shared/legacy-types';

interface Props {
  campaign: CampaignLink;
}

export default function MatchTypes({ campaign }: Props) {
  const formattedMatchTypes =
    campaign.matchTypes &&
    campaign.matchTypes.map((matchType) => {
      return <div>{formatLabel(matchType)}</div>;
    });

  return (
    <div
      className={`${
        campaign.matchTypes && 'border'
      } border-neutral-40 rounded py-[0.188rem] px-[0.438rem] text-xs w-fit font-bold self-center`}
    >
      {formattedMatchTypes}
    </div>
  );
}
