import { LegacyRef, forwardRef } from 'react';

import { SquareIcon, FilledRightChevron } from 'components/Icons';
import AdGroupDropdownMenu from './AdGroupDropdownMenu';
import { AdGroup, SearchTermCampaign } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';

interface Props {
  ariaLabel?: string;
  adGroups: AdGroup[];
  adGroupSelections: AdGroup[] | null;
  setAdGroupSelection: (value: AdGroup[] | null) => void;
  campaigns: SearchTermCampaign[];
  selectedCampaign: SearchTermCampaign;
  setSelectedCampaign: (value: SearchTermCampaign) => void;
  position?: string;
  searchTerm?: string;
  loadingCampaigns: boolean;
  adGroupsLoading: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  setSearchTerm: (value: string) => void;
}

const DropdownMenu = forwardRef(function DropdownMenu(
  {
    ariaLabel,
    adGroups,
    adGroupSelections,
    setAdGroupSelection,
    campaigns,
    selectedCampaign,
    setSelectedCampaign,
    position,
    searchTerm,
    loadingCampaigns,
    adGroupsLoading,
    open,
    setOpen,
    setSearchTerm,
  }: Props,
  ref: LegacyRef<HTMLDivElement> | undefined,
) {
  function selectCampaign(campaign: SearchTermCampaign) {
    setSelectedCampaign(campaign);
  }

  function renderOptions() {
    return campaigns.map((option) => {
      const { campaignName, campaignId } = option;
      const selected =
        campaigns.length === 1 ||
        campaignName === selectedCampaign.campaignName;

      function highlightedCampaignName() {
        // make searched text bold
        if (campaignName.indexOf(searchTerm!) >= 0) {
          const updated = campaignName.replace(
            searchTerm!,
            '<strong>' + searchTerm + '</strong>',
          );

          return (
            <div
              dangerouslySetInnerHTML={{ __html: updated }}
              className="w-[21rem] truncate"
            />
          );
        }

        return campaignName;
      }

      return (
        <div key={campaignId} className="max-w-[26rem]" aria-label={ariaLabel}>
          <div
            className={`flex justify-between rounded p-3 py-4 hover:cursor-pointer ${
              selected ? 'bg-neutral-20' : 'bg-white'
            }`}
            onClick={() => selectCampaign(option)}
          >
            <div className="flex">
              <div className="mt-[0.313rem]">
                <SquareIcon />
              </div>
              <div className="ml-3 ">{highlightedCampaignName()}</div>
            </div>
            <div className="ml-2 my-auto">
              <FilledRightChevron />
            </div>
          </div>
          {option.campaignId === selectedCampaign.campaignId && (
            <AdGroupDropdownMenu
              adGroups={adGroups}
              adGroupSelections={adGroupSelections}
              setAdGroupSelections={setAdGroupSelection}
              position={position}
              adGroupsLoading={adGroupsLoading}
              open={open}
              setOpen={setOpen}
              setSearchTerm={setSearchTerm}
            />
          )}
        </div>
      );
    });
  }
  return (
    <div
      className={`fixed w-[27rem] !z-50 mt-1 rounded-lg bg-white ${position} 
       `}
    >
      <div
        ref={ref}
        className={`rounded-xl border p-4 text-sm border-neutral-20 scrollbar-hide max-h-[14.75rem] overflow-scroll text-left`}
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        aria-label="Campaigns dropdown list"
      >
        {loadingCampaigns && (
          <div className="w-8 m-auto pt-2 pb-2">
            <Spinner />
          </div>
        )}
        {!loadingCampaigns && (
          <>
            {campaigns.length > 0 && (
              <div className="text-xs font-bold text-neutral-80 pb-3">
                Campaigns
              </div>
            )}
            {campaigns.length === 0 && (
              <div className="text-xs font-bold text-neutral-80 pt-4 pb-3 text-center">
                No campaigns found
              </div>
            )}
            {renderOptions()}
          </>
        )}
      </div>
    </div>
  );
});

export default DropdownMenu;
