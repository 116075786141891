import useUserSelector from 'state/user/useUserSelector';
import { isTrialing } from '../helpers';
import { TODAY } from 'helpers/dates/dates';

interface Props {
  colour?: string;
}
export default function TrialBadge({ colour = 'text-secondary-100' }: Props) {
  const { planLabel, trialEndDate } = useUserSelector();
  const trialing = trialEndDate && isTrialing(trialEndDate, TODAY);

  return (
    <div className="w-fit flex !leading-4 h-[1.125rem] text-xs border border-secondary-100 rounded">
      <div className={` bg-secondary-100 font-medium text-white px-1 `}>
        {planLabel}
      </div>
      {trialing && (
        <div className={`${colour} italic pl-[0.128rem] pr-[0.275rem]`}>
          Trial
        </div>
      )}
    </div>
  );
}
