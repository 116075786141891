import { Clock } from 'components/Icons';

interface Props {
  isProductSignals: boolean;
}
export default function Frequency({ isProductSignals }: Props) {
  const frequencyMessage = () => {
    if (isProductSignals) {
      return <span className="font-bold">Instantly</span>;
    }

    return (
      <>
        <span className="font-bold">Daily</span> at{' '}
        <span className="font-bold">8:00am GMT</span>
      </>
    );
  };

  return (
    <div className="border-t border-neutral-40 pt-4 mt-4 text-sm flex">
      <Clock />
      <span className="ml-1">This rule will run {frequencyMessage()}</span>
    </div>
  );
}
