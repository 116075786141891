interface Props {
  background?: string;
  iconColour?: string;
  strokeColour?: string;
  size?: string;
}

export function AsteriskIcon({
  background = 'fill-neutral-80',
  iconColour = 'fill-white',
  strokeColour = 'stroke-white',
  size = '16',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        className={`${background} ${strokeColour}`}
        strokeWidth="2"
      />
      <path
        d="M7.47155 11.001L7.67331 8.53946L5.58846 9.93916L5 8.95776L7.33704 7.97637L5.01681 7.01106L5.57165 6.06184L7.69012 7.46154L7.45474 5H8.59804L8.34584 7.46154L10.4475 6.06184L10.9855 6.99497L8.66529 7.99245L11.0023 8.97385L10.4475 9.92307L8.34584 8.53946L8.58122 11.001H7.47155Z"
        fill="white"
        className={iconColour}
      />
    </svg>
  );
}
