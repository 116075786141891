import { Profile, CampaignLink } from 'shared/legacy-types';
import CampaignLinkCard from './Link/CampaignLinkCard';
import useUser from 'hooks/useUser';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import RadioDropdown from 'ui/components/Dropdowns/RadioDropdown';
import { PlusIcon } from 'components/Icons';
import CampaignLinkPaginationComponent from './CampaignLinkPaginationComponent';

interface Props {
  openAddModal: () => void;
  openDeleteModal: (campaign: CampaignLink) => void;
  openEditModal: (campaign: CampaignLink) => void;
  filterBrandOptions: { value: string; label: string }[];
  filterMarketplaceOptions: { value: string; label: string }[];
  brandFilter: string;
  setBrandFilter: (brand: string) => void;
  marketplaceFilter: string;
  setMarketplaceFilter: (marketplace: string) => void;
  totalCampaignLinks: number;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  paginatedCampaignLinks: CampaignLink[];
  disableNextButton: boolean;
  disablePrevButton: boolean;
  openAddModalToCopyCampaignLink: (campaign: CampaignLink) => void;
}

export default function CampaignMappingComponent({
  openAddModal,
  openDeleteModal,
  openEditModal,
  filterBrandOptions,
  filterMarketplaceOptions,
  brandFilter,
  setBrandFilter,
  marketplaceFilter,
  setMarketplaceFilter,
  totalCampaignLinks,
  pageNumber,
  setPageNumber,
  paginatedCampaignLinks,
  disableNextButton,
  disablePrevButton,
  openAddModalToCopyCampaignLink,
}: Props) {
  const { getProfileData } = useUser();

  function renderCampaignLinks() {
    return (
      paginatedCampaignLinks &&
      paginatedCampaignLinks.map((campaign, index) => {
        const profileData = getProfileData(parseInt(campaign.profileId));
        const { currencyCode, countryCode, accountName } =
          profileData || ({} as Profile);

        return (
          <CampaignLinkCard
            key={index}
            brand={accountName}
            marketplace={countryCode}
            campaign={campaign}
            currency={currencyCode}
            openDeleteLinkModal={openDeleteModal}
            openEditLinkModal={openEditModal}
            openAddModalToCopyCampaignLink={openAddModalToCopyCampaignLink}
          />
        );
      })
    );
  }

  return (
    <>
      <div className="mb-[1.688rem] flex justify-between overflow-hidden">
        <h2 className="font-bold">Campaign mapping</h2>
        <PrimaryButton onClick={() => openAddModal()}>
          <div className="flex">
            <span className="mr-1">
              <PlusIcon fill="fill-white" />
            </span>
            Add campaign link
          </div>
        </PrimaryButton>
      </div>
      <div className="flex">
        <div className="mb-6 flex">
          <RadioDropdown
            label="Brand"
            options={filterBrandOptions}
            selectedValue={brandFilter || 'all'}
            onChange={(event) => setBrandFilter(event.target.value)}
            width="w-[10rem]"
          />
        </div>
        <div className="mb-6 ml-4 flex">
          <RadioDropdown
            label="Marketplaces"
            options={filterMarketplaceOptions}
            selectedValue={marketplaceFilter || 'all'}
            onChange={(event) => setMarketplaceFilter(event.target.value)}
            width="w-[10rem]"
          />
        </div>
      </div>
      <div className="w-full bg-secondary-40 h-8 rounded py-2 px-4 flex border-b border-secondary-60 z-20">
        <div className="text-xs font-bold w-[2rem]  my-auto mr-6">Brand</div>
        <div className="text-xs font-bold w-[3.5rem] mr-6 my-auto">Mktp</div>
        <div className="text-xs font-bold basis-[7rem] flex-auto mr-6 truncate">
          Source
        </div>
        <div className="w-4 bg-secondary-40 mr-6 truncate"></div>
        <div className="text-xs font-bold basis-[7rem] flex-auto mr-6 truncate">
          Destination
        </div>
        <div className="text-xs font-bold w-[5rem] mr-6 truncate">
          Match types
        </div>
        <div className="text-xs font-bold w-[6.35rem] mr-6 truncate">
          Targeting type
        </div>
        <div className="text-xs font-bold w-[3rem] mr-6 truncate">Negate</div>
        <div className="text-xs font-bold w-[4.5rem] truncate">Bid</div>
      </div>
      {renderCampaignLinks()}
      <CampaignLinkPaginationComponent
        totalCampaignLinks={totalCampaignLinks}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paginatedCampaignLinks={paginatedCampaignLinks}
        disableNextButton={disableNextButton}
        disablePrevButton={disablePrevButton}
      />
    </>
  );
}
