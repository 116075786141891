import { useState } from 'react';
import Modal from 'components/Modals/Modal';
import { InfoIcon } from 'components/Icons';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import UserInviteCard from 'pages/Dashboard/ShareWorkspace/UserInviteCard';
import useShareWorkspaceModal from './useShareWorkspaceModal';
import Subheader from './Subheader';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ShareWorkspaceModal({ open, setOpen }: Props) {
  const [navOpen, setNavOpen] = useState<number | null>(null);

  const {
    invitedUsers,
    isValid,
    setInvitedUser,
    invitedUser,
    inviteUser,
    revokeInvite,
    alreadyInvited,
    sendingInvite,
    loading,
    inviteToRevoke,
    loadingInvites,
    emailVerified,
  } = useShareWorkspaceModal();

  function renderInvitedUsers() {
    if (loadingInvites) {
      return (
        <div className="flex justify-center">
          <Spinner colour={'stroke-primary-100'} />
        </div>
      );
    }

    return [...invitedUsers]
      .sort((a) => (a.status === 'accepted' ? -1 : 1))
      .map((invite, index) => {
        return (
          <UserInviteCard
            invite={invite}
            key={invite.id}
            index={index}
            loading={loading}
            revokeInvite={revokeInvite}
            inviteToRevoke={inviteToRevoke}
            navOpen={navOpen}
            setNavOpen={setNavOpen}
          />
        );
      });
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6 w-[31rem]">
        <div>
          <div className="flex justify-between">
            <h3 className=" mb-4 truncate font-bold">Share workspace</h3>
            <div
              className="mt-[0.1875rem] hover:cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="text-sm">
            <Subheader />
          </div>
        </div>
        <div className="flex mt-4">
          <input
            type="email"
            className={`my-0 h-[3.063rem] w-[25rem] rounded border-2 p-4 text-sm focus:border-2 focus:outline-none ${
              emailVerified
                ? 'border-primary-80 focus:border-primary-80'
                : 'focus:border-neutral-40 border-neutral-40 hover:cursor-not-allowed'
            }
              `}
            placeholder="example@email.com"
            value={invitedUser.email}
            onChange={(event) =>
              setInvitedUser({ ...invitedUser, email: event.target.value })
            }
            aria-label="Invite user"
            disabled={!emailVerified}
          />
          <div className="ml-4 my-auto">
            <PrimaryButton
              disabled={!isValid || alreadyInvited}
              onClick={() => inviteUser(invitedUser)}
            >
              {sendingInvite ? (
                <div className="w-[2.4rem] mx-auto pl-[0.59rem]">
                  <Spinner colour={'stroke-white'} />
                </div>
              ) : (
                <>Share</>
              )}
            </PrimaryButton>
          </div>
        </div>
        {alreadyInvited && (
          <div className="bg-error-bg border-error flex h-fit w-full mt-3 rounded border px-3 py-2 text-sm">
            <span className="mr-1 my-auto">
              <InfoIcon fill={'fill-error'} />
            </span>
            <span className="my-auto">
              This email is already registered on fern
            </span>
          </div>
        )}
        {invitedUsers && invitedUsers.length > 0 && (
          <div className="text-xs font-bold mt-8">Invited</div>
        )}
        {renderInvitedUsers()}
      </div>
    </Modal>
  );
}
