import { ChevronRight } from 'components/Icons/';
import TextLink, {
  TextLinkFontSize,
  TextLinkStyle,
} from 'design-system/components/TextLink';

type Props = {
  link: string;
  linkLabel: string;
  currentPage: string;
};

export default function Breadcrumbs({
  link,
  linkLabel,
  currentPage,
}: Props): JSX.Element {
  return (
    <div className="flex text-xs text-neutral-80 mb-[0.875rem] w-[10.75rem]">
      <TextLink
        link={link}
        label={linkLabel}
        style={TextLinkStyle.BREADCRUMB}
        size={TextLinkFontSize.EXTRA_SMALL}
      />
      <div className="mx-2 mt-0.5">
        <ChevronRight />
      </div>
      <div className="truncate">{currentPage}</div>
    </div>
  );
}
