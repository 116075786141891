type Props = {
  title: string;
  onClick?: () => void;
  subtitle?: string;
  child?: React.ReactNode;
};

export default function Card({
  title,
  subtitle,
  onClick,
  child,
}: Props): JSX.Element {
  return (
    <div
      className={`${onClick && 'hover:cursor-pointer'} border-neutral-40 border border-l-4 rounded mb-3 bg-white py-4 px-6`}
      onClick={onClick}
    >
      <div className="truncate font-bold">{title}</div>
      {subtitle != null && (
        <div className="truncate text-sm text-neutral-80 mt-[0.375rem]">
          {subtitle}
        </div>
      )}
      {child}
    </div>
  );
}
