import {
  SEND_EMAIL,
  UPDATE_BID,
  UPDATE_BUDGET,
  SEND_SLACK,
} from 'ui/constants/actions';
import { UpdateBidAction, SlackAction } from 'shared/legacy-types';
import { getNotice } from 'ui/helpers/actions';
import { Action, ActionTab } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';

interface Props {
  actionTab: ActionTab;
  bidValue: string;
  action: Action;
  isPauseOrEnable: boolean;
}

export default function ActionDescription({
  actionTab,
  bidValue,
  action,
  isPauseOrEnable,
}: Props) {
  const { user } = useUserSelector();

  const bidAction = action as UpdateBidAction;
  const slackAction = action as SlackAction;

  if (actionTab.type === UPDATE_BID || actionTab.type === UPDATE_BUDGET) {
    return (
      <div className=" text-neutral-80 flex text-sm">
        {bidValue}%<span className="mx-2">•</span>
        <div>
          Wait at least {bidAction.properties.buffer} day
          {bidAction.properties.buffer !== 1 && 's'}
        </div>
      </div>
    );
  }
  if (actionTab.type === SEND_EMAIL) {
    return (
      <span className="text-neutral-80 mt-px text-sm !leading-5">
        Emails will be sent to {user?.email}
      </span>
    );
  }

  if (actionTab.type === SEND_SLACK) {
    return (
      <span className="text-neutral-80 mt-px text-sm !leading-5">
        Recieve notifications in the #{slackAction.properties.channelName} slack
        channel
      </span>
    );
  }

  return (
    <div className=" text-neutral-80 text-sm">{getNotice(isPauseOrEnable)}</div>
  );
}
