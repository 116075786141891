import { useAppDispatch } from 'state/hooks';
import { updateEditingRule } from 'state/rules/rulesSlice';

export default function useRulesDispatch() {
  const dispatch = useAppDispatch();

  return {
    updateEditingRule: (updateInfo: { id: string; type: string }) =>
      dispatch(updateEditingRule(updateInfo)),
  };
}
