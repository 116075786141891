import { useEffect, useState } from 'react';
import { KeywordApproval, Result } from 'shared/legacy-types';
import * as Sentry from '@sentry/react';

export default function useLoadApprovals(
  results: Result[] | undefined,
  setApprovalsLoadedFirstRender: (loaded: boolean) => void,
  getKeywordApprovalsRequest?: (searchTerms: string[]) => Promise<any>,
) {
  const [approvalsLoading, setApprovalsLoading] = useState<boolean>(false);
  const [keywordApprovals, setKeywordApprovals] = useState<KeywordApproval[]>(
    [],
  );

  useEffect(() => {
    const searchTermResults =
      results && results.map((result) => result.searchTerm.toString());

    async function getKeywordApprovals() {
      if (searchTermResults && getKeywordApprovalsRequest) {
        try {
          setApprovalsLoading(true);
          const response = await getKeywordApprovalsRequest(searchTermResults);
          setKeywordApprovals(response.data);
          setApprovalsLoading(false);
          setApprovalsLoadedFirstRender(true);
        } catch (error) {
          Sentry.captureException(error);
          setApprovalsLoading(false);
        }
      }
    }
    searchTermResults && searchTermResults.length > 0 && getKeywordApprovals();
    // eslint-disable-next-line
  }, [results]);

  return { approvalsLoading, keywordApprovals, setKeywordApprovals };
}
