import { createContext } from 'react';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import { Profile, User } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import useSetup from './useSetup';
import { StepProgress } from 'components/ProgressBar';

type ContextData = {
  user: User | undefined;
  noProfiles: boolean;
  profiles: Profile[];
  stepsProgress: StepProgress[];
};

export const SetupContext = createContext<ContextData>({
  user: undefined,
  noProfiles: false,
  profiles: [],
  stepsProgress: [],
});

export default function Setup(): JSX.Element {
  const { user } = useUserSelector();

  const { componentToDisplay, loading, profiles, stepsProgress } = useSetup();

  if (!user || loading) {
    return (
      <div className="h-screen flex">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  const Component = componentToDisplay.component;

  return (
    <SetupContext.Provider
      value={{
        user,
        noProfiles: user.amazonAccess && profiles.length === 0 && !loading,
        profiles: profiles,
        stepsProgress: stepsProgress,
      }}
    >
      <>
        <Component />
      </>
    </SetupContext.Provider>
  );
}
