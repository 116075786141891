import { Link, useParams, useMatch } from 'react-router-dom';
import { HomeIcon, ArchiveIcon } from 'components/Icons';
import useFoldersSelector from 'state/folders/useFoldersSelector';
import Folder from 'pages/Dashboard/Folders/Folder';

export default function Folders() {
  const { folderId } = useParams();
  const archivedPage = useMatch('/archived');
  const homePage = useMatch('/');

  const { folders } = useFoldersSelector();

  function renderFolders() {
    const sortedFolders =
      folders && folders.slice().sort((a, b) => a.name.localeCompare(b.name));
    return sortedFolders.map((folder) => {
      const isSelected = folderId === folder.id.toString();

      return <Folder key={folder.id} isSelected={isSelected} folder={folder} />;
    });
  }

  return (
    <div
      className="min-w-[14.75rem] bg-white rounded-md mr-8 h-fit"
      style={{ boxShadow: '0rem 0.125rem 0.25rem rgba(0, 18, 51, 0.05)' }}
    >
      <Link to="/">
        <div
          className={`w-full h-[2.75rem] px-4 py-[0.844rem] text-sm flex hover:cursor-pointer ${
            homePage && 'bg-primary-100 text-white font-bold rounded-t-md'
          }`}
        >
          <div className="mr-3">
            <HomeIcon
              colour={
                homePage
                  ? 'fill-white stroke-white'
                  : 'fill-primary-80 stroke-primary-80'
              }
              stroke={homePage ? 'stroke-primary-100' : 'stroke-white'}
            />
          </div>
          Home
        </div>
      </Link>
      {renderFolders()}
      <Link to="/archived">
        <div
          className={`w-full h-[2.75rem] px-4 py-[0.844rem] text-sm flex hover:cursor-pointer ${
            archivedPage && 'bg-primary-100 text-white font-bold rounded-b-md'
          }`}
        >
          <div className="mr-3">
            <ArchiveIcon
              fill={archivedPage ? 'fill-white' : 'fill-primary-80'}
              stroke={archivedPage ? 'stroke-white' : 'stroke-primary-80'}
              fillBackground={archivedPage ? 'fill-primary-100' : 'fill-white'}
              strokeBackground={
                archivedPage ? 'stroke-primary-100' : 'stroke-white'
              }
            />
          </div>
          Archive
        </div>
      </Link>
    </div>
  );
}
