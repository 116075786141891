import {
  ConditionOperator,
  ConditionGroupOperator,
  RuleStatus,
  DataType,
  TargetingType,
  MatchType,
  UserInviteStatus,
  FixedBid,
} from 'shared/legacy-types';
import { CONDITION_FIELDS } from './conditionFields';

import { deepClone } from '../helpers/deepClone';

export const TARGETS = 'targets';
export const AD_GROUPS = 'adGroups';
export const CAMPAIGNS = 'campaigns';
export const SEARCH_TERMS = 'searchTerms';
export const NEGATIVE_SEARCH_TERMS = 'negativeSearchTerms';
export const PRODUCTS = 'products';
export const ADS = 'ads';
export const PRODUCT_SIGNALS = 'productSignals';

export const MARKETPLACE = 'marketplace';

export const FIELD_HEADERS = {
  marketplace: 'marketplace',
};

export const DEFAULT_CONDITION = {
  field: '',
  operator: '' as ConditionOperator,
  value: '',
};

export const DEFAULT_GROUP = {
  operator: '&&' as ConditionGroupOperator,
  conditions: [{ ...DEFAULT_CONDITION }],
};

export const DEFAULT_PERIOD = '14';
export const LONG_PERIOD = '30';
export const SHORT_PERIOD = '5';

export const VALID_PERIODS_FOR_TARGETS = [DEFAULT_PERIOD, LONG_PERIOD];
export const VALID_PERIODS_FOR_CAMPAIGNS = [
  SHORT_PERIOD,
  DEFAULT_PERIOD,
  LONG_PERIOD,
];
export const VALID_PERIODS_FOR_SEARCH_TERMS = [LONG_PERIOD];

export const DEFAULT_STEP = {
  operator: '&&' as ConditionGroupOperator,
  period: DEFAULT_PERIOD,
  conditionGroups: [{ ...DEFAULT_GROUP }],
  actions: [],
  name: null,
};

export const DEFAULT_STEPS = [{ ...DEFAULT_STEP }];

export const DEFAULT_RULE = {
  adTypes: [],
  dataType: TARGETS as DataType,
  profileIds: [],
  name: 'Untitled rule',
  status: 'DRAFT' as RuleStatus,
  steps: deepClone(DEFAULT_STEPS),
};

export const DEFAULT_USER_INVITE = {
  name: '',
  email: '',
  status: 'pending' as UserInviteStatus,
  id: '',
  invitedBy: '',
  createdAt: '',
};

export const REGULAR_INPUT = 'regularInput';
export const DROPDOWN_INPUT = 'dropdownInput';

export const INPUT_TYPE_OPTIONS = [
  { label: 'Enter value', value: REGULAR_INPUT },
  { label: 'Select field', value: DROPDOWN_INPUT },
];

export const BUDGET_OPTION = { label: 'Budget', value: 'budget' };

export const EXPRESSION_OPTIONS = {
  clicks: [{ label: 'Mktp CR Clicks', value: 'profileCrClicks' }],
  bid: [{ label: 'CPC', value: 'costPerClick' }],
  cost: [BUDGET_OPTION],
  averageDailyCost: [BUDGET_OPTION],
  product: [CONDITION_FIELDS.averageCr],
};

export const OPERATORS = [
  { label: 'Contains', value: 'contains' as ConditionOperator, type: 'string' },
  {
    label: 'Does not contain',
    value: 'not_contains' as ConditionOperator,
    type: 'string',
  },
  { label: 'Exactly matches', value: '=' as ConditionOperator, type: 'string' },
  {
    label: 'Does not exactly match',
    value: '!=' as ConditionOperator,
    type: 'string',
  },
  {
    label: 'Begins with',
    value: 'begins_with' as ConditionOperator,
    type: 'string',
  },
  {
    label: 'Ends with',
    value: 'ends_with' as ConditionOperator,
    type: 'string',
  },
  { label: 'Greater than', value: '>' as ConditionOperator, type: 'number' },
  {
    label: 'Greater than or equal to',
    value: '>=' as ConditionOperator,
    type: 'number',
  },
  { label: 'Less than', value: '<' as ConditionOperator, type: 'number' },
  {
    label: 'Less than or equal to',
    value: '<=' as ConditionOperator,
    type: 'number',
  },
  { label: 'Equal to', value: '=' as ConditionOperator, type: 'number' },
  { label: 'Not equal to', value: '!=' as ConditionOperator, type: 'number' },
  {
    label: 'Changes to',
    value: 'changes_to' as ConditionOperator,
    type: 'boolean',
  },
];

export const GROUP_OPERATORS = [
  { label: 'or', value: '||' as ConditionGroupOperator },
  { label: 'and', value: '&&' as ConditionGroupOperator },
];

export const DEFAULT_CAMPAIGN_LINK = {
  profileId: '',
  id: '',
  source: {
    campaignName: '',
    campaignId: '',
    adGroups: [{ adGroupName: '', adGroupId: '' }],
  },
  destination: {
    campaignName: '',
    campaignId: '',
    adGroups: [{ adGroupName: '', adGroupId: '' }],
  },
  matchTypes: [] as MatchType[],
  targetingType: 'keyword' as TargetingType,
  bid: { fixedBid: '' } as FixedBid,
  negateKeywords: { negate: false },
  createdAt: '',
};

export const PRODUCT = 'product';
export const KEYWORD = 'keyword';

export const TARGETING_TYPES: Array<{ value: TargetingType; label: string }> = [
  { label: 'Keyword', value: KEYWORD },
  { label: 'Product', value: PRODUCT },
];

export const MATCH_TYPES: Array<{ value: MatchType; label: string }> = [
  { label: 'Broad', value: 'broad' },
  { label: 'Phrase', value: 'phrase' },
  { label: 'Exact', value: 'exact' },
];

export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';
export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const DRAFT = 'DRAFT';
export const ARCHIVED = 'ARCHIVED';

export const DATE_OPTIONS = [
  { value: SHORT_PERIOD, label: 'Last 5 days' },
  { value: DEFAULT_PERIOD, label: 'Up to 14 days ago' },
  { value: LONG_PERIOD, label: 'Up to 30 days ago' },
];

// labels
export const DATA_TYPE_LABELS = [
  { value: TARGETS, label: 'Targets' },
  { value: CAMPAIGNS, label: 'Campaigns' },
  { value: AD_GROUPS, label: 'Ad groups' },
  { value: SEARCH_TERMS, label: 'Search terms' },
  { value: PRODUCT_SIGNALS, label: 'Product Signals' },
  { value: PRODUCTS, label: 'Products' },
];

export const DATA_TYPE_OPTIONS_INTERNAL = [
  { value: TARGETS, label: 'Targets' },
  { value: CAMPAIGNS, label: 'Campaigns' },
  { value: SEARCH_TERMS, label: 'Search terms' },
  { value: PRODUCT_SIGNALS, label: 'Product Signals' },
];

export const DATA_TYPE_OPTIONS = [
  { value: TARGETS, label: 'Targets' },
  { value: CAMPAIGNS, label: 'Campaigns' },
  { value: SEARCH_TERMS, label: 'Search terms' },
];

export const FIELD_HEADER_LABELS = [
  { value: TARGETS, label: 'Targets' },
  { value: CAMPAIGNS, label: 'Campaigns' },
  { value: AD_GROUPS, label: 'Ad groups' },
  { value: FIELD_HEADERS.marketplace, label: 'Marketplace' },
  { value: PRODUCTS, label: 'Products' },
  { value: SEARCH_TERMS, label: 'Search terms' },
  { value: PRODUCT_SIGNALS, label: 'Product Signals' },
];

export const FIELD_HEADER_LABELS_SINGULAR = [
  { value: TARGETS, label: 'Target' },
  { value: CAMPAIGNS, label: 'Campaign' },
  { value: AD_GROUPS, label: 'Ad group' },
  { value: PRODUCTS, label: 'Product' },
  { value: FIELD_HEADERS.marketplace, label: 'Mktp' },
  { value: SEARCH_TERMS, label: 'Search term' },
  { value: PRODUCT_SIGNALS, label: 'Product Signals' },
];

export const AD_OPTIONS = [
  { value: 'SB', label: 'Sponsored Brands' },
  { value: 'SD', label: 'Sponsored Display' },
  { value: 'SP', label: 'Sponsored Product' },
];

// Data Type fields
export const ALL_DATA_TYPE_FIELDS = [
  CONDITION_FIELDS.clicks,
  CONDITION_FIELDS.impressions,
  CONDITION_FIELDS.spend,
  CONDITION_FIELDS.cpc,
  CONDITION_FIELDS.ctr,
  CONDITION_FIELDS.adSales,
  CONDITION_FIELDS.adOrders,
  CONDITION_FIELDS.acos,
  CONDITION_FIELDS.roas,
  CONDITION_FIELDS.adConversionRate,
];

export const CAMPAIGN_FIELDS = [
  CONDITION_FIELDS.newCampaignName,
  CONDITION_FIELDS.status,
  CONDITION_FIELDS.budget,
  CONDITION_FIELDS.averageDailyCost,
];

export const TARGETS_FIELDS = [
  CONDITION_FIELDS.adGroupName,
  CONDITION_FIELDS.campaignName,
  CONDITION_FIELDS.targetingText,
  CONDITION_FIELDS.matchType,
  CONDITION_FIELDS.bid,
];

export const SEARCH_TERMS_FIELDS = [
  CONDITION_FIELDS.searchTerm,
  CONDITION_FIELDS.bid,
  CONDITION_FIELDS.campaignName,
  CONDITION_FIELDS.targetingText,
];

export const MARKETPLACE_FIELDS = [
  CONDITION_FIELDS.monthlySpendTarget,
  CONDITION_FIELDS.monthToDateSpendGoal,
  CONDITION_FIELDS.monthToDateSpendActual,
];

export const PRODUCTS_FIELDS = [
  CONDITION_FIELDS.asin,
  CONDITION_FIELDS.revenue,
  CONDITION_FIELDS.tacos,
  CONDITION_FIELDS.buyBox,
  CONDITION_FIELDS.totalCr,
  CONDITION_FIELDS.organicCr,
  CONDITION_FIELDS.totalOrders,
  CONDITION_FIELDS.totalUnits,
  CONDITION_FIELDS.sessions,
  CONDITION_FIELDS.pageViews,
  CONDITION_FIELDS.fbaStockAvailable,
  CONDITION_FIELDS.deliveryDays,
];

export const PRODUCT_SIGNALS_FIELDS = [
  CONDITION_FIELDS.winningBuyBox,
  CONDITION_FIELDS.fbaStockAvailable,
  CONDITION_FIELDS.normalDeliveryWindow,
  // CONDITION_FIELDS.status, (might need a productStatus field)
  // CONDITION_FIELDS.pricingHealth,
  // CONDITION_FIELDS.promotionStatus,
  CONDITION_FIELDS.primeEligibilityStatus,
  CONDITION_FIELDS.mainCategorySalesRank,
  CONDITION_FIELDS.subCategorySalesRank,
];

export const GROUPED_CONDITION_FIELDS = {
  [TARGETS]: {
    [TARGETS]: [...TARGETS_FIELDS, ...ALL_DATA_TYPE_FIELDS],
    marketplace: [...MARKETPLACE_FIELDS],
    [PRODUCTS]: [...PRODUCTS_FIELDS],
  },
  [CAMPAIGNS]: {
    [CAMPAIGNS]: [...CAMPAIGN_FIELDS, ...ALL_DATA_TYPE_FIELDS],
    marketplace: [...MARKETPLACE_FIELDS],
    [PRODUCTS]: [...PRODUCTS_FIELDS],
  },
  [SEARCH_TERMS]: {
    [SEARCH_TERMS]: [...SEARCH_TERMS_FIELDS, ...ALL_DATA_TYPE_FIELDS],
  },
  [PRODUCT_SIGNALS]: {
    [PRODUCT_SIGNALS]: [...PRODUCT_SIGNALS_FIELDS],
  },
};

export const GROUPED_CONDITION_FIELDS_EXTERNAL = {
  [TARGETS]: {
    [TARGETS]: [...TARGETS_FIELDS, ...ALL_DATA_TYPE_FIELDS],
    marketplace: [...MARKETPLACE_FIELDS],
  },
  [CAMPAIGNS]: {
    [CAMPAIGNS]: [...CAMPAIGN_FIELDS, ...ALL_DATA_TYPE_FIELDS],
    marketplace: [...MARKETPLACE_FIELDS],
  },
  [SEARCH_TERMS]: {
    [SEARCH_TERMS]: [...SEARCH_TERMS_FIELDS, ...ALL_DATA_TYPE_FIELDS],
  },
};
