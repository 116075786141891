import {
  Condition,
  MetricCondition,
  Rule,
  ValueCondition,
} from 'shared/legacy-types';
import { fieldWithoutPrefix, getFieldPrefix } from 'ui/helpers/rules/fields';
import {
  MARKETPLACE_FIELDS,
  FIELD_HEADERS,
  BUDGET_OPTION,
} from 'ui/constants/rules';
import useUser from 'hooks/useUser';
import {
  findConditionField,
  displayChooseInputDropdown,
} from '../helpers/fields';

export default function useConditionValue(
  condition: Condition,
  rule: Omit<Rule, 'id'>,
  handleInputChange: (value: string, isMetric: boolean) => void,
  setInputType: (value: string) => void,
  error?: boolean,
  isCampaignSpend?: boolean,
) {
  const metricCondition = condition as MetricCondition;
  const valueCondition = condition as ValueCondition;
  const isValueCondition = condition.hasOwnProperty('value');
  const selectedField = fieldWithoutPrefix(condition.field);
  const fieldPrefix = getFieldPrefix(condition.field);

  const conditionValue = condition.hasOwnProperty('value')
    ? (valueCondition.value as string)
    : metricCondition.metric;

  const isAverageDailyCost = condition.field === 'campaigns:averageDailyCost';

  const { allProfilesHaveSpendTargets } = useUser();
  const ruleHasSpendTargets = allProfilesHaveSpendTargets(rule.profileIds);
  const disable =
    !ruleHasSpendTargets && condition.field.includes(FIELD_HEADERS.marketplace);

  const errorCheck = error && !conditionValue;
  const errorStyles =
    'bg-error bg-opacity-[.2] border border-error border-opacity-[.4] focus:bg-white !pl-[0.938rem]';

  function showPercentIcon(selectedField: string) {
    if (
      selectedField === 'click_through_rate' ||
      selectedField === 'ad_conversion_rate' ||
      selectedField === 'acos' ||
      selectedField === 'marketplace_acos'
    ) {
      return true;
    }
    return false;
  }

  // condition fields control what kind of input to show
  const currentField = findConditionField(selectedField);
  const showChooseInputDropdown = displayChooseInputDropdown(
    currentField,
    fieldPrefix,
  );
  const showDropdown = currentField && currentField.inputType === 'dropdown';

  const fieldOptions = () => {
    if (isCampaignSpend || isAverageDailyCost) {
      return [BUDGET_OPTION];
    }

    if (showDropdown) {
      return [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ];
    }

    return MARKETPLACE_FIELDS.filter((field) => field.value !== selectedField);
  };

  function updateValueType(value: string) {
    // reset condition value
    handleInputChange('', false);
    setInputType(value);
  }

  function updateInputValue(value: string) {
    // currently assuming that all fields in value dropdown are metrics
    handleInputChange(value, true);
  }

  return {
    updateInputValue,
    updateValueType,
    showPercentIcon,
    fieldOptions,
    showChooseInputDropdown,
    errorCheck,
    errorStyles,
    isValueCondition,
    disable,
    showDropdown,
    conditionValue,
    selectedField,
  };
}
