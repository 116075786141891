export const getCurrencySymbol = (currency: string | undefined) => {
  if (!currency) return null;
  // TODO consistent locale detection - there is a package in ui
  const language = navigator.language || 'en-IN';
  return (0)
    .toLocaleString(language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};

// stop user from entering non-numeric values into input
export const preventNonNumericInput = (
  event: React.KeyboardEvent<HTMLInputElement>,
  currencyCode: string,
) => {
  const allowedChars = '0123456789';
  function contains(stringValue: string, charValue: string) {
    // If a user selects the input to the left of the currency currencyCode, any typed numbers will be moved to the start of the input value
    if (
      (event.key.length > 0 && event.currentTarget.selectionStart === 0) ||
      (event.currentTarget.selectionStart &&
        event.currentTarget.selectionStart <
          event.currentTarget.value.indexOf(currencyCode) + currencyCode.length)
    ) {
      event.currentTarget.selectionStart = event.currentTarget.value.length;
    }
    return stringValue.indexOf(charValue) > -1;
  }
  const invalidKey =
    (event.key.length === 1 && !contains(allowedChars, event.key)) ||
    (event.key === '.' && contains(event.key, '.'));
  invalidKey && event.preventDefault();
};

export const removeNonNumericChars = (value: string) => {
  //remove currencyCode
  let updatedValue = value.replace(/^\D+/g, '');
  //remove commas
  return updatedValue.replace(/,/g, '');
};

// sets the first selectable cursor position to the right of the currency currencyCode on focus
export const setCursor = (
  event: React.FocusEvent<HTMLInputElement>,
  currencyCode: string,
  value: string,
) => {
  const stroke = event.currentTarget.value;
  const position =
    stroke.indexOf(currencyCode) + currencyCode.length + value.length;
  const strokePosition = stroke.indexOf(stroke) + position;
  event.currentTarget.setSelectionRange(strokePosition, strokePosition);
};

export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  currencyCode: string,
  input: string,
) => {
  // if user presses arrow left key cursor is to next to the country code, prevents cursor moving
  if (
    (event.key === 'ArrowLeft' &&
      event.currentTarget.selectionStart! <= currencyCode.length - 1) ||
    (event.key === 'ArrowLeft' && input.length === 0)
  ) {
    event.preventDefault();
  }
  // if currency code is longer than 1 character and cursor is in between, moves any input to the right of the currency code
  if (
    (event.key.length > 0 && event.currentTarget.selectionStart === 0) ||
    (event.currentTarget.selectionStart &&
      event.currentTarget.selectionStart <
        event.currentTarget.value.indexOf(currencyCode) + currencyCode.length)
  ) {
    event.currentTarget.selectionStart = event.currentTarget.value.length;
  }
};
