import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import AddRegionModal from '../Region/AddRegionModal';
import Brand from './Brand/Index';
import Brands from './Brands/Index';

export default function BrandsTab(): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<string>();

  const isBrandPage = useMatch('/settings/:brandId');
  const isBrandsPage = useMatch('/settings');

  function selectRegion(event: React.ChangeEvent<HTMLInputElement>): void {
    const { target } = event;
    setSelectedRegion(target.value);
  }

  return (
    <>
      <div className="relative max-w-[40rem]">
        {isBrandPage && <Brand setOpenModal={setOpenModal} />}
        {isBrandsPage && <Brands setOpenModal={setOpenModal} />}
      </div>
      <AddRegionModal
        open={openModal}
        setOpen={setOpenModal}
        selectedRegion={selectedRegion}
        selectRegion={selectRegion}
      />
    </>
  );
}
