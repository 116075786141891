import 'components/Radio/radio.css';

interface Props {
  error?: boolean;
  id: string;
  select: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  unCheck?: () => void;
  selected?: boolean;
  label?: string;
}

export function Radio(props: Props) {
  const { error, id, select, checked, unCheck, label } = props;

  const unCheckRadio = () => {
    if (checked) {
      unCheck && unCheck();
    }
  };

  return (
    <div
      className="flex items-center h-6 w-full"
      onClick={() => unCheckRadio()}
    >
      <label>
        <input
          type="radio"
          name="radio"
          className={`border-neutral-40 w-6 h-6 ${
            error ? 'border-error' : ''
          } focus:border my-0 checked:before:bg-primary-100 checked:border-primary-100 checked:before:pl-[0.1875rem] checked:before:pt-[0.1875rem] hover:cursor-pointer`}
          id={id}
          value={id}
          checked={checked}
          onChange={select}
        />{' '}
        {label && (
          <span
            className={`ml-2 items-center absolute text-sm !leading-6 text-neutral-100 hover:cursor-pointer w-auto inline ${
              checked && 'font-bold leading'
            }`}
          >
            {label}
          </span>
        )}
      </label>
    </div>
  );
}
