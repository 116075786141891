import { useState } from 'react';
import { useRef } from 'react';
import { GlassIcon, SquareIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import DropdownMenu from './DropdownMenu';
import Chevron from './Chevron';
import { AdGroup, SearchTermCampaign } from 'shared/legacy-types';

type DropdownType = 'source' | 'destination';

interface Props {
  ariaLabel?: string;
  disabled?: boolean;
  position?: string;
  styles?: string;
  campaigns: SearchTermCampaign[];
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  adGroups: AdGroup[];
  placeholder?: string;
  adGroupSelections: AdGroup[] | null;
  setAdGroupSelection: (value: AdGroup[] | null) => void;
  selectedCampaign: SearchTermCampaign;
  setSelectedCampaign: (value: SearchTermCampaign) => void;
  dropdownType?: DropdownType;
  loadingCampaigns: boolean;
  adGroupsLoading: boolean;
}

export default function CampaignSearchDropdown({
  ariaLabel,
  disabled,
  position,
  styles,
  campaigns,
  searchTerm,
  setSearchTerm,
  adGroups,
  placeholder,
  adGroupSelections,
  setAdGroupSelection,
  selectedCampaign,
  setSelectedCampaign,
  dropdownType,
  loadingCampaigns,
  adGroupsLoading,
}: Props) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);
  useClickOutside(setOpen, ref, dropdownRef);

  const allAdGroupsSelected =
    adGroupSelections && adGroups.length === adGroupSelections.length;

  const firstAdGroupFromSelectedAdGroups =
    adGroupSelections && adGroupSelections[0];

  function adGroupLabel() {
    // sets initial label if only one ad group available and is selected
    if (adGroupSelections && adGroups.length === 1 && allAdGroupsSelected) {
      const firstAdGroupFromAllAdGroups = adGroups[0];
      if (!firstAdGroupFromAllAdGroups) {
        throw new Error('Ad group not found');
      }

      return (
        <div className="truncate">
          {firstAdGroupFromAllAdGroups.adGroupName}
        </div>
      );
    }
    // sets label if more than one ad group is selected
    if (
      adGroupSelections &&
      adGroupSelections.length > 1 &&
      !allAdGroupsSelected
    ) {
      if (!firstAdGroupFromSelectedAdGroups) {
        throw new Error('Ad group not found');
      }

      return (
        <div className="flex">
          <div className=" max-w-[20rem] truncate">
            {firstAdGroupFromSelectedAdGroups.adGroupName}
          </div>{' '}
          <div className="mx-1">•</div>
          <div className="w-[5rem]"> + {adGroupSelections.length - 1} more</div>
        </div>
      );
    }
    // sets initial label if all ad groups are selected or if all ad groups selected
    if (adGroupSelections === null || allAdGroupsSelected) {
      return <div>All ad groups</div>;
    }

    if (!firstAdGroupFromSelectedAdGroups) {
      throw new Error('Ad group not found');
    }

    // sets label if only one ad group is selected while editing
    if (adGroupSelections && adGroupSelections.length === 1) {
      return (
        adGroupSelections && (
          <div className="truncate">
            {firstAdGroupFromSelectedAdGroups.adGroupName}
          </div>
        )
      );
    }

    return firstAdGroupFromSelectedAdGroups.adGroupName;
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`text-left text-sm text-neutral-100 w-[27rem] ${
          disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
        }`}
        onClick={() => !disabled && setOpen(!open)}
        aria-label={ariaLabel}
      >
        <div className="relative w-[27rem] min-h-[3.188rem]">
          {adGroupSelections &&
            (adGroupSelections.length === 0 ||
              (firstAdGroupFromSelectedAdGroups &&
                firstAdGroupFromSelectedAdGroups.adGroupId === '')) &&
            adGroupSelections !== null && (
              <>
                <div className="absolute left-3 top-[1.094rem]">
                  <GlassIcon />
                </div>
                <input
                  placeholder={placeholder}
                  className={`focus:outline-none focus:border-primary-100 focus:border-2 focus:pl-[2.188rem] focus:pb-px rounded w-[27rem] h-[3.188rem] pl-[2.25rem]
                ${disabled && 'bg-white'}
                ${styles}
                ${open && 'border-primary-100 !border-2 pl-[2.188rem]'}
                ${open && dropdownType === 'source' ? 'pt-0' : 'pb-px'}
                `}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                  disabled={disabled}
                />
              </>
            )}
          {((adGroupSelections &&
            adGroupSelections.length >= 1 &&
            firstAdGroupFromSelectedAdGroups &&
            firstAdGroupFromSelectedAdGroups.adGroupId !== '') ||
            adGroupSelections === null) && (
            <div
              className={`bg-white min-h-[4.375rem] py-4 pr-8 pl-4 rounded ${styles} `}
            >
              <div className="flex">
                <div className="pr-3 mt-[0.938rem]">
                  <SquareIcon />
                </div>
                <div className="truncate">{selectedCampaign.campaignName}</div>
              </div>
              <div
                className={`pl-5 mr-3 text-neutral-80 text-xs ${
                  adGroupSelections &&
                  adGroupSelections.length >= 1 &&
                  'truncate'
                }`}
              >
                {adGroupLabel()}
              </div>
            </div>
          )}
          <Chevron open={open} adGroupSelections={adGroupSelections} />
        </div>
      </div>
      {(open || searchTerm) && (
        <DropdownMenu
          ariaLabel="Campaigns"
          adGroups={adGroups}
          adGroupSelections={adGroupSelections}
          setAdGroupSelection={setAdGroupSelection}
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          ref={ref}
          position={position}
          searchTerm={searchTerm}
          loadingCampaigns={loadingCampaigns}
          adGroupsLoading={adGroupsLoading}
          open={open}
          setOpen={setOpen}
          setSearchTerm={setSearchTerm}
        />
      )}
    </div>
  );
}
