import { useState, useEffect } from 'react';

// Toggle themes for darker and lighter backgrounds
export type ThemeTypes = 'dark' | 'light';
interface Props {
  text?: string;
  enable?: boolean;
  toggle?: (enabled: boolean) => void;
  ariaLabel?: string;
  theme?: ThemeTypes;
}

export default function Toggle({
  text,
  enable,
  toggle,
  ariaLabel,
  theme,
}: Props) {
  const [enabled, setEnabled] = useState<boolean>();

  useEffect(() => {
    setEnabled(enable);
  }, [enable]);

  function handleToggle() {
    setEnabled(!enabled);
    toggle && toggle(!enabled);
  }

  return (
    <button
      type="button"
      className={`relative inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent
        transition-colors duration-200 ease-in-out focus:outline-none
        ${
          theme === 'dark'
            ? enabled
              ? 'bg-primary-100'
              : 'bg-neutral-60'
            : enabled
              ? 'bg-primary-80'
              : 'bg-neutral-40'
        }`}
      role="switch"
      aria-checked={enabled}
      aria-label={ariaLabel}
      onClick={handleToggle}
    >
      <span className="sr-only">{text}</span>
      <span
        aria-hidden="true"
        className={`pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow transition duration-100 ease-in-out 
          ${enabled ? 'translate-x-4' : 'translate-x-0'}`}
      />
    </button>
  );
}
