import { useState, useContext } from 'react';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import RegionCard from 'pages/Onboarding/Region/RegionCard';
import { SetupContext } from '../Setup';
import OnboardingLayout from '../OnboardingLayout';

export default function Region() {
  const [selectedRegion, setSelectedRegion] = useState<string>();
  const disableSubmit = !selectedRegion;

  const { user, noProfiles } = useContext(SetupContext) || {};

  function selectRegion(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    setSelectedRegion(target.value);
  }

  function continueToAmazon() {
    window.location.replace(
      `/v1/oauth/amazon-redirect?region=${selectedRegion}&token=${localStorage.getItem('token')}`,
    );
  }

  function errorMessage() {
    return (
      <div
        className={`animate-fadeIn absolute left-0 right-0 top-[80px] m-auto w-[23.75rem] rounded-xl border px-6 py-4`}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderColor: 'rgba(0, 0, 0, 0.04)',
          boxShadow: '0px 4px 32px rgba(0, 18, 51, 0.08)',
        }}
      >
        <div className="font-bold">No profiles were found</div> from the account
        details you entered
      </div>
    );
  }
  return (
    <>
      <OnboardingLayout>
        <div className="flex flex-col h-[85vh] justify-between">
          <div>
            {noProfiles && <div>{errorMessage()}</div>}
            <span className="text-sm">
              Hey {user?.firstName}, let's connect your Amazon ads account
            </span>
            <h3 data-testid="region-title" className="mb-6 mt-2 font-bold">
              Choose a region to get started
            </h3>
            <div className="mb-8">
              <RegionCard
                region="Europe"
                id="EU"
                selectRegion={selectRegion}
                selected={selectedRegion === 'EU'}
              />
              <RegionCard
                region="North America"
                id="NA"
                selectRegion={selectRegion}
                selected={selectedRegion === 'NA'}
              />
              <RegionCard
                region="Asia Pacific"
                id="FE"
                selectRegion={selectRegion}
                selected={selectedRegion === 'FE'}
              />
            </div>
          </div>
          <div className={`pb-8 mx-auto`}>
            <div>
              <PrimaryButton
                size="lg"
                disabled={disableSubmit}
                onClick={continueToAmazon}
              >
                <div>Continue to Amazon</div>
              </PrimaryButton>
              <div className="text-neutral-80 pt-6 text-xs">
                We'll redirect you to sign into your Amazon ads account.
              </div>
              <div className="text-neutral-80 pt-2 text-xs font-bold">
                You can add and remove regions at any time from Settings after
                completing sign up
              </div>
            </div>
          </div>
        </div>
      </OnboardingLayout>
    </>
  );
}
