import { useState, useEffect } from 'react';
import Spinner from 'ui/Spinner/Spinner';
import * as Sentry from '@sentry/react';

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  onChange: (enabled: boolean) => Promise<void>;
  enable?: boolean;
  darkMode?: boolean;
  label?: [string, string];
  checkIsValid?: () => void;
}

export default function ToggleButton({
  disabled,
  onChange,
  enable,
  darkMode,
  label,
  checkIsValid,
}: Props) {
  const [enabled, setEnabled] = useState<boolean>();
  const [publishing, setPublishing] = useState<boolean>();

  useEffect(() => {
    setEnabled(enable);
  }, [enable]);

  const darkModeStyle = 'bg-white bg-opacity-[8%]';
  const lightModeStyle = 'bg-neutral-100 bg-opacity-[8%]';

  async function switchToggle() {
    setPublishing(true);
    try {
      await onChange(!enabled);
      setEnabled(!enabled);
      setPublishing(false);
    } catch (error) {
      Sentry.captureException(error);
      setPublishing(false);
    }
  }

  function darkStyle() {
    if (darkMode) {
      return darkModeStyle;
    }
    return lightModeStyle;
  }

  if (disabled) {
    return (
      <div>
        <button
          onClick={() => disabled && checkIsValid && checkIsValid()}
          type="button"
          className={`relative inline-flex flex-shrink-0 rounded-full 
          transition-colors duration-200 ease-in-out fos:outline-none w-20 bg-transparent border-neutral-40 border-2 h-[2.5625rem]
          `}
          role="switch"
          aria-checked={enabled}
        >
          <span
            className={`my-auto pt-[0.219rem] w-11 h-6 text-sm font-bold text-neutral-60 relative left-6`}
          >
            {enabled ? <>{label?.[0]}</> : <>{label?.[1]}</>}
          </span>
          <span
            className={`my-auto pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-100 ease-in-out translate-x-[-2.25rem] `}
          />
        </button>
      </div>
    );
  }

  return (
    <button
      type="button"
      className={`${(!enabled || publishing) && 'bg-neutral-40'} ${!enabled && darkStyle()} ${
        publishing && darkMode && darkModeStyle
      } relative inline-flex flex-shrink-0 cursor-pointer rounded-full 
        transition-colors duration-200 ease-in-out fos:outline-none w-20 h-[2.5625rem] bg-primary-100
       `}
      role="switch"
      aria-checked={enabled}
      onClick={() => {
        switchToggle();
      }}
    >
      {enabled && !publishing && (
        <span
          className={`my-auto pt-[0.219rem] pl-4 w-11 h-6 text-sm font-bold text-white`}
        >
          {label && label[0]}
        </span>
      )}
      {!enabled && !publishing && (
        <span
          className={` ${
            darkMode && 'text-neutral-60'
          } my-auto pt-[0.219rem] w-11 h-6 text-sm font-bold text-neutral-80 relative left-6 `}
        >
          {label && label[1]}
        </span>
      )}
      {publishing && (
        <span
          className={` ${
            darkMode && 'text-neutral-60'
          } my-auto pt-[0.125rem] w-11 h-6 text-sm font-bold text-neutral-80 relative left-5 fill pr-2`}
        >
          <Spinner
            colour={` stroke-neutral-60 ${darkMode && 'stroke-white'}`}
          />
        </span>
      )}
      <span
        className={` my-auto pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-100 ease-in-out  
           ${enabled || publishing ? 'translate-x-2' : 'translate-x-[-2.25rem]'} 
         `}
      />
    </button>
  );
}
