import TextSaveButton from 'pages/Rule/Header/TextSaveButton';
import { Optional, Rule } from 'shared/legacy-types';
import ToggleButton from 'components/Toggles/ToggleButton';
import { ENABLED } from 'ui/constants/rules';
import Breadcrumbs from 'components/Breadcrumbs';
import PreviewButton from './PreviewButton';

interface Props {
  rule: Optional<Rule, 'id'>;
  onChange: (rule: Optional<Rule, 'id'>) => void;
  onSave: () => void;
  loading: boolean;
  success: boolean;
  isEditing: boolean;
  isRuleValid: boolean | undefined;
  checkInvalidFields: () => void;
  onToggleChange: (enabled: boolean) => Promise<void>;
  disableToggle: boolean;
  disableSaveButton: boolean;
}

export default function Header({
  onChange,
  rule,
  loading,
  success,
  onSave,
  isEditing,
  isRuleValid,
  checkInvalidFields,
  onToggleChange,
  disableSaveButton,
  disableToggle,
}: Props) {
  function saveTitle(event: any) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      document.getElementById('rule-title')?.blur();
      onSave();
    }
  }

  return (
    <div
      className={`z-40 flex h-[7rem] justify-between bg-white px-8 pb-5 pt-8 sm:px-[-1.5rem] md:mx-[-1.5rem] lg:mx-[-1.5rem] xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem] -mt-6
        `}
      style={{ boxShadow: '0rem 0.313rem 1rem rgba(5, 26, 22, 0.05)' }}
    >
      <div className="flex-1">
        <Breadcrumbs link="/" linkLabel="Rules" currentPage={rule.name!} />
        <h3
          className={`font-bold ${isEditing ? 'text-neutral-100' : 'text-neutral-100'} max-w-2xl`}
        >
          <input
            className="text-red peer:focus:truncate ml-[-0.25rem] h-[1.938rem] w-full overflow-hidden truncate border-0 bg-white pl-[0.25rem] text-2xl underline underline-offset-4 focus:pl-[0.25rem] focus:pt-[0.125rem] focus:outline-none decoration-neutral-40 focus:decoration-primary-100 decoration-1"
            placeholder="Untitled rule"
            id="rule-title"
            value={rule?.name}
            onKeyDown={(event) => saveTitle(event)}
            onChange={(event) => {
              onChange({ ...rule, name: event.target.value });
            }}
            aria-label="Rule title"
          />
        </h3>
      </div>
      <div className="flex h-[2.625rem] w-auto space-x-3 ">
        <TextSaveButton
          loading={loading}
          success={success}
          onSave={onSave}
          isEditing={isEditing}
          disabled={disableSaveButton}
          rule={rule}
        />
        <PreviewButton
          rule={rule}
          isRuleValid={isRuleValid}
          checkInvalidFields={checkInvalidFields}
          isEditing={isEditing}
        />
        <ToggleButton
          disabled={disableToggle}
          onChange={onToggleChange}
          label={['On', 'Off']}
          enable={rule.status === ENABLED}
          checkIsValid={checkInvalidFields}
        />
      </div>
    </div>
  );
}
