import { CrossIcon } from 'components/Icons';
import useBannerDispatch from 'state/banners/useBannerDispatch';
import useUserSelector from 'state/user/useUserSelector';
import useVerifyEmail from 'hooks/setup/useVerifyEmail';

export default function Banner(): JSX.Element {
  const { removeBanner } = useBannerDispatch();
  const { user } = useUserSelector();
  const { resendEmail, verifySent } = useVerifyEmail();

  return (
    <div
      className={`bg-secondary-100 z-50 fixed top-0 w-full py-4 px-6 flex min-h-[49px] justify-center`}
    >
      <div className={`text-white text-sm mx-auto`}>
        <div className="flex">
          <span>
            Please verify your email using the link we've sent to{' '}
            <span className="font-bold">{user?.email}</span>.
          </span>
          <span
            className={`font-bold ml-1 ${
              verifySent
                ? 'text-secondary-60'
                : 'underline cursor-pointer text-primary-100'
            }`}
            onClick={() => !verifySent && resendEmail()}
          >
            Resend email
          </span>
          <div
            className="text-white text-sm ml-auto cursor-pointer absolute right-4"
            onClick={() => {
              removeBanner();
            }}
          >
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
