import LimitCounter from './LimitCounter';
import PlanBadge from './PlanBadge';

interface props {
  limit: number | undefined;
  count: number;
  atLimit: boolean;
}

export default function PlanBadgeAndCounter({ limit, count, atLimit }: props) {
  return (
    <div className="my-auto">
      <div className="flex ml-3 h-6">
        {limit && (
          <>
            <div className="mr-4  my-auto">
              <LimitCounter limit={limit} count={count} atLimit={atLimit} />
            </div>
            <span className="bg-neutral-40 w-px"></span>
          </>
        )}
        <div className={`ml-0 my-auto ${limit && 'ml-4'} `}>
          <PlanBadge />
        </div>
      </div>
    </div>
  );
}
