import { useState, useRef, useContext } from 'react';
import { TickIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import { RuleContext } from 'pages/Rule/RuleContainer';

interface Option {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  options: Option[];
  selectedValue: string | undefined;
  selectValue: (value: string) => void;
}

export default function ExpressionDropdown({
  label,
  options,
  selectedValue,
  selectValue,
}: Props) {
  const { highlightInvalidFields } = useContext(RuleContext);
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  function select(value: string) {
    selectValue(value);
    setOpen(false);
  }

  function getLabel() {
    const selectedLabel =
      options &&
      options.find((option) => option.value === selectedValue)?.label;

    if (selectedLabel) {
      return selectedLabel;
    }
    return <div className="font-normal text-neutral-80 truncate">{label}</div>;
  }

  function renderDropdownMenu() {
    if (!options || options.length === 0) {
      return null;
    }

    function renderOptions() {
      return options.map((option) => {
        const { label, value } = option;
        const selected = value === selectedValue;

        return (
          <div
            key={value}
            className={`bg-white 
            ${selected && 'font-bold !leading-5'} 
            p-3 rounded text-sm hover:cursor-pointer flex justify-between`}
            onClick={() => select(value)}
          >
            {label}
            {selected && <TickIcon />}
          </div>
        );
      });
    }
    return (
      <div
        aria-label="Expression metric list"
        className="absolute !z-50 w-80 bg-white rounded-xl mt-1"
      >
        <div
          ref={ref}
          className="py-6 px-4 text-sm border rounded-xl border-neutral-20 text-left"
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          {renderOptions()}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`text-sm text-left text-neutral-100 hover:cursor-pointer 
        ${highlightInvalidFields && !selectedValue && 'bg-white rounded'}
        `}
        onClick={() => setOpen(!open)}
        aria-label="Dropdown to select expression metric"
      >
        <div
          tabIndex={0}
          className={`focus:border-primary-100 focus:border-2 focus:!pl-[0.56rem] focus:!pt-[0.525rem] pl-[0.688rem] pr-10 focus:pr-[40px] rounded h-[2.313rem] relative truncate
          ${
            highlightInvalidFields && !selectedValue
              ? 'bg-error bg-opacity-[.2] border border-error border-opacity-[.4] focus:bg-white !pl-[0.613rem] !pt-[0.588rem]'
              : 'border-0 border-neutral-40 bg-white pt-[0.65rem] pb-[0.688rem]'
          }`}
        >
          {getLabel()}
        </div>
        <div
          className={`pointer-events-none absolute right-[0.875rem] flex items-center
          ${open ? 'top-[1rem]' : 'top-[1rem]'}
          `}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {open ? (
              <path
                d="M1 5.5L5 1.5L9 5.5"
                className="stroke-black"
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            ) : (
              <path
                d="M1 0.5L5 4.5L9 0.5"
                className="stroke-neutral-80"
                strokeWidth="1.33333"
                strokeLinejoin="round"
              />
            )}
          </svg>
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
