import { useState } from 'react';

import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import Modal from 'components/Modals/Modal';

import ChooseSellerVendor from './ChooseSellerVendor';
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ChoosePlatformModal({ open, setOpen }: Props) {
  const [selectedPlatform, setSelectedPlatform] = useState('');

  function continueToAmazon() {
    window.location.replace(
      `/v1/oauth/sp-api-redirect?platform=${selectedPlatform}&token=${localStorage.getItem('token')}`,
    );
  }

  function selectPlatform(checked: boolean, platform: string) {
    if (!checked) {
      setSelectedPlatform('');
      return;
    }

    setSelectedPlatform(platform);
  }

  function handleClose() {
    setSelectedPlatform('');
    setOpen(false);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex justify-center h-[29rem] w-[38.75rem] bg-white  p-[3rem] ">
        <span className="absolute right-[2rem] top-[2rem] hover:cursor-pointer">
          <CrossIcon
            onClick={() => handleClose()}
            aria-label="back to settings"
          />
        </span>
        <div className="">
          <ChooseSellerVendor
            selectPlatform={selectPlatform}
            selectedPlatform={selectedPlatform}
          />
          <div className="mx-auto w-[14rem] mt-8">
            <PrimaryButton
              size="lg"
              disabled={!selectedPlatform}
              onClick={continueToAmazon}
            >
              <div>Continue to Amazon</div>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
