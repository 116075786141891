import { useEffect, useState } from 'react';
import useSlackRequests from 'api/hooks/requests/useSlackRequests';
import { SEND_SLACK } from 'ui/constants/actions';
import { Action } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';

type SlackChannel = {
  id: string;
  name: string;
};

export default function useSlack(setSelection: (selection: Action) => void) {
  const [channels, setChannels] = useState<SlackChannel[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useUserSelector();
  const { fetchSlackChannels } = useSlackRequests();

  const slackIntegration = user?.slackIntegration;

  useEffect(() => {
    async function getSlackChannels() {
      setLoading(true);
      const response = await fetchSlackChannels();
      setLoading(false);
      setChannels(response.data);
    }
    slackIntegration && getSlackChannels();
    // eslint-disable-next-line
  }, [slackIntegration]);

  const formattedChannels =
    channels &&
    channels.map((channel) => {
      return {
        value: channel.id,
        label: channel.name,
      };
    });

  const updateSelection = (id: string) => {
    // find from list of channels
    const channel = channels && channels.find((channel) => channel.id === id);
    if (!channel) return;

    setSelection({
      type: SEND_SLACK,
      properties: {
        channelId: id,
        channelName: channel.name,
      },
    });
  };

  return { formattedChannels, slackIntegration, loading, updateSelection };
}
