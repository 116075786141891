import { templatesList } from 'constants/templates/templates';
import Tab from '../../components/Tabs/Tab';

interface Props {
  setSelection: (selection: string) => void;
  selection: string;
}

export default function TemplateHeader({ setSelection, selection }: Props) {
  function renderNavTabs() {
    return templatesList.map((tab) => {
      return (
        <Tab
          selection={selection}
          setSelection={setSelection}
          name={tab.name}
          label={tab.label}
        />
      );
    });
  }
  return (
    <div
      className={`fixed w-full z-40 bg-white sm:px-[-1.5rem] md:mx-[-1.5rem] lg:mx-[-1.5rem] xl:mx-[-4.875rem] min-[1440px]:mx-[-7.5rem] -mt-6`}
      style={{ boxShadow: '0rem 0.313rem 1rem rgba(5, 26, 22, 0.05)' }}
    >
      <div className="min-[1440px]:px-[7.5rem] xl:px-[4.875rem] lg:px-[1.5rem] md:px-6 sm:px-6">
        <h2 className="font-bold mb-[0.938rem] pt-[1.688rem]">Templates</h2>
        <div className="flex w-content space-x-5 text-neutral-80">
          <Tab
            selection={selection}
            setSelection={setSelection}
            name="all"
            label="All"
          />
          {renderNavTabs()}
        </div>
      </div>
    </div>
  );
}
