import Dates from 'components/DatePicker/Dates';
import useUser from 'hooks/useUser';
import { useParams } from 'react-router-dom';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  setEndDate: (date: Date) => void;
  setStartDate: (date: Date) => void;
  minimumDate?: Date;
}

export default function DetailHeader({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  minimumDate,
}: Props) {
  const { getProfileData } = useUser();
  const { profileId } = useParams<{ profileId: string }>();

  const profile = getProfileData(parseInt(profileId || ''));

  return (
    <div className="mt-[-8px] flex border-b border-neutral-40 pb-4">
      <div className="h-8 border border-neutral-40 bg-neutral-20 !leading-8 px-3 text-neutral-80 rounded-lg font-medium text-xs">
        {profile?.accountName}
      </div>
      <div className="h-8 border border-neutral-40 bg-neutral-20 !leading-8 px-3 text-neutral-80 rounded-lg font-medium text-xs ml-4">
        {profile?.country}
      </div>
      <div className="ml-4">
        <Dates
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          minimumDate={minimumDate}
        />
      </div>
    </div>
  );
}
