interface Props {
  background?: string;
  iconColour?: string;
  strokeColour?: string;
}

export function NegativeCircleIcon({}: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="2" fill="#6CE1B2" />
      <circle cx="8" cy="8" r="5" fill="white" />
    </svg>
  );
}
