import { ChangeEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { sendEvent } from 'helpers/analytics';
import {
  UseHistoryContainerGetRunsQuery,
  UseHistoryContainerGetRunsDocument,
  UseHistoryContainerGetRulesQuery,
  UseHistoryContainerGetRulesDocument,
  UseHistoryContainerGetBrandsDocument,
  UseHistoryContainerGetBrandsQuery,
} from 'codegen/graphql';
import { ApolloError, useQuery } from '@apollo/client';
import { Option } from 'ui/components/Dropdowns/RadioDropdown';

type HistoryContainerHook = {
  runConnection: UseHistoryContainerGetRunsQuery['runs'] | undefined;
  rules: UseHistoryContainerGetRulesQuery['rules'];
  runsLoading: boolean;
  rulesLoading: boolean;
  selectRule: (event: ChangeEvent<HTMLInputElement>) => void;
  deSelectRule: () => void;
  selectRun: () => void;
  ruleFilter: string;
  getRuleOptions: () => Option[];
  runsError: ApolloError | undefined;
  rulesError: ApolloError | undefined;
  brandFilter: string;
  brandOptions: Option[];
  brandsError: ApolloError | undefined;
  brandsLoading: boolean;
  setBrandFilter: (value: string) => void;
  fetchMoreRuns: () => Promise<void>;
  hasNextPage: boolean;
};

const RUNS_PER_PAGE = 20;

export default function useHistoryContainer(): HistoryContainerHook {
  const [ruleFilter, setRuleFilter] = useState<string>('');
  const [brandFilter, setBrandFilter] = useState<string>('all');

  const navigate = useNavigate();
  const { ruleId } = useParams();

  const brandId = brandFilter === 'all' ? null : brandFilter;

  const {
    data,
    loading: runsLoading,
    error: runsError,
    fetchMore,
  } = useQuery<UseHistoryContainerGetRunsQuery>(
    UseHistoryContainerGetRunsDocument,
    {
      variables: { first: RUNS_PER_PAGE, ruleId, brandId, after: null },
    },
  );
  const {
    data: rulesData,
    loading: rulesLoading,
    error: rulesError,
  } = useQuery<UseHistoryContainerGetRulesQuery>(
    UseHistoryContainerGetRulesDocument,
  );
  const {
    data: brandsData,
    loading: brandsLoading,
    error: brandsError,
  } = useQuery<UseHistoryContainerGetBrandsQuery>(
    UseHistoryContainerGetBrandsDocument,
    {
      variables: { onlyWithMarketplacesConnected: true },
    },
  );

  const runConnection = data?.runs;
  const rules = rulesData?.rules ?? [];
  const brands = brandsData?.brands ?? [];
  const hasNextPage = runConnection?.pageInfo.hasNextPage ?? false;

  const formattedBrands = brands.map((brand) => {
    const { id, name } = brand;
    return { value: id, label: name };
  });

  const brandOptions = [
    { label: 'All Brands', value: 'all' },
    ...formattedBrands,
  ];

  useEffect(
    function initRuleId() {
      if (ruleId != null) {
        setRuleFilter(ruleId);
      } else {
        setRuleFilter('all');
      }
    },
    // eslint-disable-next-line
    [ruleId],
  );

  function selectRule(event: ChangeEvent<HTMLInputElement>): void {
    if (event.target.value === 'all') {
      navigate(`/history`);
      return;
    }
    navigate(`/history/${event.target.value}`);
  }

  function deSelectRule(): void {
    setRuleFilter('all');
    navigate(`/history`);
  }

  function getRuleOptions(): Option[] {
    if (rules.length > 0) {
      const options = rules.map((rule) => {
        const { id, name } = rule;
        return { value: id, label: name };
      });
      return [{ ...{ label: 'All Rules', value: 'all' } }, ...options];
    }

    return [{ label: 'All Rules', value: 'all' }];
  }

  function selectRun(): void {
    sendEvent('click_run');
  }

  async function fetchMoreRuns(): Promise<void> {
    if (hasNextPage) {
      await fetchMore({
        variables: {
          after: data?.runs.pageInfo.endCursor,
        },
      });
    }
  }

  return {
    runConnection,
    rules,
    runsLoading,
    rulesLoading,
    selectRule,
    deSelectRule,
    selectRun,
    ruleFilter,
    getRuleOptions,
    runsError,
    rulesError,
    brandFilter,
    brandOptions,
    brandsError,
    brandsLoading,
    setBrandFilter,
    fetchMoreRuns,
    hasNextPage,
  };
}
