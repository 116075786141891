import useRequest from 'api/hooks/useRequest';

export default function useReportingRequests() {
  const { request } = useRequest();

  const getCampaignTimeSeriesMetrics = async (
    profileId: string,
    campaignId: string,
    fromDate: string,
    toDate: string,
  ) => {
    return await request(
      'get',
      `/v1/reporting/time-series/profiles/${profileId}/campaigns/${campaignId}?fromDate=${fromDate}&toDate=${toDate}`,

      {},
    );
  };

  const getCampaignReportingMetrics = async (
    profileId: string,
    fromDate: string,
    toDate: string,
    campaignId?: string | null,
  ) => {
    const campaignIdParam = campaignId ? `&campaignId=${campaignId}` : '';

    return await request(
      'get',
      `/v1/reporting/metrics/profiles/${profileId}/campaigns?fromDate=${fromDate}&toDate=${toDate}${campaignIdParam}`,
      {},
    );
  };

  const getProfileTimeSeriesMetrics = async (
    profileId: string,
    fromDate: string,
    toDate: string,
  ) => {
    return await request(
      'get',
      `/v1/reporting/time-series/profiles/${profileId}?fromDate=${fromDate}&toDate=${toDate}`,
      {},
    );
  };

  const getProfileReportingTotals = async (
    profileId: string,
    fromDate: string,
    toDate: string,
  ) => {
    return await request(
      'get',
      `/v1/reporting/metrics/profiles/${profileId}?fromDate=${fromDate}&toDate=${toDate}`,
      {},
    );
  };

  const findProfileMinimumDate = async (profileId: string) => {
    return await request(
      'get',
      `/v1/reporting/metrics/profiles/${profileId}/minimum-date`,
      {},
    );
  };

  return {
    getCampaignTimeSeriesMetrics,
    getProfileTimeSeriesMetrics,
    getCampaignReportingMetrics,
    getProfileReportingTotals,
    findProfileMinimumDate,
  };
}
