import { Rule } from 'shared/legacy-types';

type Props = {
  rule: Rule;
};

export default function ArchivedRuleCard({ rule }: Props) {
  const { id } = rule;

  return (
    <div
      className={`bg-white mb-2 pl-3 pr-6 py-[0.9063rem] rounded border border-l-4 border-neutral-40 h-[3.5rem]`}
      key={id}
    >
      <div className="flex justify-between">
        <div className="text-sm text-neutral-80 font-bold !leading-[1.625rem]">
          {rule.name}
        </div>
      </div>
    </div>
  );
}
