import { ChangeEvent } from 'react';

interface Props {
  error?: boolean;
  value?: string;
  width?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function TextArea({ width = 'full', ...props }: Props) {
  const { error, value, onChange } = props;
  return (
    <div className="relative">
      <textarea
        id="name"
        name="name"
        value={value}
        className={` placeholder-transparent resize-none overflow-auto rounded-md top-[1.625rem] border border-t-[1.95rem] border-white peer pl-4 pb-3 px-3 text-neutral-100 text-base outline outline-neutral-40 min-h-[7.25rem] max-h-[25rem] ${`w-${width}`} outline-1
        focus:outline-2 focus:outline-primary-80 ${error ? 'outline-error' : 'outline-neutral-40'}`}
        placeholder="Message"
        onChange={onChange}
      />
      <label
        htmlFor="name"
        className="absolute tracking-tighter rounded left-3.5 top-[1rem] peer:top-[0.875rem] peer-focus:top-[1rem] pr-[9.25rem] pb-0.5 pl-1 text-neutral-80 text-xs ease-in-out duration-100 peer-placeholder-shown:text-base peer-placeholder-shown:text-neutral-80 peer-placeholder-shown:top-[1.125rem] bg-white peer-focus:text-neutral-80 peer-focus:text-xs "
      >
        Message
      </label>
    </div>
  );
}
