import './dates.css';
import { TickIcon } from 'components/Icons';

interface Props {
  children: React.ReactNode;
  updatePresetDateRange: (dateRange: string) => void;
  presetDateRange: string;
}

export default function DateContainer({
  children,
  updatePresetDateRange,
  presetDateRange,
}: Props) {
  return (
    <div
      className=" z-50 flex border border-neutral-40 rounded-[12px] mr-[-20px] bg-white min-h-[22.5rem]"
      style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
    >
      <div className="bg-primary-20 px-3 py-4 border-r border-neutral-40 rounded-l-[12px] text-sm">
        <div
          className={`p-3 w-[175px] hover:cursor-pointer flex justify-between ${
            presetDateRange === 'yesterday' && 'font-bold'
          }`}
          onClick={() => updatePresetDateRange('yesterday')}
        >
          Yesterday
          {presetDateRange === 'yesterday' && <TickIcon />}
        </div>
        <div
          className={`p-3 hover:cursor-pointer flex justify-between ${presetDateRange === '7' && 'font-bold'}`}
          onClick={() => updatePresetDateRange('7')}
        >
          Last 7 days
          {presetDateRange === '7' && <TickIcon />}
        </div>
        <div
          className={`p-3 hover:cursor-pointer flex justify-between ${presetDateRange === '30' && 'font-bold'}`}
          onClick={() => updatePresetDateRange('30')}
        >
          Last 30 days
          {presetDateRange === '30' && <TickIcon />}
        </div>
        {/* <div
          className={`p-3 hover:cursor-pointer flex justify-between ${presetDateRange === 'all' && 'font-bold'}`}
          onClick={() => updatePresetDateRange('all')}
        >
          All time
          {presetDateRange === 'all' && <TickIcon />}
        </div> */}
      </div>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  );
}
