import { TickCircle, InfoIcon } from 'components/Icons';
import { User } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import useVerifyEmail from 'hooks/setup/useVerifyEmail';

export default function SendEmail() {
  const { user } = useUserSelector();
  const { emailVerified } = user as User;
  const { resendEmail, verifySent } = useVerifyEmail();

  return (
    <>
      <div className="mt-2 text-sm">
        <div className="text-neutral-80 pb-6 mb-6 leading-6 border-b border-neutral-40">
          <span>Get notified when your conditions are met.</span>
        </div>
      </div>
      <>
        <div className="bg-primary-40 border-primary-80 flex w-fit rounded border px-[0.688rem] py-[0.4rem]">
          <span className="mr-1">
            <InfoIcon />
          </span>
          {emailVerified ? (
            <span className="mt-px text-sm !leading-5">
              Emails will be sent to{' '}
              <span className="font-bold">{user?.email}</span>
            </span>
          ) : (
            <span className="text-sm">
              <div>
                Verify your email using the link we've sent you to start using
                this action.
                {verifySent ? (
                  <span className="ml-1 font-bold underline">Email sent</span>
                ) : (
                  <span
                    className="ml-1 cursor-pointer font-bold underline"
                    onClick={resendEmail}
                  >
                    Resend email
                  </span>
                )}
              </div>
            </span>
          )}
        </div>
      </>

      <div className="mt-[8.563rem] flex justify-center">
        <div>
          <div className="mb-2 flex justify-center">
            <TickCircle
              iconColour="stroke-white"
              background="fill-secondary-40"
              size="20"
            />
          </div>
          <div className="text-neutral-60">
            This action has no other settings
          </div>
        </div>
      </div>
    </>
  );
}
