import useUserSelector from 'state/user/useUserSelector';
import { Profile, User, Config } from 'shared/legacy-types';
import { brandOptions, getMarketplaces } from 'helpers/user/userProfiles';

export default function useUser() {
  const { user, userConfig } = useUserSelector();
  const { selectedProfiles } = user || ({} as User);
  const profiles = selectedProfiles as Profile[];
  const { profilesWithSpendTarget } = userConfig || ({} as Config);

  function getBrandOptions() {
    return brandOptions(profiles);
  }

  function getMarketOptions(selectedBrandName: string) {
    return getMarketplaces(profiles, selectedBrandName);
  }

  function getProfileData(profileId: number) {
    return profiles.find((profile) => profile.id === profileId);
  }

  function allProfilesHaveSpendTargets(profileIds: number[]) {
    if (!profileIds || profileIds.length === 0) return false;
    return profileIds.every((id) =>
      profilesWithSpendTarget.includes(id.toString()),
    );
  }

  return {
    getBrandOptions,
    getMarketOptions,
    getProfileData,
    userProfiles: profiles,
    allProfilesHaveSpendTargets,
  };
}
