import { useEffect, useState } from 'react';
import useSlackRequests from 'api/hooks/requests/useSlackRequests';
import useUserSelector from 'state/user/useUserSelector';
import * as Sentry from '@sentry/react';

type SlackWorkspace = {
  name: string;
  icon: string;
};

export default function useIntegrations() {
  const [slackWorkspace, setSlackWorkspace] = useState<SlackWorkspace>();
  const [loading, setLoading] = useState<boolean>(true);

  const { user, isInternalUser } = useUserSelector();
  const slackIntegrated = user?.slackIntegration;

  const { fetchSlackWorkspace } = useSlackRequests();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const getSlackWorkspace = async () => {
      try {
        setLoading(true);
        const response = await fetchSlackWorkspace();
        setSlackWorkspace(response.data);
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
      }
    };
    slackIntegrated && getSlackWorkspace();
    !slackIntegrated && setLoading(false);
    // eslint-disable-next-line
  }, []);

  function continueToSlack() {
    window.location.replace(`/v1/oauth/slack-redirect?token=${token}`);
  }

  return {
    continueToSlack,
    slackWorkspace,
    loading,
    slackIntegrated,
    isInternalUser,
  };
}
