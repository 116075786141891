interface Props {
  background?: string;
  iconColour?: string;
  size?: string;
}

export function BudgetIcon({
  background = 'fill-primary-80',
  iconColour = 'fill-white',
  size = '16',
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="2" className={background} />
      <path
        d="M8.1951 3.39394C8.07122 3.39394 7.96018 3.31754 7.91589 3.20185C7.87161 3.08616 7.76056 3.00977 7.63669 3.00977H5.90625L6.77618 5.18711H9.20199L9.91885 3.39394H8.1951Z"
        className={iconColour}
      />
      <path
        d="M9.19547 6.15967L12.757 10.9958C12.9098 11.2017 13 11.4506 13 11.7192C13 12.4267 12.3783 13 11.6112 13H4.38879C3.62173 13 3 12.4267 3 11.7192C3 11.4508 3.09 11.2017 3.24306 10.9958L6.80529 6.15967H9.19547Z"
        className={iconColour}
      />
    </svg>
  );
}
