import { PlanTier } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import { TickCircle } from 'components/Icons';
import { NeutralCircle } from 'components/Icons';
import CallToAction from './CallToAction';
import { STARTER } from 'constants/plans';

interface Props {
  tier: PlanTier;
  index: number;
  setOpen: (open: boolean) => void;
}

export default function Tier({ tier, index, setOpen }: Props) {
  const { userPlan } = useUserSelector();
  const { plan, label, description, pricing, mostPopular, tierFeatures } = tier;
  const isCurrentPlan = userPlan === plan;
  const isStarterPlan = plan === STARTER;
  const starterPlanAvailable = plan === STARTER && userPlan !== STARTER;

  const price = () => {
    if (!pricing) {
      return (
        <div className="flex leading-[26px]">
          <h3 className="font-bold">Free</h3>
          <span className="text-neutral-80 ml-1">Forever</span>
        </div>
      );
    }

    return (
      <div className="flex leading-[26px]">
        <span className="text-neutral-80 mr-1">From</span>
        <h3 className="font-bold">£{pricing.monthly}</h3>
        <span className="text-neutral-80 ml-1">/month</span>
      </div>
    );
  };

  const features = () => {
    return (
      tierFeatures &&
      tierFeatures.map((feature, index) => {
        return (
          <div className="flex mb-4" key={index}>
            {feature.available ? <TickCircle size="20" /> : <NeutralCircle />}
            <div className="text-sm text-neutral-100 ml-2">{feature.label}</div>
          </div>
        );
      })
    );
  };

  return (
    <div
      key={index}
      className="border border-neutral-40 rounded-md p-6 w-[18rem]"
      style={{ boxShadow: '0rem 0.5rem 0.75rem 0rem rgba(5, 26, 22, 0.08)' }}
    >
      <div className="font-medium text-primary-100 text-sm">{label}</div>
      <div className="mt-2 flex">{price()}</div>
      <div className="text-neutral-80 mt-2 text-xs mb-6">{description}</div>
      <div>{features()}</div>
      <div className="border-t border-neutral-40 pt-6 mt-6 pb-4 flex justify-center">
        <CallToAction
          plan={plan}
          mostPopular={mostPopular}
          isCurrentPlan={isCurrentPlan}
          isStarterPlan={isStarterPlan}
          setOpen={setOpen}
        />
      </div>
      {plan !== 'starter' && (
        <div className="text-xs text-neutral-80 min-w-[15rem]">
          Have a custom requirement?{' '}
          <a
            href="https://www.meetfern.ai/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-primary-100 underline font-bold">
              Contact sales
            </span>
          </a>
        </div>
      )}
      {starterPlanAvailable && (
        <div className="text-xs text-neutral-80 min-w-[15rem]">
          Downgrade by cancelling your current plan
        </div>
      )}
    </div>
  );
}
