import { useAppSelector } from 'state/hooks';
import { capitalizeFirstLetter } from 'helpers/textFormat';

export default function useUserSelector() {
  return {
    user: useAppSelector((state) => state.user.user),
    access: useAppSelector((state) => state.user.access),
    userStatus: useAppSelector((state) => state.user.status),
    isInternalUser: useAppSelector(
      (state) => state.user.access?.preview.internal,
    ),
    userLimits: useAppSelector((state) => state.user.access?.limits),
    userPlan: useAppSelector((state) => state.user.access?.plan),
    planLabel: useAppSelector((state) =>
      capitalizeFirstLetter(state.user.access?.plan),
    ),
    userConfig: useAppSelector((state) => state.user.config),
    trialEndDate: useAppSelector((state) => state.user.user?.trialEnd),
    subscriptionStatus: useAppSelector(
      (state) => state.user.user?.subscriptionStatus,
    ),
    permissions: useAppSelector((state) => state.user.access?.permissions),
    usedFreeTrials: useAppSelector((state) => state.user.user?.usedFreeTrials),
  };
}
