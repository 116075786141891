interface Props {
  active?: boolean;
}

export function Question({ active }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.5"
        className={`${!active && 'stroke-neutral-80'} ${active && 'fill-primary-100'}`}
      />
      <path
        d="M8 8.15039L8 8.90039"
        className={`${active ? 'stroke-white' : 'stroke-neutral-80'}`}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.3164H8.00667"
        className={`${active ? 'stroke-white' : 'stroke-neutral-80'}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04608 7.22544C8.77586 7.48842 8.40685 7.65039 8 7.65039V8.65039C8.34573 8.65039 8.68602 8.57871 9 8.44168C9.13507 8.38273 9.26526 8.31169 9.38893 8.22907C9.80005 7.95436 10.1205 7.56392 10.3097 7.1071C10.4989 6.65029 10.5484 6.14762 10.452 5.66267C10.3555 5.17771 10.1174 4.73226 9.76777 4.38262C9.41814 4.03299 8.97268 3.79489 8.48773 3.69843C8.00277 3.60197 7.50011 3.65147 7.04329 3.84069C6.58648 4.02991 6.19603 4.35034 5.92133 4.76147C5.8387 4.88513 5.76766 5.01532 5.70871 5.15039C5.57168 5.46437 5.5 5.80467 5.5 6.15039H6.5C6.5 5.74647 6.65966 5.37983 6.91931 5.11015C6.93089 5.09811 6.94267 5.08628 6.95464 5.07465C7.22342 4.81342 7.58976 4.65204 7.9938 4.6504C7.99586 4.6504 7.99793 4.65039 8 4.65039C8.00345 4.65039 8.00691 4.6504 8.01036 4.65043C8.83062 4.65597 9.4945 5.3199 9.49997 6.14018C9.49999 6.14358 9.5 6.14698 9.5 6.15039C9.5 6.15246 9.5 6.15453 9.49999 6.1566C9.49837 6.55632 9.3404 6.91915 9.08408 7.18711C9.07165 7.20011 9.05898 7.21289 9.04608 7.22544Z"
        className={`${active ? 'fill-white' : 'fill-neutral-80'}`}
      />
    </svg>
  );
}
