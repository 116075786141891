export function LargeSlackIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="3" fill="#28C585" />
      <path
        d="M6.81866 14.3549C6.81866 15.3988 5.97496 16.2425 4.93105 16.2425C3.88715 16.2425 3.04344 15.3988 3.04344 14.3549C3.04344 13.311 3.88715 12.4673 4.93105 12.4673H6.81866V14.3549ZM7.76246 14.3549C7.76246 13.311 8.60617 12.4673 9.65007 12.4673C10.694 12.4673 11.5377 13.311 11.5377 14.3549V19.0739C11.5377 20.1178 10.694 20.9615 9.65007 20.9615C8.60617 20.9615 7.76246 20.1178 7.76246 19.0739V14.3549Z"
        fill="white"
      />
      <path
        d="M9.64437 6.77522C8.60046 6.77522 7.75676 5.93151 7.75676 4.88761C7.75676 3.8437 8.60046 3 9.64437 3C10.6883 3 11.532 3.8437 11.532 4.88761V6.77522H9.64437ZM9.64437 7.73332C10.6883 7.73332 11.532 8.57703 11.532 9.62093C11.532 10.6648 10.6883 11.5085 9.64437 11.5085H4.91105C3.86714 11.5085 3.02344 10.6648 3.02344 9.62093C3.02344 8.57703 3.86714 7.73332 4.91105 7.73332H9.64437Z"
        fill="white"
      />
      <path
        d="M17.2148 9.62093C17.2148 8.57703 18.0585 7.73332 19.1024 7.73332C20.1463 7.73332 20.99 8.57703 20.99 9.62093C20.99 10.6648 20.1463 11.5085 19.1024 11.5085H17.2148V9.62093ZM16.271 9.62093C16.271 10.6648 15.4273 11.5085 14.3833 11.5085C13.3394 11.5085 12.4957 10.6648 12.4957 9.62093V4.88761C12.4957 3.8437 13.3394 3 14.3833 3C15.4273 3 16.271 3.8437 16.271 4.88761V9.62093Z"
        fill="white"
      />
      <path
        d="M14.3833 17.1863C15.4272 17.1863 16.271 18.03 16.271 19.0739C16.271 20.1178 15.4272 20.9615 14.3833 20.9615C13.3394 20.9615 12.4957 20.1178 12.4957 19.0739V17.1863H14.3833ZM14.3833 16.2425C13.3394 16.2425 12.4957 15.3988 12.4957 14.3549C12.4957 13.311 13.3394 12.4673 14.3833 12.4673H19.1167C20.1606 12.4673 21.0043 13.311 21.0043 14.3549C21.0043 15.3988 20.1606 16.2425 19.1167 16.2425H14.3833Z"
        fill="white"
      />
    </svg>
  );
}
