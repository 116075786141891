import {
  ConditionGroupOperator,
  ConditionOperator,
  UpdateBidAction,
  RuleStatus,
  ExpressionMetric,
  ExpressionOperator,
  ConditionExpression,
  DataType,
} from 'shared/legacy-types';
import { CURRENT_BID, UPDATE_BID } from 'ui/constants/actions';
import RightArrowsImage from 'components/Images/Templates/RightArrowsImage';
import { DEFAULT_PERIOD, LONG_PERIOD, TARGETS } from 'ui/constants/rules';

export const optimiseTargetBids = {
  id: 'optimise_target_bids',
  description:
    'Adjust your bids every day according to an ACOS goal using one rule.',
  goal: 'Optimise ACOS/ROAS',
  category: 'Advertising',
  featureToggle: false,
  image: RightArrowsImage,
  rule: {
    name: 'Optimise all target bids',
    adTypes: ['SP', 'SD', 'SB'],
    dataType: TARGETS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'Profitable Targets',
        operator: '||' as ConditionGroupOperator,
        period: DEFAULT_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: CURRENT_BID,
              value: 20,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${TARGETS}:acos`,
                operator: '<=' as ConditionOperator,
                value: '15',
              },
              {
                field: `${TARGETS}:bid`,
                operator: '<' as ConditionOperator,
                value: {
                  metric: 'costPerClick' as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '2',
                } as ConditionExpression,
              },
            ],
          },
        ],
      },
      {
        name: 'Unprofitable Targets',
        operator: '&&' as ConditionGroupOperator,
        period: DEFAULT_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: CURRENT_BID,
              value: -20,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${TARGETS}:acos`,
                operator: '>' as ConditionOperator,
                value: '15',
              },
            ],
          },
        ],
      },
      {
        name: 'Non-Converting Targets',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: UPDATE_BID,
            properties: {
              metric: 'cpc',
              value: -40,
              buffer: 1,
            },
          } as UpdateBidAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${TARGETS}:ad_orders`,
                operator: '=' as ConditionOperator,
                value: '0',
              },
              {
                field: `${TARGETS}:clicks`,
                operator: '>' as ConditionOperator,
                value: {
                  metric: 'profileCrClicks' as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '2',
                } as ConditionExpression,
              },
            ],
          },
        ],
      },
    ],
  },
};
