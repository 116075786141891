interface Props {
  error?: boolean;
  id?: string;
  select: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean | undefined;
  label?: string;
  size?: string;
  labelStyles?: string;
  approved?: boolean;
  rejected?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
}

export default function Checkbox(props: Props) {
  const {
    error,
    id,
    select,
    checked,
    label,
    size = '6',
    labelStyles,
    approved,
    rejected,
    disabled,
    ariaLabel,
  } = props;
  const smallCheckbox = size === '5';

  return (
    <div id={id} className={`flex h-${size} w-full`} aria-label={ariaLabel}>
      {!approved && !rejected && !disabled && (
        <label className="flex">
          <input
            id="check"
            type="checkbox"
            name="checkbox"
            className={`w-${size} h-${size} rounded 
          ${error ? 'border-error' : ''} 
          my-0 accent-primary-100 checked:before:bg-primary-100 
          ${
            smallCheckbox
              ? 'checked:before:pl-[0.085rem] checked:before:pt-[0.05rem]'
              : 'checked:before:pl-[0.1875rem] checked:before:pt-[0.1875rem]'
          } 
          checked:before:rounded checked:border-primary-100 before:bg-neutral-40 before:rounded before:border-neutral-40 border-0 hover:cursor-pointer peer`}
            value={id}
            checked={checked && (!approved || !rejected || !disabled)}
            onChange={select}
          />
          <svg
            className={`invisible peer-checked:visible ${
              smallCheckbox ? 'mt-[1px] ml-[-19px]' : 'mt-[3px] ml-[-19px]'
            } hover:cursor-pointer`}
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.91967 11.6403L10.1697 17.7653L18.15 6.675"
              stroke="#ffffff"
              strokeWidth="3"
            />
          </svg>
          <span
            className={`ml-8 items-center absolute text-sm !leading-6 text-neutral-100 hover:cursor-pointer w-auto inline ${
              checked && 'font-bold leading'
            } ${labelStyles}`}
          >
            {label}
          </span>
        </label>
      )}
      <div className="flex">
        {approved && (
          <svg
            className="hover:cursor-not-allowed"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame 533">
              <rect
                y="0.5"
                width="20"
                height="20"
                rx="3.33333"
                fill="#E6E8E8"
              />
              <path
                id="Line 3"
                d="M5.39562 10.456L8.66033 14.2648L13.6229 7.3683"
                stroke="white"
                strokeWidth="1.66667"
              />
            </g>
          </svg>
        )}
        {(rejected || disabled) && (
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame 534">
              <rect
                y="0.5"
                width="20"
                height="20"
                rx="3.33333"
                fill="#E6E8E8"
              />
              <line
                id="Line 280"
                x1="6"
                y1="10.5"
                x2="14"
                y2="10.5"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
}
