import { useEffect, useState, useContext } from 'react';

import { ActionContext } from '../ActionContext';
import { ReactComponent as PercentIcon } from 'icons/generic/percent.svg';
import Dropdown from 'components/Dropdowns/Dropdown';
import {
  CONDITION_OPTIONS,
  BID_OPTIONS,
  BUDGET_OPTIONS,
  BUFFER_OPTIONS,
  UPDATE_BID,
} from 'ui/constants/actions';
import { Action, UpdateBidAction } from 'shared/legacy-types';
import Tooltip from 'ui/Tooltips/Tooltip';

interface Props {
  setSelection: (selection: Action) => void;
  selection: Action;
  currentAction: string;
}

export default function UpdateValue({
  selection,
  currentAction,
  setSelection,
}: Props) {
  const [condition, setCondition] = useState<string>('increase');

  const { action } = useContext(ActionContext);

  const bidSelection = selection as UpdateBidAction;
  const isIncrease =
    bidSelection.properties?.value && bidSelection.properties?.value >= 0;
  const isDecrease =
    bidSelection.properties?.value && bidSelection.properties?.value < 0;
  const selectedAction = currentAction as 'updateBid' | 'updateBudget';

  // when switching tabs default to increase
  useEffect(
    function initCondition() {
      if (!bidSelection.properties?.value) {
        setCondition('increase');
        return;
      }

      if (selectedAction === action.type) {
        if (isIncrease) {
          setCondition('increase');
          return;
        }
        if (isDecrease) {
          setCondition('decrease');
          return;
        }
      }
    },
    // eslint-disable-next-line
    [selectedAction],
  );

  // remove minus for display
  const getInputValue = () => {
    if (!isIncrease && !isDecrease) {
      return '';
    }

    if (isIncrease) {
      return bidSelection?.properties.value!.toString();
    }

    return bidSelection?.properties.value!.toString().substring(1);
  };

  function getOptions() {
    if (currentAction === UPDATE_BID) {
      return BID_OPTIONS;
    }

    return BUDGET_OPTIONS;
  }

  // if decrease is selection then change value to minus
  function handleConditionSelection(value: string) {
    setCondition(value);
    const getValue = () => {
      // if we have a value already
      if (bidSelection.properties.value && value === 'decrease') {
        return -bidSelection.properties.value;
      }

      // if no value when we open modal
      if (value === 'decrease') {
        return '-0';
      }

      return getInputValue();
    };

    setSelection({
      type: selectedAction,
      properties: {
        ...bidSelection.properties,
        value: +getValue(),
      },
    });
  }

  function handleValue(event: React.ChangeEvent<HTMLInputElement>) {
    const value =
      condition === 'increase' ? +event.target.value : -event.target.value;
    setSelection({
      type: selectedAction,
      properties: {
        ...bidSelection.properties,
        value,
      },
    });
  }

  function message() {
    if (selectedAction === UPDATE_BID) {
      return (
        <span>
          Choose to <span className="font-medium">increase</span> or{' '}
          <span className="font-medium">decrease</span> based on the{' '}
          <span className="font-medium">Current bid</span> or{' '}
          <span className="font-medium">CPC</span> by a{' '}
          <span className="font-medium">percentage</span> when your conditions
          are met.
        </span>
      );
    }

    return (
      <span>
        Choose to <span className="font-medium">increase</span> or{' '}
        <span className="font-medium">decrease</span> the{' '}
        <span className="font-medium">Current budget</span> by a{' '}
        <span className="font-medium">%</span> when your conditions are met.
      </span>
    );
  }
  return (
    <div className="mt-2">
      <div className="border-b border-neutral-40 mb-6">
        <div className="text-neutral-80 pb-6 text-sm  max-w-[30.188rem]">
          {message()}
        </div>
      </div>

      <Dropdown
        label="Select condition"
        options={CONDITION_OPTIONS}
        selectedValue={condition}
        selectValue={(type) => handleConditionSelection(type)}
        ariaLabel="Select condition"
      />
      <div className="mt-4 flex flex-row">
        <div className="flex-1">
          <Dropdown
            options={getOptions()}
            selectedValue={bidSelection?.properties.metric}
            selectValue={(selection) =>
              setSelection({
                type: selectedAction,
                properties: {
                  ...bidSelection.properties,
                  metric: selection,
                },
              })
            }
          />
        </div>
        <div className="text-neutral-80 mx-4 text-sm !leading-[2.563rem]">
          by
        </div>
        <div className="relative flex-1">
          <input
            value={getInputValue()}
            placeholder="Enter value"
            required
            onChange={(event) => handleValue(event)}
            className="border-neutral-40 focus:border-primary-100 mb-0 mt-0 block h-[2.563rem] w-full p-2 text-sm focus:border-2 focus:pl-[0.438rem] focus:outline-none"
            aria-label="Update target bid value"
          />
          <div className="absolute right-4 top-4">
            <PercentIcon />
          </div>
        </div>
      </div>
      <div className="border-neutral-40 z-50 mt-6 border-t pt-6">
        <div className="flex">
          <div className="text-neutral-80 mb-[0.3125rem] mr-1 text-xs">
            Buffer time
          </div>
          <Tooltip
            content={
              <>
                <div>
                  When fern finds a change in this data and applies your chosen
                  action, how long do you want to wait before applying again.
                </div>
                <br />
                <div>The longer you wait the more data you collect.</div>
              </>
            }
          />
        </div>
        {/* Convert buffer to string for dropdown and back to number for API */}
        <Dropdown
          label="Select buffer"
          aria-label="Bid buffer"
          options={BUFFER_OPTIONS}
          shortOptionsList={true}
          selectedValue={bidSelection.properties.buffer.toString() || '1'}
          selectValue={(value) =>
            setSelection({
              type: selectedAction,
              properties: {
                ...bidSelection.properties,
                buffer: +value,
              },
            })
          }
        />
      </div>
    </div>
  );
}
