import { useState, useRef, useEffect } from 'react';
import { TickIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import Chevrons from 'ui/components/Dropdowns/Chevrons';
import { Option } from 'shared/legacy-types';
import { filterOptionsBySearchTerm } from 'helpers/utils';

interface Props {
  label?: string;
  options: Option[];
  selectedValue: string | undefined;
  selectValue: (value: string) => void;
  disabled?: boolean;
  styles?: string;
}

export default function SearchDropdown({
  label,
  options,
  selectedValue,
  selectValue,
  disabled,
  styles,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (selectedValue) {
      const selectedLabel = options.find(
        (option) => option.value === selectedValue,
      )?.label;

      if (!selectedLabel) {
        return;
      }
      setSearchTerm(selectedLabel);
    }
  }, [selectedValue, options]);

  useClickOutside(setOpen, ref, dropdownRef);

  const filteredOptions = filterOptionsBySearchTerm(searchTerm, options);

  function select(value: string) {
    selectValue(value);
    const selectedLabel = options.find(
      (option) => option.value === value,
    )?.label;
    setSearchTerm(selectedLabel || '');
    setOpen(false);
  }

  function renderDropdownMenu() {
    function renderOptions() {
      return filteredOptions.map((option) => {
        const { label, value } = option;
        const selected = value === selectedValue;

        return (
          <div
            key={value}
            className={`bg-white ${
              selected && 'font-bold !leading-5'
            } flex justify-between rounded p-3 text-sm hover:cursor-pointer `}
            onClick={() => select(value)}
          >
            <div className="flex">{label}</div>
            {selected && <TickIcon />}
          </div>
        );
      });
    }
    return (
      <div className={`absolute !z-50 mt-1 w-80 rounded-xl bg-white `}>
        <div
          ref={ref}
          aria-label="Dropdown list"
          className={`rounded-xl border px-4 text-sm  border-neutral-20 scrollbar-hide max-h-[11.75rem] text-left max-h-[11.7rem]'
        } border-neutral-20 scrollbar-hide max-h-[11.75rem] overflow-scroll`}
          style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
        >
          {renderOptions()}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={dropdownRef}
        className={`text-left text-sm text-neutral-100 relative ${
          disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
        }`}
        onClick={() => !disabled && setOpen(!open)}
      >
        <input
          tabIndex={0}
          className={`focus:outline-none relative h-[2.563rem] my-0 focus:my-0 w-full truncate rounded border border-neutral-40 bg-white pl-[0.688rem] pr-[1.75rem] font-bold focus:pl-[0.625rem] focus:pr-[1.688rem] placeholder:font-normal ${styles} 
           ${
             disabled
               ? 'text-neutral-60 focus:border-neutral-40 focus:pl-[0.688rem] focus:pt-[0.688rem] placeholder:text-neutral-60'
               : 'focus:border-primary-100 focus:border-2 placeholder:text-neutral-80'
           }`}
          placeholder={label}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setOpen(true);
          }}
          value={searchTerm}
        />

        <div
          className={`pointer-events-none absolute ${
            open ? 'top-[1.07rem]' : 'top-[1.18rem]'
          }  right-4 flex items-center`}
        >
          <Chevrons
            open={open}
            disabled={disabled}
            openColour="stroke-black"
            closedColour="stroke-neutral-80"
          />
        </div>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
