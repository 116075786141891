interface Props {
  stroke?: string;
}

export function GoalsIcon({ stroke = 'stroke-neutral-60' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8.2" className={stroke} strokeWidth="1.6" />
      <circle cx="10" cy="10" r="3.2" className={stroke} strokeWidth="1.6" />
    </svg>
  );
}
