import { forwardRef } from 'react';
import SecondaryButton from '../../Buttons/SecondaryButton';
import PrimaryButton from '../../Buttons/PrimaryButton';

import { showLoadMoreButton } from '../../../helpers/stepResults';
import SmallSpinner from '../../../Spinner/SmallSpinner';
import { Result } from 'shared/legacy-types';

interface Props {
  results: Result[];
  loadMoreResults?: () => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  noResultsFound?: boolean;
}

const SCROLL_HEIGHT = 7;

const LoadMore = forwardRef(function LoadMore(
  {
    results,
    enablePagination,
    noResultsFound,
    loadMoreResults,
    loadingMoreResults,
  }: Props,
  ref: any,
) {
  function scrollToTop() {
    if (ref) {
      // @ts-ignore
      ref.current?.scrollTo({
        top: 0,
      });
    }
  }

  if (!results || (results && results.length < SCROLL_HEIGHT)) {
    return null;
  }

  return (
    <>
      {showLoadMoreButton(results.length) &&
        enablePagination &&
        !noResultsFound && (
          <PrimaryButton
            onClick={() => loadMoreResults && loadMoreResults()}
            disabled={loadingMoreResults}
          >
            <>
              {loadingMoreResults ? (
                <div className="w-[4.437rem] pl-7">
                  <SmallSpinner loading={!loadingMoreResults} />
                </div>
              ) : (
                <>Load more</>
              )}
            </>
          </PrimaryButton>
        )}
      <div className="ml-3">
        <SecondaryButton
          colour="border-secondary-100"
          onClick={() => scrollToTop()}
        >
          <>Back to top</>
        </SecondaryButton>
      </div>
    </>
  );
});

export default LoadMore;
