import { useState, useContext, useEffect } from 'react';

import {
  Condition,
  Rule,
  ConditionExpression,
  ValueCondition,
} from 'shared/legacy-types';
import { fieldWithoutPrefix } from 'ui/helpers/rules/fields';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';

export default function useExpressions(
  condition: Condition,
  rule: Omit<Rule, 'id'>,
  onChange: (rule: Omit<Rule, 'id'>) => void,
  index: number,
  groupIndex: number,
  isCampaignSpend: boolean,
) {
  const isValueCondition = condition.hasOwnProperty('value');
  const valueCondition = condition as ValueCondition;
  const expressionValue = valueCondition.value as ConditionExpression;
  const isExpression =
    isValueCondition && expressionValue.hasOwnProperty('metric');
  const selectedField = fieldWithoutPrefix(condition.field);
  const { stepIndex } = useContext(StepContext) || {};

  const [toggleEnabled, setToggleEnabled] = useState<boolean>(isExpression);

  const step = rule.steps[stepIndex];
  const conditionGroup = step && step.conditionGroups[groupIndex];

  // update toggle if a condition is removed
  useEffect(() => {
    if (
      isExpression &&
      !toggleEnabled &&
      (expressionValue.metric || expressionValue.value)
    ) {
      setToggleEnabled(true);
    }
    if (!isExpression && toggleEnabled) {
      setToggleEnabled(false);
    }
    // eslint-disable-next-line
  }, [isExpression]);

  function showExpressionToggle() {
    if (
      selectedField === 'clicks' ||
      selectedField === 'bid' ||
      isCampaignSpend ||
      selectedField === 'averageDailyCost'
    ) {
      return true;
    }
    return false;
  }

  function updateToggle(enabled: boolean) {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update toggle',
      );
    }

    // always clear value when toggling expression on or off
    const updatedCondition = {
      field: valueCondition.field,
      operator: valueCondition.operator,
      value: '',
    } as ValueCondition;

    conditionGroup.conditions[index] = {
      ...updatedCondition,
    };
    onChange({ ...rule });
    setToggleEnabled(enabled);
  }

  return {
    showExpressionToggle,
    updateToggle,
    toggleEnabled,
    setToggleEnabled,
  };
}
