import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';

export default function ButtonDesignComponent() {
  return (
    <section id="buttons" className="border-neutral-40 border-1 border-t p-10">
      <h4 className="pb-2 pt-10 font-bold">Large</h4>
      <div id="buttons-lrg" className="space-x-8">
        <PrimaryButton size="lg">
          <div>Primary</div>
        </PrimaryButton>
        <PrimaryButton size="lg" disabled>
          <div>Disabled</div>
        </PrimaryButton>
        <SecondaryButton size="lg">
          <div>Secondary</div>
        </SecondaryButton>
        <SecondaryButton size="lg" disabled>
          <div>Disabled</div>
        </SecondaryButton>
      </div>
      <h4 className="pb-2 pt-20 font-bold">Medium</h4>
      <div id="buttons-md" className="space-x-8">
        <PrimaryButton size="md">
          <div>Primary</div>
        </PrimaryButton>
        <PrimaryButton size="md" disabled>
          <div>Disabled</div>
        </PrimaryButton>
        <SecondaryButton size="md">
          <div>Secondary</div>
        </SecondaryButton>
        <SecondaryButton size="md" disabled>
          <div>Disabled</div>
        </SecondaryButton>
      </div>
    </section>
  );
}
