import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import Dropdown from 'components/Dropdowns/Dropdown';
import useUser from 'hooks/useUser';
import { SpendTarget, Profile, User } from 'shared/legacy-types';
import Tooltip from 'ui/Tooltips/Tooltip';
import useUserSelector from 'state/user/useUserSelector';
import { getMarketplaces } from 'helpers/user/userProfiles';
import CurrencyInput from 'components/Inputs/CurrencyInput';

interface Props {
  setOpen: (open: boolean) => void;
  newSpendTarget: Partial<SpendTarget>;
  updateSpendTarget: (value: string | number, type: string) => void;
  updateAmounts: (value: string) => void;
  setModalStep: (step: string) => void;
  reviewStep: string;
  selectedBrand: string;
  spendTargetProfileIds: string[];
  recurringAmount: string;
}

export default function CreateSpendTargetForm({
  setOpen,
  newSpendTarget,
  updateSpendTarget,
  updateAmounts,
  setModalStep,
  reviewStep,
  selectedBrand,
  spendTargetProfileIds,
  recurringAmount,
}: Props) {
  const { getBrandOptions, getProfileData } = useUser();
  const profileData =
    newSpendTarget.profileId &&
    getProfileData(parseInt(newSpendTarget.profileId));
  const { currencyCode } = profileData || {};
  const { user } = useUserSelector();
  const { selectedProfiles } = user || ({} as User);
  const profiles = selectedProfiles as Profile[];

  //filter out any profiles that already have a spend target
  const filteredProfiles = profiles.filter((profile) => {
    return !spendTargetProfileIds.includes(profile.id.toString());
  });

  // convert to dropdown options list
  const marketplaceOptions = getMarketplaces(filteredProfiles, selectedBrand);

  // disable if no profileID or no amount
  const disableContinue =
    !newSpendTarget.profileId ||
    (newSpendTarget.monthlySpendTargets &&
      (!newSpendTarget.monthlySpendTargets[0] ||
        newSpendTarget.monthlySpendTargets[0].spend === '0' ||
        newSpendTarget.monthlySpendTargets[0].spend === ''));

  const selectedMarketplace = newSpendTarget.profileId
    ? newSpendTarget.profileId.toString()
    : '';

  const amountTooltip = (
    <>
      <div>This amount will be set for this and all upcoming months.</div>
      <div>
        <i>You can edit these amounts at any time</i>
      </div>
    </>
  );

  return (
    <div className="!overflow-visible">
      <div className="h-[23rem] w-[28rem] bg-white p-6">
        <div className="flex justify-between">
          <h3 className="truncate font-bold">Add spend target</h3>
          <div
            className="mt-[0.1875rem] hover:cursor-pointer"
            onClick={() => setOpen(false)}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="text-neutral-80 mt-2 w-[20.625rem] text-sm">
          Adding spend targets will give you access to new metrics that fern
          calculates for you
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          <div>
            <label className="text-neutral-80 mb-1 text-xs">Brand</label>
            <Dropdown
              label="Select brand"
              options={getBrandOptions()}
              selectedValue={selectedBrand}
              selectValue={(value) => updateSpendTarget(value, 'brand')}
            />
          </div>
          <div>
            <label className="text-neutral-80 mb-1 text-xs">Marketplace</label>
            <Dropdown
              label={
                marketplaceOptions.length === 0 && selectedBrand
                  ? 'All marketplaces used'
                  : 'Select marketplace'
              }
              options={marketplaceOptions}
              selectedValue={selectedMarketplace}
              selectValue={(value) => updateSpendTarget(value, 'profileId')}
              disabled={!selectedBrand || marketplaceOptions.length === 0}
            />
          </div>
        </div>
        <div className="relative mt-6">
          <div className="flex">
            <label className="text-neutral-80 mb-1 mr-1 text-xs">
              Set all months to
            </label>
            <Tooltip content={amountTooltip} width="w-[20.25rem]" />
          </div>
          <CurrencyInput
            value={recurringAmount}
            onChange={updateAmounts}
            currencyCode={currencyCode}
            ariaLabel="Set all months to"
          />
        </div>
        <div className="flex justify-end pt-14">
          <div onClick={() => setModalStep(reviewStep)}>
            <PrimaryButton disabled={disableContinue}>
              <>Continue</>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}
