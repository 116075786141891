interface Props {
  height?: string;
  rounded?: string;
}

export default function RightArrowsImage({
  height = '131',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clipPath="url(#clip0_5373_39081)">
        <rect width="76" height="131" className="fill-tertiary-80" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9309 63.9341C17.176 75.4567 0.121475 92.8364 -11 113.841L-11 14.0381C0.123039 35.0383 17.1773 52.4142 37.9309 63.9341Z"
          className="fill-tertiary-100"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.9074 63.9255C66.1454 75.4481 49.0852 92.8312 37.9609 113.841L37.9609 14C49.0836 35.0134 66.1441 52.4002 86.9074 63.9255Z"
          className="fill-tertiary-100"
        />
      </g>
      <defs>
        <clipPath id="clip0_5373_39081">
          <rect width="76" height="131" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
