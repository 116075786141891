import { useEffect, useState } from 'react';
import { Rule, Optional } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import { PRODUCT_SIGNALS } from 'ui/constants/rules';
import useProductsRequests from 'api/hooks/requests/useProductsRequests';

export interface Asin {
  asin: string;
  imageUrl: string;
  name: string;
}

export interface SpApiMarketplaceId {
  id: string;
  countryCode: string;
  name: string;
  defaultCurrencyCode: string;
  defaultLanguageCode: string;
  domainName: string;
}

export default function useProductSignals(
  rule: Optional<Rule, 'id'>,
  onChange: (rule: Optional<Rule, 'id'>) => void,
) {
  const [asins, setAsins] = useState<Asin[]>([]);
  const [spApiMarketplaceIds, setSpApiMarketplaceIds] = useState<
    SpApiMarketplaceId[]
  >([]);
  const [loadingAsins, setLoadingAsins] = useState<boolean>(false);
  const [loadingMarketplaces, setLoadingMarketplaces] =
    useState<boolean>(false);

  const { userConfig } = useUserSelector();
  const { spApiAccounts } = userConfig || {};
  const { getAsins, getSpApiMarketplaceIds } = useProductsRequests();

  const selectedSpApiAccount = () => {
    if (rule.sellingPartnerId && spApiAccounts && spApiAccounts.length > 0) {
      return spApiAccounts.find(
        (account) => account.sellingPartnerId === rule.sellingPartnerId,
      );
    }

    return null;
  };

  const brandId = selectedSpApiAccount()?.id;

  useEffect(() => {
    async function fetchSpApiMarketplaceIds() {
      if (
        rule.dataType === PRODUCT_SIGNALS &&
        rule.sellingPartnerId &&
        brandId
      ) {
        setLoadingMarketplaces(true);
        const response = await getSpApiMarketplaceIds(brandId);
        setSpApiMarketplaceIds(response.data);
        setLoadingMarketplaces(false);
      }
    }
    fetchSpApiMarketplaceIds();
    //eslint-disable-next-line
  }, [rule.dataType, rule.sellingPartnerId]);

  useEffect(() => {
    async function fetchAsins() {
      if (
        rule.dataType === PRODUCT_SIGNALS &&
        rule.sellingPartnerId &&
        rule.spApiMarketplaceId
      ) {
        setLoadingAsins(true);
        const response = await getAsins(
          rule.sellingPartnerId,
          rule.spApiMarketplaceId,
        );
        setAsins(response.data);
        setLoadingAsins(false);
      }
    }
    fetchAsins();
    //eslint-disable-next-line
  }, [rule.spApiMarketplaceId]);

  function selectSpApiMarketplaceId(marketplace: string) {
    rule.asins = [];
    if (rule.spApiMarketplaceId === marketplace) {
      rule.spApiMarketplaceId = '';
      onChange({ ...rule });
      return;
    }

    rule.spApiMarketplaceId = marketplace;

    onChange({ ...rule });
  }

  function selectSellerBrand(brand: string) {
    rule.asins = [];
    rule.spApiMarketplaceId = '';
    if (rule.sellingPartnerId === brand) {
      rule.sellingPartnerId = '';
      onChange({ ...rule });
      return;
    }

    rule.sellingPartnerId = brand;

    onChange({ ...rule });
  }

  const sellerBrandOptions = () => {
    if (!spApiAccounts || spApiAccounts.length === 0) {
      return [];
    }

    const formattedBrands = spApiAccounts.map((account) => {
      return {
        label: account.sellingPartnerId,
        value: account.sellingPartnerId,
      };
    });

    return formattedBrands;
  };

  const productMarketplaces = () => {
    const formattedMarketplaces =
      spApiMarketplaceIds &&
      spApiMarketplaceIds.map((marketplace) => {
        return {
          label: marketplace.name,
          value: marketplace.id,
        };
      });

    return formattedMarketplaces;
  };

  function selectAsin(asin: string) {
    const currentAsins = rule.asins || [];
    // if asin is already selected, remove it
    if (currentAsins.includes(asin)) {
      rule.asins = currentAsins.filter((currentAsin) => currentAsin !== asin);
      onChange({ ...rule });
      return;
    }
    rule.asins = [...currentAsins, asin];
    onChange({ ...rule });
  }

  return {
    asins,
    spApiMarketplaceIds,
    loadingAsins,
    loadingMarketplaces,
    selectSellerBrand,
    selectSpApiMarketplaceId,
    sellerBrandOptions,
    productMarketplaces,
    selectAsin,
    spApiAccounts,
  };
}
