export function MultiplyIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.53906"
        y="3.5"
        width="12.6754"
        height="1.4632"
        rx="0.7316"
        transform="rotate(45 4.53906 3.5)"
        className="fill-secondary-80"
      />
      <rect
        width="12.6754"
        height="1.4632"
        rx="0.7316"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 12.4609 3.5)"
        className="fill-secondary-80"
      />
    </svg>
  );
}
