import useRequest from 'api/hooks/useRequest';
import { Profile } from 'shared/legacy-types';

function wait(second: number) {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(null);
    }, second * 1000),
  );
}

export default function useSetupRequests() {
  const { request } = useRequest();

  const connectProfiles = async (ids: number[] | null) => {
    return await request('post', '/v1/selected-profiles', ids);
  };

  let retryCount = 0;

  const getProfiles = async (): Promise<Profile[]> => {
    const response = await request('get', '/v1/available-profiles');
    if (response.status === 202) {
      if (retryCount > 30) {
        throw new Error('Unable to fetch available profiles');
      }
      await wait(2);
      retryCount += 1;
      return getProfiles();
    }
    return response.data;
  };

  return { connectProfiles, getProfiles };
}
