export function AlertIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10573 4.10768C5.66773 2.54569 7.7884 1.66602 9.9974 1.66602C12.2064 1.66602 14.3271 2.54569 15.8891 4.10768C17.4511 5.66968 18.3307 7.79035 18.3307 9.99935C18.3307 12.2083 17.4511 14.329 15.8891 15.891C14.3271 17.453 12.2064 18.3327 9.9974 18.3327C7.7884 18.3327 5.66773 17.453 4.10573 15.891C2.54373 14.329 1.66406 12.2083 1.66406 9.99935C1.66406 7.79035 2.54373 5.66968 4.10573 4.10768Z"
        className={`fill-error`}
      />
      <path
        d="M10 5.5L10 10.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.334H10.0083"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
