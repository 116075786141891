import { useState } from 'react';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import { Optional, Rule } from 'shared/legacy-types';
import { deepClone } from 'ui/helpers/deepClone';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type UseRulesHook = {
  rules: Rule[];
  setRules: (rules: Rule[]) => void;
  archiving: boolean;
  removeRule: () => Promise<void>;
  openArchiveModal: (rule: Rule) => void;
  duplicateRule: (ruleId: string) => Promise<void>;
  ruleToDelete: Rule | undefined;
  archiveModalOpen: boolean;
  setArchiveModalOpen: (open: boolean) => void;
};

const NEXT_INDEX = 1;

export default function useRules(): UseRulesHook {
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const [ruleToDelete, setRuleToDelete] = useState<Rule>();
  const [rules, setRules] = useState<Rule[]>([]);
  const [archiving, setArchiving] = useState<boolean>(false);

  const { createRule, archiveRule } = useRuleRequests();

  async function duplicateRule(ruleId: string): Promise<void> {
    const rule = rules.find((rule) => rule.id === ruleId);
    const ruleToCopy = deepClone(rule) as Optional<Rule, 'id'>;
    ruleToCopy.name = `Copy of ${ruleToCopy.name}`;
    delete ruleToCopy.id;
    delete ruleToCopy.createdAt;
    delete ruleToCopy.updatedAt;

    const newRule = await createRule(ruleToCopy);

    // put duplicated rule below original
    const originalIndex = rules.map((rule) => rule.id).indexOf(ruleId);
    const copiedRules = deepClone(rules) as Rule[];
    copiedRules.splice(originalIndex + NEXT_INDEX, 0, newRule);
    copiedRules.join();

    setRules(copiedRules);
  }

  function openArchiveModal(rule: Rule): void {
    setArchiving(false);
    setRuleToDelete(rule);
    setArchiveModalOpen(true);
  }

  async function removeRule(): Promise<void> {
    if (ruleToDelete == null) return;
    setArchiving(true);
    await archiveRule(ruleToDelete.id);
    setArchiving(false);
    const filteredRules = rules.filter((rule) => rule.id !== ruleToDelete.id);
    setRules(filteredRules);
    setArchiveModalOpen(false);
    showToast(
      `Your rule ${ruleToDelete.name} has been archived`,
      ToastStyle.SUCCESS,
    );
  }

  return {
    rules,
    setRules,
    archiving,
    removeRule,
    openArchiveModal,
    duplicateRule,
    ruleToDelete,
    archiveModalOpen,
    setArchiveModalOpen,
  };
}
