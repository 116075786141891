interface props {
  fill?: string;
  stroke?: string;
}

export function MoveFolderIcon({
  fill = 'fill-neutral-80',
  stroke = 'stroke-neutral-80',
}: props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54784 5.41603C8.68694 5.62467 8.92111 5.75 9.17188 5.75H16.6719C16.915 5.75 17.1481 5.84658 17.3201 6.01849C17.492 6.19039 17.5885 6.42355 17.5885 6.66667V15.8333C17.5885 16.0764 17.492 16.3096 17.3201 16.4815C17.1481 16.6534 16.915 16.75 16.6719 16.75H3.33854C3.09543 16.75 2.86227 16.6534 2.69036 16.4815C2.51845 16.3096 2.42188 16.0764 2.42188 15.8333V4.16667C2.42188 3.92355 2.51845 3.69039 2.69036 3.51849C2.86227 3.34658 3.09543 3.25 3.33854 3.25H7.10382L8.54784 5.41603Z"
        className={`${fill} ${stroke}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45 12.475V14.875L14.25 10.875L9.45 6.875V9.275H6.25V12.475H9.45Z"
        fill="white"
      />
    </svg>
  );
}
