import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  openSpendTargetModal: () => void;
}

export default function SpendTargetAlertModal({
  open,
  setOpen,
  openSpendTargetModal,
}: Props) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="bg-white p-6 w-[24.813rem]" aria-label="Archive alert">
        <h5 className="truncate font-bold">Spend target Required</h5>
        <div className="text-neutral-80 mt-1">
          Your conditions use Marketplace metrics that require a spend target to
          be set on <span className="font-bold">all marketplaces</span> before
          you can turn on this rule.
        </div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <SecondaryButton onClick={() => setOpen(false)}>
            <>Continue editing</>
          </SecondaryButton>
          <div className="ml-3">
            <PrimaryButton onClick={() => openSpendTargetModal()}>
              <>Add target</>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
