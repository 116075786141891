import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  IsDuplicateCampaignLinkDocument,
  IsDuplicateCampaignLinkMutation,
  MatchType,
  TargetingType,
} from 'codegen/graphql';
import { CampaignLink } from 'shared/legacy-types';
import { allFieldsRequiredForValidationCheckPopulated } from '../helpers/campaignLinks';
import { IsDuplicateCampaignLinkInput } from 'codegen/graphql';

export default function useCampaignLinkValidation(
  campaignLink: CampaignLink,
  validationRequired: boolean = true,
) {
  const [
    mutateFunction,
    { data, loading: isDuplicateCampaignLinkValidationRunning },
  ] = useMutation<IsDuplicateCampaignLinkMutation>(
    IsDuplicateCampaignLinkDocument,
  );

  const isDuplicateCampaignLink = data ? !!data.isDuplicateCampaignLink : false;

  const fieldsThatTriggerValidationCheck = [
    campaignLink.matchTypes,
    campaignLink.source,
    campaignLink.destination,
    campaignLink.targetingType,
  ];

  function buildDuplicateCampaignLinkInput(
    campaignLink: CampaignLink,
  ): IsDuplicateCampaignLinkInput {
    const sourceAdGroupIds = campaignLink.source.adGroups
      ? campaignLink.source.adGroups.map((adGroup) => adGroup.adGroupId)
      : [];
    const destinationAdGroupIds = campaignLink.destination.adGroups
      ? campaignLink.destination.adGroups.map((adGroup) => adGroup.adGroupId)
      : [];

    return {
      marketplaceId: campaignLink.profileId,
      targetingType: campaignLink.targetingType.toUpperCase() as TargetingType,
      matchTypes: campaignLink.matchTypes.map((matchType) =>
        matchType.toUpperCase(),
      ) as MatchType[],
      source: {
        campaignId: campaignLink.source.campaignId,
        adGroupIds: sourceAdGroupIds,
      },
      destination: {
        campaignId: campaignLink.destination.campaignId,
        adGroupIds: destinationAdGroupIds,
      },
    };
  }

  useEffect(() => {
    if (
      allFieldsRequiredForValidationCheckPopulated(campaignLink) &&
      validationRequired
    ) {
      mutateFunction({
        variables: {
          input: buildDuplicateCampaignLinkInput(campaignLink),
        },
      });
    }
    // eslint-disable-next-line
  }, fieldsThatTriggerValidationCheck);

  return { isDuplicateCampaignLink, isDuplicateCampaignLinkValidationRunning };
}
