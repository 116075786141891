interface Props {
  fill?: string;
}

export function MappingIcon({ fill = 'fill-neutral-60' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67188 16.2363H3.06574C9.60306 16.2363 14.9138 10.9256 14.9138 4.38824V4.26279L16.684 6.03299L17.6736 5.04336L14.2168 1.60059L10.774 5.04336L11.7637 6.03299L13.5199 4.26279V4.38824C13.5199 10.1587 8.83654 14.8421 3.06609 14.8421H1.67223L1.67188 16.2363Z"
        className={fill}
      />
      <path
        d="M7.72391 5.0016C7.72391 3.46835 6.46943 2.21387 4.93617 2.21387C3.40292 2.21387 2.14844 3.46835 2.14844 5.0016C2.14844 6.53485 3.40292 7.78934 4.93617 7.78934C6.46943 7.78934 7.72391 6.53485 7.72391 5.0016ZM3.5423 5.0016C3.5423 4.23499 4.16956 3.60773 4.93617 3.60773C5.70278 3.60773 6.33004 4.23499 6.33004 5.0016C6.33004 5.76821 5.70278 6.39547 4.93617 6.39547C4.16956 6.39547 3.5423 5.76821 3.5423 5.0016Z"
        className={fill}
      />
      <path
        d="M14.1476 18.3965L15.7505 16.8075L17.3534 18.3965L18.3292 17.4208L16.7401 15.8179L18.3292 14.215L17.3534 13.2393L15.7505 14.8283L14.1476 13.2393L13.1719 14.215L14.7609 15.8179L13.1719 17.4208L14.1476 18.3965Z"
        className={fill}
      />
    </svg>
  );
}
