import { ReactElement } from 'react';
type Size = 'lg' | 'md';

interface Props {
  children: ReactElement;
  disabled?: boolean;
  size?: Size;
  onClick?: () => void;
  colour?: string;
  styles?: string;
}

export default function SecondaryButton({
  children,
  disabled,
  size = 'md',
  onClick,
  colour = 'border-primary-100',
  styles,
}: Props) {
  const largeStyle = 'px-7 h-[3.25rem] text-base';
  const mediumStyle = 'h-[2.5625rem] px-5 text-sm';

  const isLarge = size === 'lg';

  function getStyle() {
    if (isLarge) {
      return largeStyle;
    }
    return mediumStyle;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${styles} ${getStyle()} disabled:border-neutral-40 rounded-full border-2 bg-transparent
      font-bold text-neutral-100 disabled:cursor-not-allowed ${colour} hover:brightness-75 focus:outline-none disabled:hover:brightness-100`}
    >
      {children}
    </button>
  );
}
