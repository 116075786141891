import useAction from '../../../hooks/useAction';
import {
  UPDATE_BID,
  UPDATE_BUDGET,
  HARVEST_KEYWORDS,
} from '../../../constants/actions';
import { Action } from 'shared/legacy-types';

interface Props {
  action: Action;
  dataType: string;
  selectedAction: string;
  setSelectedAction: (action: string) => void;
}

export default function ActionResultsTab({
  action,
  dataType,
  selectedAction,
  setSelectedAction,
}: Props) {
  const {
    isPauseOrEnable,
    conditionLabel,
    pauseEnablelabel,
    currentActionTab: actionTab,
  } = useAction(action, dataType);

  const tabLabel = () => {
    if (actionTab.type === UPDATE_BID) {
      return `${conditionLabel} bid`;
    }

    if (actionTab.type === UPDATE_BUDGET) {
      return `${conditionLabel} budget`;
    }

    if (isPauseOrEnable) {
      return `${actionTab.label} ${pauseEnablelabel}`;
    }

    if (actionTab.type === HARVEST_KEYWORDS) {
      return `Add keyword`;
    }

    return actionTab.label;
  };

  return (
    <>
      <div
        className={`h-content inline w-fit justify-between pb-[1.030rem] hover:cursor-pointer`}
        onClick={() => setSelectedAction(action.type)}
      >
        <span
          className={`text-neutral-80 mr-5 w-12 px-1 py-4 text-sm font-bold leading-6 tracking-[-0.01rem] ${
            selectedAction === action.type &&
            'border-primary-100 border-b px-[-1px] text-neutral-100'
          } `}
        >
          {tabLabel()}
        </span>
      </div>
    </>
  );
}
