interface Props {
  openCreateSpendTargetModal: (open: boolean) => void;
}

export default function AddSpendTargetTooltip({
  openCreateSpendTargetModal,
}: Props) {
  return (
    <span
      className="absolute left-0 top-[-2.5rem] z-50 h-fit w-[13.75rem] flex-wrap rounded bg-neutral-100 p-2 text-xs text-white hover:cursor-pointer"
      onClick={() => openCreateSpendTargetModal(true)}
    >
      Set up a spend target to use these metrics{' '}
      <span className="text-primary-100 underline">Add now</span>
    </span>
  );
}
