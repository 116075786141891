interface Props {
  background?: string;
  iconColour?: string;
  size?: string;
}

export function PauseIcon({
  background = 'fill-primary-80',
  iconColour = 'fill-white',
}: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
        className={background}
      />
      <path
        d="M5 4.66667C5 4.29848 5.29848 4 5.66667 4H6.33333C6.70152 4 7 4.29848 7 4.66667V11.3333C7 11.7015 6.70152 12 6.33333 12H5.66667C5.29848 12 5 11.7015 5 11.3333V4.66667Z"
        className={iconColour}
      />
      <path
        d="M9 4.66667C9 4.29848 9.29848 4 9.66667 4H10.3333C10.7015 4 11 4.29848 11 4.66667V11.3333C11 11.7015 10.7015 12 10.3333 12H9.66667C9.29848 12 9 11.7015 9 11.3333V4.66667Z"
        className={iconColour}
      />
    </svg>
  );
}
