import { FIELD_HEADER_LABELS_SINGULAR } from '../../constants/rules';
import { CONDITION_FIELDS } from '../../constants/conditionFields';

export const fieldWithoutPrefix = (fieldValue: string) => {
  // if no prefix just return the fieldValue
  if (!fieldValue.includes(':')) {
    return fieldValue;
  }

  return fieldValue.slice(fieldValue.indexOf(':') + 1);
};

export const getFieldPrefix = (fieldValue: string) => {
  // if no prefix just return the fieldValue
  if (!fieldValue.includes(':')) {
    return '';
  }

  return fieldValue.slice(0, fieldValue.indexOf(':'));
};

export const getPrefixLabel = (fieldValue: string) => {
  const prefix = getFieldPrefix(fieldValue);
  return FIELD_HEADER_LABELS_SINGULAR.find((label) => label.value === prefix)
    ?.label;
};

// need to remove prefix to find the field
// Get field configuration from field value
export const getField = (field: string) => {
  const fieldValue = fieldWithoutPrefix(field);
  return Object.values(CONDITION_FIELDS).find(
    (field) => field.value === fieldValue,
  );
};
