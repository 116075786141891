import { useEffect } from 'react';

export default function useSetEnvironment(
  setUserEnv: (env: string) => void,
): void {
  const testing = window.location.href.match('https://testfern.net/*');
  const development = window.location.href.match('http://localhost:3000/*');

  useEffect(() => {
    const faviconLink = document.getElementsByTagName('link')[0];

    function setEnvironment(): void {
      if (testing ?? development) {
        setUserEnv(testing ? 'test' : 'development');

        if (faviconLink) {
          faviconLink.href = '/favicon-test.png';
        }
      }
    }

    setEnvironment();
    // eslint-disable-next-line
  }, []);
}
