export function LargeCirclesIcon() {
  return (
    <div>
      <svg
        width="140"
        height="217"
        viewBox="0 0 140 217"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="394"
          height="394"
          transform="translate(0 -177)"
          fill="#051A16"
        />
        <circle
          cx="196.997"
          cy="20.0011"
          r="119.902"
          stroke="#000d0a"
          strokeWidth="18.2597"
        />
        <circle
          cx="196.995"
          cy="19.9985"
          r="158.134"
          stroke="#000d0a"
          strokeWidth="18.2597"
        />
        <circle
          cx="197"
          cy="20"
          r="194.345"
          stroke="#000d0a"
          strokeWidth="5.30997"
        />
        <circle
          cx="197.002"
          cy="19.9982"
          r="84.8567"
          stroke="#000d0a"
          strokeWidth="18.2597"
        />
      </svg>
    </div>
  );
}
