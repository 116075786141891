import { MeatballIcon } from 'components/Icons';
import useClickOutside from 'ui/hooks/useClickOutside';
import { useRef, useState } from 'react';
import NavPopup from './NavPopup';
import useUserSelector from 'state/user/useUserSelector';
import TrialBadge from './TrialBadge';

export default function UserDetail() {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const { user } = useUserSelector();

  const navRef = useRef(null);
  const userAreaRef = useRef(null);
  useClickOutside(setNavOpen, navRef, userAreaRef);

  const firstName = user && user.firstName;
  const firstNameInitial = firstName && firstName[0] ? firstName[0] : '•';

  return (
    <div
      ref={userAreaRef}
      className="flex w-full justify-between px-6 pt-[0.9375rem] hover:cursor-pointer"
      onClick={() => setNavOpen(!navOpen)}
    >
      <div className="flex">
        <span
          className="bg-primary-80 mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full"
          title={user?.email}
        >
          <span className="bg-primary-80 font-medium leading-none text-neutral-100">
            {firstNameInitial.toUpperCase()}
          </span>
        </span>
        <div className={`mr-3 `}>
          <div className="mt-[-0.125rem] text-sm font-bold text-white truncate max-w-[8.75rem]">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="mt-1">
            <TrialBadge colour="text-white" />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <MeatballIcon />
      </div>
      {navOpen && <NavPopup ref={navRef} user={user} />}
    </div>
  );
}
