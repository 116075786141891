interface props {
  showPass: boolean;
}

export function ShowHideIcon({ showPass }: props) {
  return (
    <div>
      {!showPass && (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.832031 10.7135C0.832031 10.7135 4.16536 4.04688 9.9987 4.04688C15.832 4.04688 19.1654 10.7135 19.1654 10.7135C19.1654 10.7135 15.832 17.3802 9.9987 17.3802C4.16536 17.3802 0.832031 10.7135 0.832031 10.7135Z"
            fill="#9BA3A2"
            stroke="#9BA3A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {showPass && (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9487 15.6636C13.5242 16.7494 11.7896 17.3509 9.9987 17.3802C4.16536 17.3802 0.832031 10.7136 0.832031 10.7136C1.86861 8.78181 3.30631 7.09407 5.0487 5.76356M8.2487 4.2469C8.82231 4.11263 9.40959 4.04551 9.9987 4.0469C15.832 4.0469 19.1654 10.7136 19.1654 10.7136C18.6595 11.6599 18.0562 12.5508 17.3654 13.3719M11.7654 12.4802C11.5365 12.7259 11.2605 12.9229 10.9538 13.0595C10.6472 13.1961 10.3161 13.2696 9.98044 13.2755C9.64476 13.2815 9.31133 13.2197 9.00004 13.094C8.68875 12.9682 8.40597 12.7811 8.16857 12.5437C7.93117 12.3063 7.74403 12.0235 7.61829 11.7122C7.49255 11.4009 7.4308 11.0675 7.43673 10.7318C7.44265 10.3961 7.51612 10.0651 7.65276 9.75844C7.7894 9.45177 7.98641 9.17577 8.23203 8.9469"
            stroke="#505F5C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 3L17.5 18"
            stroke="#505F5C"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
}
