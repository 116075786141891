import { DiamondIcon, RoundedSquareIcon } from 'components/Icons';
import TableCell from '../ResultsTable/TableCell';

interface props {
  style?: string;
  campaignName: string;
  adGroups: string;
}

export default function KeywordTableCell({
  style,
  campaignName,
  adGroups,
}: props) {
  const value = `${campaignName} ${adGroups}`;
  return (
    <TableCell style={style} value={value}>
      <>
        <div className="pt-0">
          <span className="flex">
            <span className="my-auto mr-2 ">
              <RoundedSquareIcon />
            </span>
            <span className="truncate">{campaignName}</span>
          </span>
          <span className="flex ml-4 mt-1">
            <span className="my-auto mt-0.5">
              <DiamondIcon />
            </span>
            <span className="ml-[6px] text-xs text-neutral-80 truncate">
              {adGroups}
            </span>
          </span>
        </div>
      </>
    </TableCell>
  );
}
