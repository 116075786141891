import { KeywordApprovals } from 'shared/legacy-types';
import useRequest from '../useRequest';
import { useState } from 'react';

const TIME_TO_CONSIDER_APPROVING_REQUEST_LONG = 1000;
const TIME_TO_PREVENT_FLICKER_FROM_MODAL_ANIMATION = 500;

export default function useKeywordApprovalRequests() {
  const { request } = useRequest();
  const [isApprovingKeywords, setIsApprovingKeywords] = useState(false);
  const [isApprovingKeywordsTakingLong, setIsApprovingKeywordsTakingLong] =
    useState(false);

  const approveKeywordsRequest = async (keywordApprovals: KeywordApprovals) => {
    setIsApprovingKeywords(true);
    const timeoutId = setTimeout(
      () => setIsApprovingKeywordsTakingLong(true),
      TIME_TO_CONSIDER_APPROVING_REQUEST_LONG,
    );
    try {
      return await request('post', `/v1/keyword-approval`, keywordApprovals);
    } finally {
      clearTimeout(timeoutId);
      setIsApprovingKeywords(false);
      setTimeout(
        () => setIsApprovingKeywordsTakingLong(false),
        TIME_TO_PREVENT_FLICKER_FROM_MODAL_ANIMATION,
      );
    }
  };

  const getKeywordApprovalsRequest = async (searchTerms: string[]) => {
    return await request('post', `/v1/keyword-approval/find-by-search-terms`, {
      searchTerms,
    });
  };

  return {
    approveKeywordsRequest,
    getKeywordApprovalsRequest,
    isApprovingKeywords,
    isApprovingKeywordsTakingLong,
    setIsApprovingKeywordsTakingLong,
  };
}
