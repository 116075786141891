import { PLAN_DETAILS, STARTER } from 'constants/plans';
import { Plan } from 'shared/legacy-types';

export const isPaidPlan = (planName: Plan) => {
  return PLAN_DETAILS[planName].pricing !== null;
};

export const planDoesNotExist = (planName: Plan) => {
  return !PLAN_DETAILS[planName];
};

export const movingBetweenPaidPlans = (
  selectedPlan: Plan | undefined,
  userPlan: Plan | undefined,
  alreadyOnPlan: boolean,
) => {
  if (
    !selectedPlan ||
    !userPlan ||
    alreadyOnPlan ||
    planDoesNotExist(selectedPlan)
  ) {
    return false;
  }

  return isPaidPlan(selectedPlan) && isPaidPlan(userPlan);
};

export const movingToStarterPlan = (
  selectedPlan: Plan | undefined,
  userPlan: Plan | undefined,
) => {
  if (!selectedPlan || !userPlan) {
    return false;
  }
  return selectedPlan === STARTER && userPlan !== STARTER;
};

export const movingToPaidPlan = (
  selectedPlan: Plan | undefined,
  userPlan: Plan | undefined,
) => {
  if (!selectedPlan || !userPlan) {
    return false;
  }
  return userPlan === STARTER && isPaidPlan(selectedPlan);
};
