export function PaymentArrowIcon() {
  return (
    <div className="z-10">
      <svg
        width="290"
        height="220"
        viewBox="0 0 290 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.2785 198.378C72.7649 263.091 73.4167 332.129 93.2339 396.538L-106.879 196.425C-42.471 216.241 26.5665 216.892 91.2785 198.378Z"
          fill="#000d0a"
          opacity={0.8}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M189.458 100.159C170.929 164.888 171.575 233.947 191.398 298.374L-8.75391 98.2217C55.6722 118.043 124.73 118.689 189.458 100.159Z"
          fill="#000d0a"
          opacity={0.8}
        />
      </svg>
    </div>
  );
}
