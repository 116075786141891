import { BannerType } from 'components/Banners/shared';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Banner = {
  type: BannerType;
  message: string;
};

export type BannerState = {
  banners: Banner[];
};

const initialState: BannerState = {
  banners: [],
};

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    addBanner(state: BannerState, action: PayloadAction<Banner>) {
      const banner = {
        type: action.payload.type,
        message: action.payload.message,
      };
      state.banners = [...state.banners, banner];
    },
    removeBanner(state: BannerState) {
      state.banners = [];
    },
  },
});

export const { addBanner, removeBanner } = bannerSlice.actions;
export default bannerSlice.reducer;
