import { useState, useEffect } from 'react';
import { KeywordApproval, Result, RunStep } from 'shared/legacy-types';
import { APPROVED, REJECTED } from '../../../constants/rules';
import { resultMatch } from './keywordApprovals';

export default function useSortApprovals(
  step: RunStep,
  keywordApprovals: KeywordApproval[] | undefined,
) {
  const [actionedResults, setActionedResults] = useState<Result[] | undefined>(
    [],
  );
  const [resultsToBeActioned, setResultsToBeActioned] = useState<
    Result[] | undefined
  >([]);

  useEffect(() => {
    const alreadyActioned =
      step.results &&
      step.results.filter((result) => {
        return (
          keywordApprovals &&
          keywordApprovals.find((approval) => {
            return (
              resultMatch(result, approval) &&
              (approval.status === APPROVED || approval.status === REJECTED)
            );
          })
        );
      });

    const unactioned =
      step.results &&
      step.results.filter((result) => {
        const alreadyActioned =
          keywordApprovals &&
          keywordApprovals.find((approval) => {
            return (
              resultMatch(result, approval) &&
              (approval.status === APPROVED || approval.status === REJECTED)
            );
          });
        if (alreadyActioned) {
          return false;
        }
        return true;
      });

    // No campaign links at bottom of list
    const sortedUnactionedResults =
      unactioned &&
      unactioned.sort((a, b) => {
        if (a.destinationCampaignId && !b.destinationCampaignId) {
          return -1;
        }
        if (!a.destinationCampaignId && b.destinationCampaignId) {
          return 1;
        }
        return 0;
      });

    setActionedResults(alreadyActioned);
    setResultsToBeActioned(sortedUnactionedResults);
    // eslint-disable-next-line
  }, [keywordApprovals]);

  return { actionedResults, resultsToBeActioned };
}
