import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import useUserSelector from 'state/user/useUserSelector';
import useBannerSelector from 'state/banners/useBannerSelector';
import { User } from 'shared/legacy-types';
import TrialBadge from './TrialBadge';
import TrialProgress from './TrialProgress';
import { isTrialing, daysLeftInTrial } from '../helpers';
import { TODAY } from 'helpers/dates/dates';
import * as Sentry from '@sentry/browser';

interface Props {
  user: User | null;
}

const NavPopup = forwardRef(function NavPopup({ user }: Props, ref: any) {
  const { trialEndDate } = useUserSelector();
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;
  const trialing = trialEndDate && isTrialing(trialEndDate, TODAY);
  const trialDaysLeft = trialEndDate && daysLeftInTrial(trialEndDate, TODAY);
  const trialLastDay = trialDaysLeft === 0;

  const firstName = user && user.firstName;
  const firstNameInitial = firstName && firstName[0] ? firstName[0] : '•';

  function logout() {
    Sentry.setUser(null);
    localStorage.removeItem('token');
    window.location.replace('/login.html');
  }

  const TrialInfo = () => {
    if (!trialing) return null;

    return (
      <div className="text-secondary-100 text-xs !leading-[1.125rem] font-medium">
        {trialLastDay ? (
          <>Last day of trial</>
        ) : (
          <>
            {trialDaysLeft} day{trialDaysLeft !== 1 && <>s</>} left
          </>
        )}
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={`absolute bottom-[1.125rem] w-[18.75rem] rounded-md bg-white p-4 ${
        bannerOpen && 'bottom-[4.188rem]'
      } left-[12.5rem] z-40`}
      style={{ boxShadow: '0rem 0.25rem 1rem -0.25rem rgba(0, 18, 51, 0.08)' }}
    >
      <div>
        <div className="bg-primary-60 mx-[-1rem] mt-[-1rem] rounded-t px-4 py-3 flex justify-between ">
          <TrialBadge />
          {TrialInfo()}
        </div>
        {trialing && <TrialProgress />}
        <div className="flex border-b border-neutral-40 py-4 mb-4">
          <span
            className="bg-primary-100 mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full"
            title={user?.email}
          >
            <span className="bg-primary-100 font-medium leading-none text-neutral-100">
              {firstNameInitial.toUpperCase()}
            </span>
          </span>
          <div className={`mr-3 `}>
            <div className="mt-[-0.125rem] text-sm font-bold">
              {user?.firstName} {user?.lastName}
            </div>
            <div className="text-neutral-80 mt-1 text-xs ">{user?.email}</div>
          </div>
        </div>
      </div>
      <Link to="/account">
        <div className="text-neutral-100 hover:bg-neutral-20 rounded p-3 text-sm hover:cursor-pointer">
          Manage account
        </div>
      </Link>
      <Link to="/settings">
        <div className="text-neutral-100 hover:bg-neutral-20 rounded p-3 text-sm hover:cursor-pointer">
          Settings
        </div>
      </Link>
      <div
        className="text-neutral-1-0 hover:bg-neutral-20 rounded p-3 text-sm hover:cursor-pointer"
        onClick={() => logout()}
      >
        Log out
      </div>
    </div>
  );
});

export default NavPopup;
