import { useContext, useState } from 'react';
import { UPDATE_BID, UPDATE_BUDGET } from 'ui/constants/actions';
import { Rule } from 'shared/legacy-types';
import useAction from 'ui/hooks/useAction';
import { ActionContext } from '../ActionContext';

export default function useRuleAction() {
  const [removeActionOpen, setRemoveActionOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { action, rule, stepIndex, actionIndex, onChange } =
    useContext(ActionContext);
  const {
    isPauseOrEnable,
    metricLabel,
    conditionLabel,
    pauseEnablelabel,
    isKeywordHarvesting,
    bidBudgetValue,
    currentActionTab: actionTab,
  } = useAction(action, rule.dataType);

  const bidValue = `${metricLabel} by ${bidBudgetValue}`;

  function removeAction() {
    if (!rule.steps || !rule.steps[stepIndex]) {
      throw new Error('No steps found, so cannot remove action');
    }

    rule.steps[stepIndex]?.actions.splice(actionIndex, 1);

    onChange({ ...rule } as Rule);
    setRemoveActionOpen(false);
  }

  const actionTitle = () => {
    if (actionTab.type === UPDATE_BID) {
      return `${conditionLabel} bid`;
    }

    if (actionTab.type === UPDATE_BUDGET) {
      return `${conditionLabel} budget`;
    }

    if (isPauseOrEnable) {
      return `${actionTab.label} ${pauseEnablelabel}`;
    }

    return actionTab.label;
  };

  return {
    removeAction,
    actionTitle,
    removeActionOpen,
    setRemoveActionOpen,
    editModalOpen,
    setEditModalOpen,
    bidValue,
    actionTab,
    isKeywordHarvesting,
    isPauseOrEnable,
  };
}
