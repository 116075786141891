import { useState, useContext } from 'react';
import { Rule, IsValid, Optional } from 'shared/legacy-types';
import useScope from 'pages/Rule/Scope/useScope';
import { SEARCH_TERMS, AD_OPTIONS } from 'ui/constants/rules';
import SpendTargetAlertModal from './SpendTarget/SpendTargetAlertModal';
import useSpendTargetAlert from './SpendTarget/useSpendTargetAlert';
import { RuleContext } from '../RuleContainer';
import ResetStepsAlert from './ResetStepsAlert';
import ScopeComponent from './ScopeComponent';
import useUser from 'hooks/useUser';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

type Props = {
  rule: Optional<Rule, 'id'>;
  onChange: (rule: Optional<Rule, 'id'>) => void;
  isValid: IsValid;
};

export default function ScopeContainer({
  onChange,
  rule,
  isValid,
}: Props): JSX.Element {
  const [selectedBrandName, setSelectedBrandName] = useState<string>('');

  const { showSpendTargetAlert, setShowSpendTargetAlert, spendTargetCheck } =
    useSpendTargetAlert(rule);
  const {
    selectAdType,
    selectBrand,
    selectMarketplace,
    selectedMarketplaces,
    deSelectBrand,
    selectDataType,
    userDataTypes,
    showResetAlert,
    setShowResetAlert,
    resetSteps,
  } = useScope(rule, onChange, setSelectedBrandName, spendTargetCheck);

  const { openCreateSpendTargetModal, highlightInvalidFields } =
    useContext(RuleContext);
  const { getBrandOptions, getMarketOptions } = useUser();

  // for search term data type only allow sb and sp ad types
  const filteredAdOptions = AD_OPTIONS.filter((option) => {
    if (rule.dataType === SEARCH_TERMS && option.value === 'SD') {
      return false;
    }
    return true;
  });

  function closeAlertModal(showModal: boolean): void {
    setShowSpendTargetAlert(showModal);
    showToast(
      `Marketplace metrics have been disabled until you add a spend target for ${selectedBrandName}. You won't be able to turn on your rule until you fix this.`,
      ToastStyle.WARNING,
    );
  }

  function openCreateModal(): void {
    setShowSpendTargetAlert(false);
    openCreateSpendTargetModal(true);
  }

  return (
    <>
      <ScopeComponent
        userDataTypes={userDataTypes}
        rule={rule}
        selectDataType={selectDataType}
        filteredAdOptions={filteredAdOptions}
        selectAdType={selectAdType}
        highlightInvalidFields={highlightInvalidFields}
        isValid={isValid}
        selectedBrandName={selectedBrandName}
        selectBrand={selectBrand}
        deSelectBrand={deSelectBrand}
        selectedMarketplaces={selectedMarketplaces}
        selectMarketplace={selectMarketplace}
        marketplaceOptions={() => getMarketOptions(selectedBrandName)}
        brandOptions={() => getBrandOptions()}
        onChange={onChange}
      />
      {showSpendTargetAlert && (
        <SpendTargetAlertModal
          open={showSpendTargetAlert}
          setOpen={(showModal) => {
            closeAlertModal(showModal);
          }}
          openSpendTargetModal={() => {
            openCreateModal();
          }}
        />
      )}
      {showResetAlert && (
        <ResetStepsAlert
          open={showResetAlert}
          setOpen={setShowResetAlert}
          resetSteps={resetSteps}
        />
      )}
    </>
  );
}
