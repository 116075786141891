import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunStep } from 'shared/legacy-types';

export interface RunsState {
  runSteps: RunStep[] | undefined;
}

const initialState: RunsState = {
  runSteps: undefined,
};

const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    updateRunSteps(state, action: PayloadAction<RunStep[] | undefined>) {
      state.runSteps = action.payload;
    },
  },
});

export const { updateRunSteps } = rulesSlice.actions;
export default rulesSlice.reducer;
