import useRequest from 'api/hooks/useRequest';

export default function useFolderRequests() {
  const { request } = useRequest();

  const getFolders = async () => {
    return await request('get', '/v1/folders');
  };

  const createFolder = async (folderName: string) => {
    return await request('post', `/v1/folders`, { name: folderName });
  };

  const renameFolder = async (folderId: string, folderName: string) => {
    return await request('put', `/v1/folders/${folderId}`, {
      name: folderName,
    });
  };

  const moveRules = async (folderId: string, rules: string[]) => {
    return await request('post', `/v1/folders/move-rules`, {
      ruleIds: rules,
      folderId: folderId || null,
    });
  };

  const removeFolder = async (folderId: string, archiveRules: boolean) => {
    return await request('delete', `/v1/folders/${folderId}`, { archiveRules });
  };

  return {
    getFolders,
    createFolder,
    renameFolder,
    moveRules,
    removeFolder,
  };
}
