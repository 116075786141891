import {
  getInternationalNumber,
  browserLocale,
} from '../localisation/localisation';

export const formatCurrency = (
  value: number,
  currencyCode: string,
  subUnit: number,
) => {
  return getInternationalNumber(
    value,
    currencyCode,
    'currency',
    subUnit,
    subUnit,
  );
};

export const getCurrencySymbol = (currencyCode: string) => {
  const currency = new Intl.NumberFormat(browserLocale(), {
    style: 'currency',
    currency: currencyCode,
  })
    .formatToParts(1)
    .find((x) => x.type === 'currency');

  return currency ? currency.value : '';
};
