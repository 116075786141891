import { browserLocale } from 'ui/helpers/localisation/localisation';

const locale = browserLocale() === 'en-US' ? 'en-US' : 'en-GB';

export default function DateFormatting() {
  return (
    <section id="dates" className="p-10 border-neutral-40 border-t border-1">
      <h4>US:</h4>
      {new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(Date.parse('2023-01-25T07:56:20Z'))}
      <h4 className="mt-6">UK and the rest of the world:</h4>
      {new Intl.DateTimeFormat('en-GB', {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(Date.parse('2023-01-25T07:56:20Z'))}
      <h4 className="mt-6">
        Based on your browser settings ({locale}) - either US or UK & Rest of
        the world:
      </h4>
      {new Intl.DateTimeFormat(locale, {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(Date.parse('2023-01-25T07:56:20Z'))}
    </section>
  );
}
