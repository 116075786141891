import { ReactElement, useContext } from 'react';
import { SetupContext } from 'pages/Onboarding/Setup';
import useBannerSelector from 'state/banners/useBannerSelector';
import ProgressBar from 'components/ProgressBar';

interface Props {
  children: ReactElement;
}

export default function OnboardingLayout({ children }: Props) {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  const { stepsProgress } = useContext(SetupContext) || {};

  return (
    <div className={`${bannerOpen && 'pt-[3rem]'}`}>
      <div className="max-h-screen flex justify-center pt-8">
        <div className="text-center z-20">
          <div className="flex justify-center mb-6">
            <ProgressBar stepsProgress={stepsProgress} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
