import useUser from 'hooks/useUser';
import SpendTarget from 'pages/SpendTargets/SpendTarget';

import { Profile } from 'shared/legacy-types';

interface Props {
  filterSpendTargets: () => string[];
  openDeleteModal: (profileId: string) => void;
  openEditModal: (profileId: string) => void;
}

export default function SpendTargets({
  filterSpendTargets,
  openDeleteModal,
  openEditModal,
}: Props) {
  const { getProfileData } = useUser();

  function renderSpendTargets() {
    const filteredSpendTargets = filterSpendTargets();

    return filteredSpendTargets.map((profileId: string) => {
      const profileData = getProfileData(+profileId);
      const { countryCode, country, accountName } =
        profileData || ({} as Profile);

      // cant use this yet, currently using 409 to prevent delete
      const isLive = false;

      function openEditGoalModal() {
        openEditModal(profileId);
      }

      function openDeleteGoalModal() {
        openDeleteModal(profileId);
      }

      return (
        <SpendTarget
          brand={accountName}
          countryCode={countryCode}
          country={country}
          openDeleteModal={openDeleteGoalModal}
          openEditModal={openEditGoalModal}
          isLive={isLive}
        />
      );
    });
  }

  return <>{renderSpendTargets()}</>;
}
