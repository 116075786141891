import { Link, useNavigate } from 'react-router-dom';
import { sendEvent } from 'helpers/analytics';

import { TemplateIcon, OutlineDashIcon } from 'components/Icons';

interface Props {
  dropDownRef: React.RefObject<HTMLDivElement>;
  openDropdown: boolean;
}

export default function CreateRuleDropdown({
  dropDownRef,
  openDropdown,
}: Props) {
  const navigate = useNavigate();

  function navigateToRuleEditor() {
    sendEvent('create_rule');
    navigate('/rules/new');
  }

  if (!openDropdown) return null;

  return (
    <div
      ref={dropDownRef}
      className="absolute bg-white py-[0.6875rem] px-[0.9375rem] w-[13.75rem] rounded-md top-[2.813rem] right-[0rem] border border-neutral-40 z-50"
      style={{ boxShadow: '0rem 0.25rem 1rem, -0.25rem rgba(0, 18, 51, 0.08)' }}
    >
      <div
        className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded flex"
        onClick={() => navigateToRuleEditor()}
      >
        <div className="mr-2">
          <OutlineDashIcon />
        </div>
        <div className="leading-5">Start from scratch</div>
      </div>
      <Link to="/templates">
        <div className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded flex">
          <div className="mr-2">
            <TemplateIcon fill="fill-neutral-80" />
          </div>
          <div className="leading-5">Choose a template</div>
        </div>
      </Link>
    </div>
  );
}
