interface Props {
  stroke?: string;
}

export function TrashIcon({ stroke = 'stroke-neutral-80' }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4H3.33333H14"
        className={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6615 4.0026V13.3359C12.6615 13.6896 12.521 14.0287 12.2709 14.2787C12.0209 14.5288 11.6817 14.6693 11.3281 14.6693H4.66146C4.30784 14.6693 3.9687 14.5288 3.71865 14.2787C3.4686 14.0287 3.32813 13.6896 3.32812 13.3359V4.0026M5.32813 4.0026V2.66927C5.32813 2.31565 5.4686 1.97651 5.71865 1.72646C5.9687 1.47641 6.30784 1.33594 6.66146 1.33594H9.32813C9.68175 1.33594 10.0209 1.47641 10.2709 1.72646C10.521 1.97651 10.6615 2.31565 10.6615 2.66927V4.0026"
        className={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.67188 7.33594V11.3359"
        className={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32812 7.33594V11.3359"
        className={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
