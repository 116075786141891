import ArchivedRuleCard from 'pages/Dashboard/Archived/ArchivedRuleCard';
import useArchived from 'pages/Dashboard/Archived/useArchived';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import Dashboard from 'pages/Dashboard/Dashboard';

export default function ArchivedRulesPage() {
  const { archivedRules, loadingArchived } = useArchived();

  function renderArchivedRules() {
    if (loadingArchived) {
      return (
        <div className="h-screen flex">
          <div className="m-auto mt-80">
            <LargeSpinner />
          </div>
        </div>
      );
    }

    if (!loadingArchived && archivedRules?.length === 0) {
      return (
        <section className="bg-[url(./icons/specific/blank-rules.svg)]   h-[13.3125rem] max-w-[41.125rem] mx-auto mt-[-9rem] bg-no-repeat relative">
          <div className="mt-40 text-center">
            <h4 className="my-6 font-bold absolute top-[5.2rem] left-[28%]">
              You haven't archived any rules yet
            </h4>
          </div>
        </section>
      );
    }

    return archivedRules?.map((rule) => (
      <ArchivedRuleCard key={rule.id} rule={rule} />
    ));
  }

  return (
    <Dashboard>
      <>{renderArchivedRules()}</>
    </Dashboard>
  );
}
