interface Props {
  background?: string;
  iconColour?: string;
  strokeColour?: string;
}

export function LargeNegativeCircleIcon({}: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="2" fill="#6CE1B2" />
      <circle cx="12" cy="12" r="8" fill="white" />
    </svg>
  );
}
