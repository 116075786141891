import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  GetBrandIdFromSellingPartnerApiOAuthRedirectDocument,
  GetBrandIdFromSellingPartnerApiOAuthRedirectMutation,
} from 'codegen/graphql';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';
import * as Sentry from '@sentry/react';

type UseBrandRedirectHook = {
  redirectModalOpen: boolean;
};

export default function useBrandRedirect(): UseBrandRedirectHook {
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const navigate = useNavigate();

  const [mutateFunction, { data, loading, error }] =
    useMutation<GetBrandIdFromSellingPartnerApiOAuthRedirectMutation>(
      GetBrandIdFromSellingPartnerApiOAuthRedirectDocument,
    );

  const [params] = useSearchParams();
  const state = params.get('state');
  const oAuthCode = params.get('spapi_oauth_code');
  const sellingPartnerId = params.get('selling_partner_id');

  useEffect(() => {
    async function checkRedirect(): Promise<void> {
      if (state != null && oAuthCode != null && sellingPartnerId != null) {
        setRedirectModalOpen(true);
        await mutateFunction({
          variables: {
            stateToken: state,
          },
        });
      }
    }

    checkRedirect();
  }, []);

  useEffect(() => {
    if (data && !loading) {
      const brandId = data.getBrandIdFromSellingPartnerApiOAuthRedirect;
      navigate(
        `/settings/${brandId}?state=${state}&oauth_code=${oAuthCode}&selling_partner_id=${sellingPartnerId}`,
      );
      setRedirectModalOpen(false);
    }

    if (error) {
      Sentry.captureMessage(
        `SP-API connect brand error at root, Error: ${error.message}`,
      );
      showToast(
        'Something went wrong when connecting your brand, please try again.',
        ToastStyle.ERROR,
      );
    }
  }, [data]);

  return { redirectModalOpen };
}
