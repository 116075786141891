import { useContext } from 'react';

import { ACTION_GROUPS } from 'ui/constants/actions';
import { ActionGroup, ActionTab } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import ActionTabComponent from './ActionTab';
import { UPDATE_BID } from 'ui/constants/actions';
import { CAMPAIGNS } from 'ui/constants/rules';

interface Props {
  setSelectedAction: (selected: string) => void;
  selectedAction: string;
}

export default function ActionTabs({
  setSelectedAction,
  selectedAction,
}: Props) {
  const { isInternalUser } = useUserSelector();
  const { rule } = useContext(StepContext);

  const updatedActions = ACTION_GROUPS.map((actionGroup: ActionGroup) => {
    const actionsList = actionGroup.actions.filter((action) => {
      // filter by selected step data type
      if (!action.availableFor?.includes(rule.dataType!)) {
        return false;
      }

      if (!isInternalUser && action.hide) {
        return false;
      }

      return true;
    });

    return { ...actionGroup, actions: actionsList };
  });

  function getActionTabs(actionsList: ActionTab[]) {
    return actionsList.map((action: any, index: number) => {
      if (action.type === UPDATE_BID && rule.dataType === CAMPAIGNS) {
        return false;
      }

      return (
        <ActionTabComponent
          action={action}
          index={index}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          actionsList={actionsList}
        />
      );
    });
  }

  function renderActions() {
    return updatedActions.map((actionGroup, index) => {
      const { actions, label } = actionGroup;

      const hasActions = actions.length > 0;
      const firstGroup = index === 0;

      if (hasActions) {
        return (
          <div>
            <div
              className={`border-neutral-40  mx-[-1rem] mb-3 flex justify-between border-y-[0.063rem] bg-white p-4 text-neutral-100 ${
                firstGroup && 'border-t-0'
              }`}
            >
              <div className="text-sm font-bold leading-[1.375rem]">
                {label}
              </div>
            </div>
            <div
              className="border-neutral-40 mb-3 rounded-lg border bg-white"
              style={{
                boxShadow: '0rem 0.125rem 0.25rem rgba(0, 18, 51, 0.05)',
              }}
            >
              {getActionTabs(actions)}
            </div>
          </div>
        );
      }

      return null;
    });
  }

  return <>{renderActions()}</>;
}
