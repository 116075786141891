interface Props {
  stroke?: string;
}

export function RuleIcon({ stroke = 'stroke-neutral-60' }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15C12.5 16.3807 13.6193 17.5 15 17.5C16.3807 17.5 17.5 16.3807 17.5 15C17.5 13.6193 16.3807 12.5 15 12.5C13.6193 12.5 12.5 13.6193 12.5 15Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${stroke}`}
      />
      <path
        d="M2.5 5C2.5 6.38071 3.61929 7.5 5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5Z"
        className={`${stroke}`}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15C10.5109 15 8.60322 14.2098 7.1967 12.8033C5.79018 11.3968 5 9.48912 5 7.5"
        className={`${stroke}`}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7.5V17.5"
        className={`${stroke}`}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
