import { useContext } from 'react';

import {
  Condition,
  Rule,
  ConditionOperator,
  ExpressionTypes,
  ConditionExpression,
  ExpressionMetric,
  ExpressionOperator,
  MetricCondition,
  ValueCondition,
} from 'shared/legacy-types';
import { OPERATORS, REGULAR_INPUT, PRODUCT_SIGNALS } from 'ui/constants/rules';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import { getField } from 'ui/helpers/rules/fields';

export default function useCondition(
  onChange: (rule: Omit<Rule, 'id'>) => void,
  rule: Omit<Rule, 'id'>,
  index: number,
  condition: Condition,
  groupIndex: number,
  setToggleEnabled: (enabled: boolean) => void,
  setInputType: (value: string) => void,
) {
  const { stepIndex } = useContext(StepContext) || {};

  const step = rule.steps[stepIndex];
  const conditionGroup = step && step.conditionGroups[groupIndex];

  function removeCondition(conditions: Condition[]) {
    if (!step) {
      throw new Error('No step found, so cannot remove condition');
    }
    // if last condition then remove group
    if (conditions.length === 1 && groupIndex > 0) {
      step.conditionGroups.splice(groupIndex, 1);
      onChange({ ...rule });
      return;
    }

    if (conditions) {
      conditions.splice(index, 1);
      onChange({ ...rule });
    }
  }

  // filter operators list based on field type (string/number/boolean)
  function getOperators() {
    const filtered = OPERATORS.filter((operator) => {
      if (operator.type === getField(condition.field)?.type) {
        return operator;
      }
      return null;
    });

    // update label for product signal number operators
    const updated = filtered.map((operator) => {
      if (operator.type === 'number' && rule.dataType === PRODUCT_SIGNALS) {
        return { ...operator, label: `Changes to ${operator.label}` };
      }
      return operator;
    });

    return updated;
  }

  function getInputType() {
    if (getField(condition.field)?.type === 'string') {
      return 'text';
    }

    return 'number';
  }

  function updateFieldSelection(value: string) {
    const metricCondition = condition as MetricCondition;
    const valueCondition = condition as ValueCondition;
    const isValueCondition = condition.hasOwnProperty('value');

    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update condition',
      );
    }

    // reset value
    isValueCondition
      ? (valueCondition.value = '')
      : (metricCondition.metric = '');

    //reset operator
    isValueCondition
      ? (valueCondition.operator = '' as ConditionOperator)
      : (metricCondition.operator = '' as ConditionOperator);

    setInputType(REGULAR_INPUT);

    // if user selects same field, reset input
    // or assign new one to field
    if (isValueCondition) {
      if (valueCondition.field === value) {
        //reset input
        valueCondition.field = '';
      } else {
        // add value to inputs
        valueCondition.field = value;
      }
    } else {
      if (metricCondition.field === value) {
        //reset input
        metricCondition.field = '';
      } else {
        // add value to inputs
        metricCondition.field = value;
      }
    }

    // reset toggle if user changes field
    setToggleEnabled(false);

    const updatedCondition = isValueCondition
      ? valueCondition
      : metricCondition;

    conditionGroup.conditions[index] = {
      ...updatedCondition,
    };

    onChange({ ...rule });
  }

  function updateOperator(value: string) {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update operator',
      );
    }

    if (condition.operator === value) {
      condition.operator = '' as ConditionOperator;
    } else {
      condition.operator = value as ConditionOperator;
    }

    conditionGroup.conditions[index] = {
      ...condition,
    };

    onChange({ ...rule });
  }

  function handleInputChange(value: string, isMetric: boolean = false) {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update condition',
      );
    }

    const metricCondition = condition as MetricCondition;
    const valueCondition = condition as ValueCondition;

    const updatedCondition = isMetric
      ? {
          field: metricCondition.field,
          operator: metricCondition.operator,
          metric: value,
        }
      : {
          field: valueCondition.field,
          operator: valueCondition.operator,
          value,
        };

    conditionGroup.conditions[index] = {
      ...updatedCondition,
    };

    onChange({ ...rule });
  }

  function updateExpression(value: string, type: ExpressionTypes) {
    if (!step || !conditionGroup) {
      throw new Error(
        'No step or condition groups found, so cannot update expression',
      );
    }

    // if there's a string value, reset to expression
    // operator hardcoded to * until user can change operator
    const valueCondition = condition as ValueCondition;

    if (typeof valueCondition.value === 'string') {
      valueCondition.value = {
        metric: '' as ExpressionMetric,
        value: '',
        operator: '*' as ExpressionOperator,
      } as ConditionExpression;
    }

    const conditionExpression = valueCondition.value as ConditionExpression;

    if (conditionExpression[type] === value) {
      conditionExpression[type] = '' as ExpressionMetric;
    } else {
      conditionExpression[type] = value as ExpressionMetric;
    }

    conditionGroup.conditions[index] = {
      ...condition,
      value: conditionExpression,
    };

    onChange({ ...rule });
  }

  return {
    removeCondition,
    getOperators,
    handleInputChange,
    getInputType,
    updateFieldSelection,
    updateOperator,
    updateExpression,
  };
}
