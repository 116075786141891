import { useState } from 'react';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { AccountType, Marketplace } from 'codegen/graphql';
import Spinner from 'ui/Spinner/Spinner';
import Pill, { PillStyle } from 'design-system/components/Pill/Pill';
import MarketplaceRetailData from './MarketplaceRetailData';

type Props = {
  connectMarketplace: (marketplaceId: string, onComplete: () => void) => void;
  isConnectingMarketplace: boolean;
  marketplace: Marketplace;
  brandAccountType: AccountType;
  sellingPartnerAuthRedirectRunning: boolean;
};

export default function MarketplaceStatus({
  connectMarketplace,
  isConnectingMarketplace,
  marketplace,
  brandAccountType,
  sellingPartnerAuthRedirectRunning,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { isConnectedForAdsData, id } = marketplace;

  function ConnectButton(): JSX.Element {
    return (
      <PrimaryButton
        disabled={isConnectingMarketplace}
        width="w-[6.25rem]"
        size="sm"
        onClick={() => {
          setIsLoading(true);
          connectMarketplace(id, () => {
            setIsLoading(false);
          });
        }}
      >
        {isLoading ? (
          <span className="flex justify-center">
            <Spinner />
          </span>
        ) : (
          <>Connect</>
        )}
      </PrimaryButton>
    );
  }

  return (
    <div className="mt-5 flex">
      <div className="mr-8 w-[7.85rem]">
        <div className="text-xs text-neutral-80 mb-0.5">Ads data</div>
        <div className="h-8 flex flex-col justify-center">
          {isConnectedForAdsData ? (
            <Pill label="Connected" style={PillStyle.PRIMARY} />
          ) : (
            <>{ConnectButton()}</>
          )}
        </div>
      </div>
      <MarketplaceRetailData
        marketplace={marketplace}
        brandAccountType={brandAccountType}
        sellingPartnerAuthRedirectRunning={sellingPartnerAuthRedirectRunning}
      />
    </div>
  );
}
