import { KeywordApproval, Result } from 'shared/legacy-types';

const searchTermMatch = (
  searchTerm: string | undefined,
  comparisonSearchTerm: string,
) => {
  return searchTerm === comparisonSearchTerm;
};

const campaignIdMatch = (
  campaignId: string | undefined,
  comparisonCampaignId: string,
) => {
  return campaignId === comparisonCampaignId;
};

const targetingTypeMatch = (
  targetingType: string | undefined,
  comparisonTargetingType: string,
) => {
  return targetingType === comparisonTargetingType;
};

const adGroupsMatch = (
  adGroupIds: string[] | null | undefined,
  comparisonAdGroupIds: string[] | null | undefined,
) => {
  return (
    (!adGroupIds && !comparisonAdGroupIds) ||
    (adGroupIds &&
      comparisonAdGroupIds &&
      adGroupIds.every(
        (adGroupId, index) =>
          comparisonAdGroupIds && adGroupId === comparisonAdGroupIds[index],
      ))
  );
};

const matchTypesMatch = (
  matchTypes: string[] | undefined,
  comparisonMatchTypes: string[],
) => {
  return (
    (!matchTypes && !comparisonMatchTypes) ||
    (matchTypes &&
      comparisonMatchTypes &&
      matchTypes.every(
        (matchType, index) => matchType === comparisonMatchTypes[index],
      ))
  );
};

export const resultMatch = (
  result: Result,
  approval: Partial<KeywordApproval>,
) => {
  if (!approval) {
    return false;
  }

  return (
    searchTermMatch(approval.searchTerm, result.searchTerm) &&
    campaignIdMatch(approval.campaignId, result.destinationCampaignId) &&
    targetingTypeMatch(approval.targetingType, result.targetingType) &&
    adGroupsMatch(approval.adGroupIds, result.destinationAdGroupIds) &&
    matchTypesMatch(approval.matchTypes, result.matchTypes)
  );
};

export const approvalsMatch = (
  approval: Omit<KeywordApproval, 'status'>,
  comparisonApproval: Omit<KeywordApproval, 'status'>,
) => {
  return (
    searchTermMatch(approval.searchTerm, comparisonApproval.searchTerm) &&
    campaignIdMatch(approval.campaignId, comparisonApproval.campaignId) &&
    adGroupsMatch(comparisonApproval.adGroupIds, approval.adGroupIds) &&
    targetingTypeMatch(
      approval.targetingType,
      comparisonApproval.targetingType,
    ) &&
    matchTypesMatch(comparisonApproval.matchTypes, approval.matchTypes)
  );
};

export const resultsWaitingApproval = (
  results: Result[] | undefined,
  keywordApprovals: KeywordApproval[],
) => {
  const resultsWithDestination =
    results && results.filter((result) => result.destinationCampaignId);

  if (!resultsWithDestination) {
    return [];
  }

  return resultsWithDestination.filter((result) => {
    const isApprovedOrRejected = keywordApprovals.find((approval) => {
      return resultMatch(result, approval);
    });
    return !isApprovedOrRejected;
  });
};

export const filterDuplicates = (
  results: Result[] | undefined,
  keywordApprovals: KeywordApproval[],
) => {
  const resultsWaitingForApproval = resultsWaitingApproval(
    results,
    keywordApprovals,
  );

  return (
    resultsWaitingForApproval &&
    resultsWaitingForApproval.filter((result, currentIndex) => {
      const index =
        resultsWaitingForApproval &&
        resultsWaitingForApproval.findIndex((currentResult) => {
          const adTypeMatch = currentResult.adType === result.adType;

          return (
            searchTermMatch(currentResult.searchTerm, result.searchTerm) &&
            campaignIdMatch(
              currentResult.destinationCampaignId,
              result.destinationCampaignId,
            ) &&
            targetingTypeMatch(
              currentResult.targetingType,
              result.targetingType,
            ) &&
            adGroupsMatch(
              currentResult.destinationAdGroupIds,
              result.destinationAdGroupIds,
            ) &&
            matchTypesMatch(currentResult.matchTypes, result.matchTypes) &&
            adTypeMatch
          );
        });
      return index === currentIndex;
    })
  );
};
