import { useEffect, useState, createContext } from 'react';
import { useParams, useBlocker } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import { Optional, Rule } from 'shared/legacy-types';
import RuleComponent from 'pages/Rule/RuleComponent';
import { DEFAULT_RULE } from 'ui/constants/rules';
import useRuleRequests from 'api/hooks/requests/useRuleRequests';
import useRule from 'pages/Rule/useRule';
import { TEMPLATES } from 'constants/templates/templates';
import useRulesDispatch from 'state/rules/useRulesDispatch';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import useUserDispatch from 'state/user/useUserDispatch';
import useRequest from 'api/hooks/useRequest';
import CreateSpendTargetModal from 'components/SpendTargets/CreateSpendTargetModal';
import useCreateSpendTarget from 'hooks/useCreateSpendTarget';
import useUserSelector from 'state/user/useUserSelector';
import { deepClone } from 'ui/helpers/deepClone';
import * as Sentry from '@sentry/react';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';
import { UserState } from 'state/user/userSlice';

type ContextData = {
  openCreateSpendTargetModal: (open: boolean) => void;
  highlightInvalidFields: boolean;
};

export const RuleContext = createContext<ContextData>({
  openCreateSpendTargetModal: () => {},
  highlightInvalidFields: false,
});

const HTTP_OK = 200;

function RuleContainer(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [ruleLoading, setRuleLoading] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);
  const [rule, setRule] = useState<Optional<Rule, 'id'>>(
    deepClone(DEFAULT_RULE) as Rule,
  );
  const [unsavedModalOpen, setUnsavedModalOpen] = useState<boolean>(false);

  const { ruleId } = useParams();
  const { getRule, updateRule, createRule } = useRuleRequests();
  const {
    toggleRuleStatus,
    isSavingEnabled,
    isToggleEnabled,
    isValid,
    isRuleValid,
    checkInvalidFields,
    highlightInvalidFields,
    isEditing,
  } = useRule(rule);
  const { updateEditingRule } = useRulesDispatch();

  const { updateUser } = useUserDispatch();
  const { request } = useRequest();
  const { userConfig } = useUserSelector();
  const { profilesWithSpendTarget } = userConfig ?? {};
  const spendTargetProfileIds = profilesWithSpendTarget ?? [];

  const {
    updateCreateModalOpen,
    createModalOpen,
    newSpendTarget,
    setNewSpendTarget,
    addSpendTarget,
    creatingSpendTarget,
  } = useCreateSpendTarget();

  const blocker = useBlocker(isEditing);

  useEffect(
    function initModal() {
      if (blocker.state === 'blocked') {
        setUnsavedModalOpen(true);
      }
    },
    [blocker],
  );

  // so profilesWithSpendTargets updates
  useEffect(() => {
    async function fetchUser(): Promise<void> {
      const response = await request('get', '/v1/me');
      updateUser(response.data as UserState);
    }
    void fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isEditing) {
      blocker.reset();
    }

    if (isEditing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => null;
    }
  }, [blocker, isEditing]);

  useEffect(() => {
    const initRule = async (): Promise<void> => {
      if (ruleId != null) {
        const template = TEMPLATES[ruleId as keyof typeof TEMPLATES];
        if (template) {
          updateEditingRule({ id: ruleId, type: 'add' });
          setRule(deepClone(template.rule) as Rule);
        } else {
          updateEditingRule({ id: ruleId, type: 'remove' });
          setRuleLoading(true);
          const rule = (await getRule(ruleId)) as Rule;
          setRuleLoading(false);
          setRule(rule);
        }
      }
    };
    void initRule();
    // eslint-disable-next-line
  }, []);

  const saveState = (continueNavigation?: () => void): void => {
    setLoading(false);
    setSuccess(true);
    updateEditingRule({ id: rule.id ?? '', type: 'remove' });
    if (continueNavigation) {
      continueNavigation();
    }
  };

  async function save(continueNavigation?: () => void): Promise<void> {
    if (!rule.name) {
      rule.name = 'Untitled rule';
    }
    setLoading(true);

    if (rule.id != null) {
      const updatedRule = { ...rule, updatedAt: new Date().toISOString() };
      try {
        const response = await updateRule(updatedRule as Rule);
        if (response.status === HTTP_OK) {
          saveState(continueNavigation);
        }
        setRule(updatedRule);
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
      }
    } else {
      const newRule = await createRule({ ...rule });
      const newId = newRule.id;
      updateEditingRule({ id: ruleId ?? '', type: 'remove' });
      window.history.pushState(null, 'rules', `/rules/${newId}`);
      saveState(continueNavigation);
      setLoading(false);
      setRule(newRule);
    }
  }

  function modifyRule(rule: Optional<Rule, 'id'>): void {
    setRule({ ...rule });
    setSuccess(false);
    updateEditingRule({ id: rule.id ?? '', type: 'add' });
  }

  async function createSpendTarget(
    brandName: string | undefined,
  ): Promise<void> {
    const onCreateSuccess = (): void => {
      showToast(`Spend target added for ${brandName}`, ToastStyle.SUCCESS);
    };

    await addSpendTarget(onCreateSuccess);
  }

  return (
    <>
      <RuleContext.Provider
        value={{
          openCreateSpendTargetModal: updateCreateModalOpen,
          highlightInvalidFields: highlightInvalidFields,
        }}
      >
        <DashboardLayout>
          <RuleComponent
            rule={rule}
            onChange={modifyRule}
            onSave={save}
            loading={loading}
            ruleLoading={ruleLoading}
            success={success}
            isEditing={isEditing}
            isValid={isValid}
            isRuleValid={isRuleValid}
            checkInvalidFields={checkInvalidFields}
            onToggleChange={(enabled) => toggleRuleStatus(enabled, setRule)}
            disableToggle={!isToggleEnabled}
            disableSaveButton={!isSavingEnabled}
            highlightInvalidFields={highlightInvalidFields}
          />
        </DashboardLayout>
      </RuleContext.Provider>
      <UnsavedChangesModal
        open={unsavedModalOpen}
        setOpen={setUnsavedModalOpen}
        blocker={blocker}
        save={save}
        loading={loading}
        title="You're about to leave this rule. What would you like to do with the changes?"
      />
      {createModalOpen && (
        <CreateSpendTargetModal
          open={createModalOpen}
          setOpen={updateCreateModalOpen}
          newSpendTarget={newSpendTarget}
          setNewSpendTarget={setNewSpendTarget}
          addSpendTarget={(brandName) => createSpendTarget(brandName)}
          creatingSpendTarget={creatingSpendTarget}
          spendTargetProfileIds={spendTargetProfileIds}
        />
      )}
    </>
  );
}

export default RuleContainer;
