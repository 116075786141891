import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import Dropdown from 'components/Dropdowns/Dropdown';
import { TARGETING_TYPES, MATCH_TYPES } from 'ui/constants/rules';
import { EditModalCircles, EditModalArrows } from 'components/Icons';
import { CampaignLink } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';
import SimpleMultiDropdown from 'components/Dropdowns/SimpleMultiDropdown/SimpleMultiDropdown';
import Bid from '../Components/Bid';
import useAddCampaignLink from 'pages/CampaignMapping/Modals/AddCampaignLink/useAddCampaignLink';
import CampaignDropdowns from '../Components/CampaignDropdowns';
import ProfileSelection from '../Components/ProfileSelection';
import KeywordNegation from '../Components/KeywordNegation';
import ErrorMessage from 'components/Errors/ErrorMessage';
import {
  IS_DUPLICATE_MESSAGE,
  DUPLICATE_LINKS_WARNING,
} from 'constants/keywordHarvesting';
import {
  allFieldsRequiredForValidationCheckPopulated,
  allCampaignLinkFieldsPopulated,
  campaignLinksMatch,
} from 'pages/CampaignMapping/helpers/campaignLinks';
import Warning from 'components/Alerts/Warning';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  campaignLinks: CampaignLink[];
  setCampaignLinks: (campaigns: CampaignLink[]) => void;
  campaignLinkToCopy: CampaignLink | null;
}

export default function AddCampaignLinkModal({
  open,
  setOpen,
  campaignLinks,
  setCampaignLinks,
  campaignLinkToCopy,
}: Props) {
  const {
    addBid,
    updateCampaignLinkForm,
    brand,
    addNewCampaignLink,
    submittingCampaignLink,
    campaignLink,
    setCampaignLink,
    isDuplicateCampaignLink,
    isDuplicateCampaignLinkValidationRunning,
    initialCampaignLink,
  } = useAddCampaignLink(
    campaignLinks,
    setCampaignLinks,
    setOpen,
    campaignLinkToCopy,
  );

  const campaignLinkHasChanged = !campaignLinksMatch(
    campaignLink,
    initialCampaignLink,
  );

  const showUpdateFieldsWarning =
    !campaignLinkHasChanged && !!campaignLinkToCopy;

  const showDuplicateError =
    isDuplicateCampaignLink &&
    allFieldsRequiredForValidationCheckPopulated(campaignLink) &&
    campaignLinkHasChanged;

  const disableAddSaveButton =
    !allCampaignLinkFieldsPopulated(campaignLink) ||
    isDuplicateCampaignLink ||
    isDuplicateCampaignLinkValidationRunning;

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="w-[31rem] min-h-[39.188rem] ">
        <div className="relative flex">
          <span className="absolute top-0 right-0">
            <EditModalCircles />
          </span>
          <span className="absolute bottom-0 left-0">
            <EditModalArrows />
          </span>
          <div className="bg-secondary-40 p-8 max-h-[23.688rem] ">
            <h5 className="font-bold text-2xl mb-2">Add campaign link</h5>
            <div className="text-neutral-80 w-[24.188rem] text-sm mb-8">
              Choose which campaign/ad group you want new keywords to get added
              to
            </div>
            <ProfileSelection
              brand={brand}
              updateProfileInfo={updateCampaignLinkForm}
              campaignLink={campaignLink}
            />
            <CampaignDropdowns
              campaignLink={campaignLink}
              open={open}
              setCampaignLink={setCampaignLink}
            />
          </div>
        </div>
        <div className="pt-10 pb-[1.938rem] px-8">
          <div className="flex">
            <div className="basis-[13rem] flex-auto">
              <div className="text-neutral-80 mb-1 text-xs">Targeting type</div>
              <Dropdown
                label="Select one"
                ariaLabel="Select targeting type"
                options={TARGETING_TYPES}
                selectedValue={campaignLink.targetingType}
                selectValue={(value) =>
                  updateCampaignLinkForm(value, 'targetingType')
                }
              />
            </div>
            <div
              className={`w-[13rem] ml-4 ${campaignLink.targetingType === 'product' && 'hidden'}`}
            >
              <div className="text-neutral-80 mb-1 text-xs">Match type</div>
              <SimpleMultiDropdown
                label="Select"
                ariaLabel="Select match type"
                options={MATCH_TYPES}
                selectedValues={campaignLink.matchTypes || []}
                selectValue={(value) =>
                  updateCampaignLinkForm(value, 'matchType')
                }
              />
            </div>
          </div>
          <div className="mt-6">
            <KeywordNegation
              campaignLink={campaignLink}
              setCampaignLink={setCampaignLink}
            />
          </div>
          <div className="relative flex-1 pt-6">
            <Bid campaignLink={campaignLink} setLinkBid={addBid} />
          </div>
          {showDuplicateError && (
            <div className="mt-3">
              <ErrorMessage showBorder message={<>{IS_DUPLICATE_MESSAGE}</>} />
            </div>
          )}
          {showUpdateFieldsWarning && (
            <div className="mt-3">
              <Warning message={<>{DUPLICATE_LINKS_WARNING}</>} />
            </div>
          )}
          <div className="flex justify-end bg-gray-50 mt-7">
            <SecondaryButton onClick={() => setOpen(false)}>
              <>Cancel</>
            </SecondaryButton>
            <div className="ml-3">
              <PrimaryButton
                onClick={() => addNewCampaignLink()}
                disabled={disableAddSaveButton}
              >
                <span>
                  {submittingCampaignLink && (
                    <div className="w-[1.966rem] pl-2">
                      <Spinner colour={'stroke-white'} />
                    </div>
                  )}
                  {!submittingCampaignLink && <span>Save</span>}
                </span>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
