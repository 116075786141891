import PlatformRadio from './PlatformRadio';

interface props {
  selectPlatform: (checked: boolean, platform: string) => void;
  selectedPlatform: string;
}
export default function ChooseSellerVendor({
  selectPlatform,
  selectedPlatform,
}: props) {
  return (
    <>
      <div className="mx-auto text-center text-sm text-neutral-80">
        Now let's connect to Amazon Seller Central
      </div>
      <h3 className="mx-auto mb-8 mt-2 text-center font-medium">
        Choose a platform
      </h3>
      <PlatformRadio
        selectPlatform={selectPlatform}
        selectedPlatform={selectedPlatform}
        type="seller"
        description="You sell directly to Amazon's customers"
        name="Seller"
      />
      <div className="mt-3">
        <PlatformRadio
          selectPlatform={selectPlatform}
          selectedPlatform={selectedPlatform}
          type="vendor"
          description="You sell directly to Amazon"
          name="Vendor"
        />
      </div>
    </>
  );
}
