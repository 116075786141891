import { formatCurrency } from 'ui/helpers/currency/currency';

export default function CurrencyFormatting() {
  const us = formatCurrency(1000, 'USD', 0);
  const usDecimals = formatCurrency(1000, 'USD', 2);

  const uk = formatCurrency(1000, 'GBP', 0);
  const ukDecimals = formatCurrency(1000, 'GBP', 2);

  const europe = formatCurrency(1000, 'EUR', 0);
  const europeDecimals = formatCurrency(1000, 'EUR', 2);

  return (
    <section id="currency" className="p-10 border-neutral-40 border-t border-1">
      <h4 className="mb-2">US:</h4>
      {us} <div>{usDecimals}</div>
      <h4 className="mt-6 mb-2">UK:</h4>
      {uk}
      <div>{ukDecimals}</div>
      <h4 className="mt-6 mb-2">Europe:</h4>
      {europe}
      <div>{europeDecimals}</div>
    </section>
  );
}
