import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import useUserSelector from 'state/user/useUserSelector';
import useUserRequests from 'api/hooks/requests/useUserRequests';

export const BRANDS = 'brands';
export const INTEGRATIONS = 'integrations';
export const SELLING_PARTNER_ACCOUNTS = 'selling-partner-accounts';

export default function useSettings() {
  const [tabSelection, setTabSelection] = useState<string>(BRANDS);

  const { userStatus, isInternalUser } = useUserSelector();
  const { fetchUser } = useUserRequests();
  const isIntegrationPage = useMatch(`/settings/${INTEGRATIONS}`);
  const isSellingPartnerAccountsPage = useMatch(
    `/settings/${SELLING_PARTNER_ACCOUNTS}`,
  );
  const isBrandsPage = useMatch('/settings/*');
  const navigate = useNavigate();

  const tabs = isInternalUser
    ? [
        { name: BRANDS, label: 'Brands' },
        { name: INTEGRATIONS, label: 'Integrations' },
        { name: SELLING_PARTNER_ACCOUNTS, label: 'Selling Partner Accounts' },
      ]
    : [{ name: BRANDS, label: 'Brands' }];

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isIntegrationPage && isInternalUser) {
      setTabSelection(INTEGRATIONS);
    }
    if (isSellingPartnerAccountsPage && isInternalUser) {
      setTabSelection(SELLING_PARTNER_ACCOUNTS);
    }
    if (isBrandsPage) {
      setTabSelection(BRANDS);
    }

    // eslint-disable-next-line
  }, []);

  function updateTab(tab: string) {
    setTabSelection(tab);

    if (tab === BRANDS) {
      navigate('/settings');
      return;
    }
    navigate(`/settings/${tab}`);
  }

  return {
    userStatus,
    tabs,
    tabSelection,
    updateTab,
  };
}
