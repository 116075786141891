import useBannerSelector from 'state/banners/useBannerSelector';
import ReversedLogo from 'components/Images/ReversedLogo';
import { PaymentArrowIcon, LargeCirclesIcon } from 'components/Icons';
import PlanDetails from './PlanDetails';
import { Plan } from 'shared/legacy-types';

interface Props {
  trialEnded?: boolean;
  plan?: Plan;
}

export default function PlanSection({ plan, trialEnded }: Props) {
  const { banners } = useBannerSelector();
  const bannerOpen = banners.length > 0;

  return (
    <div className="text-white bg-neutral-100 w-1/2 h-full min-h-screen py-11 px-20 flex flex-col justify-center ">
      <div className="absolute top-0 right-1/2 z-20">
        <LargeCirclesIcon />
      </div>
      <div
        className={`absolute ${bannerOpen ? 'top-[4.5rem]' : 'top-10'} top-10`}
      >
        <ReversedLogo />
      </div>
      <div className="max-w-[31rem]">
        <PlanDetails trialEnded={trialEnded} plan={plan} />
      </div>

      <div className="absolute bottom-0 left-0 z-20">
        <PaymentArrowIcon />
      </div>
    </div>
  );
}
