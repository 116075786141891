import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CampaignMetrics, ChartData } from 'shared/legacy-types';
import useReportingRequests from 'api/hooks/requests/useReportingRequests';
import useUser from 'hooks/useUser';
import { getCurrencySymbol } from 'helpers/currency';
import { convertToChartFormat } from 'pages/Reporting/helpers';
import * as Sentry from '@sentry/react';

const defaultChartData = {
  date: '',
  data: [],
};

type UseCampaignDetailHook = {
  campaignSummary: CampaignMetrics | undefined;
  currencyCode: string | undefined;
  loadingSummary: boolean;
  chartData: ChartData[];
  campaignMetricsLoading: boolean;
  campaignName: string;
  countryCode: string | undefined;
};

export default function useCampaignDetail(
  startDate: string,
  endDate: string,
): UseCampaignDetailHook {
  const [campaignSummary, setCampaignSummary] = useState<CampaignMetrics>();
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ChartData[]>([defaultChartData]);
  const [campaignMetricsLoading, setCampaignMetricsLoading] =
    useState<boolean>(false);
  const [campaignName, setCampaignName] = useState<string>('');

  const { campaignId, profileId } = useParams();
  const { getCampaignReportingMetrics, getCampaignTimeSeriesMetrics } =
    useReportingRequests();

  const { getProfileData } = useUser();
  const profileData = getProfileData(parseInt(profileId ?? ''));
  const currencyCode = profileData?.currencyCode;
  const currencySymbol = getCurrencySymbol(currencyCode) ?? '';
  const countryCode = profileData?.countryCode;

  useEffect(() => {
    async function getCampaignMetrics(): Promise<void> {
      if (profileId != null && campaignId != null) {
        try {
          setCampaignMetricsLoading(true);
          const response = await getCampaignTimeSeriesMetrics(
            profileId,
            campaignId,
            startDate,
            endDate,
          );

          const chartRawData = response.data as CampaignMetrics[];
          const name = chartRawData[0]?.campaignName;
          setCampaignName(name ?? '');
          setCampaignMetricsLoading(false);
          const chartData = convertToChartFormat(
            chartRawData,
            currencySymbol,
          ) as ChartData[];
          setChartData(chartData);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
    startDate && endDate && campaignId != null && getCampaignMetrics();
    // eslint-disable-next-line
  }, [profileId, startDate, endDate]);

  useEffect(() => {
    async function fetchCampaignSummary(): Promise<void> {
      if (profileId != null && campaignId != null) {
        setLoadingSummary(true);
        const response = await getCampaignReportingMetrics(
          profileId,
          startDate,
          endDate,
          campaignId,
        );

        const campaignMetrics = response.data as CampaignMetrics[];
        const campaignSummary = campaignMetrics[0] as CampaignMetrics;
        setCampaignSummary(campaignSummary);
        setLoadingSummary(false);
      }
    }
    profileId != null && startDate && endDate && fetchCampaignSummary();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return {
    campaignSummary,
    currencyCode,
    loadingSummary,
    chartData,
    campaignMetricsLoading,
    campaignName,
    countryCode,
  };
}
