import LargeSpinner from 'ui/Spinner/LargeSpinner';
import useBrand from './useBrand';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Card from 'design-system/components/Card/Card';
import { getCountryName } from '../utils';
import MarketplaceStatus from './MarketplaceStatus';

type Props = {
  setOpenModal: (open: boolean) => void;
};

export default function BrandPage({ setOpenModal }: Props): JSX.Element | null {
  const {
    brand,
    connectMarketplace,
    isConnectingMarketplace,
    error,
    loading,
    isFirstRequestCompleted,
    brandId,
    sellingPartnerAuthRedirectRunning,
  } = useBrand();
  const navigate = useNavigate();

  if (loading || !isFirstRequestCompleted) {
    return (
      <div className="flex justify-center mt-20">
        <LargeSpinner />
      </div>
    );
  }

  if (error) {
    Sentry.captureMessage('Error fetching a brand');
    return (
      <div className="text-sm">
        Something went wrong, please try refreshing the page. If the error
        persists, please get in contact with support
      </div>
    );
  }

  if (!brand) {
    Sentry.captureMessage(`Brand not found: ${brandId}`);
    navigate('/error/404');
    return null;
  }

  const marketplaces = brand.marketplaces;
  const brandName = brand.name;
  const accountType = brand.accountType;

  function renderMarketplaces(): JSX.Element[] {
    return marketplaces.map((marketplace) => {
      const { countryCode } = marketplace;
      const countryName = getCountryName(countryCode);

      return (
        <Card
          title={countryName}
          child={
            <MarketplaceStatus
              marketplace={marketplace}
              connectMarketplace={connectMarketplace}
              isConnectingMarketplace={isConnectingMarketplace}
              brandAccountType={accountType}
              sellingPartnerAuthRedirectRunning={
                sellingPartnerAuthRedirectRunning
              }
            />
          }
        />
      );
    });
  }

  return (
    <>
      <Header setOpenModal={setOpenModal} brandName={brandName} />
      <div className="mt-10">
        {marketplaces.length > 0 && renderMarketplaces()}
      </div>
    </>
  );
}
