import { ReactElement } from 'react';
import { ExclamationCircle } from 'components/Icons';

interface Props {
  message: ReactElement;
  showBorder?: boolean;
  styles?: string;
}

export default function ErrorMessage({
  message,
  styles,
  showBorder = false,
}: Props) {
  return (
    <div
      className={`${styles} ${showBorder && 'border border-error'} relative bg-[#FFF2F2] rounded mx-auto flex-row grow`}
    >
      <div className="relative flex px-4 py-3 mx-auto">
        <span className="w-[1.563rem] my-auto">
          <ExclamationCircle />
        </span>
        <span className=" text-neutral-100 leading-[1.05rem] text-sm w-max text-left pt-[0.063rem] pl-2">
          {message}
        </span>
      </div>
    </div>
  );
}
