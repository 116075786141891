import { Result } from 'shared/legacy-types';
import { Column } from '../../../constants/runs';
import TableColumn from './TableColumn';
import { KeywordApproval } from 'shared/legacy-types';

interface Props {
  results: Result[];
  preset: Column[];
  isEmailResults: boolean;
  isKeywordHarvesting?: boolean;
  selectedResults?: Partial<KeywordApproval>[];
  keywordApprovals?: KeywordApproval[];
  enableKeywordApproval?: boolean;
  selectResult?: (keywordApproval: Omit<KeywordApproval, 'status'>) => void;
}

export default function TableRows({
  results,
  preset,
  selectedResults,
  keywordApprovals,
  isEmailResults,
  isKeywordHarvesting,
  enableKeywordApproval,
  selectResult,
}: Props) {
  const renderResults = () => {
    return results?.map((result: Result, index) => {
      const isFirstRow = index === 0;
      const isLastRow = index + 1 === results.length;
      const { currencyCode } = result;
      const currency = currencyCode || '';

      const columns = preset.map((column, columnIndex) => {
        return (
          <TableColumn
            column={column}
            columnIndex={columnIndex}
            isFirstRow={isFirstRow}
            isLastRow={isLastRow}
            currency={currency}
            result={result}
            selectedResults={selectedResults}
            keywordApprovals={keywordApprovals}
            isEmailResults={isEmailResults}
            isKeywordHarvesting={isKeywordHarvesting}
            enableKeywordApproval={enableKeywordApproval}
            preset={preset}
            selectResult={selectResult}
          />
        );
      });

      return (
        <div
          key={index}
          className="even:bg-secondary-20 table-row text-sm text-neutral-100"
        >
          {columns}
        </div>
      );
    });
  };

  return (
    <div className="mt-3 table-row-group rounded">
      <div className="h-3" />
      {renderResults()}
    </div>
  );
}
