import { useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import { RunStep } from 'shared/legacy-types';
import { getPreset } from '../../helpers/results/results';
import ResultsTable from './ResultsTable/ResultsTable';
import useAction from '../../hooks/useAction';
import ResultsToolbar from './ResultsToolbar';
import KeywordApprovalTable from './KeywordApprovalTable/KeywordApprovalTable';
import { HARVEST_KEYWORDS } from '../../constants/actions';

interface Props {
  step: RunStep;
  dataType: string;
  dataLoaded: boolean;
  loading: boolean;
  loadMoreResults?: (stepid: number | undefined) => void;
  loadingMoreResults?: boolean;
  enablePagination?: boolean;
  actionTimestamp?: string;
  isLive?: boolean;
  isPreview?: boolean;
}

export default function Results({
  step,
  dataType,
  loading,
  actionTimestamp,
  loadMoreResults,
  loadingMoreResults,
  enablePagination,
  isLive,
  isPreview,
}: Props) {
  const { actions, results, incompleteData, stepId } = step || {};
  const firstAction = actions && actions[0];
  if (!firstAction) {
    throw Error('Missing first action');
  }
  const [selectedAction, setSelectedAction] = useState<string>(
    firstAction.type,
  );
  const currentAction = actions.find(
    (action) => action.type === selectedAction,
  );
  const {
    isPauseOrEnable,
    isBidOrBudget,
    isKeywordHarvesting,
    isAddNegativeKeywords,
  } = useAction(currentAction, dataType);
  const noResults = results && results.length === 0;
  const [showExtended, setShowExtended] = useState<boolean>(false);

  const preset = getPreset(
    dataType,
    showExtended,
    isBidOrBudget,
    isPauseOrEnable,
    isKeywordHarvesting,
    isAddNegativeKeywords,
    currentAction!,
  );

  function loadMore() {
    loadMoreResults && loadMoreResults(stepId);
  }

  if (
    dataType === 'searchTerms' &&
    selectedAction === HARVEST_KEYWORDS &&
    !isPreview
  ) {
    return (
      <KeywordApprovalTable
        step={step}
        dataType={dataType}
        loading={loading}
        actionTimestamp={actionTimestamp}
        loadMoreResults={loadMoreResults}
        loadingMoreResults={loadingMoreResults}
        enablePagination={enablePagination}
        isLive={isLive}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        preset={preset}
        showExtended={showExtended}
        setShowExtended={setShowExtended}
      />
    );
  }

  return (
    <div className="border-neutral-40 border-t p-6 relative">
      <div className="mb-[0.625rem] flex">
        <h5 className="mb-2 font-bold">Results</h5>
      </div>
      <>
        {!loading && noResults && (
          <div className="bg-primary-20 text-neutral-80 rounded py-5 text-center text-sm">
            No results were found
          </div>
        )}
      </>
      {loading && <Spinner />}
      {results && results.length > 0 && !loading && (
        <>
          <ResultsToolbar
            actionTimestamp={actionTimestamp}
            isLive={isLive}
            currentAction={currentAction}
            incompleteData={incompleteData}
            noResultsFound={noResults}
            actions={actions}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            dataType={dataType}
            showExtended={showExtended}
            setShowExtended={setShowExtended}
          />
          {preset && (
            <ResultsTable
              preset={preset}
              results={results}
              extended={showExtended}
              loadMoreResults={loadMore}
              loadingMoreResults={loadingMoreResults}
              enablePagination={enablePagination}
              noResultsFound={noResults}
              selectedAction={selectedAction}
            />
          )}
        </>
      )}
    </div>
  );
}
