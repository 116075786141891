import { useState } from 'react';

import { SpendTarget } from 'shared/legacy-types';
import useSpendTargetRequests from 'api/hooks/requests/useSpendTargetRequests';
import useRequest from 'api/hooks/useRequest';
import useUserDispatch from 'state/user/useUserDispatch';
import * as Sentry from '@sentry/react';

const defaultSpendTarget: Omit<SpendTarget, 'profileId'> = {
  monthlySpendTargets: [],
};

export const addDecimalsToSpend = (spendTarget: Partial<SpendTarget>) => {
  const newMonthlySpendTargets = spendTarget.monthlySpendTargets?.map(
    (month) => {
      return {
        ...month,
        spend: parseFloat(month.spend as string).toFixed(2),
      };
    },
  );
  return { ...spendTarget, monthlySpendTargets: newMonthlySpendTargets };
};

export default function useCreateSpendTarget(
  spendTargetProfileIds?: string[],
  setSpendTargetProfileIds?: (profileids: string[]) => void,
) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newSpendTarget, setNewSpendTarget] =
    useState<Partial<SpendTarget>>(defaultSpendTarget);
  const [creatingSpendTarget, setCreatingSpendTarget] =
    useState<boolean>(false);

  const { createSpendTargetRequest } = useSpendTargetRequests();
  const { request } = useRequest();
  const { updateUser } = useUserDispatch();

  function updateCreateModalOpen(open: boolean) {
    setCreateModalOpen(open);
    setNewSpendTarget(defaultSpendTarget);
  }

  const fetchUser = async () => {
    const response = await request('get', '/v1/me');
    updateUser(response.data);
  };

  async function addSpendTarget(onSuccessActions?: () => void) {
    setCreatingSpendTarget(true);
    // format spend to 2 decimal places for system
    const updatedNewSpendTarget = addDecimalsToSpend(newSpendTarget);
    const spendTarget = updatedNewSpendTarget as SpendTarget;
    try {
      await createSpendTargetRequest(spendTarget);
      setCreatingSpendTarget(false);
      // add new SpendTarget to list of spend targets
      const id = newSpendTarget.profileId as string;
      // Only needed for spendTargets list page
      if (spendTargetProfileIds && setSpendTargetProfileIds) {
        setSpendTargetProfileIds([...spendTargetProfileIds, id]);
      }
      setCreateModalOpen(false);
      setNewSpendTarget(defaultSpendTarget);
      // perform any additional actions passed in & refetch user
      onSuccessActions && (await fetchUser());
      onSuccessActions && onSuccessActions();
    } catch (error) {
      Sentry.captureException(error);
      setCreatingSpendTarget(false);
      setCreateModalOpen(false);
      setNewSpendTarget(defaultSpendTarget);
    }
  }

  return {
    createModalOpen,
    updateCreateModalOpen,
    newSpendTarget,
    setNewSpendTarget,
    creatingSpendTarget,
    addSpendTarget,
  };
}
