import { ChevronDownIcon } from 'components/Icons';
import { ReactComponent as ChevronUpIcon } from 'icons/generic/chevron-up.svg';
import { FIELD_HEADERS, FIELD_HEADER_LABELS } from 'ui/constants/rules';
import { Option } from './useFieldDropdown';
import AddSpendTargetTooltip from './AddSpendTargetTooltip';
import FieldOption from './FieldOption';

interface Props {
  selectedValue: string | undefined;
  ruleHasSpendTargets: boolean;
  select: (value: string, prefix?: string) => void;
  options: { [key: string]: Option[] };
  openHeadings: string[];
  setOpenHeadings: (openHeadings: string[]) => void;
  disabledTooltipOpen: boolean;
  setDisabledTooltipOpen: (disabledTooltipOpen: boolean) => void;
  openCreateSpendTargetModal: (open: boolean) => void;
}

export default function DropdownOptions({
  selectedValue,
  ruleHasSpendTargets,
  select,
  options,
  openHeadings,
  setOpenHeadings,
  disabledTooltipOpen,
  setDisabledTooltipOpen,
  openCreateSpendTargetModal,
}: Props) {
  function renderOptions() {
    function getOptions(options: Option[], prefix?: string) {
      return options.map((option, index) => {
        return (
          <FieldOption
            option={option}
            prefix={prefix}
            selectedValue={selectedValue}
            ruleHasSpendTargets={ruleHasSpendTargets}
            select={select}
            index={index}
            optionsCount={options.length}
          />
        );
      });
    }

    // Subheadings in dropdown
    return Object.entries(options).map(([key, value], index) => {
      const isFirst = index === 0;
      const headingIsOpen = openHeadings.includes(key!);
      const headerLabel = FIELD_HEADER_LABELS.find(
        (label) => label.value === key,
      )?.label;

      const disableOption =
        key === FIELD_HEADERS.marketplace && !ruleHasSpendTargets;

      function manageHeading() {
        if (headingIsOpen) {
          const filtered = openHeadings.filter((head) => head !== key);
          setOpenHeadings(filtered);
          return;
        }

        setOpenHeadings([...openHeadings, key!]);
      }

      return (
        <div>
          <div
            className={`${!isFirst && 'mt-6'} flex hover:cursor-pointer`}
            onClick={() => manageHeading()}
          >
            <div className={`mb-2 text-sm font-bold`}>{headerLabel}</div>
            <div className="ml-1 mt-[0.0625rem]">
              {headingIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </div>
          <div className="border-neutral-40 mb-4 border-b" />
          {headingIsOpen && (
            <div
              className={`${disableOption && 'relative'}`}
              onMouseEnter={() => setDisabledTooltipOpen(true)}
              onMouseLeave={() => setDisabledTooltipOpen(false)}
            >
              {getOptions(value, key)}
              {disableOption && disabledTooltipOpen && (
                <AddSpendTargetTooltip
                  openCreateSpendTargetModal={openCreateSpendTargetModal}
                />
              )}
            </div>
          )}
        </div>
      );
    });
  }

  return <>{renderOptions()}</>;
}
