import { CONDITION_FIELDS } from 'ui/constants/conditionFields';
import { Field } from 'shared/legacy-types';

export const findConditionField = (selectedField: string) => {
  const currentField = Object.entries(CONDITION_FIELDS).find(([_, value]) => {
    if (value.value === selectedField) {
      return value;
    }
    return null;
  });

  const currentFieldInfo = currentField && (currentField[1] as Field);
  return currentFieldInfo;
};

export const displayChooseInputDropdown = (
  currentField: Field | undefined,
  fieldPrefix: string,
) => {
  if (!currentField) {
    return false;
  }

  // if no headers are set, there's no restriction
  // if headers are set, check if the current header matches the selected field prefix
  // ie targets and campaigns headers have 'spend' field, yet only campaigns shows the chooseInputDropdown
  const fieldHeadersMatch =
    !currentField.inputTypeHeaders ||
    (currentField.inputTypeHeaders &&
      currentField.inputTypeHeaders.includes(fieldPrefix));

  const showChooseInputDropdown =
    currentField &&
    currentField.inputType === 'valueOrDropdown' &&
    fieldHeadersMatch;

  return showChooseInputDropdown;
};
