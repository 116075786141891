import {
  ConditionGroupOperator,
  ConditionOperator,
  RuleStatus,
  DataType,
  HarvestKeywordsAction,
} from 'shared/legacy-types';
import { HARVEST_KEYWORDS } from 'ui/constants/actions';
import { LONG_PERIOD, SEARCH_TERMS } from 'ui/constants/rules';
import KeyImage from 'components/Images/Templates/KeyImage';

export const harvestKeywords = {
  id: 'harvest_keywords',
  description:
    'Add new converting customer search terms as keywords to your manual campaigns and target new converting ASINs. Use Campaign Mapping to specify Source and Destination Campaigns to harvest keywords and ASINs.',
  goal: 'Keyword Harvesting',
  category: 'Advertising',
  featureToggle: false,
  comingSoon: false,
  image: KeyImage,
  rule: {
    name: 'Harvest KWs & ASINs',
    adTypes: ['SP'],
    dataType: SEARCH_TERMS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'Converting Search Terms',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: HARVEST_KEYWORDS,
            properties: {
              sendEmail: false,
            },
          } as HarvestKeywordsAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${SEARCH_TERMS}:ad_orders`,
                operator: '>=' as ConditionOperator,
                value: '1',
              },
              {
                field: `${SEARCH_TERMS}:search_term`,
                operator: 'not_contains' as ConditionOperator,
                value: 'b0',
              },
            ],
          },
        ],
      },
      {
        name: 'Converting ASINs',
        operator: '&&' as ConditionGroupOperator,
        period: LONG_PERIOD,
        actions: [
          {
            type: HARVEST_KEYWORDS,
            properties: {
              sendEmail: false,
            },
          } as HarvestKeywordsAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${SEARCH_TERMS}:ad_orders`,
                operator: '>=' as ConditionOperator,
                value: '1',
              },
              {
                field: `${SEARCH_TERMS}:search_term`,
                operator: 'begins_with' as ConditionOperator,
                value: 'b0',
              },
            ],
          },
        ],
      },
    ],
  },
};
