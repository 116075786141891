import { Template, TemplateList } from 'shared/legacy-types';

import { optimiseTargetBids } from './optimiseTargetBids';
import { optimiseBidsForCampaign } from './optimiseBidsForCampaign';
import { optimiseCampaignBudgets } from './optimiseCampaignBudgets';
import { optimiseTowardsSpendTargets } from './optimiseTowardsSpendTargets';
import { harvestKeywords } from './harvestKeywords';
import { optimiseBidsForTacos } from './optimiseBidsForTacos';
import { pauseAdsForAsins } from './pauseAdsForAsins';
import { pauseAdsHighDeliveryWindow } from './pauseAdsHighDeliveryWindow';
import { harvestKeywordsForCampaign } from './harvestKeywordsForCampaign';
import { optimiseTargetBidsInStages } from './optimiseTargetBidsInStages';

export const TEMPLATES: { [key: string]: Template } = {
  optimise_target_bids: optimiseTargetBids,
  optimise_bids_for_campaign: optimiseBidsForCampaign,
  optimise_campaign_budgets: optimiseCampaignBudgets,
  optimise_towards_spend_targets: optimiseTowardsSpendTargets,
  harvest_keywords: harvestKeywords,
  harvest_keywords_for_campaign: harvestKeywordsForCampaign,
  optimise_bids_for_tacos: optimiseBidsForTacos,
  pause_ads_for_asins: pauseAdsForAsins,
  pause_ads_high_delivery_window: pauseAdsHighDeliveryWindow,
  optimise_target_bids_in_stages: optimiseTargetBidsInStages,
};

export const templatesList = [
  {
    name: 'bids',
    label: 'Bids',
    templates: [
      TEMPLATES['optimise_target_bids'],
      TEMPLATES['optimise_target_bids_in_stages'],
      TEMPLATES['optimise_bids_for_campaign'],
      TEMPLATES['optimise_bids_for_tacos'],
    ],
  } as TemplateList,
  {
    name: 'budgets',
    label: 'Budgets',
    templates: [
      TEMPLATES['optimise_campaign_budgets'],
      TEMPLATES['optimise_towards_spend_targets'],
    ],
  } as TemplateList,
  {
    name: 'keywords',
    label: 'Keyword harvesting',
    templates: [
      TEMPLATES['harvest_keywords'],
      TEMPLATES['harvest_keywords_for_campaign'],
    ],
  } as TemplateList,
  {
    name: 'products',
    label: 'Products',
    templates: [
      TEMPLATES['pause_ads_for_asins'],
      TEMPLATES['pause_ads_high_delivery_window'],
    ],
  } as TemplateList,
];
