import { ReactComponent as UkIcon } from 'icons/flags/uk.svg';
import { ReactComponent as GermanyIcon } from 'icons/flags/germany.svg';
import { ReactComponent as SpainIcon } from 'icons/flags/spain.svg';
import { ReactComponent as FranceIcon } from 'icons/flags/france.svg';
import { ReactComponent as ItalyIcon } from 'icons/flags/italy.svg';
import { ReactComponent as NetherlandsIcon } from 'icons/flags/netherlands.svg';
import { ReactComponent as PolandIcon } from 'icons/flags/poland.svg';
import { ReactComponent as SwedenIcon } from 'icons/flags/sweden.svg';
import { ReactComponent as UsIcon } from 'icons/flags/us.svg';
import { ReactComponent as CanadaIcon } from 'icons/flags/canada.svg';
import { ReactComponent as MexicoIcon } from 'icons/flags/mexico.svg';
import { ReactComponent as BrazilIcon } from 'icons/flags/brazil.svg';
import { ReactComponent as AustraliaIcon } from 'icons/flags/australia.svg';
import { ReactComponent as JapanIcon } from 'icons/flags/japan.svg';
import { ReactComponent as IndiaIcon } from 'icons/flags/india.svg';
import { ReactComponent as SingaporeIcon } from 'icons/flags/singapore.svg';

export const EUROPE = [
  { country: 'United Kingdom', icon: UkIcon },
  { country: 'Germany', icon: GermanyIcon },
  { country: 'Spain', icon: SpainIcon },
  { country: 'France', icon: FranceIcon },
  { country: 'Italy', icon: ItalyIcon },
  { country: 'Netherlands', icon: NetherlandsIcon },
  { country: 'Poland', icon: PolandIcon },
  { country: 'Sweden', icon: SwedenIcon },
];

export const NORTH_AMERICA = [
  { country: 'United States of America', icon: UsIcon },
  { country: 'Canada', icon: CanadaIcon },
  { country: 'Mexico', icon: MexicoIcon },
  { country: 'Brazil', icon: BrazilIcon },
];

export const ASIA_PACIFIC = [
  { country: 'Australia', icon: AustraliaIcon },
  { country: 'Japan', icon: JapanIcon },
  { country: 'India', icon: IndiaIcon },
  { country: 'Singapore', icon: SingaporeIcon },
];
