interface Props {
  selection: string;
  setSelection: (selection: string) => void;
  name: string;
  label: string;
}

export default function Tab({ selection, setSelection, name, label }: Props) {
  const selectedStyles = 'border-primary-100 border-b-2 text-neutral-100';

  return (
    <span
      className={`flex w-content py-4 px-1 cursor-pointer text-sm font-bold ${
        selection === name ? selectedStyles : 'text-neutral-80'
      }`}
      onClick={() => setSelection(name)}
    >
      {label}
    </span>
  );
}
