interface Props {
  colour?: string;
  stroke?: string;
}

export function HomeIcon({
  colour = 'fill-primary-80 stroke-primary-80',
  stroke = 'stroke-white',
}: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.66667L8 2L14 5.66667V13C14 13.3536 13.8595 13.6928 13.6095 13.9428C13.3594 14.1929 13.0203 14.3333 12.6667 14.3333H3.33333C2.97971 14.3333 2.64057 14.1929 2.39052 13.9428C2.14048 13.6928 2 13.3536 2 13V5.66667Z"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={colour}
      />
      <path
        d="M6 15.3327V10.666C6 9.56144 6.89543 8.66602 8 8.66602V8.66602C9.10457 8.66602 10 9.56145 10 10.666V15.3327"
        className={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
