export function DiamondIcon() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7753_83654)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33641 3.99976L3.99928 5.66263L5.66216 3.99976L3.99928 2.33689L2.33641 3.99976ZM1.04828 3.44941C0.744325 3.75336 0.744325 4.24616 1.04828 4.55011L3.44894 6.95076C3.75288 7.25471 4.24567 7.25471 4.54962 6.95076L6.95027 4.55011C7.25422 4.24616 7.25422 3.75336 6.95027 3.44941L4.54962 1.04876C4.24567 0.744813 3.75289 0.744813 3.44894 1.04876L1.04828 3.44941Z"
          fill="#6CE1B2"
        />
      </g>
      <defs>
        <clipPath id="clip0_7753_83654">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
