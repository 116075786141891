import { useState, useEffect } from 'react';

import { Profile } from 'shared/legacy-types';
import MultiDropdown from 'components/Dropdowns/MultiDropdown/MultiDropdown';
import { CircleMinusIcon, PlusIcon } from 'components/Icons';
import { Brand } from 'shared/legacy-types';

interface Props {
  selectBrand: (id: number, name: string) => void;
  brandProfiles: Profile[] | undefined;
  selectedBrands: Brand[];
  selectMarketplace: (
    event: React.ChangeEvent<HTMLInputElement>,
    brandName: string,
  ) => void;
  brandId: number;
  accountName: string;
  brandType: string;
  atLimit?: boolean;
}

export default function BrandCard({
  brandId,
  accountName,
  selectedBrands,
  brandProfiles,
  selectMarketplace,
  selectBrand,
  brandType,
  atLimit,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const selectedBrand = selectedBrands.find(
    (brand) => brand.name === accountName,
  );
  const isSelected = selectedBrand && selectedBrand.marketplaceIds.length > 0;

  const brandLabel = brandType.charAt(0).toUpperCase() + brandType.slice(1);

  useEffect(
    function initOpen() {
      if (selectedBrand) {
        setOpen(true);
      }
    },
    [selectedBrand],
  );

  function handleOpen() {
    setOpen(true);
    selectBrand(brandId, accountName);
  }

  const filteredBrands = brandProfiles?.filter((profile) => {
    if (selectedBrand && profile.accountName === accountName) {
      return profile;
    }
    return null;
  });

  const options = filteredBrands?.map((brand) => {
    return { value: brand.id, label: brand.country };
  });

  return (
    <div aria-label="Brand card">
      {open ? (
        <div
          className={`rounded-lg border border-neutral-40 w-[37rem] ml-5 m-auto py-3 px-[1.1875rem] text-left mb-2 hover:cursor-pointer ${
            isSelected &&
            'border-2 border-primary-100 px-[1.125rem] pb-[0.875rem] mt-[-0.0625rem]'
          } `}
          style={{
            boxShadow: '0rem 0.25rem 1rem 0.125rem rgba(243, 243, 243, 0.6)',
          }}
        >
          <div className="flex justify-between max-h-10">
            <div className="my-auto">
              <div className="font-bold text-neutral-100 leading-4 mb-1.5">
                {accountName}
              </div>
              <div className="text-sm text-neutral-60 leading-4">
                {brandLabel}
              </div>
            </div>
            <div className="my-auto">
              <div onClick={() => setOpen(false)} aria-label="Close brand">
                <CircleMinusIcon />
              </div>
            </div>
          </div>
          {open && (
            <div className="mt-2">
              <MultiDropdown
                label="Marketplaces"
                options={options!}
                defaultValue="Choose marketplaces"
                selectedValues={selectedBrand?.marketplaceIds}
                onChange={(event) => selectMarketplace(event, accountName)}
                width="13rem"
                disabled={atLimit && !isSelected}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => handleOpen()}
          className={`rounded-lg border border-neutral-40 w-[37rem] ml-5 py-[0.594rem] px-[1.1875rem] text-left mb-2 hover:cursor-pointer ${
            isSelected &&
            'border-2 border-primary-100 px-[1.125rem] pb-[0.875rem] mt-[-0.0625rem]'
          }`}
          style={{
            boxShadow: '0rem 0.25rem 1rem 0.125rem rgba(243, 243, 243, 0.6)',
          }}
        >
          <div className="flex justify-between max-h-14">
            <div className="my-auto">
              <div className="font-bold text-neutral-100 leading-4 mb-1">
                {accountName}
              </div>
              <div className="text-sm text-neutral-60 leading-4">
                {brandType}
              </div>
            </div>
            <div className="my-auto">
              <span className="w-[37rem] h-full">
                <PlusIcon />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
