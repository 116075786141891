interface Props {
  height?: string;
  rounded?: string;
}

export default function ArrowsDownImage({
  height = '148',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={`${rounded}`}
    >
      <g clipPath="url(#clip0_9121_78706)">
        <rect width="76" height="148" fill="#FFEAA7" />
        <circle
          cx="38.0032"
          cy="29.6985"
          r="42.1948"
          fill="#ED6C4D"
          stroke="#ED6C4D"
          strokeWidth="11.0073"
        />
        <g clipPath="url(#clip1_9121_78706)">
          <path
            d="M16.6016 48.8687L20.8822 44.5269M20.8822 44.5269L24.7333 48.4331M20.8822 44.5269L28.3733 36.9288M37.17 28.0064C38.2604 29.1273 39.5587 30.0184 40.99 30.6283C42.4214 31.2382 43.9575 31.555 45.51 31.5602C47.0626 31.5655 48.6008 31.2592 50.0361 30.6591C51.4715 30.0589 52.7756 29.1767 53.8734 28.0632C54.9712 26.9497 55.841 25.627 56.4327 24.1711C57.0244 22.7153 57.3264 21.1551 57.3212 19.5804C57.316 18.0057 57.0037 16.4476 56.4024 14.9958C55.8011 13.544 54.9225 12.2272 53.8174 11.1212C51.5972 8.94626 48.6237 7.74278 45.5371 7.76998C42.4506 7.79719 39.4981 9.0529 37.3156 11.2667C35.133 13.4804 33.895 16.4751 33.8681 19.6057C33.8413 22.7363 35.0278 25.7524 37.1722 28.0043L37.17 28.0064ZM37.17 28.0064L28.3733 36.9288M28.3733 36.9288L33.8681 42.5021"
            stroke="#FFEAA7"
            strokeWidth="7.33823"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M-210 -194H282V-196H-210V-194ZM283 -193V299H285V-193H283ZM282 300H-210V302H282V300ZM-211 299V-193H-213V299H-211ZM-210 300C-210.552 300 -211 299.552 -211 299H-213C-213 300.657 -211.657 302 -210 302V300ZM283 299C283 299.552 282.552 300 282 300V302C283.657 302 285 300.657 285 299H283ZM282 -194C282.552 -194 283 -193.552 283 -193H285C285 -194.657 283.657 -196 282 -196V-194ZM-210 -196C-211.657 -196 -213 -194.657 -213 -193H-211C-211 -193.552 -210.552 -194 -210 -194V-196Z"
          fill="black"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9417 115.647C28.9375 131.876 15.3514 145.211 -1.06966 153.906L76.9453 153.906C60.5273 145.21 46.9439 131.875 37.9417 115.647Z"
          fill="#182841"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9541 77.3936C28.9499 93.6188 15.3656 106.951 -1.05272 115.645L76.9688 115.645C60.5475 106.952 46.9604 93.6198 37.9541 77.3936Z"
          fill="#ED6C4D"
        />
      </g>
      <defs>
        <clipPath id="clip0_9121_78706">
          <rect width="76" height="148" fill="white" />
        </clipPath>
        <clipPath id="clip1_9121_78706">
          <rect
            width="52.1015"
            height="51.3676"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 12.3203 3.2793)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
