interface Props {
  height?: string;
  rounded?: string;
}

export default function KeyArrowDownImage({
  height = '148',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
    >
      <g clipPath="url(#clip0_10914_996)">
        <rect width="76" height={height} fill="#FFEAA7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9407 35.2628C28.9365 19.0322 15.3496 5.69552 -1.0727 -3.00001L76.9463 -3C60.527 5.69671 46.9429 19.0333 37.9407 35.2628Z"
          fill="#662466"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.9541 73.5163C28.9498 57.2895 15.3647 43.9558 -1.05479 35.2617L76.9707 35.2617C60.5482 43.9546 46.9603 57.2885 37.9541 73.5163Z"
          fill="#ED6C4D"
        />
        <circle
          cx="38.0032"
          cy="119.699"
          r="42.1948"
          fill="#662466"
          stroke="#662466"
          strokeWidth="11.0073"
        />
        <g clipPath="url(#clip1_10914_996)">
          <path
            d="M16.6016 131.869L20.8822 127.527M20.8822 127.527L24.7333 131.433M20.8822 127.527L28.3733 119.929M37.17 111.006C38.2604 112.127 39.5587 113.018 40.99 113.628C42.4214 114.238 43.9575 114.555 45.51 114.56C47.0626 114.566 48.6008 114.259 50.0361 113.659C51.4715 113.059 52.7756 112.177 53.8734 111.063C54.9712 109.95 55.841 108.627 56.4327 107.171C57.0244 105.715 57.3264 104.155 57.3212 102.58C57.316 101.006 57.0037 99.4476 56.4024 97.9958C55.8011 96.544 54.9225 95.2272 53.8174 94.1212C51.5972 91.9463 48.6237 90.7428 45.5371 90.77C42.4506 90.7972 39.4981 92.0529 37.3156 94.2667C35.133 96.4804 33.895 99.4751 33.8681 102.606C33.8413 105.736 35.0278 108.752 37.1722 111.004L37.17 111.006ZM37.17 111.006L28.3733 119.929M28.3733 119.929L33.8681 125.502"
            stroke="#ED6C4D"
            strokeWidth="7.33823"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M-210 -104H282V-106H-210V-104ZM283 -103V389H285V-103H283ZM282 390H-210V392H282V390ZM-211 389V-103H-213V389H-211ZM-210 390C-210.552 390 -211 389.552 -211 389H-213C-213 390.657 -211.657 392 -210 392V390ZM283 389C283 389.552 282.552 390 282 390V392C283.657 392 285 390.657 285 389H283ZM282 -104C282.552 -104 283 -103.552 283 -103H285C285 -104.657 283.657 -106 282 -106V-104ZM-210 -106C-211.657 -106 -213 -104.657 -213 -103H-211C-211 -103.552 -210.552 -104 -210 -104V-106Z"
          fill="black"
          fillOpacity="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_10914_996">
          <rect width="76" height={height} fill="white" />
        </clipPath>
        <clipPath id="clip1_10914_996">
          <rect
            width="52.1015"
            height="51.3676"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 12.3203 86.2793)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
