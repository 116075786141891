import Subscribe from './Subscribe';
import useUserSelector from 'state/user/useUserSelector';
import { useState } from 'react';
import useSubscriptionRequests from 'api/hooks/requests/useSubscriptionRequests';

export default function PlanPayment() {
  const [loading, setLoading] = useState(false);

  const { userPlan } = useUserSelector();
  const { createStripePortalSession } = useSubscriptionRequests();

  async function subscribe() {
    setLoading(true);
    const response = await createStripePortalSession('payment_method_update');
    const url = response.headers['location'];
    window.location.replace(url);
    setLoading(false);
  }

  const buttonText = 'Add payment method';

  return (
    <Subscribe
      plan={userPlan}
      trialEnded={true}
      subscribe={subscribe}
      buttonText={buttonText}
      loading={loading}
    />
  );
}
