import { useState, useEffect } from 'react';
import useUser from 'hooks/useUser';
import { useParams, useNavigate } from 'react-router-dom';
import {
  brandOptions as getBrandOptions,
  getMarketplaces,
} from 'helpers/user/userProfiles';

export default function useFilters() {
  const [brandFilter, setBrandFilter] = useState<string>('');
  const [marketplaceFilter, setMarketplaceFilter] = useState<string>('');
  const [marketplaceOptions, setMarketplaceOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [brandOptions, setBrandOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const { profileId } = useParams<{ profileId: string }>();
  const navigate = useNavigate();

  const { getProfileData, userProfiles } = useUser();

  const storedProfileId = sessionStorage.getItem('reportingProfileId');

  // Set brand and marketplace defaults on initial load
  // Or use stored profile id from session or params
  // In one hook to avoid re-rendering when brand changes
  useEffect(() => {
    const storedId = () => {
      if (storedProfileId) {
        // need to check that the stored profile id is in their list of profiles
        const profile = getProfileData(parseInt(storedProfileId));
        if (profile) {
          return storedProfileId;
        }
      }
      sessionStorage.removeItem('reportingProfileId');
      return '';
    };
    // set brand
    const id = profileId ? profileId : storedId();

    const brands = getBrandOptions(userProfiles);
    let brand = '';
    if (brands.length > 0) {
      setBrandOptions([...brands]);

      // Profile id in params takes priority over stored profile id
      if (id) {
        const profile = getProfileData(parseInt(id));
        brand = profile?.accountName || '';
      } else {
        const firstBrand = brands[0];
        if (!firstBrand) {
          throw Error('Missing first brand');
        }
        brand = firstBrand.value;
      }
    }

    setBrandFilter(brand);

    // Set marketplace
    if (id) {
      setMarketplaceFilter(id);
      navigate(`/reporting/profile/${id}`);
    }

    const marketplaces = getMarketplaces(userProfiles, brand);
    const marketplacesWithoutDuplicates = marketplaces.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value),
    );

    if (marketplacesWithoutDuplicates.length > 0) {
      setMarketplaceOptions([...marketplacesWithoutDuplicates]);
    }

    // If no stored profile id and no profile id in params set to first profile
    // for the marketplace filter and url
    const firstMarketplaceWithoutDuplicates = marketplacesWithoutDuplicates[0];
    if (!id && firstMarketplaceWithoutDuplicates) {
      setMarketplaceFilter(firstMarketplaceWithoutDuplicates.value);
      navigate(`/reporting/profile/${firstMarketplaceWithoutDuplicates.value}`);
    }

    // eslint-disable-next-line
  }, []);

  function updateBrandFilter(value: string) {
    setBrandFilter(value);
    const marketplaces = getMarketplaces(userProfiles, value);
    const marketplacesWithoutDuplicates = marketplaces.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value),
    );

    const firstMarketplaceWithoutDuplicates = marketplacesWithoutDuplicates[0];
    if (firstMarketplaceWithoutDuplicates) {
      setMarketplaceOptions([...marketplacesWithoutDuplicates]);
      setMarketplaceFilter(firstMarketplaceWithoutDuplicates.value);
      navigate(`/reporting/profile/${firstMarketplaceWithoutDuplicates.value}`);
      sessionStorage.setItem(
        'reportingProfileId',
        firstMarketplaceWithoutDuplicates.value,
      );
    }
  }

  return {
    updateBrandFilter,
    marketplaceFilter,
    setMarketplaceFilter,
    brandFilter,
    marketplaceOptions,
    brandOptions,
  };
}
