interface Props {
  iconColour?: string;
  background?: string;
  size?: string;
  ariaLabel?: string;
}

export function TickCircle({
  iconColour = 'stroke-primary-80',
  background = 'fill-primary-40',
  size = '16',
  ariaLabel,
}: Props) {
  return (
    <div aria-label={ariaLabel}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" className={background} />
        <path
          d="M4.5 7.8946L7.16976 10.5653L11.5987 5.39946"
          className={iconColour}
          strokeWidth="1.22"
        />
      </svg>
    </div>
  );
}
