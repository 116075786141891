interface Props {
  height?: string;
  rounded?: string;
}

export default function TriangleSquareImage({
  height = '152',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_10718_1668)">
        <rect
          width="76"
          height="152"
          transform="matrix(1 0 0 -1 0 152)"
          fill="#662466"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 76H76V152H0V76ZM19.0228 94.5432H57.4644V133.457H19.0228V94.5432Z"
          fill="#2ABBDD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9902 101.982H50.0245V126.017H25.9902V101.982Z"
          fill="#F7C7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 21L38 55L55 21H21Z"
          fill="#F7C7FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_10718_1668">
          <rect
            width="76"
            height="152"
            fill="white"
            transform="matrix(1 0 0 -1 0 152)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
