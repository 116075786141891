export function CircleMinusIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#E6E6E9" />
      <rect
        x="7"
        y="12.5"
        width="1.16667"
        height="9.33333"
        rx="0.291667"
        transform="rotate(-90 7 12.5)"
        fill="#5C677D"
      />
    </svg>
  );
}
