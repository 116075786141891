import { getField, getPrefixLabel } from 'ui/helpers/rules/fields';
import { FIELD_HEADERS } from 'ui/constants/rules';

interface Props {
  label?: string;
  selectedValue: string | undefined;
  ruleHasSpendTargets: boolean;
}

export default function Label({
  label,
  selectedValue,
  ruleHasSpendTargets,
}: Props) {
  if (selectedValue) {
    // remove prefix and get label
    if (getField(selectedValue)?.label) {
      const prefixLabel = getPrefixLabel(selectedValue);
      const fieldValue = getField(selectedValue)?.label;

      const disable =
        !ruleHasSpendTargets &&
        selectedValue.includes(FIELD_HEADERS.marketplace);

      return (
        <>
          <span
            className={`${disable ? 'text-neutral-60' : 'text-neutral-80'}`}
          >
            {prefixLabel}
          </span>
          <span className="text-neutral-60 mx-1">•</span>
          <span className={`${disable && 'text-neutral-60'}`}>
            {fieldValue}
          </span>{' '}
        </>
      );
    }
  }

  return <div className="text-neutral-80 truncate">{label}</div>;
}
