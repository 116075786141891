import { useState, useEffect } from 'react';

import Modal from 'components/Modals/Modal';
import ReviewSpendTarget from 'components/SpendTargets/ReviewSpendTarget';
import { SpendTarget, MonthlySpendTarget } from 'shared/legacy-types';
import useSpendTargetRequests from 'api/hooks/requests/useSpendTargetRequests';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateSpendTarget: () => void;
  updatingSpendTarget: boolean;
  spendTarget: Partial<SpendTarget>;
  setSpendTarget: (spendTarget: Partial<SpendTarget>) => void;
  spendTargetToEdit: string;
}

export default function EditSpendTargetModal({
  open,
  setOpen,
  spendTarget,
  setSpendTarget,
  updateSpendTarget,
  updatingSpendTarget,
  spendTargetToEdit,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { getSpendTargetRequest } = useSpendTargetRequests();

  useEffect(() => {
    async function fetchSpendTarget() {
      setLoading(true);
      const response = await getSpendTargetRequest(spendTargetToEdit);
      setLoading(false);
      // backend always uses decimals in monetary values, while the interface was designed for full unit only
      const monthlySpendTargets = (response.data.monthlySpendTargets =
        response.data.monthlySpendTargets?.map(
          (spendTargetMonth: MonthlySpendTarget) => {
            return {
              ...spendTargetMonth,
              spend: spendTargetMonth.spend?.split('.')[0],
            };
          },
        ));

      setSpendTarget({ ...response.data, monthlySpendTargets });
    }
    spendTargetToEdit && fetchSpendTarget();
    // eslint-disable-next-line
  }, []);

  function updateAmount(value: string, month: string) {
    const monthlySpendTargets =
      spendTarget.monthlySpendTargets &&
      spendTarget.monthlySpendTargets.map((spendTargetMonth) => {
        if (spendTargetMonth.month === month)
          return { ...spendTargetMonth, spend: value };
        return spendTargetMonth;
      });
    setSpendTarget({ ...spendTarget, monthlySpendTargets });
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <ReviewSpendTarget
        newSpendTarget={spendTarget}
        saveSpendTarget={() => updateSpendTarget()}
        savingSpendTarget={updatingSpendTarget}
        updateAmount={updateAmount}
        buttonText="Save"
        spendTargetProfileId={spendTargetToEdit}
        loading={loading}
        title="Edit spend target"
      />
    </Modal>
  );
}
