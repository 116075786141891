import useRequest from 'api/hooks/useRequest';
import { SpendTarget } from 'shared/legacy-types';

export default function useSpendTargetRequests() {
  const { request } = useRequest();

  const createSpendTargetRequest = async (spendTarget: SpendTarget) => {
    return await request('post', `/v1/spend-targets`, spendTarget);
  };

  const getSpendTargetRequest = async (profileId: string) => {
    return await request('get', `/v1/spend-targets/${profileId}`, {});
  };

  const updateSpendTargetRequest = async (spendTarget: SpendTarget) => {
    return await request(
      'put',
      `/v1/spend-targets/${spendTarget.profileId}`,
      spendTarget,
    );
  };

  const deleteSpendTargetRequest = async (profileId: string) => {
    return await request('delete', `/v1/spend-targets/${profileId}`, {});
  };

  return {
    createSpendTargetRequest,
    getSpendTargetRequest,
    updateSpendTargetRequest,
    deleteSpendTargetRequest,
  };
}
