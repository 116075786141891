import { useState, useEffect } from 'react';
import { CampaignLink } from 'shared/legacy-types';
import { brandOptions, getMarketplaces } from 'helpers/user/userProfiles';
import useUser from 'hooks/useUser';
import useCampaignLinksRequests from 'api/hooks/requests/useCampaignLinksRequests';
import { DEFAULT_CAMPAIGN_LINK } from 'ui/constants/rules';
import * as Sentry from '@sentry/react';
import { sortCampaignLinksDescending } from '../helpers/campaignLinks';

export default function useCampaignLinks() {
  const { getMarketOptions, getProfileData, userProfiles } = useUser();
  const { getCampaignLinksRequest, deleteCampaignLinkRequest } =
    useCampaignLinksRequests();

  const [campaignLinks, setCampaignLinks] = useState<CampaignLink[]>([]);
  const [brandFilter, setBrandFilter] = useState<string>('');
  const [marketplaceFilter, setMarketplaceFilter] = useState<string>('');
  const [filterBrandOptions, setFilterBrandOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [filterMarketplaceOptions, setFilterMarketplaceOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [campaignLinkToDelete, setCampaignToDelete] = useState<CampaignLink>(
    DEFAULT_CAMPAIGN_LINK,
  );
  const [deletingLink, setDeletingLink] = useState<boolean>(false);
  const [loadingCampaignLinks, setLoadingCampaignLinks] =
    useState<boolean>(false);
  const [campaignLinksLoaded, setCampaignLinksLoaded] =
    useState<boolean>(false);

  const [pageNumber, setPageNumber] = useState<number>(0);

  useEffect(() => {
    async function getCampaignLinks() {
      setLoadingCampaignLinks(true);
      try {
        const response = await getCampaignLinksRequest();
        const sortedLinks = sortCampaignLinksDescending(response.data);
        setCampaignLinks(sortedLinks);
        setLoadingCampaignLinks(false);
        setCampaignLinksLoaded(true);
      } catch (error) {
        Sentry.captureException(error);
        setLoadingCampaignLinks(false);
      }
    }
    getCampaignLinks();
    // eslint-disable-next-line
  }, [campaignLinks.length]);

  useEffect(() => {
    // update filter when campaigns change
    const fullCampaigns = campaignLinks.map((campaign) => {
      return userProfiles.find(
        (profile) => profile.id === parseInt(campaign.profileId),
      )!;
    });
    const filteredProfiles =
      fullCampaigns && fullCampaigns.filter((profile) => profile !== undefined);
    const brands = brandOptions(filteredProfiles);
    setFilterBrandOptions([{ label: 'All', value: 'all' }, ...brands]);
    // eslint-disable-next-line
  }, [brandFilter, userProfiles, campaignLinks]);

  useEffect(() => {
    // remove duplicates from marketplace options
    const marketplaces = getMarketplaces(userProfiles, brandFilter);
    const marketplacesWithoutDuplicates = marketplaces.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value),
    );
    setFilterMarketplaceOptions([
      { label: 'All', value: 'all' },
      ...marketplacesWithoutDuplicates,
    ]);
    // eslint-disable-next-line
  }, [brandFilter, campaignLinks]);

  function openDeleteModal(campaign: CampaignLink) {
    setDeleteModalOpen(true);
    setCampaignToDelete(campaign);
  }

  async function deleteCampaignLink() {
    setDeletingLink(true);
    const updatedCampaignLinks =
      campaignLinkToDelete &&
      campaignLinks.filter(
        (campaignLink) => campaignLink.id !== campaignLinkToDelete.id,
      );
    try {
      await deleteCampaignLinkRequest(campaignLinkToDelete);
      setDeleteModalOpen(false);
      setCampaignLinks(updatedCampaignLinks);
      setDeletingLink(false);
    } catch (error) {
      Sentry.captureException(error);
      setDeleteModalOpen(false);
      setDeletingLink(false);
    }
  }

  function filterCampaigns() {
    // Filter campaign links by brand and marketplace
    return (
      campaignLinks &&
      campaignLinks.filter((campaign) => {
        const profileData = getProfileData(+campaign.profileId);

        const brandMatch =
          brandFilter === 'all' ||
          brandFilter === '' ||
          profileData?.accountName === brandFilter;

        const marketplaceMatch =
          marketplaceFilter === 'all' ||
          marketplaceFilter === '' ||
          profileData?.id.toString() === marketplaceFilter;

        return brandMatch && marketplaceMatch;
      })
    );
  }

  return {
    getMarketOptions,
    campaignLinks,
    setCampaignLinks,
    pageNumber,
    setPageNumber,
    deleteModalOpen,
    setDeleteModalOpen,
    openDeleteModal,
    deleteCampaignLink,
    deletingLink,
    campaignLinkToDelete,
    filterCampaigns,
    filterBrandOptions,
    filterMarketplaceOptions,
    brandFilter,
    setBrandFilter,
    marketplaceFilter,
    setMarketplaceFilter,
    loadingCampaignLinks,
    campaignLinksLoaded,
  };
}
