import useUserDispatch from 'state/user/useUserDispatch';
import useRequest from 'api/hooks/useRequest';
import { useEffect, useState } from 'react';

// get user so that we have up to date spendTarget profile ids
export default function useFetchUser(spendTargetProfileIds: string[]) {
  const [userLoaded, setUserLoaded] = useState(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);

  const { updateUser } = useUserDispatch();
  const { request } = useRequest();

  useEffect(() => {
    async function fetchUser() {
      setLoadingUser(true);
      const response = await request('get', '/v1/me');
      setLoadingUser(false);
      updateUser(response.data);
      setUserLoaded(true);
    }
    fetchUser();
    // eslint-disable-next-line
  }, [spendTargetProfileIds]);

  return { userLoaded, loadingUser };
}
