import './checkbox.css';

interface Props {
  error?: boolean;
  id?: string;
  select: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean | undefined;
  label?: string;
  size?: string;
  labelStyles?: string;
  disabled?: boolean;
}

export default function Checkbox(props: Props) {
  const {
    error,
    id,
    select,
    checked,
    label,
    size = '6',
    labelStyles,
    disabled,
  } = props;
  const smallCheckbox = size === '5';

  return (
    <div id={id} className={`flex h-${size} w-full`}>
      <label className="flex">
        <input
          id="check"
          type="checkbox"
          name="checkbox"
          className={`w-${size} h-${size} rounded
          ${error ? 'border-error' : ''} my-0 accent-primary-100 checked:before:bg-primary-100 
          ${
            smallCheckbox
              ? 'checked:before:pl-[0.085rem] checked:before:pt-[0.05rem]'
              : 'checked:before:pl-[0.1875rem] checked:before:pt-[0.1875rem]'
          }
          checked:before:rounded checked:border-primary-100 before:bg-neutral-40 before:rounded before:border-neutral-40 border-0 hover:cursor-pointer peer`}
          value={id}
          checked={checked}
          onChange={select}
          disabled={disabled}
        />
        <svg
          className={`invisible peer-checked:visible hover:cursor-pointer 
          ${smallCheckbox ? 'mt-[1px] ml-[-19px]' : 'mt-[3px] ml-[-21px]'} `}
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.91967 11.6403L10.1697 17.7653L18.15 6.675"
            stroke="#ffffff"
            strokeWidth="3"
          />
        </svg>
        <span
          className={`ml-8 items-center absolute text-sm !leading-6 text-neutral-100 hover:cursor-pointer w-auto inline ${
            checked && 'font-bold leading'
          } ${labelStyles}`}
        >
          {label}
        </span>
      </label>
    </div>
  );
}
