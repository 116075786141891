import RegionCard from 'pages/Onboarding/Region/RegionCard';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import Modal from 'components/Modals/Modal';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectRegion: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRegion?: string | undefined;
}

export default function AddRegionModal({
  open,
  setOpen,
  selectedRegion,
  selectRegion,
}: Props) {
  const disableSubmit = !selectedRegion;

  function continueToAmazon() {
    window.location.replace(
      `/v1/oauth/amazon-redirect?region=${selectedRegion}&token=${localStorage.getItem('token')}`,
    );
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex h-[43rem] w-[42rem] bg-white px-[2.5rem] ">
        <span className="absolute right-[2rem] top-[2rem] hover:cursor-pointer">
          <CrossIcon
            onClick={() => setOpen(false)}
            aria-label="back to settings"
          />
        </span>
        <div className="">
          <h2
            data-testid="region-title"
            className="mx-auto mb-3 mt-[3rem] w-[28rem] text-center text-[32px] font-bold"
          >
            Choose another region in which you are selling
          </h2>
          <div className="mx-auto mb-8 w-[28rem] text-center text-sm">
            We'll redirect you to sign into your Amazon ads account
          </div>
          <RegionCard
            region="Europe"
            id="EU"
            selectRegion={selectRegion}
            selected={selectedRegion === 'EU'}
          />
          <RegionCard
            region="North America"
            id="NA"
            selectRegion={selectRegion}
            selected={selectedRegion === 'NA'}
          />
          <RegionCard
            region="Asia Pacific"
            id="FE"
            selectRegion={selectRegion}
            selected={selectedRegion === 'FE'}
          />
          <div className="pt-8"></div>
          <div className="mx-auto w-[14rem] relative group">
            <PrimaryButton
              size="lg"
              disabled={disableSubmit}
              onClick={continueToAmazon}
            >
              <div>Continue to Amazon</div>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
