import { ValueGetterParams } from 'ag-grid-enterprise';
import { CampaignTableHeader } from '../tableHeaders';
import { getValueAsNumber } from '../utils';

export type RatioValue = {
  antecedent: number;
  consequent: number;
  toString: () => string;
};

export function ratioCalculation(
  antecedent: number,
  consequent: number,
): number {
  if (consequent === 0) return 0;

  return antecedent / consequent;
}

export function ratioValueGetter(
  params: ValueGetterParams,
  antecedentField: CampaignTableHeader,
  consequentField: CampaignTableHeader,
): RatioValue {
  const antecedent = getValueAsNumber(
    params.getValue(antecedentField),
    antecedentField,
    params.node?.group ?? false,
  );

  const consequent = getValueAsNumber(
    params.getValue(consequentField),
    consequentField,
    params.node?.group ?? false,
  );

  let ratioPercentageValue = 0;
  if (params.node && !params.node.group) {
    ratioPercentageValue = ratioCalculation(antecedent, consequent);
  }

  return {
    antecedent,
    consequent,
    toString: () => `${ratioPercentageValue}`,
  };
}

export function ratioAggregationFunction(
  columnValues: RatioValue[],
): RatioValue {
  const antecedentSum = columnValues.reduce((sum, value) => {
    return sum + value.antecedent;
  }, 0);

  const consequentSum = columnValues.reduce((sum, value) => {
    return sum + value.consequent;
  }, 0);

  return {
    antecedent: antecedentSum,
    consequent: consequentSum,
    toString: () => `${ratioCalculation(antecedentSum, consequentSum)}`,
  };
}
