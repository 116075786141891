import { useState, useContext } from 'react';

import RuleAction from 'pages/Rule/Steps/Action/RuleAction/RuleAction';
import AddActionCard from 'pages/Rule/Steps/Action/AddActionCard';
import AddActionModal from 'pages/Rule/Steps/Action/ActionModals/AddModal/AddActionModal';
import { ActionContext } from 'pages/Rule/Steps/Action/ActionContext';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import { PlusIcon } from 'components/Icons';
import { getActionsUsed } from 'ui/helpers/actions';
import useUserSelector from 'state/user/useUserSelector';

export default function ActionContainer() {
  const [open, setOpen] = useState(false);
  const { onChange, rule, stepIndex, step } = useContext(StepContext);
  const { actions } = step;
  const { isInternalUser } = useUserSelector();

  const noActions = actions.length === 0;
  const disableAddAction = getActionsUsed(
    actions,
    isInternalUser,
    rule.dataType,
  );

  const currentStep = rule.steps[stepIndex];

  function openModal() {
    setOpen(true);
  }

  function renderActions() {
    const stepActions = currentStep ? currentStep.actions : [];

    return stepActions.map((action, actionIndex) => {
      return (
        <ActionContext.Provider
          value={{ action, rule, onChange, step, stepIndex, actionIndex }}
          key={actionIndex}
        >
          <RuleAction key={actionIndex} />
        </ActionContext.Provider>
      );
    });
  }

  return (
    <>
      {renderActions()}
      {currentStep && currentStep.actions && open && (
        <AddActionModal open={open} setOpen={setOpen} />
      )}
      {noActions && <AddActionCard setOpen={setOpen} />}
      <div
        className={`mt-2 text-sm font-bold flex hover:cursor-pointer p-1 ${noActions && 'hidden'}`}
        onClick={disableAddAction ? () => setOpen(false) : () => openModal()}
      >
        <div className="group/item">
          {disableAddAction && (
            <span
              className={`absolute h-[1.875rem] w-content font-normal p-2 group-hover/item:translate-y-[-2rem] rounded bg-neutral-100 text-white text-xs group/edit group-hover/item:block hidden`}
              aria-label="Action tooltip"
            >
              You've added all available actions for this data type
            </span>
          )}
          <span className="flex">
            <PlusIcon disabled={disableAddAction} />
            <span
              className={`pl-1 ${disableAddAction && 'text-neutral-60 hover:cursor-not-allowed'}`}
            >
              Action
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
