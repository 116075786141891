import { useRef } from 'react';
import { MeatballIcon } from 'components/Icons';
import { UserInvite } from 'shared/legacy-types';
import Spinner from 'ui/Spinner/Spinner';

interface Props {
  invite: Partial<UserInvite>;
  loading?: boolean;
  revokeInvite?: (inviteEmail: string) => void;
  inviteToRevoke?: String;
  index: number;
  navOpen: number | null;
  setNavOpen: (index: number | null) => void;
}

export default function UserInviteCard({
  invite,
  index,
  loading,
  revokeInvite,
  inviteToRevoke,
  navOpen,
  setNavOpen,
}: Props) {
  const isInviteToRevoke = inviteToRevoke === invite.email;
  const navRef = useRef(null);
  const meatballRef = useRef(null);
  const coloursList = {
    'bg-[#B0AEFF]': 1,
    'bg-[#FF9D66]': 2,
    'bg-tertiary-80': 3,
    'bg-[#FFC700]': 4,
    'bg-[#FF5C5D]': 5,
  };
  const coloursArray = Object.keys(coloursList);
  const coloursIndex = index! % coloursArray.length;
  const colours = coloursArray[coloursIndex];

  const { id, status, email } = invite;

  const emailFirstLetter = email && email[0] ? email[0] : '•';

  function revokePendingInvite(inviteEmail: string) {
    setNavOpen(null);
    revokeInvite && revokeInvite(inviteEmail);
  }

  function handleNav(index: number) {
    if (navOpen === index) {
      setNavOpen(null);
      return;
    }
    setNavOpen(index);
  }

  return (
    <>
      <div key={id} className="flex w-full justify-between mt-5">
        <div className="flex w-full">
          {status === 'accepted' && (
            <>
              <span
                className={`${colours} mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full`}
                title={email}
              >
                <span
                  className={`${id}  font-medium leading-none text-neutral-100`}
                >
                  {emailFirstLetter.toUpperCase()}
                </span>
              </span>
              <div className={`mr-3 my-auto`}>
                <div className="text-neutral-80 !leading-8 text-xs ">
                  {email}
                </div>
              </div>
            </>
          )}
          {status === 'pending' && (
            <>
              <span
                className={`bg-neutral-40 mr-2 inline-flex h-8 w-8 items-center justify-center rounded-full`}
                title={email}
              >
                <span
                  className={`bg-neutral-80 font-medium leading-none text-neutral-100`}
                ></span>
              </span>
              <div className={`mr-3 my-auto`}>
                <div className="mt-[-0.125rem] text-sm text-neutral-80">
                  {email}
                </div>
                <div className="text-neutral-80 mt-1 text-xs ">Pending</div>
              </div>
              <div className="pt-2 absolute right-8">
                {loading && isInviteToRevoke ? (
                  <Spinner />
                ) : (
                  <span
                    onClick={() => handleNav(index)}
                    className="hover:cursor-pointer"
                  >
                    <MeatballIcon ariaLabel={'Remove invite'} />
                    {navOpen === index && (
                      <div
                        ref={meatballRef}
                        className="relative right-[12.5rem]"
                      >
                        <div
                          ref={navRef}
                          className="fixed bg-white py-[0.6875rem] px-[0.9375rem] w-[13.75rem] rounded-md border border-neutral-40 z-50"
                          style={{
                            boxShadow:
                              '0rem 0.25rem 1rem, -0.25rem rgba(0, 18, 51, 0.08)',
                          }}
                          data-cy="nav-popup"
                        >
                          <div
                            className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded"
                            onClick={() => email && revokePendingInvite(email)}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    )}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
