import { useContext, useEffect, useState } from 'react';
import { ReactComponent as CircleMinusIcon } from 'icons/generic/circle-minus.svg';
import {
  Condition,
  ConditionOperator,
  MetricCondition,
  Rule,
  ValueCondition,
} from 'shared/legacy-types';
import InlineOperatorToggle from 'components/InlineOperatorToggle';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import useCondition from 'pages/Rule/Steps/Conditions/useCondition';
import ConditionDropdown from 'pages/Rule/Steps/Conditions/Dropdowns/ConditionDropdown';
import { MARKETPLACE_FIELDS } from 'ui/constants/rules';
import ExpressionToggle from 'components/Toggles/ExpressionToggle';
import FieldDropdown from './Dropdowns/FieldDropdown/FieldDropdown';
import ConditionValue from './ConditionValue/ConditionValue';
import useExpressions from './Expressions/useExpressions';
import { RuleContext } from '../../RuleContainer';
import { REGULAR_INPUT, DROPDOWN_INPUT } from 'ui/constants/rules';

interface Props {
  rule: Omit<Rule, 'id'>;
  onChange: (rule: Omit<Rule, 'id'>) => void;
  condition: Condition;
  index: number;
  groupIndex: number;
  handleOperator: (newOperator: string) => void;
  operator: string;
}

export default function ConditionComponent({
  onChange,
  condition,
  index,
  rule,
  groupIndex,
  handleOperator,
  operator,
}: Props) {
  const isCampaignSpend = condition.field === 'campaigns:cost' || false;
  const { highlightInvalidFields } = useContext(RuleContext);

  const metricCondition = condition as MetricCondition;
  const valueCondition = condition as ValueCondition;
  const isValueCondition = condition.hasOwnProperty('value');

  const conditionValueMetric = isValueCondition
    ? valueCondition.value
    : metricCondition.metric;

  const isMarketplaceFieldValueType =
    MARKETPLACE_FIELDS.find((field) => field.value === conditionValueMetric)
      ?.value === conditionValueMetric;
  const isBudgetMetricType = metricCondition.metric === 'budget';

  const initialInputType =
    isMarketplaceFieldValueType || isBudgetMetricType
      ? DROPDOWN_INPUT
      : REGULAR_INPUT;

  const isEmptyValueField =
    (metricCondition.metric || valueCondition.value) === '';

  // condition value input type - when user has a choice
  const [inputType, setInputType] = useState<string>(initialInputType);
  const { stepIndex } = useContext(StepContext) || {};

  const {
    setToggleEnabled,
    updateToggle,
    toggleEnabled,
    showExpressionToggle,
  } = useExpressions(
    condition,
    rule,
    onChange,
    index,
    groupIndex,
    isCampaignSpend,
  );

  const {
    removeCondition,
    getOperators,
    handleInputChange,
    getInputType,
    updateFieldSelection,
    updateOperator,
    updateExpression,
  } = useCondition(
    onChange,
    rule,
    index,
    condition,
    groupIndex,
    setToggleEnabled,
    setInputType,
  );

  const currentStep = rule.steps[stepIndex];
  const currentConditionGroup =
    currentStep && currentStep.conditionGroups[groupIndex];
  const conditions =
    (currentConditionGroup && currentConditionGroup.conditions) || [];
  const showEditOperator = index === 1;
  const showDisabledOperator = !showEditOperator && index !== 0;
  const showOperator = showEditOperator || showDisabledOperator;
  const showRemoveButton =
    (conditions && conditions.length > 1) || groupIndex > 0;
  const currentDataType = rule.dataType;

  // update value type if initial value type gets updated
  // ie condition removed
  useEffect(() => {
    if (initialInputType) {
      setInputType(initialInputType);
    }
  }, [initialInputType]);

  function getPadding(): string {
    if (showExpressionToggle() && showRemoveButton) {
      return 'pr-[6.063rem]';
    }
    if (showExpressionToggle() && !showRemoveButton) {
      return 'pr-[3.688rem]';
    }

    if (!showExpressionToggle() && showRemoveButton) {
      return 'pr-[2.438rem]';
    }
    if (!showExpressionToggle() && !showRemoveButton) {
      return 'pr-0';
    }
    return '';
  }

  return (
    <div
      className={`relative my-1 flex justify-between ${getPadding()}`}
      key={index}
    >
      <div
        className={`border-neutral-40 flex w-full rounded border-b`}
        style={{ boxShadow: '0rem 0rem 0.25rem 0rem rgba(5, 26, 22, 0.08)' }}
      >
        {showEditOperator && (
          <InlineOperatorToggle
            operator={operator}
            operators={['&&', '||']}
            handleOperator={handleOperator}
          />
        )}
        {showDisabledOperator && (
          <InlineOperatorToggle operator={operator} disabled />
        )}
        <div
          className={`${toggleEnabled && showExpressionToggle() ? 'grow self-stretch' : 'w-1/3'}`}
        >
          <FieldDropdown
            label="Select field"
            currentDataType={currentDataType}
            selectedValue={condition.field}
            selectValue={(value) => updateFieldSelection(value)}
            selectedProfileIds={rule.profileIds}
            error={highlightInvalidFields && condition.field === ''}
          />
        </div>
        <div
          className={`${toggleEnabled && showExpressionToggle() ? 'w-2/12' : 'w-1/3'}`}
        >
          <ConditionDropdown
            label="Select condition"
            options={getOperators()}
            selectedValue={condition.operator}
            selectValue={(value) => updateOperator(value)}
            showOperator={showOperator}
            type="center"
            ariaLabel="Condition operators"
            error={
              highlightInvalidFields &&
              condition.operator === ('' as ConditionOperator)
            }
          />
        </div>
        <div
          className={`${toggleEnabled && showExpressionToggle() ? 'w-5/12' : 'w-1/3'} relative inline`}
        >
          <ConditionValue
            toggleEnabled={toggleEnabled}
            showExpressionToggle={showExpressionToggle}
            condition={condition}
            updateExpression={updateExpression}
            handleInputChange={handleInputChange}
            getInputType={getInputType}
            inputType={inputType}
            setInputType={setInputType}
            isCampaignSpend={isCampaignSpend}
            rule={rule}
            error={highlightInvalidFields && isEmptyValueField}
          />
        </div>
      </div>
      <div className={`absolute right-0 top-[0.563rem] flex h-6`}>
        {showExpressionToggle() && (
          <div className={`${showRemoveButton && 'mr-4'} my-auto bg-white`}>
            <ExpressionToggle enable={toggleEnabled} toggle={updateToggle} />
          </div>
        )}
        {showRemoveButton && (
          <div className="flex flex-col justify-center hover:cursor-pointer">
            <div onClick={() => removeCondition(conditions)}>
              <CircleMinusIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
