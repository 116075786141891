import {
  ConditionGroupOperator,
  ConditionOperator,
  RuleStatus,
  ExpressionMetric,
  ExpressionOperator,
  UpdateBudgetAction,
  DataType,
} from 'shared/legacy-types';
import { UPDATE_BUDGET } from 'ui/constants/actions';
import ArrowsDownImage from 'components/Images/Templates/ArrowsDownImage';
import { CAMPAIGNS, DEFAULT_PERIOD, SHORT_PERIOD } from 'ui/constants/rules';

export const optimiseCampaignBudgets = {
  id: 'optimise_campaign_budgets',
  description:
    'Adjust your campaign budgets every day according to an ACOS goal using one rule.',
  goal: 'Optimise spend',
  category: 'Advertising',
  featureToggle: false,
  comingSoon: false,
  image: ArrowsDownImage,
  rule: {
    name: 'Optimise campaign budgets',
    adTypes: ['SP'],
    dataType: CAMPAIGNS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'Profitable Campaigns',
        operator: '&&' as ConditionGroupOperator,
        period: SHORT_PERIOD,
        actions: [
          {
            type: UPDATE_BUDGET,
            properties: {
              metric: 'currentBudget',
              value: 20,
              buffer: 1,
            },
          } as UpdateBudgetAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${CAMPAIGNS}:averageDailyCost`,
                operator: '>=' as ConditionOperator,
                value: {
                  metric: 'budget' as ExpressionMetric,
                  operator: '*' as ExpressionOperator,
                  value: '0.8',
                },
              },
              {
                field: `${CAMPAIGNS}:acos`,
                operator: '<=' as ConditionOperator,
                value: '15',
              },
            ],
          },
        ],
      },
      {
        name: 'Unprofitable Campaigns',
        operator: '&&' as ConditionGroupOperator,
        period: DEFAULT_PERIOD,
        actions: [
          {
            type: UPDATE_BUDGET,
            properties: {
              metric: 'currentBudget',
              value: -20,
              buffer: 5,
            },
          } as UpdateBudgetAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${CAMPAIGNS}:acos`,
                operator: '>' as ConditionOperator,
                value: '30',
              },
              {
                field: `${CAMPAIGNS}:averageDailyCost`,
                operator: '>' as ConditionOperator,
                value: '10',
              },
            ],
          },
        ],
      },
    ],
  },
};
