import {
  ConditionGroupOperator,
  ConditionOperator,
  RuleStatus,
  DataType,
  ChangeStatusAction,
  EmailAction,
} from 'shared/legacy-types';
import { CHANGE_STATUS, SEND_EMAIL } from 'ui/constants/actions';
import { DEFAULT_PERIOD, PRODUCTS } from 'ui/constants/rules';
import SplitTriangleImage from 'components/Images/Templates/SplitTriangleImage';

export const pauseAdsHighDeliveryWindow = {
  id: 'pause_ads_high_delivery_window',
  description:
    'Pause an ad when the product is out of stock and ads are still delivering: products with incoming stock often show a high delivery window, which can lead to a decrease in conversion rate and therefore wasted ad spend.',
  goal: 'Reduce wasted spend',
  category: 'Advertising',
  featureToggle: true,
  comingSoon: true,
  image: SplitTriangleImage,
  rule: {
    name: 'Pause ads for ASINs with high delivery window',
    adTypes: ['SP', 'SD', 'SB'],
    dataType: PRODUCTS as DataType,
    profileIds: [],
    status: 'DRAFT' as RuleStatus,
    steps: [
      {
        name: 'High Delivery Window',
        operator: '&&' as ConditionGroupOperator,
        period: DEFAULT_PERIOD,
        actions: [
          {
            type: CHANGE_STATUS,
            properties: {
              entityType: 'ads',
              status: 'paused',
            },
          } as ChangeStatusAction,
          {
            type: SEND_EMAIL,
          } as EmailAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:delivery_days`,
                operator: '>' as ConditionOperator,
                value: '7',
              },
            ],
          },
        ],
      },
      {
        name: 'Short Delivery Window',
        operator: '&&' as ConditionGroupOperator,
        period: DEFAULT_PERIOD,
        actions: [
          {
            type: CHANGE_STATUS,
            properties: {
              entityType: 'ads',
              status: 'enabled',
            },
          } as ChangeStatusAction,
          {
            type: SEND_EMAIL,
          } as EmailAction,
        ],
        conditionGroups: [
          {
            operator: '&&' as ConditionGroupOperator,
            conditions: [
              {
                field: `${PRODUCTS}:delivery_days`,
                operator: '<=' as ConditionOperator,
                value: '7',
              },
            ],
          },
        ],
      },
    ],
  },
};
