import { useRef, useEffect } from 'react';
import DashboardLayout from 'layouts/DashboardLayout';
import RuleHistory from './RuleHistory';
import useHistoryContainer from './hooks/useHistoryContainer';
import * as Sentry from '@sentry/react';

export default function HistoryContainer(): JSX.Element {
  const {
    runConnection,
    rulesLoading,
    runsLoading,
    ruleFilter,
    selectRule,
    deSelectRule,
    getRuleOptions,
    runsError,
    rulesError,
    brandFilter,
    setBrandFilter,
    brandOptions,
    brandsError,
    brandsLoading,
    fetchMoreRuns,
    hasNextPage,
  } = useHistoryContainer();

  const runsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function fetchMoreRunsForLargeScreens(): Promise<void> {
      if (!runsRef.current) return;

      const runsDoNotFillScreen =
        runsRef.current.clientHeight < window.innerHeight;

      if (runsDoNotFillScreen && hasNextPage) {
        await fetchMoreRuns();
      }
    }
    //eslint-disable-next-line
    fetchMoreRunsForLargeScreens();
  }, [runsRef, runConnection]);

  if (runsError) {
    Sentry.captureMessage('Error fetching runs');
    return (
      <DashboardLayout>
        <div className="text-sm">
          Something went wrong, please try refreshing the page. If the error
          persists, please get in contact with support
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div ref={runsRef}>
        <h2 className={`font-bold mb-4`}>History</h2>
        <RuleHistory
          runConnection={runConnection}
          ruleOptions={getRuleOptions()}
          ruleFilter={ruleFilter}
          selectRule={selectRule}
          deSelectRule={deSelectRule}
          brandFilter={brandFilter}
          setBrandFilter={setBrandFilter}
          brandOptions={brandOptions}
          rulesLoading={rulesLoading}
          runsLoading={runsLoading}
          brandsLoading={brandsLoading}
          rulesError={rulesError?.message}
          brandsError={brandsError?.message}
          fetchMoreRuns={fetchMoreRuns}
          hasNextPage={hasNextPage}
        />
      </div>
    </DashboardLayout>
  );
}
