import { useContext } from 'react';
import ExpressionDropdown from 'pages/Rule/Steps/Conditions/Expressions/ExpressionDropdown';
import { MultiplyIcon } from 'components/Icons';
import { EXPRESSION_OPTIONS } from 'ui/constants/rules';
import {
  Condition,
  ConditionExpression,
  ExpressionTypes,
  ValueCondition,
} from 'shared/legacy-types';
import { fieldWithoutPrefix } from 'ui/helpers/rules/fields';
import { RuleContext } from 'pages/Rule/RuleContainer';

interface Props {
  condition: Condition;
  updateExpression: (value: string, type: ExpressionTypes) => void;
}

export default function ExpressionInput({
  condition,
  updateExpression,
}: Props) {
  const { highlightInvalidFields } = useContext(RuleContext);
  const field = fieldWithoutPrefix(condition.field);
  const expressionOptions =
    EXPRESSION_OPTIONS[field as keyof typeof EXPRESSION_OPTIONS];

  const valueCondition = condition as ValueCondition;
  const value = valueCondition.value as ConditionExpression;

  return (
    <div className="flex flex-row p-0.5 bg-secondary-40 rounded-r relative">
      <div className="self-stretch grow flex-col max-[970px]:w-[80px] max-[1150px]:w-[115px] min-[1150px]:w-[170px]">
        <ExpressionDropdown
          label="Select metric"
          options={expressionOptions}
          selectedValue={value.metric}
          selectValue={(value) => updateExpression(value, 'metric')}
        />
      </div>
      <span className="mx-0.5 mt-[10px] flex-col ">
        <MultiplyIcon />
      </span>
      <div
        className={`flex-col max-[970px]:w-[80px] max-[1150px]:w-[115px] min-[1150px]:w-[170px] self-stretch grow 
        ${highlightInvalidFields && !value.value && 'bg-white rounded'}
        `}
      >
        <input
          value={value.value}
          onChange={(event) => updateExpression(event.target.value, 'value')}
          placeholder="Enter value"
          className={`rounded h-[2.313rem] w-full my-0 pl-3 pr-[1.375rem] placeholder:text-neutral-80 text-sm placeholder:text-sm truncate focus:border-primary-100 focus:border-2 focus:outline-none focus:pl-[0.625rem] 
          ${
            highlightInvalidFields && !value.value
              ? 'bg-error bg-opacity-[.2] border border-error border-opacity-[.4] focus:bg-white pl-[0.688rem]'
              : 'border-0 border-l-0.5 border-neutral-40  bg-white'
          }
          `}
          aria-label="Expression value"
        />
      </div>
    </div>
  );
}
