interface Props {
  colour?: string;
}

export function GlassIcon({ colour = 'stroke-neutral-60' }: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7052_54178)">
        <circle
          cx="7.20841"
          cy="7.77734"
          r="5.29793"
          transform="rotate(-45 7.20841 7.77734)"
          className={colour}
          strokeWidth="1.333"
        />
        <line
          x1="10.401"
          y1="11.4018"
          x2="13.9577"
          y2="14.9585"
          className={colour}
          strokeWidth="1.333"
        />
      </g>
      <defs>
        <clipPath id="clip0_7052_54178">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
