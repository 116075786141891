import { InfoIcon } from 'components/Icons/';
import useSubscriptionRequests from 'api/hooks/requests/useSubscriptionRequests';
import { useState } from 'react';

export default function PortalLink() {
  const [loading, setLoading] = useState(false);

  const { createStripePortalSession } = useSubscriptionRequests();

  async function visitStripePortal() {
    if (loading) return;
    setLoading(true);
    const response = await createStripePortalSession();
    const url = response.headers['location'];
    window.location.replace(url);
    setLoading(false);
  }

  return (
    <div
      className={`${
        loading && 'animate-pulse'
      } bg-primary-40 border-primary-80 mb-3 mt-2 flex h-fit w-fit max-w-[22rem] rounded border px-[0.688rem] py-[0.4rem]`}
    >
      <span className="mr-1.5 ">
        <InfoIcon />
      </span>
      <div className="mt-[0.032rem] text-sm !leading-5 flex">
        <div>
          To update a payment method, view your billing or cancel your plan{' '}
          <span
            className="font-bold underline hover:cursor-pointer"
            onClick={visitStripePortal}
          >
            {' '}
            visit the Stripe portal
          </span>
        </div>
      </div>
    </div>
  );
}
