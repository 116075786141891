interface Props {
  height?: string;
  rounded?: string;
}

export default function ArrowsDownImage({
  height = '131',
  rounded = '',
}: Props) {
  return (
    <svg
      width="76"
      height={height}
      viewBox="0 0 76 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${rounded}`}
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_8869_75071)">
        <rect width="76" height="131" fill="#6E6AFF" />
        <g clip-path="url(#clip1_8869_75071)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M77.2556 28.0234L77.2556 65.6472L77.2556 103.271C64.7261 103.271 39.6318 73.7845 39.6318 65.6472C39.6318 57.3173 64.5628 28.0234 77.2556 28.0234Z"
            fill="#182841"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M39.6326 103.271L39.6326 65.6472L39.6326 28.0234C27.1031 28.0234 2.00879 57.5099 2.00879 65.6472C2.00879 73.9771 26.9398 103.271 39.6326 103.271Z"
            fill="#182841"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8869_75071">
          <rect width="76" height="131" fill="white" />
        </clipPath>
        <clipPath id="clip1_8869_75071">
          <rect
            width="76"
            height="76"
            fill="white"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 2 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
