import { useState, useEffect } from 'react';
import useUserSelector from 'state/user/useUserSelector';
import { UserInvite } from 'shared/legacy-types';
import { DEFAULT_USER_INVITE } from 'ui/constants/rules';
import { deepClone } from 'ui/helpers/deepClone';
import useShareWorkspaceRequests from 'api/hooks/requests/useShareWorkspaceRequests';
import useRequest from 'api/hooks/useRequest';
import * as Sentry from '@sentry/react';

export default function useShareWorkspaceModal() {
  const { user } = useUserSelector();
  const { sendUserInvite, revokeUserInvite } = useShareWorkspaceRequests();
  const { request } = useRequest();

  const copiedUserInvite = deepClone(DEFAULT_USER_INVITE);

  const [isValid, setIsValid] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<Partial<UserInvite>[]>([]);
  const [invitedUser, setInvitedUser] =
    useState<Partial<UserInvite>>(copiedUserInvite);
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);
  const [alreadyInvited, setAlreadyInvited] = useState<boolean>(false);
  const [inviteToRevoke, setInviteToRevoke] = useState<String>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInvites, setLoadingInvites] = useState<boolean>(false);

  const emailVerified = user?.emailVerified;

  useEffect(() => {
    async function fetchUser() {
      setLoadingInvites(true);
      const response = await request('get', '/v1/me');
      setLoadingInvites(false);
      setInvitedUsers(response.data.config.userInvites);
    }
    fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(
    //Checks if inputted email is vaild
    function validation() {
      setAlreadyInvited(false);
      // REGEX taken from type="email validation"
      const EMAIL_REGEX =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      invitedUser.email &&
        setIsValid(
          invitedUser.email.length > 1 && EMAIL_REGEX.test(invitedUser.email),
        );
    },
    // eslint-disable-next-line
    [invitedUser.email],
  );

  async function revokeInvite(inviteEmail: string) {
    const updatedInviteList = invitedUsers.filter(
      (invitee) => invitee.email !== inviteEmail,
    );
    try {
      setInviteToRevoke(inviteEmail);
      setLoading(true);
      await revokeUserInvite(inviteEmail);
      setInvitedUsers(updatedInviteList);
      setLoading(false);
      setInviteToRevoke('');
    } catch (error) {
      Sentry.captureException(error);
      setLoading(false);
      setInviteToRevoke('');
    }
  }

  async function inviteUser(invitedUser: Partial<UserInvite>) {
    const inviteSent =
      invitedUsers.find((invitee) => invitee.email === invitedUser.email) ||
      invitedUser.email === user?.email;
    if (inviteSent) {
      setAlreadyInvited(true);
      return;
    }
    setSendingInvite(true);
    try {
      const response =
        invitedUser.email && (await sendUserInvite(invitedUser.email));

      // Conflict, user already exists
      if (response && response.status === 409) {
        setSendingInvite(false);
        setAlreadyInvited(true);
        return;
      }
      setInvitedUsers([...invitedUsers, invitedUser]);
      setInvitedUser({ ...invitedUser, email: '' });
      setSendingInvite(false);
      setIsValid(false);
    } catch (error) {
      Sentry.captureException(error);
      setSendingInvite(false);
    }
  }
  return {
    invitedUsers,
    isValid,
    inviteUser,
    revokeInvite,
    alreadyInvited,
    sendingInvite,
    invitedUser,
    setInvitedUser,
    loading,
    inviteToRevoke,
    loadingInvites,
    emailVerified,
  };
}
