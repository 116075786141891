export function LockIconCircle() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#505F5C" />
      <g clip-path="url(#clip0_11650_2914)">
        <path
          d="M20.1024 15.0514H11.8976C10.9728 15.0514 10.224 15.8018 10.224 16.725V21.525C10.224 22.4498 10.9728 23.2002 11.8976 23.2002H20.1024C21.0272 23.2002 21.776 22.4498 21.776 21.525V16.725C21.776 15.8018 21.0272 15.0514 20.1024 15.0514ZM16.7648 19.4098V20.7746C16.7648 21.1954 16.424 21.5394 16 21.5394C15.576 21.5394 15.2352 21.1954 15.2352 20.7746V19.4098C14.8176 19.1538 14.5408 18.693 14.5408 18.1698C14.5408 17.365 15.1952 16.7122 16 16.7122C16.8048 16.7122 17.4592 17.365 17.4592 18.1698C17.4592 18.693 17.1824 19.1538 16.7648 19.4098Z"
          fill="#E6E8E8"
        />
        <path
          d="M13.8927 12.5811C13.8927 11.4195 14.8383 10.4755 15.9999 10.4755C17.1615 10.4755 18.1071 11.4195 18.1071 12.5811V14.5715H19.7807V12.5811C19.7807 10.4963 18.0847 8.80029 15.9999 8.80029C13.9151 8.80029 12.2191 10.4963 12.2191 12.5811V14.5715H13.8927V12.5811Z"
          fill="#E6E8E8"
        />
      </g>
      <defs>
        <clipPath id="clip0_11650_2914">
          <rect
            width="14.4"
            height="14.4"
            fill="white"
            transform="translate(8.79999 8.80005)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
