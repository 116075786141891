interface Props {
  fill?: string;
  stroke?: string;
}

export function NeutralCircle({
  fill = 'fill-[#FAFAFA]',
  stroke = 'stroke-[#505F5C]',
}: Props) {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="10" className={fill} />
        <line
          x1="4"
          y1="10"
          x2="16"
          y2="10"
          className={stroke}
          strokeOpacity="0.3"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
