import useUser from 'hooks/useUser';
import Dropdown from 'components/Dropdowns/Dropdown';
import { CampaignLink } from 'shared/legacy-types';
import SearchDropdown from 'components/Dropdowns/SearchDropdown/SearchDropdown';

interface Props {
  campaignLink: CampaignLink;
  updateProfileInfo: (value: string, key: string) => void;
  brand: string;
}

export default function ProfileSelection({
  campaignLink,
  updateProfileInfo,
  brand,
}: Props) {
  const { getBrandOptions, getMarketOptions } = useUser();

  return (
    <div className="flex">
      <div className="w-[13rem] mr-4">
        <div className="text-neutral-80 mb-1 text-xs">Brand</div>
        <SearchDropdown
          label="Select brand"
          selectValue={(value) => updateProfileInfo(value, 'brand')}
          selectedValue={brand}
          options={getBrandOptions()}
        />
      </div>
      <div className="w-[13rem]">
        <div className="text-neutral-80 mb-1 text-xs">Marketplace</div>
        <Dropdown
          label={'Select marketplace'}
          ariaLabel={'Select marketplace'}
          options={getMarketOptions(brand)}
          selectedValue={campaignLink.profileId?.toString()}
          selectValue={(value) => updateProfileInfo(value, 'profileId')}
          disabled={!brand}
        />
      </div>
    </div>
  );
}
