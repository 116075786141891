import { ChangeEvent, useEffect, useState } from 'react';
import { Rule, Profile, Optional, DataType } from 'shared/legacy-types';
import useUserSelector from 'state/user/useUserSelector';
import useRuleProfiles from 'hooks/rules/useRuleProfiles';
import {
  DATA_TYPE_OPTIONS,
  DATA_TYPE_OPTIONS_INTERNAL,
  DEFAULT_STEPS,
  SEARCH_TERMS,
  PRODUCT_SIGNALS,
  LONG_PERIOD,
} from 'ui/constants/rules';
import { ruleHasValues } from 'ui/helpers/rules/ruleChecks';
import { deepClone } from 'ui/helpers/deepClone';

export default function useScope(
  rule: Optional<Rule, 'id'>,
  onChange: (rule: Optional<Rule, 'id'>) => void,
  setSelectedBrandName: (name: string) => void,
  spendTargetCheck: (profileIds?: number[]) => void,
) {
  const [showResetAlert, setShowResetAlert] = useState<boolean>(false);
  const [newDataType, setNewDataType] = useState<DataType>('' as DataType);

  const { user, isInternalUser } = useUserSelector();
  const { selectedProfiles } = user || {};
  const profiles = selectedProfiles as Profile[];
  const { getRuleProfile } = useRuleProfiles();
  const profile = getRuleProfile(rule);

  useEffect(
    function initSelectedBrand() {
      if (rule?.profileIds && rule?.profileIds.length > 0 && profiles) {
        setSelectedBrandName(profile?.accountName!);
      }
    },
    [rule, setSelectedBrandName, profiles, profile],
  );

  function userDataTypes() {
    if (isInternalUser) {
      return DATA_TYPE_OPTIONS_INTERNAL;
    }
    return DATA_TYPE_OPTIONS;
  }

  function resetProductSignals(dataType: string) {
    // reset adTypes and period if product signals is selected
    if (dataType === PRODUCT_SIGNALS) {
      rule.adTypes = [];
      const firstStep = rule.steps[0];

      if (firstStep) {
        firstStep.period = '';
      }
      rule.profileIds = [];
      setSelectedBrandName('');
    }
  }

  function resetSearchterms(dataType: string) {
    // remove sd adType if search terms is selected
    // search terms only has 30 days period

    if (dataType === SEARCH_TERMS) {
      rule.adTypes = rule.adTypes.filter((adType) => adType !== 'SD');
      const firstStep = rule.steps[0];

      if (firstStep) {
        firstStep.period = LONG_PERIOD;
      }
    }
  }

  function selectDataType(event: ChangeEvent<HTMLInputElement>) {
    // either show reset alert or continue
    if (ruleHasValues(rule)) {
      setShowResetAlert(true);
      setNewDataType(event.target.value as DataType);
      return;
    }

    rule.dataType = event.target.value as DataType;
    rule.steps = deepClone(DEFAULT_STEPS);
    const firstStep = rule.steps[0];

    if (rule.dataType === SEARCH_TERMS && firstStep) {
      firstStep.period = LONG_PERIOD;
    }

    rule.sellingPartnerId = '';
    rule.spApiMarketplaceId = '';
    rule.asins = [];
    resetProductSignals(event.target.value);
    resetSearchterms(event.target.value);

    onChange({ ...rule });
  }

  function resetSteps() {
    setShowResetAlert(false);
    rule.steps = deepClone(DEFAULT_STEPS);
    rule.dataType = newDataType as DataType;

    rule.asins = [];
    rule.sellingPartnerId = '';
    rule.spApiMarketplaceId = '';
    resetProductSignals(rule.dataType);
    resetSearchterms(rule.dataType);
    onChange({ ...rule });
  }

  function selectAdType(event: ChangeEvent<HTMLInputElement>) {
    const index = rule?.adTypes?.indexOf(event.target.value);

    if (index! >= 0) {
      rule.adTypes?.splice(index!, 1);
    } else {
      rule.adTypes?.push(event.target.value);
    }

    onChange({ ...rule });
  }

  function selectBrand(event: ChangeEvent<HTMLInputElement>) {
    rule.profileIds = [];
    const profile = profiles?.find(
      (profile) => profile.accountName === event.target.value,
    );

    onChange({ ...rule });

    setSelectedBrandName(profile?.accountName!);
  }

  function deSelectBrand() {
    rule.profileIds = [];
    onChange({ ...rule });
    setSelectedBrandName('');
  }

  function selectMarketplace(event: ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    const { value } = target;

    const copiedRule = { ...rule };

    const alreadyExists = copiedRule?.profileIds?.find((id) => id === +value);

    // if already selected, deselect
    if (alreadyExists) {
      copiedRule.profileIds = copiedRule?.profileIds?.filter(
        (id) => id !== +value,
      );
      onChange({ ...copiedRule });
      spendTargetCheck();
      return;
    }

    const profile = profiles.find((profile) => profile.id === +value);

    copiedRule.profileIds = [...copiedRule.profileIds!, profile?.id!];

    onChange({ ...copiedRule });
    spendTargetCheck([...copiedRule.profileIds!, profile?.id!]);
  }

  const selectedMarketplaces = () => {
    if (
      !rule?.profileIds ||
      (rule?.profileIds && rule?.profileIds.length === 0)
    ) {
      return null;
    }

    const filteredProfiles = rule?.profileIds.filter((id) => {
      return id;
    });

    return filteredProfiles.map((id) => id.toString());
  };

  return {
    selectAdType,
    selectBrand,
    selectMarketplace,
    deSelectBrand,
    selectedMarketplaces,
    selectDataType,
    userDataTypes,
    showResetAlert,
    setShowResetAlert,
    resetSteps,
  };
}
