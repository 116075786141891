import { useContext } from 'react';

import ProfileSetupComponent from 'pages/Onboarding/Profile/ProfileSetupComponent';
import BrandComponent from 'pages/Onboarding/Profile/BrandComponent';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import BrandError from 'components/Errors/BrandError';
import useProfileSetup from './useProfileSetup';
import useBrandSetup from 'hooks/setup/useBrandSetup';
import { SetupContext } from '../Setup';

export default function ProfileSetupContainer() {
  const { profiles } = useContext(SetupContext) || {};

  const {
    selectedBrands,
    selectedMarketplaces,
    setSelectedBrands,
    brandProfiles,
    error,
    setDisableButton,
    disableBrandContinue,
    loading,
    atProfileLimit,
    userProfileLimit,
    setBrands,
  } = useProfileSetup(profiles);

  const { selectBrand, selectMarketplace } = useBrandSetup(
    setSelectedBrands,
    selectedBrands,
    error,
    setDisableButton,
    userProfileLimit,
  );

  if (loading) {
    return (
      <div className="h-screen flex">
        <div className="m-auto mt-80">
          <LargeSpinner />
        </div>
      </div>
    );
  }

  return (
    <ProfileSetupComponent
      disableContinue={disableBrandContinue}
      setBrand={setBrands}
      title="Choose a brand"
      selectedMarketplaces={selectedMarketplaces}
      atLimit={atProfileLimit}
      userLimit={userProfileLimit!}
    >
      <>
        {error && (
          <div className="mt-[1.5rem] mb-8">
            <BrandError error={error} brandProfiles={brandProfiles} />
          </div>
        )}
        <BrandComponent
          brandProfiles={brandProfiles}
          profiles={profiles}
          selectedBrands={selectedBrands}
          atLimit={atProfileLimit}
          selectMarketplace={selectMarketplace}
          selectBrand={selectBrand}
          error={error}
        />
      </>
    </ProfileSetupComponent>
  );
}
