import { Link } from 'react-router-dom';
import { useState } from 'react';

import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import SellerAccount from './SellerAccount';
import ChoosePlatformModal from './ChoosePlatformModal';
import useUserSelector from '../../../state/user/useUserSelector';

export default function SellingPartnerAccounts() {
  const { userConfig } = useUserSelector();
  const { spApiAccounts } = userConfig || {};
  const [openModal, setOpenModal] = useState(false);

  const noAccounts = false;
  const isStarterPlan = false;

  function renderAccounts() {
    if (noAccounts) {
      return (
        <div className="text-sm text-neutral-80 font-medium">
          No accounts connected
        </div>
      );
    }

    // loop over connected seller partner accounts - from user?
    return (
      spApiAccounts &&
      spApiAccounts.map((account, index) => {
        return (
          <SellerAccount
            index={index}
            account={{ ...account, asinCount: 0 }}
            accountsCount={spApiAccounts.length}
          />
        );
      })
    );
  }

  function renderButton() {
    if (isStarterPlan) {
      return (
        <Link
          to="https://www.meetfern.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SecondaryButton>
            <>Upgrade to add more</>
          </SecondaryButton>
        </Link>
      );
    }

    return (
      <div onClick={() => setOpenModal(true)}>
        <PrimaryButton>
          <>Add account</>
        </PrimaryButton>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-3 flex justify-between">
        <h5 className="font-medium leading-[2.5625rem] text-neutral-100">
          Connected marketplaces
        </h5>
        {renderButton()}
      </div>
      {renderAccounts()}
      <ChoosePlatformModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
}
