import { DEFAULT_GROUP, DEFAULT_STEP, SEARCH_TERMS } from 'ui/constants/rules';
import { Rule } from 'shared/legacy-types';
import { deepClone } from 'ui/helpers/deepClone';

const SEARCH_TERMS_DEFAULT_PERIOD = '30';

export default function useSteps(
  rule: Omit<Rule, 'id'>,
  onChange: (rule: Omit<Rule, 'id'>) => void,
) {
  const defaultStep = deepClone(DEFAULT_STEP);
  const defaultGroup = deepClone(DEFAULT_GROUP);

  function addStep() {
    rule.steps = rule.steps.concat(defaultStep);
    const stepIndex = rule.steps.length - 1;
    const step = rule.steps[stepIndex];

    if (rule.dataType === SEARCH_TERMS) {
      if (!step) {
        throw new Error(
          'No step found, so cannot update search terms default period',
        );
      }

      step.period = SEARCH_TERMS_DEFAULT_PERIOD;
    }
    onChange({ ...rule });
  }

  function addNewGroup(stepIndex: number) {
    const step = rule.steps[stepIndex];

    if (!step) {
      throw new Error('No step found, so cannot add new group');
    }

    step.conditionGroups = step.conditionGroups.concat({ ...defaultGroup });

    onChange({ ...rule });
  }

  function removeStep(stepIndex: number) {
    rule.steps.splice(stepIndex!, 1);
    onChange({ ...rule });
  }

  function updatePeriod(value: string, stepIndex: number) {
    const step = rule.steps[stepIndex];

    if (!step) {
      throw new Error('No step found, so cannot update period');
    }

    step.period = value;

    onChange({ ...rule });
  }

  function updateStepName(value: string, stepIndex: number) {
    const step = rule.steps[stepIndex];

    if (!step) {
      throw new Error('No step found, so cannot update step name');
    }

    if (!value) {
      step.name = null;
    } else {
      step.name = value;
    }

    onChange({ ...rule });
  }

  return { addStep, addNewGroup, removeStep, updatePeriod, updateStepName };
}
