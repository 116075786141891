import { useAppDispatch } from 'state/hooks';
import { updateUser, UserState, updateUserStatus } from 'state/user/userSlice';

export default function useUserDispatch() {
  const dispatch = useAppDispatch();

  return {
    updateUser: (user: UserState) => dispatch(updateUser(user)),
    updateUserStatus: (status: string) => dispatch(updateUserStatus(status)),
  };
}
