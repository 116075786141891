export function ListIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 13.75L13.5 13.75"
        className="stroke-neutral-100"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="4.25" cy="13.75" r="1.25" className="stroke-neutral-100" />
      <path
        d="M7.5 8.5L13.5 8.5"
        className="stroke-neutral-100"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="4.25" cy="8.5" r="1.25" className="stroke-neutral-100" />
      <path
        d="M7.5 3.75L13.5 3.75"
        className="stroke-neutral-100"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="4.25" cy="3.75" r="1.25" className="stroke-neutral-100" />
    </svg>
  );
}
