export function SharedWorkspaceIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#505F5C" />
      <path
        d="M20.824 10.5584L16.8828 22.3819L14.0364 17.3459L9.00046 14.4995L20.824 10.5584Z"
        fill="#E6E8E8"
      />
      <path
        d="M17.5397 13.8424L14.2554 18.4404L13.489 17.8931L12.9416 17.1267L17.5397 13.8424Z"
        fill="#505F5C"
      />
    </svg>
  );
}
