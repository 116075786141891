import DetailChart from 'pages/Reporting/Chart/DetailChart';
import { CampaignMetrics, ChartData } from 'shared/legacy-types';
import GridTable from 'pages/Reporting/GridTable/GridTable';
import { CAMPAIGN_ADVERTISING_TABLE_HEADERS } from 'pages/Reporting/GridTable/tableHeaders';

type Props = {
  campaignSummary: CampaignMetrics | undefined;
  currencyCode: string;
  loadingSummary: boolean;
  chartData: ChartData[];
  campaignMetricsLoading: boolean;
};

export default function CampaignDetail({
  campaignSummary,
  currencyCode,
  loadingSummary,
  chartData,
  campaignMetricsLoading,
}: Props): JSX.Element {
  const results = [];
  if (campaignSummary != null) {
    results.push(campaignSummary);
  }

  return (
    <div>
      <div className="mt-4 w-full h-full">
        <DetailChart
          data={chartData}
          currencyCode={currencyCode}
          loading={campaignMetricsLoading}
        />
      </div>
      <div className="mt-6">
        <h4 className="font-medium">Advertising</h4>
        <div className="mt-4 h-[6.25rem]">
          <GridTable
            results={results}
            currencyCode={currencyCode}
            tableHeaders={CAMPAIGN_ADVERTISING_TABLE_HEADERS}
            showPagination={false}
            showToolbar={false}
            loading={loadingSummary}
            enableCharts={false}
            gridId="campaign-advertising-table"
          />
        </div>
      </div>
    </div>
  );
}
