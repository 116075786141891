import { ChevronRight, LeftChevronIcon } from 'components/Icons';

interface Props {
  customHeaderCount: number;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  monthDate: Date;
}

export default function Header({
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  monthDate,
}: Props) {
  return (
    <div className="mt-4">
      <button
        aria-label="Previous Month"
        className={
          'react-datepicker__navigation react-datepicker__navigation--previous mt-4'
        }
        style={customHeaderCount === 1 ? { visibility: 'hidden' } : {}}
        onClick={decreaseMonth}
      >
        <LeftChevronIcon />
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', {
          month: 'short',
          year: 'numeric',
        })}
      </span>
      <button
        aria-label="Next Month"
        className={
          'react-datepicker__navigation react-datepicker__navigation--next mt-4'
        }
        style={customHeaderCount === 0 ? { visibility: 'hidden' } : {}}
        onClick={increaseMonth}
      >
        <ChevronRight colour="stroke-neutral-80" />
      </button>
    </div>
  );
}
