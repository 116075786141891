import { useState, useEffect } from 'react';
import useUser from 'hooks/useUser';
import { format } from 'date-fns';
import useReportingRequests from 'api/hooks/requests/useReportingRequests';
import {
  ReportingProfileData,
  ReportingProfileTotals,
} from 'shared/legacy-types';
import useFilters from './useFilters';
import * as Sentry from '@sentry/react';

export default function useReporting(
  startDate: Date | null,
  endDate: Date | null,
) {
  const { getMarketOptions, getProfileData, userProfiles } = useUser();

  const [reportingProfileData, setReportingProfileData] = useState<
    ReportingProfileData[]
  >([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [profileTotals, setProfileTotals] = useState<ReportingProfileTotals>(
    {} as ReportingProfileTotals,
  );
  const [totalsLoading, setTotalsLoading] = useState<boolean>(false);

  const { getProfileTimeSeriesMetrics, getProfileReportingTotals } =
    useReportingRequests();

  const formattedStartDate = startDate
    ? format(startDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');
  const formattedEndDate = endDate
    ? format(endDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  // Organise filters
  const {
    marketplaceFilter,
    brandFilter,
    marketplaceOptions,
    brandOptions,
    updateBrandFilter,
    setMarketplaceFilter,
  } = useFilters();

  const profileData = getProfileData(parseInt(marketplaceFilter));
  const currencyCode = profileData && profileData.currencyCode;

  useEffect(() => {
    async function getProfileData() {
      setDataLoading(true);
      try {
        const response = await getProfileTimeSeriesMetrics(
          marketplaceFilter,
          formattedStartDate,
          formattedEndDate,
        );
        setReportingProfileData(response.data);
        setDataLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        setDataLoading(false);
      }
    }
    formattedStartDate &&
      formattedEndDate &&
      marketplaceFilter &&
      getProfileData();
    // eslint-disable-next-line
  }, [marketplaceFilter, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    async function getProfileTotals() {
      try {
        setTotalsLoading(true);
        const response = await getProfileReportingTotals(
          marketplaceFilter,
          formattedStartDate,
          formattedEndDate,
        );
        setTotalsLoading(false);
        setProfileTotals({ ...response.data, acos: response.data.acos || 0 });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    formattedStartDate &&
      formattedEndDate &&
      marketplaceFilter &&
      getProfileTotals();

    // eslint-disable-next-line
  }, [marketplaceFilter, formattedStartDate, formattedEndDate]);

  return {
    brandFilter,
    updateBrandFilter,
    marketplaceFilter,
    setMarketplaceFilter,
    getMarketOptions,
    getProfileData,
    userProfiles,
    marketplaceOptions,
    brandOptions,
    reportingProfileData,
    dataLoading,
    currencyCode,
    profileTotals,
    totalsLoading,
  };
}
