import { Link } from 'react-router-dom';
import { useState, useRef, useContext } from 'react';
import { FolderIcon, MeatballIcon } from 'components/Icons/';
import useClickOutside from 'ui/hooks/useClickOutside';
import { Folder } from 'shared/legacy-types';
import { RulesPageContext } from '../Rules/RulesPage';

interface Props {
  isSelected: boolean;
  folder: Folder;
}

export default function FolderTab({ isSelected, folder }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const navRef = useRef(null);
  const meatballRef = useRef(null);
  const { openRemoveModal, openEditModal } = useContext(RulesPageContext);

  useClickOutside(setDropdownOpen, navRef, meatballRef);

  function openDeleteModal() {
    setDropdownOpen(false);
    openRemoveModal(true);
  }

  function openUpdateModal() {
    setDropdownOpen(false);
    openEditModal(true);
  }

  return (
    <div className="relative">
      <div key={folder.id}>
        <Link to={`/${folder.id}`}>
          <div
            className={`w-full h-[2.75rem] px-4 py-[0.844rem] text-sm flex justify-between hover:cursor-pointer relative group ${
              isSelected && 'bg-primary-100 text-white font-bold'
            }`}
          >
            <div className="flex w-content">
              <div className="mr-3">
                <FolderIcon
                  fill={isSelected ? 'fill-white' : 'fill-primary-80'}
                  stroke={isSelected ? 'stroke-white' : 'stroke-primary-80'}
                />
              </div>
              <span className="truncate max-w-[10rem]">{folder.name}</span>
            </div>
            <div
              aria-label={`${folder.name} options`}
              onClick={() => setDropdownOpen(true)}
            >
              <div
                className={`group-hover:visible invisible`}
                ref={meatballRef}
              >
                <MeatballIcon
                  stroke={
                    isSelected ? 'stroke-white ml-1' : 'stroke-neutral-80'
                  }
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
      {dropdownOpen && (
        <div
          ref={navRef}
          className="absolute bg-white py-[0.6875rem] px-[0.9375rem] w-[13.75rem] rounded-md top-[2.1875rem] right-0  border border-neutral-40 z-50"
          style={{
            boxShadow: '0rem 0.25rem 1rem, -0.25rem rgba(0, 18, 51, 0.08)',
          }}
        >
          <div
            className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded"
            onClick={() => openUpdateModal()}
          >
            Rename
          </div>
          <div
            className="p-3 text-sm text-neutral-80 font-bold hover:cursor-pointer hover:bg-neutral-20 rounded"
            onClick={() => openDeleteModal()}
          >
            Remove folder
          </div>
        </div>
      )}
    </div>
  );
}
