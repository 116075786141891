import { SEND_EMAIL, HARVEST_KEYWORDS } from '../../../constants/actions';
import { Action } from 'shared/legacy-types';

import { TickCircle, AlertIcon, InfoIcon } from 'components/Icons/';

interface Props {
  actionTimestamp?: string;
  isLive?: boolean;
  incompleteData?: boolean;
  currentAction?: Action;
}

export default function Notices({
  actionTimestamp,
  isLive,
  currentAction,
  incompleteData,
}: Props) {
  return (
    <>
      {!actionTimestamp && !isLive && (
        <div className="bg-primary-40 border-primary-80 mb-3 mt-2 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]">
          <span className="mr-1.5 ">
            <InfoIcon />
          </span>
          <span className="mt-[0.032rem] text-sm !leading-5">
            This is how the rule would be applied if it were live
          </span>
        </div>
      )}
      {currentAction &&
        currentAction.type === HARVEST_KEYWORDS &&
        !actionTimestamp && (
          <div className="bg-primary-40 border-primary-80 mb-3 mt-2 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]">
            <span className="mr-1.5 ">
              <InfoIcon />
            </span>
            <span className="mt-[0.032rem] text-sm !leading-5">
              This is a preview of the results
            </span>
          </div>
        )}
      {currentAction &&
        currentAction.type === SEND_EMAIL &&
        actionTimestamp && (
          <div className="bg-primary-40 border-primary-80 my-4 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]">
            <span className="mr-1.5 mt-0.5">
              <TickCircle
                iconColour="stroke-white"
                background="fill-primary-100"
              />
            </span>
            <span className="mt-[0.032rem] text-sm !leading-5">
              Email alert was sent on {actionTimestamp}
            </span>
          </div>
        )}
      {incompleteData && (
        <div className="relative mx-auto my-2.5 mb-4 max-h-fit rounded bg-[#FFF2F2]">
          <div className="relative mx-auto flex px-3 py-2 ">
            <span className="m-auto">
              <AlertIcon />
            </span>
            <span className="mt-0.5 w-max pl-1.5 text-sm font-thin leading-[1.05rem] text-[#001233]">
              We're still downloading your data. Some results may be missing in
              the table below. Please try reloading the page in a few minutes.
            </span>
          </div>
        </div>
      )}
    </>
  );
}
