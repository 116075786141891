import { Radio } from 'components/Radio/Radio';

interface Props {
  selectPlatform: (checked: boolean, platform: string) => void;
  selectedPlatform: string | null;
  type: string;
  description: string;
  name: string;
}

export default function PlatformRadio({
  selectPlatform,
  selectedPlatform,
  type,
  description,
  name,
}: Props) {
  const selected = selectedPlatform === type;
  return (
    <div
      className={`w-[32.75rem] flex justify-between p-6 border border-neutral-40 rounded-xl ${
        selected && 'border-primary-100 border-2 p-[23px] '
      }`}
    >
      <div>
        <h4 className="text-left">
          I'm a <span className="font-bold">{name}</span>
        </h4>
        <div className="text-neutral-80 mt-1">{description}</div>
      </div>
      <div className="my-auto">
        <Radio
          id={type}
          select={(event: React.ChangeEvent<HTMLInputElement>) =>
            selectPlatform(event.target.checked, type)
          }
          checked={selected}
        />
      </div>
    </div>
  );
}
