import type { Blocker } from 'react-router-dom';

import Modal from 'components/Modals/Modal';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import Spinner from 'ui/Spinner/Spinner';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  blocker: Blocker;
  save: (callback: () => void) => void;
  loading: boolean;
  title: string;
  continueFunction?: () => void;
};

export default function UnsavedChangesModal({
  open,
  setOpen,
  blocker,
  save,
  loading,
  title,
  continueFunction,
}: Props): JSX.Element {
  function cancel(): void {
    blocker.reset?.();
    setOpen(false);
  }

  function continueNavigation(): void {
    if (continueFunction) {
      setOpen(false);
      continueFunction();
    }
    window.onbeforeunload = () => null;
    blocker.proceed?.();
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="max-w-[25rem] bg-white p-6">
        <div className="flex justify-between">
          <h5 className="font-bold">Unsaved changes</h5>
          <div
            className="hover:cursor-pointer"
            onClick={() => {
              cancel();
            }}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="text-neutral-80 mt-1">{title}</div>
        <div className="flex justify-end bg-gray-50 pt-4">
          <div className="mr-4" data-testid="unsaved-changes-discard-button">
            <SecondaryButton
              onClick={() => {
                continueNavigation();
              }}
            >
              <>Discard</>
            </SecondaryButton>
          </div>
          <div data-testid="unsaved-changes-save-button">
            <PrimaryButton
              onClick={() => {
                save(continueNavigation);
              }}
            >
              {loading ? <Spinner /> : <>Save</>}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
