export function ExclamationCircle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#FF6767" />
      <path
        d="M8 4L8 9"
        stroke="white"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.8359H8.00833"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
