interface Props {
  fill?: string;
  stroke?: string;
}

export function ExpressionIcon({
  fill = 'fill-neutral-80',
  stroke = 'stroke-neutral-80',
}: Props) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32933 12.8078L10.752 11.2755L9.889 10.3445C9.67967 10.1215 9.53867 9.96983 9.46533 9.88983C9.39567 9.80583 9.34167 9.72416 9.30333 9.64383C9.265 9.56016 9.24567 9.46616 9.24567 9.3615C9.24567 9.1105 9.32767 8.90283 9.49167 8.73916C9.65533 8.57516 9.84 8.49316 10.046 8.49316C10.2447 8.49316 10.4013 8.52116 10.5167 8.57683C10.6317 8.63283 10.75 8.74083 10.8723 8.90116L11.7613 10.0152L12.6503 8.90116C12.7793 8.74083 12.8997 8.63283 13.0113 8.57683C13.1227 8.52116 13.278 8.49316 13.4767 8.49316C13.6823 8.49316 13.867 8.5735 14.031 8.73383C14.195 8.89416 14.2767 9.1035 14.2767 9.3615C14.2767 9.46616 14.2577 9.56016 14.2193 9.64383C14.181 9.72416 14.125 9.80583 14.052 9.88983C13.9823 9.96983 13.8427 10.1215 13.6337 10.3445L12.7707 11.2755L14.193 12.8078C14.2837 12.9055 14.3603 13.0118 14.4233 13.1268C14.4893 13.2385 14.5227 13.3708 14.5227 13.5245C14.5227 13.7788 14.4407 13.9845 14.2767 14.1415C14.113 14.2948 13.9107 14.3715 13.67 14.3715C13.5133 14.3715 13.3843 14.3385 13.283 14.2722C13.1857 14.2062 13.067 14.0962 12.9273 13.9428L11.7613 12.5622L10.595 13.9428C10.473 14.0822 10.358 14.1885 10.2497 14.2618C10.1417 14.3352 10.0093 14.3715 9.85233 14.3715C9.61167 14.3715 9.40967 14.2948 9.24567 14.1415C9.082 13.9845 9 13.7788 9 13.5245C9 13.3745 9.02767 13.2472 9.08367 13.1425C9.143 13.0382 9.22467 12.9265 9.32933 12.8078Z"
        className={fill}
      />
      <path
        d="M7.23196 5.29004C3.35096 6.39004 5.55229 14.4277 1.89063 15.5027"
        className={stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.22656 9.37207L7.69456 9.37207"
        className={stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
