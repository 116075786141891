import { useRef, useContext } from 'react';
import useClickOutside from 'ui/hooks/useClickOutside';
import { RuleContext } from 'pages/Rule/RuleContainer';
import Chevrons from './Chevrons';
import useFieldDropdown from './useFieldDropdown';
import Label from './Label';
import DropdownOptions from './DropdownOptions';

interface Props {
  label?: string;
  selectedValue: string | undefined;
  selectValue: (value: string) => void;
  currentDataType: string | undefined;
  selectedProfileIds: number[];
  error: boolean;
}

export default function FieldDropdown({
  label,
  selectedValue,
  selectValue,
  currentDataType,
  selectedProfileIds,
  error,
}: Props) {
  const {
    open,
    setOpen,
    openHeadings,
    setOpenHeadings,
    disabledTooltipOpen,
    setDisabledTooltipOpen,
    options,
    ruleHasSpendTargets,
    select,
    dropdownStyles,
  } = useFieldDropdown(currentDataType, selectedProfileIds, selectValue, error);

  const ref = useRef(null);
  const dropdownRef = useRef(null);
  const { openCreateSpendTargetModal } = useContext(RuleContext) || {};

  useClickOutside(setOpen, ref, dropdownRef);

  function renderDropdownMenu() {
    return (
      <div
        aria-label="Condition field dropdown list"
        className="absolute !z-50 mt-1  w-80 rounded-xl bg-white "
        style={{ boxShadow: '0rem 0.5rem 1rem -0.625rem rgba(0, 0, 0, 0.08)' }}
      >
        <div
          ref={ref}
          className="border-neutral-40 scrollbar-hide max-h-[25.25rem] overflow-y-auto rounded-xl border px-4 py-6 text-left text-sm  hover:cursor-pointer"
        >
          <DropdownOptions
            selectedValue={selectedValue}
            ruleHasSpendTargets={ruleHasSpendTargets}
            select={select}
            options={options}
            openHeadings={openHeadings}
            setOpenHeadings={setOpenHeadings}
            disabledTooltipOpen={disabledTooltipOpen}
            setDisabledTooltipOpen={setDisabledTooltipOpen}
            openCreateSpendTargetModal={openCreateSpendTargetModal}
          />
        </div>
      </div>
    );
  }

  return (
    <div aria-label="Condition fields">
      <div
        ref={dropdownRef}
        className="text-left text-sm text-neutral-100 hover:cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div tabIndex={0} className={dropdownStyles}>
          <span className="my-auto mr-0.5 truncate">
            <Label
              label={label}
              selectedValue={selectedValue}
              ruleHasSpendTargets={ruleHasSpendTargets}
            />
          </span>
          <span
            className={`pointer-events-none relative mx-auto my-auto mr-4 justify-end`}
          >
            <Chevrons open={open} />
          </span>
        </div>
      </div>
      {open && Object.values(options).length > 0 && renderDropdownMenu()}
    </div>
  );
}
