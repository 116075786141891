import { getGroupOperator } from '../../helpers/steps';
import { RunStep } from 'shared/legacy-types';

import Condition from './Condition';

interface Props {
  step: RunStep;
}

export default function ConditionGroups({ step }: Props) {
  const renderConditionGroups = () => {
    const { conditionGroups, operator } = step;

    const stepGroupOperatorLabel = getGroupOperator(operator);

    return conditionGroups?.map((group, index) => {
      const { conditions, operator: groupOperator } = group;
      const isFirst = index === 0;

      const conditionsList = () => {
        return conditions.map((condition, index) => {
          const isLast = index + 1 === conditions.length;

          return (
            <Condition
              key={index}
              condition={condition}
              groupOperator={groupOperator}
              isLast={isLast}
              index={index}
            />
          );
        });
      };

      return (
        <div key={index}>
          <div className="text-neutral-80">
            {isFirst ? (
              <span className="font-bold">If</span>
            ) : (
              <span className="text-sm">{stepGroupOperatorLabel}</span>
            )}
          </div>
          <div className="border-primary-100 mb-2 mt-2 border-l-2 pl-6">
            {conditionsList()}
          </div>
        </div>
      );
    });
  };

  return <>{renderConditionGroups()}</>;
}
