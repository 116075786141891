import useRequest from 'api/hooks/useRequest';
import { CampaignLink } from 'shared/legacy-types';

export default function useCampaignLinksRequests() {
  const { request } = useRequest();

  const getCampaignLinksRequest = async () => {
    return await request('get', `/v1/campaign-links`, {});
  };

  const createCampaignLinkRequest = async (campaignLink: CampaignLink) => {
    return await request('post', `/v1/campaign-links`, campaignLink);
  };

  const getCampaignLinkRequest = async (profileId: string) => {
    return await request('get', `/v1/profiles/${profileId}/campaigns`, {});
  };

  const getCampaignLinkAdGroupsRequest = async (
    profileId: string,
    campaignId: string,
  ) => {
    return await request(
      'get',
      `/v1/profiles/${profileId}/campaigns/${campaignId}/ad-groups`,
      {},
    );
  };

  const updateCampaignLinkRequest = async (campaignLink: CampaignLink) => {
    return await request(
      'put',
      `/v1/campaign-links/${campaignLink.id}`,
      campaignLink,
    );
  };

  const deleteCampaignLinkRequest = async (campaignLink: CampaignLink) => {
    return await request('delete', `/v1/campaign-links/${campaignLink.id}`, {});
  };

  return {
    createCampaignLinkRequest,
    getCampaignLinksRequest,
    getCampaignLinkRequest,
    getCampaignLinkAdGroupsRequest,
    updateCampaignLinkRequest,
    deleteCampaignLinkRequest,
  };
}
